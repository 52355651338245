import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import reportWebVitals from './reportWebVitals';
import { store, StoreContext } from './app/Stores';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const history = createBrowserHistory();

ReactDOM.render(
  <StoreContext.Provider value={store}>
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      useEnterprise={true}>
      <Router history={history}>
        <App />
      </Router>
    </GoogleReCaptchaProvider>
  </StoreContext.Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

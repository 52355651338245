import { Content, Type } from '..';
import { v4 as uuid } from "uuid";
import { makeAutoObservable } from "mobx"

export class Header implements Content {
    id: string = uuid();
    name: string = "Header";
    type: Type = Type.header;
    index: number = 0;
    width: number = 12;
    fullScreen: boolean = false;
    backgroundImageId: string = "";
    heading: string = "Heading";
    subHeading: string = "SubHeading";
    headingColor: string = "#fff";
    subHeadingColor: string = "#fff";

    constructor() {
        makeAutoObservable(this)
    }
}

export interface HeaderUpdater {
    name?: string;
    width?: number;
    fullScreen?: boolean;
    backgroundImageId?: string;
    heading?: string;
    subHeading?: string;
    headingColor?: string;
    subHeadingColor?: string;
}
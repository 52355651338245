import {observer} from "mobx-react-lite";
import {useStore} from "../../../Stores";
import React from "react";
import {Box, Grid, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";

export const AdminAddTbcTokenModal = observer(() => {
    const {adminStore, modalStore} = useStore();
    const {createTbcToken} = adminStore;
    const [submitting, setSubmitting] = React.useState(false);
    const [tbcKeyError, setTbcKeyError] = React.useState(false);
    const [token1KeyError, setToken1KeyError] = React.useState(false);
    const [token2KeyError, setToken2KeyError] = React.useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const tbcKey = data.get('tbcKey')?.toString()
        const token1Key = data.get('token1Key')?.toString()
        const token2Key = data.get('token2Key')?.toString()

        setTbcKeyError(!tbcKey)
        setToken1KeyError(!token1Key)
        setToken2KeyError(!token2Key)

        if (tbcKey && token1Key && token2Key) {
            setSubmitting(true)
            createTbcToken(
                tbcKey,token1Key,token2Key
            ).then(() => {
                modalStore.closeModal()
            }).finally(() => {
                setSubmitting(false)
            })
        }
    }

    return (
        <Box component="form"  onSubmit={handleSubmit}>
            <Grid container direction="column">
                <Grid item>
                    <TextField
                        margin="normal"
                        error={tbcKeyError}
                        onChange={() => setTbcKeyError(false)}
                        required
                        fullWidth
                        id="tbcKey"
                        label="Key"
                        name="tbcKey"
                        autoComplete="tbcKey"
                        autoFocus
                    />
                </Grid>
                <Grid item>
                    <TextField
                        margin="normal"
                        error={token1KeyError}
                        onChange={() => setToken1KeyError(false)}
                        required
                        fullWidth
                        id="token1Key"
                        label="Token 1 Key"
                        name="token1Key"
                        autoComplete="token1Key"
                        autoFocus
                    />
                </Grid>
                <Grid item>
                    <TextField
                        margin="normal"
                        error={token2KeyError}
                        onChange={() => setToken2KeyError(false)}
                        required
                        fullWidth
                        id="token2Key"
                        label="Token 2 Key"
                        name="token2Key"
                        autoComplete="token2Key"
                        autoFocus
                    />
                </Grid>
                <LoadingButton
                    type="submit"
                    loading={submitting}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Add Tbc Token
                </LoadingButton>
            </Grid>
        </Box>
    )
})
import {observer} from "mobx-react-lite";
import {useStore} from "../../../Stores";
import {useEffect, useState} from "react";
import {Button, Grid, Paper, Typography} from "@mui/material";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {LoadingComponent} from "../../Util";
import {TokenBondingCurveToken} from "../../../Models/API/Spl";
import {AdminAddTbcTokenModal} from "./";

const columns: GridColDef[] = [
    { field: 'id', headerName: 'Key', width: 450 },
    {
        field: 'tokenAName',
        headerName: 'Token A',
        width: 100
    },
    {
        field: 'tokenATicker',
        headerName: 'Token A Ticker',
        width: 140
    },
    {
        field: 'tokenBName',
        headerName: 'Token B',
        width: 100
    },
    {
        field: 'tokenBTicker',
        headerName: 'Token B Ticker',
        width: 140
    },
];

const createData = (
        id: string,
        tokenAName: string,
        tokenATicker: string,
        tokenBName: string,
        tokenBTicker: string
) => {
    return { id, tokenAName, tokenATicker, tokenBName, tokenBTicker };
}

export const AdminTbcGrid = observer(() => {
    const { adminStore, modalStore } = useStore();
    const [refreshing, setRefreshing] = useState(false);
    const {getTbcTokens, tbcTokens, selectTbcToken,selectedTbcToken,deleteTbcToken} = adminStore;
    let data: TokenBondingCurveToken[] | undefined = tbcTokens;
    let rows = [];
    if (data) {
        for (let i = 0; i < data.length; i++) {
            rows.push(createData(data[i].key, data[i].tokens[0].name, data[i].tokens[0].ticker, data[i].tokens[1].name, data[i].tokens[1].ticker))
        }
    }
    const refreshTbcTokens = () => {
        if (refreshing) { return }
        setRefreshing(true)
        getTbcTokens().finally(() => {
            setRefreshing(false)
        })
    }
    useEffect(() => {
        refreshTbcTokens()
    }, [])
    if(rows && !refreshing) {
        return (

                <Grid container>
                    <Grid item component={Paper}>
                        <Typography variant="h4" border={1} borderColor="slategrey" align="center" padding={0.5}>
                            Token Bonding Curve Tokens
                        </Typography>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[5]}
                            sx={{ backgroundColor: "paper", width: 935 }}
                            autoPageSize
                            autoHeight
                            onSelectionModelChange={(ids) => {
                                if (data) {
                                    const selectedIDs = new Set(ids);
                                    const selectedRows = data.filter((row) => selectedIDs.has(row.key)
                                    );
                                    selectTbcToken(selectedRows[0]);
                                }
                            }}

                        />
                        <Button onClick={() => modalStore.openModal(<AdminAddTbcTokenModal/>)}>Add TBC Token</Button>
                        <Button color="error" onClick={() => deleteTbcToken(selectedTbcToken?.key)}>Delete TBC Token</Button>
                    </Grid>
                </Grid>

        )
    }
    else {
        return (
                <LoadingComponent />
        )
    }
})

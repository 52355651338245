import {Box, Grid, TextField} from "@mui/material";
import {observer} from "mobx-react-lite";
import {LoadingButton} from "@mui/lab";
import React from "react";
import {useStore} from "../../../Stores";

export const AdminCreateCollectionModal = observer(() => {
    const {adminStore, modalStore} = useStore();
    const {createCollection} = adminStore;
    const [submitting, setSubmitting] = React.useState(false);
    const [collectionNameError, setCollectionNameError] = React.useState(false);
    const [collectionTitleError, setCollectionTitleError] = React.useState(false);
    const [collectionApiError, setCollectionApiError] = React.useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const collectionName = data.get('collectionName')?.toString()
        const collectionTitle = data.get('collectionTitle')?.toString()
        const collectionApi = data.get('collectionApi')?.toString()

        setCollectionNameError(!collectionName)
        setCollectionTitleError(!collectionTitle)
        setCollectionApiError(!collectionApi)

        if (collectionName && collectionTitle && collectionApi) {
            setSubmitting(true)
            createCollection(
                    collectionName,collectionTitle,collectionApi
                )
                .then(() => {
                    modalStore.closeModal()
                })
                .finally(() => {
                    setSubmitting(false)
                })
        }
    }
    return (
        <Box component="form"  onSubmit={handleSubmit}>
            <Grid container direction="column">
                <Grid item>
                    <TextField
                        margin="normal"
                        error={collectionNameError}
                        onChange={() => setCollectionNameError(false)}
                        required
                        fullWidth
                        id="collectionName"
                        label="Name"
                        name="collectionName"
                        autoComplete="collectionName"
                        autoFocus

                    />
                </Grid>
                <Grid item>
                    <TextField
                        margin="normal"
                        error={collectionTitleError}
                        onChange={() => setCollectionTitleError(false)}
                        required
                        fullWidth
                        id="collectionTitle"
                        label="Title"
                        name="collectionTitle"
                        autoComplete="collectionTitle"
                        autoFocus
                    />
                </Grid>
                <Grid item>
                    <TextField
                        margin="normal"
                        error={collectionApiError}
                        onChange={() => setCollectionApiError(false)}
                        required
                        fullWidth
                        id="collectionApi"
                        label="Api"
                        name="collectionApi"
                        autoComplete="collectionApi"
                        autoFocus
                    />
                </Grid>
                <LoadingButton
                    type="submit"
                    loading={submitting}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Create Collection
                </LoadingButton>
            </Grid>
        </Box>

    )
})
import { makeAutoObservable } from "mobx";
import { Page, Footer, NavBar, Model, Type, Theme } from ".";

export class Site implements Model {
    id: string = "";
    name: string = "";
    description: string = "";
    type: Type = Type.site;
    index: number = 0;
    theme: Theme = new Theme();
    navBar: NavBar = new NavBar();
    pages: Page[] = [];
    footer: Footer = new Footer();

    constructor() {
        makeAutoObservable(this)
    }
}

export interface SiteUpdater {
    name?: string;
    description?: string;
}
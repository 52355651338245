import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    CircularProgress,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import {
    Collection,
    CollectionKey,
    CollectionKeyType,
} from "../../Models/API/Collections";
import { useStore } from "../../Stores";
import { CollectionSelector } from "./CollectionSelector";
import { ExpandMore, Star, StarOutline } from "@mui/icons-material";

export const CollectionsEditor = observer(() => {
    const { collectionStore, commonStore } = useStore();
    const { currentSite } = commonStore;
    const [collection, setCollection] = React.useState<Collection>();

    const collectionSelector = (() => {
        return (
            <>
                <Typography>Please select a collection</Typography>
                <CollectionSelector
                    initialCollectionId={collection ? collection.id : undefined}
                    onSelect={setCollection}
                />
            </>
        );
    })();

    const collectionData = (() => {
        if (collection) {
            return (
                <>
                    <Typography variant="h4">{collection.name}</Typography>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="keys-content"
                            id="keys-header"
                        >
                            <Typography>Keys</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <CollectionKeyManager
                                collectionId={collection.id}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="cards-content"
                            id="cards-header"
                        >
                            <Typography>Cards</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <CollectionDataManager
                                collectionId={collection.id}
                            />
                        </AccordionDetails>
                    </Accordion>
                </>
            );
        }
        return undefined;
    })();

    return (
        <Stack spacing={2}>
            {collectionSelector}
            {collectionData}
        </Stack>
    );
});

interface CollectionKeyManagerProps {
    collectionId: string;
}

const CollectionKeyManager = observer(
    ({ collectionId }: CollectionKeyManagerProps) => {
        const { collectionStore, commonStore } = useStore();
        const { currentSite } = commonStore;
        const keys = collectionStore.collectionKeys[collectionId];
        if (!keys) {
            collectionStore.getCollectionKeys(collectionId);
            return (
                <Box
                    display="flex"
                    flexDirection="row"
                    paddingX={1}
                    paddingY={2}
                >
                    <Typography
                        variant="body1"
                        color="primary.main"
                        sx={{ flex: 1 }}
                    >
                        Loading keys
                    </Typography>
                    <CircularProgress
                        sx={{ flex: "0 1 auto" }}
                        size={25}
                        color="primary"
                    />
                </Box>
            );
        }
        return (
            <Stack>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">
                                    <Star color="primary" />
                                </TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Type</TableCell>
                                <TableCell align="right">Minimum</TableCell>
                                <TableCell align="right">Maximum</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {keys.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell align="center">
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() => {
                                                collectionStore.makeKeyStarred(
                                                    row.id,
                                                    row.name,
                                                    !row.starred,
                                                    collectionId
                                                );
                                            }}
                                        >
                                            {row.starred ? (
                                                <Star color="primary" />
                                            ) : (
                                                <StarOutline />
                                            )}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.type}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.minimum ?? "-"}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.maximum ?? "-"}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        );
    }
);

const CollectionDataManager = observer(
    ({ collectionId }: CollectionKeyManagerProps) => {
        const { collectionStore } = useStore();
        const data = collectionStore.collectionData[collectionId];
        if (!data) {
            collectionStore.getCollectionData(collectionId);
            return (
                <Box
                    display="flex"
                    flexDirection="row"
                    paddingX={1}
                    paddingY={2}
                >
                    <Typography
                        variant="body1"
                        color="primary.main"
                        sx={{ flex: 1 }}
                    >
                        Loading collection data
                    </Typography>
                    <CircularProgress
                        sx={{ flex: "0 1 auto" }}
                        size={25}
                        color="primary"
                    />
                </Box>
            );
        }
        const keys = collectionStore.collectionKeys[collectionId];
        const columns: CollectionKey[] = (() => {
            if (keys) {
                return keys.filter((k) => k.starred);
            }
            return [
                {
                    id: "Id",
                    type: CollectionKeyType.string,
                    name: "Id",
                    minimum: undefined,
                    maximum: undefined,
                    starred: false,
                    allowedValues: [],
                },
                {
                    id: "Id",
                    type: CollectionKeyType.string,
                    name: "Name",
                    minimum: undefined,
                    maximum: undefined,
                    starred: false,
                    allowedValues: [],
                },
            ];
        })();
        return (
            <Stack>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                {columns.map((c) => (
                                    <TableCell>{c.name}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.cards.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    {columns.map((c) => (
                                        <TableCell>
                                            {
                                                row.details.find(
                                                    (r) => r.key === c.name
                                                )?.value
                                            }
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        );
    }
);

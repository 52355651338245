import { makeAutoObservable } from "mobx";
import { v4 as uuid } from "uuid";
import { PageCondition, Content, Model, Type } from "."

export class Page implements Model {
    id: string = uuid();
    name: string = "Page";
    type: Type = Type.page;
    index: number = 0;
    path: string = "/new";
    conditions: PageCondition = new PageCondition();
    elements: Content[] = []

    constructor() {
        makeAutoObservable(this)
    }
};

export interface PageUpdater {
    name?: string;
    path?: string;
};
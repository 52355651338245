import { observer } from "mobx-react-lite"
import { Button, Stack, TextField } from '@mui/material';

import { useStore } from "../../../Stores";
import { CMSEditButtons, CMSWidthField } from '..';
import { Image as ImageModel } from '../../../Models/Site';
import { ImageGalleryForm, TemplateKeyPicker } from "../../Forms";

interface Props {
    data: ImageModel;
    collectionId?: string; // indicates template mode
}

export const CMSImage = observer(({ data, collectionId }: Props) => {
    const { cmsStore, modalStore } = useStore();
    const { working_model } = cmsStore;
    let isEditing = cmsStore.editedItemId === data.id;
    let dataToUse: ImageModel = isEditing ? (working_model as ImageModel) : data

    const templateOptions = (() => {
        if (collectionId) {
            const currentKey = (dataToUse.imageId && dataToUse.imageId.startsWith('{{')) ? dataToUse.imageId : ''
            return (
                <Button
                    variant='outlined'
                    disabled={!isEditing}
                    onClick={() => {
                        modalStore.openModal(<TemplateKeyPicker currentKey={currentKey} collectionId={collectionId} onSelect={(key: string) => {
                            cmsStore.updateImage({ imageId: `{{${key}}}` })
                        }} />)
                    }}
                >
                    Select Image ID Parameter
                </Button>
            )
        }
        return undefined
    })();

    return (
        <Stack direction="column" spacing={2} justifyContent="space-between">
            <CMSEditButtons
                id={data.id}
                index={data.index}
            />
            <h5>ID: {data.id}</h5>
            <TextField
                id="name"
                label="Name"
                value={dataToUse.name}
                onChange={(e) => cmsStore.updateImage({ name: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <CMSWidthField
                value={dataToUse.width.toString()}
                onChange={(newValue) => cmsStore.updateImage({ width: +(newValue) })}
                disabled={!isEditing}
            />
            <TextField
                id="imageId"
                label="Image ID"
                value={dataToUse.imageId}
                disabled
                fullWidth
                margin="normal"
            />
            <Button
                variant='outlined'
                disabled={!isEditing}
                onClick={() => {
                    modalStore.openModal(<ImageGalleryForm onSelectImage={(id: string) => {
                        cmsStore.updateImage({ imageId: id })
                    }} />)
                }}
            >
                {dataToUse.imageId ? 'Change' : 'Select'} Image
            </Button>
            {templateOptions}
        </Stack>
    );
});
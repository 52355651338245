import {Grid, Paper, Typography} from "@mui/material";
import {AdminSplGrid, AdminTbcGrid} from "./";


export const AdminTokens = () => {
    return (
            <Grid container direction="row" spacing={1}>
                <Grid item>
                    <AdminSplGrid/>
                </Grid>
                <Grid item>
                    <AdminTbcGrid/>
                </Grid>
            </Grid>
    )

}
import { observer } from "mobx-react-lite";
import { IFrame as IFrameModel } from "../../../Models/Site"
import { useStore } from "../../../Stores";

interface Props {
    data: IFrameModel
}

export const IFrame = observer(({ data }: Props) => {
    const { commonStore } = useStore();
    return (
        <iframe
            title={data.title}
            style={{
                top: 0,
                left: 0,
                width: "100%",
                height: `calc(100vh - ${commonStore.navbarHeight}px)`
            }}
            src={data.url}
            frameBorder="0"
        />
    )
});
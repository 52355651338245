import { Grid } from "@mui/material";
import { AdminUsersForm } from ".";
import { AdminSearchUsers } from "./AdminSearchUsers";


export const AdminUsers = () => {
    return (
        <Grid container paddingTop={2}>
            <AdminSearchUsers />
            <Grid item md={1} />
            <Grid item md={4}>
                <AdminUsersForm />
            </Grid>
        </Grid>
    )
}
import { Route } from "react-router";
import { LoadingComponent } from "../../Util";
import { history } from "../../../App";
import { AccountService, AuthRedirect, CookieService } from "../../../Services";
import { Service } from "../../../Models/Site";

export const ExternalRoutes = () => {
    const getCode = () => {
        const query = new URLSearchParams(history.location.search);
        return query.get('code') ?? "";
    }

    // Append and return the service and code to the redirect URL cookie
    const redirectUrl = (service: Service) => {
        return `${CookieService.redirectUrl()}?service=${service}&code=${getCode()}`
    }

    // Re-Route from generic `/external-*` endpoint 
    // To specific service endpoint
    // E.g.
    //      /external-login?service=twitch&code=abc123
    // Would be re routed to
    //      /twitch-login?code=abc123
    const finalUrl = (redirect: AuthRedirect) => {
        const query = new URLSearchParams(history.location.search);
        const code = query.get('code');
        const service = query.get('service');
        if (code && service) {
            return `/${service}-${redirect}?code=${code}`
        }
        return '/'
    }

    return (
        <>
            {/* >> RUNS ON BASE DOMAIN << */}
            {/*
                Start SSO journey
                Initiated from a custom domain -> executed on base domain.
                e.g. 
                    1) User wants to login to nealguides.co.uk with Twitch.
                    2) nealguides.co.uk redirects to nodia.space/external-sso
                        with parameters:
                        - service: twitch
                        - redirectUrl: nealguides.co.uk/external-login
                    3) seedr.app redirects to Twitch SSO with a redirect url seedr.app/twitch-external
            */}
            <Route key="/external-sso" path="/external-sso" render={() => {
                const query = new URLSearchParams(history.location.search);
                const redirectUrl = query.get('redirectUrl');
                const service = query.get('service');

                switch (service) {
                    case Service.twitch:
                        window.location.href = AccountService.twitchLoginUrl(AuthRedirect.external, redirectUrl);
                        break;
                    case Service.patreon:
                        window.location.href = AccountService.patreonLoginUrl(AuthRedirect.external, redirectUrl);
                        break;
                    case Service.google:
                        window.location.href = AccountService.googleLoginUrl(AuthRedirect.external, redirectUrl);
                        break;
                    // case Service.instagram:
                    //     window.location.href = AccountService.instagramLoginUrl(AuthRedirect.external, redirectUrl);
                    //     break;
                    // case Service.facebook:
                    //     window.location.href = AccountService.facebookLoginUrl(AuthRedirect.external, redirectUrl);
                    //     break;
                    // case 'rally':
                    //     AccountService.rallyLoginUrl(AuthRedirect.external, redirectUrl)
                    //         .then((redirect) => {
                    //             window.location.href = redirect.url;
                    //         })
                    //         .catch((error) => {
                    //             if (redirectUrl) {
                    //                 window.location.href = redirectUrl;
                    //             } else {
                    //                 history.push(`/login?redirectUrl=${redirectUrl}`)
                    //             }
                    //         })
                    //     break;
                    default:
                        break;
                }
                return <LoadingComponent />;
            }} />

            {/* >> RUNS ON BASE DOMAIN << */}
            {/* 
                Callback URLs 
                Route user back to custom domain
            */}
            <Route key="/twitch-external" path="/twitch-external" render={() => {
                window.location.href = redirectUrl(Service.twitch)
                return <LoadingComponent />;
            }} />
            <Route key="/patreon-external" path="/patreon-external" render={() => {
                window.location.href = redirectUrl(Service.patreon)
                return <LoadingComponent />;
            }} />
            {/*<Route key="/rally-external" path="/rally-external" render={() => {*/}
            {/*    window.location.href = redirectUrl(Service.rally)*/}
            {/*    return <LoadingComponent />;*/}
            {/*}} />*/}
            <Route key="/google-external" path="/google-external" render={() => {
                window.location.href = redirectUrl(Service.google)
                return <LoadingComponent />;
            }} />
            {/*<Route key="/instagram-external" path="/instagram-external" render={() => {*/}
            {/*    window.location.href = redirectUrl(Service.instagram)*/}
            {/*    return <LoadingComponent />;*/}
            {/*}} />*/}
            {/*<Route key="/facebook-external" path="/facebook-external" render={() => {*/}
            {/*    window.location.href = redirectUrl(Service.facebook)*/}
            {/*    return <LoadingComponent />;*/}
            {/*}} />*/}

            {/* >> RUNS ON CUSTOM DOMAIN << */}
            {/* 
                Receive SSO Access Code
                - code: SSO Access code to redeem
                - service: twitch/patreon etc.
            */}
            <Route key='/external-register' path="/external-register" render={() => {
                history.push(finalUrl(AuthRedirect.register))
                return <LoadingComponent />;
            }} />
            <Route key='/external-login' path="/external-login" render={() => {
                history.push(finalUrl(AuthRedirect.login))
                return <LoadingComponent />;
            }} />
            <Route key='/external-connect' path="/external-connect" render={() => {
                history.push(finalUrl(AuthRedirect.connect))
                return <LoadingComponent />;
            }} />
        </>
    )
}

import { makeAutoObservable, } from "mobx"

export class ListStore {
    open: boolean = false;

    constructor() {
        makeAutoObservable(this)
    }

    openList = () => {
        this.open = !this.open;
    }
}
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { CollectionDataCard } from "../../../Models/API/Collections";
import { useStore } from "../../../Stores";

const cardColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 300 },
    {
        field: 'order',
        headerName: 'Order',
        width: 150
    }
];
const keysColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 300 },
    {
        field: 'type',
        headerName: 'Type',
        width: 150
    }
];

const createCardData = (
    id: string,
    order: number,
) => {
    return { id, order };
}

const createKeysData = (
    id: string,
    type: string,
) => {
    return { id, type };
}


export const AdminCollectionsData = observer(() => {
    let i;
    const { adminStore } = useStore();
    const { selectedCollection, collectionCardList, selectedCollectionCard, collectionCardDetailList, collectionKeys, getCollectionKeys } = adminStore;
    const [keysRefreshing, setKeysRefreshing] = useState(false);
    let cardData: CollectionDataCard[] | undefined = collectionCardList;
    let cards = [];
    let keysData = collectionKeys;
    let keys = [];


    const refreshKeys = () => {
        if (keysRefreshing) { return }
        setKeysRefreshing(true)
        if (selectedCollection) {
            getCollectionKeys(selectedCollection.id).finally(() => {
                setKeysRefreshing(false)
            })
        }
    }
    useEffect(() => {
        refreshKeys()
    }, [])

    if (cardData) {
        for (i = 0; i < cardData.length; i++) {
            cards.push(createCardData(cardData[i].id, cardData[i].order))
        }
    }

    if (keysData) {
        for (i = 0; i < keysData.length; i++) {
            keys.push(createKeysData(keysData[i].name, keysData[i].type))
        }
    }

    return (
        <>
            <Grid container component={Paper} direction="column" alignContent="center">
                <Grid item margin={2}>
                    <Typography align="center" variant="h4">
                        {selectedCollection?.name != null ? selectedCollection.name : "Select Collection"}
                    </Typography>
                </Grid>
                <Grid item>
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        Title
                                    </TableCell>
                                    <TableCell>
                                        {selectedCollection?.title}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Owner
                                    </TableCell>
                                    <TableCell>
                                        {selectedCollection?.ownerName}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Collection API
                                    </TableCell>
                                    <TableCell>
                                        {selectedCollection?.collectionApiName}
                                    </TableCell>
                                </TableRow>
                                <TableRow>

                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* <Grid item margin={2}>
                        <Typography align="center" variant="h4">
                            Cards
                        </Typography>
                    </Grid>
                    <Grid item>
                        <DataGrid
                            rows={cards}
                            columns={cardColumns}
                            pageSize={10}
                            rowsPerPageOptions={[5]}
                            sx={{ backgroundColor: "paper", width: 725 }}
                            autoPageSize
                            autoHeight
                        />
                    </Grid> */}
                    <Grid item margin={2}>
                        <Typography align="center" variant="h4">
                            Keys
                        </Typography>
                    </Grid>
                    <Grid item>
                        <DataGrid
                            rows={keys}
                            columns={keysColumns}
                            pageSize={10}
                            rowsPerPageOptions={[5]}
                            sx={{ backgroundColor: "paper", width: 725 }}
                            autoPageSize
                            autoHeight
                        />
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
})

// onSelectionModelChange={(ids) => {
//     if (data) {
//         const selectedIDs = new Set(ids);
//         const selectedRows = data.filter((row) => selectedIDs.has(row.id)
//         );
//         selectCollection(selectedRows[0]);
//     }

// }} 
import {ManageAccounts, Badge, Language, Link, People, SwapHoriz} from '@mui/icons-material';
import { observer } from "mobx-react-lite";

import { useStore } from "../../Stores";
import {CreatorOverview, CreatorStaff, CreatorDomains, CreatorSocials, CreatorReferrals, CreatorTrading} from '.';
import { MasterDetail, Page, Section } from '../Common/MasterDetail';

export const CreatorProfile = observer(() => {
    const { accountStore, creatorStore } = useStore();
    const { creator } = creatorStore;
    const { user } = accountStore;

    if (!user) {
        accountStore.getUser()
        return (
            <></>
        );
    }
    if (!creator) {
        creatorStore.getCreator({ userId: user.id })
        return (
            <></>
        );
    }

    const overviewPage = new Page("Overview", "", <ManageAccounts />, <CreatorOverview creator={creator} />);
    const staffPage = new Page("Staff", "", <Badge />, <CreatorStaff />);
    const domainsPage = new Page("Domains", "", <Language />, <CreatorDomains />);
    const socialsPage = new Page("Socials", "", <Link />, <CreatorSocials />);
    const referralsPage = new Page("Referrals", "", <People />, <CreatorReferrals />);
    const tradingPage = new Page("Trading", "", <SwapHoriz />, <CreatorTrading />);

    const sections = [
        new Section([overviewPage]),
        new Section([staffPage]),
        new Section([domainsPage]),
        new Section([socialsPage]),
        new Section([referralsPage]),
        new Section([tradingPage])
    ]

    return (
        <MasterDetail sections={sections} />
    )
});

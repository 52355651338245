import { makeAutoObservable } from "mobx";
import { v4 as uuid } from "uuid";
import { Type, Content } from "..";
import { File } from "../../API";

export enum ButtonVariant {
    contained = 'contained',
    outlined = 'outlined',
    text = 'text'
}

export enum ButtonColor {
    primary = 'primary',
    secondary = 'secondary',
    custom = 'custom'
}

export enum ButtonTextColor {
    default = 'default',
    custom = 'custom'
}

export enum LinkType {
    url = 'url',
    file = 'file'
}

export class Button implements Content {
    id: string = uuid();
    name: string = "Button";
    type: Type = Type.button;
    index: number = 0;
    width: number = 12;
    title: string = "New Button";
    linkType: LinkType = LinkType.url;
    url: string = "https://nodia.space";
    file: File = new File();
    variant: ButtonVariant = ButtonVariant.contained
    color: ButtonColor = ButtonColor.primary
    colorCustom: string = '#000000'
    textColor: ButtonTextColor = ButtonTextColor.default
    textColorCustom: string = '#ffffff'
    openInNewTab: boolean = true

    constructor() {
        makeAutoObservable(this)
    }
}

export interface ButtonUpdater {
    name?: string;
    width?: number;
    title?: string;
    linkType?: LinkType;
    url?: string;
    file?: File;
    variant?: ButtonVariant;
    color?: ButtonColor;
    colorCustom?: string;
    textColor?: ButtonTextColor;
    textColorCustom?: string;
    openInNewTab?: boolean; 
}

import { LoadingButton } from "@mui/lab";
import { Box, Typography, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../../Stores";
import { MaxWidth } from ".";

export const ChangeReferralCodeForm = observer(() => {
    const { adminStore, modalStore, toastBarStore } = useStore()
    const { changeReferralCode, editUser, getUser } = adminStore;
    const { closeModal } = modalStore;
    const [codeError, setCodeError] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const code = data.get('code')?.toString()

        if (code && editUser) {
            setCodeError(false)
            setSubmitting(true)
            changeReferralCode(editUser.id, code)
                .finally(() => {
                    setSubmitting(false);
                    getUser(editUser.id);
                    closeModal();
                });
        } else {
            setCodeError(true)
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                maxWidth: `${MaxWidth}px`,
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Typography component="h1" variant="h5">
                Change Referral Code
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    error={codeError}
                    onChange={() => setCodeError(false)}
                    required
                    fullWidth
                    id="code"
                    label="Referral Code"
                    name="code"
                    autoComplete="referralcode"
                    autoFocus
                />
                <LoadingButton
                    type="submit"
                    loading={submitting}
                    disabled={toastBarStore.open}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Change Referral Code
                </LoadingButton>
            </Box>
        </Box>
    );
});
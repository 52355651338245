import { Stack, Box, Typography, Card, CardContent, CardMedia, CardHeader, Button, Tooltip, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItem, List, CircularProgress, IconButton } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Done, Email, Link, EmojiEvents, ContentCopy, Warning, Refresh, Pending, Close } from '@mui/icons-material';

import TwitchLogo from "../../Assets/connectedaccounts/TwitchLogo.png";
import PatreonLogo from "../../Assets/connectedaccounts/PatreonLogo.png";
import GoogleLogo from "../../Assets/connectedaccounts/GoogleLogo.png";
import { ActiveReferral, User } from "../../Models/API";
import { Service } from "../../Models/Site";
import Consts from "../Common/Consts";
import { ServiceLoginButton } from "../Forms";
import { LoadingButton } from "@mui/lab";
import React, { useCallback, useEffect, useLayoutEffect } from "react";
import { useStore } from "../../Stores";
import { AuthRedirect } from "../../Services";
import { useTheme } from "@mui/material/styles";

interface Props {
    user: User
}

const notComplete: React.ReactNode =
    <Stack spacing={1}>
        <Typography align="center">How To Earn Rewards:</Typography>
        <Typography align="center">If you signed up with email, complete email confirmation by clicking the link in the confirmation sent to your inbox.</Typography>
        <Typography align="center">Ensure you have one social account eg. Twitch or Patreon connected.</Typography>
        <Typography align="center">Create a Rally account if you haven't already at Rally.io then connect your new Rally Account to your account.</Typography>
        <Typography align="center">If you have completed all three steps then you will be eligible for your reward!</Typography>
        <Typography align="center">If your Rally account is too old to receive rewards through the rally growth challenge you can still receive referral rewards by sharing the link below.</Typography>
        <Typography align="center">Each new Rally User that completes the steps on this page using your referral code will receive $RLY 70 and you will earn $RLY 25!</Typography>
    </Stack>

const notEligible: React.ReactNode =
    <Stack spacing={1}>
        <Typography align="center">Your Rally account is no longer classified as a new Rally account as its creation date is too far in the past. Unfortunately, the growth challenge rewards are only available to new Rally users.</Typography>
        <Typography align="center">You can still refer friends to the platform to earn $RLY 25.</Typography>
    </Stack>

const eligible: React.ReactNode =
    <Stack spacing={1}>
        <Typography align="center">Congratulations, you are eligible to receive your $RLY 70 reward from the Rally Growth Challenge.</Typography>
        <Typography align="center">To keep earning share the code/link below.</Typography>
        <Typography align="center">Each new Rally User that completes the steps on this page using your referral code will receive $RLY 70 and you will earn $RLY 25!</Typography>
    </Stack>

interface Content {
    image?: string,
    text: React.ReactNode,
    button: React.ReactNode
}

enum State {
    notComplete,
    notEligible,
    eligible
}

const textForState = (state: State): React.ReactNode => {
    switch (state) {
        case State.eligible: return eligible;
        default: return notComplete;
    }
}

export const Rewards = observer(({ user }: Props) => {
    const theme = useTheme();
    const { accountStore, toastBarStore } = useStore();
    const { referrals } = accountStore;

    const [refreshing, setRefreshing] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [footerNode, setFooterNode] = React.useState<HTMLDivElement>();
    const footerRef = useCallback(node => {
        if (node !== null) {
            setFooterNode(node);
        }
    }, []);
    const [footerHeight, setFooterHeight] = React.useState(0)

    useLayoutEffect(() => {
        if (footerNode) {
            const measure = () => {
                setFooterHeight(footerNode.getBoundingClientRect().height)
            }
            measure()
            window.addEventListener("resize", measure);
            return () => {
                window.removeEventListener("resize", measure);
            };
        }
    }, [footerNode])
    const [sendingEmailConfirmation, setSendingEmailConfirmation] = React.useState(false)

    const confirmEmail = () => {
        setSendingEmailConfirmation(true)
        accountStore.generateConfirmEmail()
            .then(() => {
                toastBarStore.showMessage(`An email was sent to ${user.email}. Please check your inbox.`)
            }).finally(() => {
                setSendingEmailConfirmation(false)
            })
    }

    // const toggleRallyAccount = (connected: boolean) => {
    //     if (connected) {
    //         accountStore.unlinkService(Service.rally)
    //         return
    //     }
    //     AccountService.rallyLoginUrl(AuthRedirect.connect).then((redirect: RedirectUrl) => {
    //         window.location.href = redirect.url;
    //     });
    // }

    const refreshReferrals = () => {
        if (refreshing) { return }
        setRefreshing(true)
        accountStore.getActiveReferrals().finally(() => {
            setRefreshing(false)
        })
    }

    useEffect(() => {
        refreshReferrals()
    }, []) // Run once (dont delete it plz)

    const emailConfirmed = user.emailConfirmed
    const twitchConnected = user.twitchUser ? user.twitchUser.id !== null : false
    const patreonConnected = user.patreonUser ? user.patreonUser.id !== null : false
    const googleConnected = user.googleUser ? user.googleUser.id !== null : false
    const instagramConnected = user.instagramUser ? user.instagramUser.id !== null : false
    const facebookConnected = user.facebookUser ? user.facebookUser.id !== null : false

    const socialConnected = twitchConnected || patreonConnected || googleConnected || instagramConnected || facebookConnected
    const rallyConnected = user.rallyUser ? user.rallyUser.username !== null : false
    const isEligible = user.rewardsEnabled

    const state = ((): State => {
        if (isEligible) {
            return State.eligible
        }
        if (emailConfirmed && socialConnected && rallyConnected) {
            return State.notEligible
        }
        return State.notComplete
    })();
    const text = textForState(state);

    const linkDescription = (name: string) => `Connect your ${name} account to your Nodia account to unlock premium content and earn rewards.`
    const buttonSX = { mr: 2, px: 8, height: '50px', width: '150px' }

    const card = (completed: boolean, title: string, defaultIcon: JSX.Element, contents: Content[], subheader?: React.ReactNode, failed?: boolean) => {
        let borderSX = completed ? { border: 2, borderColor: theme.palette.success.main } : { border: 2, borderColor: theme.palette.error.main }
        let avatar = completed ? <Done color="success" /> : <Close color="error" />
        if (failed && borderSX) {
            borderSX.borderColor = theme.palette.warning.main;
            avatar = <Warning color="warning" />
        }
        return (
            <Card sx={{ m: 2, ...Consts.containerSX, ...borderSX }}>
                <CardHeader
                    sx={{ flex: 1 }}
                    avatar={avatar}
                    title={title}
                    subheader={subheader}
                />
                {contents.map((c, index) => {
                    return (
                        <CardContent key={index} sx={{ display: 'flex', alignItems: 'center', }}>
                            {
                                c.image ?
                                    <CardMedia
                                        height='100%'
                                        component="img"
                                        sx={{ width: '150px', px: 1 }}
                                        image={c.image}
                                    />
                                    : undefined
                            }
                            <CardContent sx={{ flex: '1 1 auto' }}>
                                {c.text}
                            </CardContent>
                            {c.button}
                        </CardContent>
                    )
                })}
            </Card>
        )
    }
    const completedButton = () => {
        return (
            <Button variant="outlined" sx={buttonSX} disabled><Done /></Button>
        )
    }
    const headerCard = (
        <Paper sx={{ p: 4, my: 2, ...Consts.containerSX }}>
            <Stack spacing={5}>
                {text}
                <Typography variant="h5" align="center">Accounts without a Rally connection will not receive referral rewards from any referral made before Rally is connected.</Typography>
            </Stack>
        </Paper>
    )

    const payoutCard = state === State.eligible ? (
        <Paper sx={{ p: 4, my: 2, ...Consts.containerSX }}>
            <Stack spacing={1}>
                <Typography align="center">The Rewards week runs from Wednesday to Tuesday.</Typography>
                <Typography align="center">Rewards earned in any given week will be paid the following Wednesday (one week after).</Typography>
            </Stack>
        </Paper>
    ) : null

    const confirmEmailCard = state === State.notComplete ? card(
        emailConfirmed,
        'Confirm email address',
        <Email color="primary" />,
        [{
            text: `Please click the confirmation link that was sent to ${user.email}, or resend the email.`,
            button: emailConfirmed ?
                completedButton() :
                <LoadingButton
                    loading={sendingEmailConfirmation}
                    disabled={emailConfirmed || toastBarStore.open}
                    variant="contained"
                    sx={buttonSX}
                    onClick={confirmEmail}
                >
                    Resend
                </LoadingButton>
        }]
    ) : undefined
    const socialsCard = state === State.notComplete ? card(
        socialConnected,
        'Link a social account',
        <Link color="primary" />,
        [
            {
                image: TwitchLogo,
                text: linkDescription('Twitch'),
                button: twitchConnected ?
                    completedButton() :
                    <ServiceLoginButton service={Service.twitch} redirect={AuthRedirect.connect} title="Connect" props={{ variant: 'contained', sx: buttonSX }} />
            },
            {
                image: PatreonLogo,
                text: linkDescription('Patreon'),
                button: patreonConnected ?
                    completedButton() :
                    <ServiceLoginButton service={Service.patreon} redirect={AuthRedirect.connect} title="Connect" props={{ variant: 'contained', sx: buttonSX }} />
            },
            {
                image: GoogleLogo,
                text: linkDescription('Google'),
                button: googleConnected ?
                    completedButton() :
                    <ServiceLoginButton service={Service.google} redirect={AuthRedirect.connect} title="Connect" props={{ variant: 'contained', sx: buttonSX }} />
            },
            // {
            //     image: InstagramLogo,
            //     text: linkDescription('Instagram'),
            //     button: instagramConnected ?
            //         completedButton() :
            //         <ServiceLoginButton service={Service.instagram} redirect={AuthRedirect.connect} title="Connect" props={{ variant: 'contained', sx: buttonSX }} />
            // },
            // {
            //     image: FacebookLogo,
            //     text: linkDescription('Facebook'),
            //     button: facebookConnected ?
            //         completedButton() :
            //         <ServiceLoginButton service={Service.facebook} redirect={AuthRedirect.connect} title="Connect" props={{ variant: 'contained', sx: buttonSX }} />
            // }
        ]
    ) : undefined
    // const rallyCard = (() => {
    //     if (state === State.eligible) return undefined
    //     return card(
    //         rallyConnected,
    //         rallyConnected ? '' : 'Create a rally account',
    //         <HowToReg color="primary" />,
    //         [
    //             rallyConnected ? undefined : {
    //                 image: RallyLogo,
    //                 text: `Create a new Rally.io account.`,
    //                 button: <Button variant="contained" sx={buttonSX} onClick={() => setDialogOpen(true)}>Register</Button>
    //             },
    //             {
    //                 image: RallyLogo,
    //                 text: linkDescription('Rally.io'),
    //                 button: <Button
    //                     variant={rallyConnected ? 'outlined' : 'contained'}
    //                     sx={buttonSX}
    //                     onClick={() => toggleRallyAccount(rallyConnected)}>
    //                     {rallyConnected ? 'Disconnect' : 'Connect'}
    //                 </Button>
    //             }
    //         ].filter(x => x !== undefined) as Content[],
    //         rallyConnected && !isEligible ? notEligible : undefined,
    //         rallyConnected && !isEligible
    //     )
    // })();
    const referralCard = (() => {
        const referralLink = `https://${window.location.host}/rewards?referralCode=${user.referral.userReferralCode}`;
        return (
            <Stack ref={footerRef} direction='column' alignItems='center' bgcolor='secondary.main' sx={{ position: 'fixed', bottom: 0, width: `calc(100% - ${Consts.drawerWidth}px)`, minWidth: `calc(${Consts.drawerWidth}px + 450px)` }}>
                <Paper sx={{ m: 4, p: 4, ...Consts.containerSX }}>
                    <Stack mb={2} direction={{ xs: 'column', lg: 'row' }} spacing={2} alignItems='center' width={1} justifyContent='center'>
                        <Typography variant='h6' textAlign='center'>Your referral code is:</Typography>
                        <Typography variant='h4' textAlign='center'>{user.referral.userReferralCode}</Typography>
                    </Stack>
                    <Stack direction={{ xs: 'column', lg: 'row' }} spacing={2} alignItems='center' flex={1}>
                        <Typography
                            variant='h6'
                            textAlign='center'
                            alignItems='center'
                            sx={{
                                flex: '1 1 auto',
                                p: 1,
                                wordWrap: 'break-word',
                                border: '1px dashed white',
                                height: '100%'
                            }}>{referralLink}</Typography>
                        <Tooltip title='Copy Referral Link' sx={{ flex: '0 0 auto', width: '100%' }}>
                            <Button onClick={() => {
                                navigator.clipboard.writeText(referralLink).then(function () {
                                    toastBarStore.showMessage('Copied to clipboard!')
                                }, function (err) {
                                    console.error('Async: Could not copy text: ', err);
                                });
                            }}
                                sx={{ py: 1, height: '100%' }}
                                color="primary"
                                variant="contained"
                                startIcon={<ContentCopy />}>
                                Copy Referral Link
                            </Button>
                        </Tooltip>
                    </Stack>
                </Paper>
            </Stack>
        )
    })();
    const startRallyRegistration = () => {
        closeDialog();
        window.open('https://rally.io/signup/', "_blank")
    }
    const closeDialog = () => {
        setDialogOpen(false);
    }
    const registerRallyDialog = (
        <Dialog
            open={dialogOpen}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Register New Rally Account
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Don't forget - you still need to come back here and link your account after you've created it to earn rewards!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button color='primary' variant='contained' onClick={startRallyRegistration}>Go to Rally.io</Button>
            </DialogActions>
        </Dialog>
    )

    const listItem = (referral: ActiveReferral) => {
        return (
            <ListItem
                sx={{
                    mb: 2,
                    borderBottom: '1px solid rgba(255, 255, 255, 0.25)'
                }}
                key={referral.userName} >
                <Stack direction='row' spacing={2} alignItems='center' justifyContent='center'>
                    {referral.receivingRewards ? <Done color='success' /> : <Pending />}
                    <Stack>
                        <Typography variant='body1'>{referral.userName}</Typography>
                        <Typography variant='body2'>{referral.receivingRewards ? `Reward earned ${referral.dateEarned}` : 'User has not completed enrollment'}</Typography>
                    </Stack>
                </Stack>
            </ListItem>
        )
    }
    const items = referrals ? referrals.map(listItem) : []
    const referralsContent = (() => {
        if (refreshing) {
            return (
                <Box display='flex' flexDirection='row' paddingX={1} paddingY={2}>
                    <Typography variant='body1' color='primary.main' sx={{ flex: 1 }}>Loading referrals</Typography>
                    <CircularProgress sx={{ flex: '0 1 auto' }} size={25} color="primary" />
                </Box>
            )
        } else if (items.length > 0) {
            return (
                <List>
                    {items}
                </List>
            )
        }
        return (
            <Typography variant='body1' color='primary.main' sx={{ flex: 1 }}>No referrals</Typography>
        )
    })();
    const referralsCard = (() => {
        if (state === State.notComplete) {
            return <></>
        }
        return (
            <Box sx={Consts.containerSX}>
                <Card sx={{ display: 'flex', flexDirection: 'column', mt: 5, alignItems: 'leading' }} >
                    <CardHeader
                        avatar={<EmojiEvents color='primary' />}
                        title="Referrals"
                        action={
                            <IconButton aria-label="settings" onClick={refreshReferrals} disabled={refreshing}>
                                <Refresh />
                            </IconButton>
                        }
                    />
                    <CardContent sx={{ flex: '1 0 auto', display: 'flex', flexDirection: 'column' }}>
                        {referralsContent}
                    </CardContent>
                </Card>
            </Box>
        )
    })();
    return (
        <Stack direction='column' alignItems='center' mb={`${footerHeight}px`}>
            {headerCard}
            {payoutCard}
            {confirmEmailCard}
            {socialsCard}
            {/*{rallyCard}*/}
            {referralsCard}
            {referralCard}
            {registerRallyDialog}
        </Stack>
    );
});

import { makeAutoObservable } from "mobx";
import { v4 as uuid } from "uuid";
import { Type, Content } from "..";

export enum Variant {
    h1 = "h1",
    h2 = "h2",
    h3 = "h3",
    h4 = "h4",
    h5 = "h5",
    h6 = "h6",
    subtitle1 = "subtitle1",
    subtitle2 = "subtitle2",
    body1 = "body1",
    body2 = "body2",
    caption = "caption",
    button = "button",
    overline = "overline",
    inherit = "inherit"
}

export enum Alignment {
    left = "left",
    right = "right",
    center = "center",
    justify = "justify",
    inherit = "inherit"
}

export enum TextColor {
    default = "default",
    custom = "custom"
}

export class Text implements Content {
    id: string = uuid();
    name: string = "Text";
    type: Type = Type.text;
    index: number = 0;
    variant: Variant = Variant.body1;
    align: Alignment = Alignment.center;
    text: string = "New Text";
    width: number = 12;
    textColor: TextColor = TextColor.default;
    textColorCustom: string = "#000000";
    constructor() {
        makeAutoObservable(this)
    }
}

export interface TextUpdater {
    name?: string;
    variant?: Variant;
    align?: Alignment;
    text?: string;
    width?: number;
    textColor?: TextColor;
    textColorCustom?: string;
}

import { Grid, GridSize, Box, Stack } from "@mui/material"
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";

import { useStore } from "../../../Stores";
import { ElementRenderer, ImageSize, ImageWidths } from ".."
import { Container as ContainerModel, ContainerDirection } from "../../../Models/Site"

interface Props {
    data: ContainerModel
    parentWidth: number
}

export const Container = observer(({ data, parentWidth }: Props) => {
    const { commonStore } = useStore();

    const [width, setWidth] = React.useState(0)
    const theme = useTheme();

    const checkAndSetWidth = (size: ImageSize) => {
        const newWidth = Math.round((() => {
            const pWidth = parentWidth / 12
            const cWidth = data.width / 12
            const tWidth = pWidth * cWidth
            switch (size) {
                case ImageSize.xs: return ImageWidths.xs * tWidth;
                case ImageSize.sm: return ImageWidths.sm * tWidth;
                case ImageSize.md: return ImageWidths.md * tWidth;
                case ImageSize.lg: return ImageWidths.lg * tWidth;
                case ImageSize.xl: return ImageWidths.xl * tWidth;
                default: return 0
            }
        })())
        if (width < newWidth) {
            setWidth(newWidth)
        }
    }
    const xs = useMediaQuery(theme.breakpoints.down('sm'));
    const sm = useMediaQuery(theme.breakpoints.down('md'));
    const md = useMediaQuery(theme.breakpoints.down('lg'));
    const lg = useMediaQuery(theme.breakpoints.down('xl'));
    const xl = useMediaQuery(theme.breakpoints.up('xl'));

    if (xs) { checkAndSetWidth(ImageSize.xs) }
    else if (sm) { checkAndSetWidth(ImageSize.sm) }
    else if (md) { checkAndSetWidth(ImageSize.md) }
    else if (lg) { checkAndSetWidth(ImageSize.lg) }
    else if (xl) { checkAndSetWidth(ImageSize.xl) }

    const content = (() => {
        switch (data.direction) {
            case ContainerDirection.row || ContainerDirection.rowReverse:
                return (
                    <Grid
                        container
                        spacing={data.spacing ?? 4}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                        direction={data.direction}
                        justifyContent={data.justification}
                        alignItems={data.alignment}
                    >
                        {data.content.map((item, index) => {
                            return (
                                <Grid container item key={index.toString()} xs={item.width as GridSize}>
                                    {ElementRenderer.build({ model: item, parent: data, color: `${ContainerModel.textColorParam(data)}` })}
                                </Grid>
                            )
                        })}
                    </Grid>
                )
            case ContainerDirection.column || ContainerDirection.columnReverse:
                return (
                    <Stack
                        alignItems={data.alignment}
                        justifyContent={data.justification}
                        spacing={data.spacing}
                        width='100%'
                    >
                        {data.content.map((item, index) => {
                            return (
                                <Box key={index.toString()} width={`${(item.width * 100) / 12}%`}>
                                    {ElementRenderer.build({ model: item, parent: data, color: `${ContainerModel.textColorParam(data)}` })}
                                </Box>
                            )
                        })}
                    </Stack>
                )
        }
    })();
    const bgImage = data.backgroundImageId ? {
        backgroundImage: `url(${process.env.REACT_APP_API_URL}/Image?imageId=${data.backgroundImageId}&width=${width})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    } : {}
    return (
        <Box
            key={data.id}
            width={`${(data.width * 100) / 12}%`}
            sx={{
                ...bgImage,
                // backgroundColor: "paper",
                backgroundColor: ContainerModel.colorParam(data),
                marginBottom: data.marginBottom,
                borderRadius: data.cornerRadius,
                padding: data.padding,
                minHeight: data.fullScreen ? `calc(100vh - ${commonStore.navbarHeight}px)` : undefined,
                width: '100%'
            }}
        >
            {content}
        </Box>
    )
});

import { TextField, Stack, Box, Popover } from "@mui/material";
import React from "react";
import { HexColorPicker } from "react-colorful";

interface Props {
    id: string;
    label: string;
    value: string;
    onChange: (newValue: string) => void;
    disabled: boolean;
}

export const CMSColorPicker = ({ id, label, value, onChange, disabled }: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const colorPickerOpen = Boolean(anchorEl);
    const colorPickerId = colorPickerOpen ? 'simple-popover' : undefined;

    const handleClose = () => {
        setAnchorEl(null);
    };

    const validateInput = (input: string) => onChange(input.startsWith('#') ? input : '#');

    return (
        <Stack direction='row' alignItems='center' spacing={2} marginY={2}>
            <Box sx={{
                width: '50px',
                height: '50px',
                backgroundColor: value,
                borderRadius: '5px',
                border: '1px solid rgba(255,255,255,0.35)'
            }} onClick={(event) => {
                if (!disabled) {
                    setAnchorEl(event.currentTarget);
                }
            }} />
            <TextField
                id={id}
                label={label}
                value={value}
                onChange={(c) => validateInput(c.target.value)}
                disabled={disabled}
                fullWidth
                margin="normal"
            />
            <Popover
                id={colorPickerId}
                open={colorPickerOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <HexColorPicker style={{ margin: '20px' }} color={value} onChange={onChange} />
            </Popover>
        </Stack>
    )
};
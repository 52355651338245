import {Box, Button, Grid, Avatar, IconButton} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { NavBar as NavBarModel } from '../../Models/Site';
import { NavBarItem, NavBarMenu } from ".";
import { useStore } from '../../Stores';
import { RegisterForm, LoginForm } from '../Forms';
import React, { useCallback, useLayoutEffect } from 'react';
import { Help } from '@mui/icons-material';

interface Props {
    data: NavBarModel
    isPreview?: boolean
}

export const NavBar = observer(({ data, isPreview = false }: Props) => {
    const { accountStore, menuStore, modalStore, commonStore, supportStore } = useStore();
    const { open, openMenu } = menuStore;
    const { user } = accountStore;
    const {toggleSupportChat} = supportStore;

    const [headerNode, setHeaderNode] = React.useState<HTMLDivElement>();
    const headerRef = useCallback(node => {
        if (node !== null) {
            setHeaderNode(node);
        }
    }, []);
    useLayoutEffect(() => {
        if (headerNode) {
            const measure = () => {
                if (headerNode && !isPreview) {
                    commonStore.setNavbarHeight(headerNode.getBoundingClientRect().height)
                }
            }
            measure()
            window.addEventListener("resize", measure);
            return () => {
                window.removeEventListener("resize", measure);
            };
        }
    }, [headerNode, commonStore])

    const routes = data.routes.map(route => {
        return (
            <Grid item key={route.id}>
                <NavBarItem data={route} />
            </Grid>
        )
    });

    if (accountStore.isLoggedIn && !user) {
        accountStore.getUser()
    }
    return (
        <Box ref={headerRef} key={data.id} sx={{ flexGrow: 1, backgroundColor: 'secondary.main' }}>
            <Grid container
                direction='row'
                justifyContent='flex-start'
                alignItems='center'
                sx={{ padding: 2 }}
            >
                <Grid container item sm={8} direction='row' justifyContent='flex-start' alignItems='center' >
                    {routes}
                    {/* <Grid item key='/rewards'>
                        <NavBarItem data={{
                            id: 'rewards',
                            name: 'rewards',
                            type: Type.navBarItem,
                            index: routes.length,
                            path: '/rewards',
                            text: 'Rewards',
                            subRoutes: []
                        }} />
                    </Grid> */}
                </Grid>
                <Grid container item sm={4} direction='row' spacing={2} justifyContent='flex-end' alignItems='center' sx={{ paddingRight: 2 }} >
                    <Grid item marginTop={0.09}>
                        <IconButton
                            href='/support'
                            //chatbot test code
                            // onClick={toggleSupportChat}
                            color='primary'
                            size='large'
                            sx={{ padding: 0 }}
                        // sx={{ bgcolor: 'primary.main', color: 'primary.contrastText' }}
                        >
                            <Help sx={{ fontSize: "48px" }} />
                        </IconButton>
                        <NavBarMenu />
                    </Grid>

                    {user ? (
                        <>
                            <Grid item>
                            <Avatar
                                aria-haspopup="true"
                                aria-owns={open ? 'mouse-over-popover' : undefined}
                                onMouseEnter={openMenu}
                                sx={{bgcolor: 'primary.main', color: 'primary.contrastText'}}
                            >
                                {user.userName[0]}
                            </Avatar>
                            <NavBarMenu/>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item>
                                <Button
                                    onClick={() => modalStore.openModal(<RegisterForm />)}
                                    variant="contained"
                                    sx={{ fontSize: 22 }}
                                >
                                    Register
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={() => modalStore.openModal(<LoginForm />)}
                                    variant="outlined"
                                    sx={{ fontSize: 22 }}
                                >
                                    Login
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
});

export enum Cookie {
    token = 'token',
    referral = 'referral',
    creatorDomain = 'creatorDomain',
    redirectUrl = 'redirectUrl',
    httpReferrer = 'httpReferrer'
}

export class CookieService {
    private static baseUrl = process.env.REACT_APP_BASE_URL as string

    public static getDomain = () => {
        const host = window.location.host;
        if (host.indexOf(this.baseUrl) === -1) {
            // Custom Domain (e.g. nealguides.co.uk)
            return window.location.hostname
        } else if (this.baseUrl.indexOf(':') === -1) {
            // Base Domain (e.g. nodia.space)
            return this.baseUrl
        }
        // Localhost
        return this.baseUrl.substring(0, this.baseUrl.indexOf(':'))
    }

    public static redirectUrl = (): string => {
        CookieService.getCookie(Cookie.redirectUrl)
        const cookieValue = CookieService.getCookie(Cookie.redirectUrl)
        if (cookieValue) {
            return cookieValue;
        }
        return "/"
    }

    public static getCookie = (cookie: Cookie): string | undefined => {
        let name = cookie + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return undefined;
    }

    public static setCookie = (cookie: Cookie, value: string) => {
        const newCookie = `${cookie}=${value};domain=.${this.getDomain()};max-age=3600;`;
        document.cookie = newCookie;
    }

    public static deleteCookie = (cookie: Cookie) => {
        const newCookie = `${cookie}=;domain=.${this.getDomain()};expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`
        document.cookie = newCookie;
    }
}

import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { CompactUser } from "../../Models/API";
import { UserService } from "../../Services/UserService";

interface Props {
    onChange: (newValue: CompactUser | null) => void;
    error?: boolean;
    errorText?: string;
}

export const UserSearcher = observer((props: Props) => {

    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [searching, setSearching] = React.useState(false);
    const [options, setOptions] = React.useState<readonly CompactUser[]>([]);

    const onTextChange = (newValue: string) => {
        if (newValue.length > 2) {
            setLoading(true)
            setSearching(true)
            UserService
                .searchUsers(newValue)
                .then(setOptions)
                .finally(()=>setLoading(false))
        } else {
            setOptions([]);
            setSearching(false)
        }
    }

    const onUserChange = (newValue: CompactUser | null) => {
        if (newValue === null) {
            setSearching(false)
            setOptions([])
        }
        props.onChange(newValue)
    }

    return (
        <Autocomplete
            id="userpicker"
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => `${option.userName} (${option.email})`}
            options={options}
            loading={loading}
            onChange={(_, newValue) => onUserChange(newValue)}
            filterOptions={(x) => x}
            noOptionsText={searching ? 'No results' : 'Enter username or email'}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={props.error}
                    helperText={props.errorText}
                    margin="dense"
                    label="User"
                    onChange={(e) => onTextChange(e.target.value)}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
});
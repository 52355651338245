import { Language, Refresh } from "@mui/icons-material";
import { Box, Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, Paper, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../Stores";

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 300 },
    {
        field: 'userName',
        headerName: 'Username',
        width: 200
    },
    {
        field: 'email',
        headerName: 'Email',
        width: 250
    },
    {
        field: 'referredByCode',
        headerName: 'Referred By Code',
        type: 'boolean',
        width: 140
    },
    {
        field: 'emailConfirmed',
        headerName: 'Email Confirmed',
        type: 'boolean',
        width: 130
    },
    {
        field: 'hasSocial',
        headerName: 'Has Social',
        type: 'boolean',
        width: 95
    },
    {
        field: 'hasRally',
        headerName: 'Has Rally',
        type: 'boolean',
        width: 90
    },
    {
        field: 'rallyNew',
        headerName: 'New Rally',
        type: 'boolean',
        width: 100
    },
    {
        field: 'rewardsEnabled',
        headerName: 'Rewards Enabled',
        type: 'boolean',
        width: 135
    },
    {
        field: 'rewardsEnabledDate',
        headerName: 'Enabled Date',
        type: 'string',
        width: 130
    },

];

const createData = (
    id: string,
    userName: string,
    email: string,
    referredByCode: boolean,
    emailConfirmed: boolean,
    hasSocial: boolean,
    hasRally: boolean,
    rallyNew: boolean,
    rewardsEnabled: boolean,
    rewardsEnabledDate: string

) => {
    return { id, userName, email, referredByCode, emailConfirmed, hasSocial, hasRally, rallyNew, rewardsEnabled, rewardsEnabledDate };
}

export const AdminCreatorReferrals = observer(() => {
    const { adminStore } = useStore();
    const { referrals, getReferrals, selectedCreator } = adminStore;
    const [refreshing, setRefreshing] = useState(false);
    // let data: CreatorReferral[] | undefined = referrals;

    let items = [];

    if (referrals) {
        for (var i = 0; i < referrals.length; i++) {
            if (referrals[i].rewardsEnabledDate === "01/01/0001") {
                referrals[i].rewardsEnabledDate = "N/A"
            }
            items.push(createData(referrals[i].id, referrals[i].userName, referrals[i].email, referrals[i].referredByCode, referrals[i].emailConfirmed, referrals[i].hasSocial, referrals[i].hasRally, referrals[i].rallyNew, referrals[i].rewardsEnabled, referrals[i].rewardsEnabledDate));
        }
    }
    const refreshReferrals = () => {
        if (refreshing) { return }
        setRefreshing(true)
        getReferrals().finally(() => {
            setRefreshing(false);
        })
    }

    useEffect(() => {
        refreshReferrals();
    }, [])


    const content = (() => {
        if (refreshing) {
            return (
                <Box display='flex' flexDirection='row' paddingX={1} paddingY={2}>
                    <Typography variant='body1' color='primary.main' sx={{ flex: 1 }}>Loading referrals</Typography>
                    <CircularProgress sx={{ flex: '0 1 auto' }} size={25} color="primary" />
                </Box>
            )
        }
        else if (!referrals) {
            return (
                <Typography variant='body1' color='primary.main' sx={{ flex: 1 }}>No referrals</Typography>
            )
        }
        else {
            return null;
        }
    })
    return (
        <Grid container direction='column' alignItems='center'>
            <Grid item>
                <Paper sx={{ maxWidth: 1600, minWidth: 1600, width: 1000 }}>
                    <Card sx={{ display: 'flex', flexDirection: 'column', mt: 5, alignItems: 'leading' }}>
                        <CardHeader
                            avatar={<Language color='primary' />}
                            title={selectedCreator ? selectedCreator.name + " Referrals" : "Creator Referrals"}
                            action={<>
                                <IconButton aria-label="settings" onClick={refreshReferrals} disabled={refreshing}>
                                    <Refresh />
                                </IconButton>
                            </>} />
                        <CardContent sx={{ flex: '1 0 auto', display: 'flex', flexDirection: 'column' }}>
                            <Typography marginBottom={3}>
                                If Email, Social and Rally all confirmed but rewards not Enabled, Rally account too old.
                            </Typography>
                            {items ? <DataGrid
                                rows={items}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[5]}
                                disableSelectionOnClick
                                autoHeight
                                autoPageSize
                                sx={{ backgroundColor: "paper" }} /> : content}
                        </CardContent>
                    </Card>
                </Paper>
            </Grid>
        </Grid>
    )
})
import { observer } from "mobx-react-lite"
import { Autocomplete, Stack, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { useStore } from '../../Stores';
import { CMSActionBar, CMSEditButtons } from '.';
import { NavBarItem as NavBarItemModel, Page as PageModel } from '../../Models/Site';

interface Props {
    data: NavBarItemModel;
}

class RouteOption {
    id: string
    label: string
    path: string

    constructor(page: PageModel) {
        this.id = page.id
        this.label = `${page.name}: (${page.path})`
        this.path = page.path
    }
}

export const CMSNavBarItem = observer(({ data }: Props) => {
    const { cmsStore } = useStore();
    const { working_model } = cmsStore;
    let isEditing = cmsStore.editedItemId === data.id;

    const routes: RouteOption[] = cmsStore.data.pages.map((page) => new RouteOption(page));
    let dataToUse: NavBarItemModel = isEditing ? (working_model as NavBarItemModel) : data

    function currentRoute(): RouteOption | undefined {
        const path = dataToUse.path as string;
        return routes.find((route) => route.path === path);
    }
    return (
        <Stack direction="column" spacing={2} justifyContent="space-between">
            <CMSEditButtons
                id={data.id}
                index={data.index}
            />
            <h5>ID: {data.id}</h5>
            <TextField
                id="name"
                label="Name"
                value={dataToUse.name}
                onChange={(e) => cmsStore.updateNavBarItem({ name: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <TextField
                id="text"
                label="Text"
                value={dataToUse.text}
                onChange={(e) => cmsStore.updateNavBarItem({ text: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <Autocomplete
                id="path"
                value={currentRoute()}
                onChange={(_, newValue) => cmsStore.updateNavBarItem({ path: newValue?.path })}
                options={routes}
                isOptionEqualToValue={(option: RouteOption, value: RouteOption) => option === value}
                disabled={!isEditing}
                renderInput={(params) => (<TextField {...params} label="Page" />)}
            />
            <CMSActionBar
                id={data.id}
                addContent={false}
                buttons={[
                    {
                        text: "Add Sub Route",
                        action: () => cmsStore.addModel(new NavBarItemModel(), data.id),
                        icon: <AddIcon />
                    }
                ]}
            />
        </Stack>
    );
});
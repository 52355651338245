import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useLayoutEffect } from "react";

export enum Breakpoint {
    xs = 'xs', 
    sm = 'sm', 
    md = 'md', 
    lg = 'lg', 
    xl = 'xl'
}

export const Breakpoints = {
    xs: 600.0,
    sm: 900.0,
    md: 1200.0,
    lg: 1536.0,
    xl: 2000.0
}

interface Size {
    width: number,
    height: number
}

interface TheDreamProps {
    onSize?: (size: Size) => void;
    onBreakPoint?: (breakpoint: Breakpoint) => void;
    onBreakPointWidth?: (width: number) => void;
    children?: React.ReactNode;
}

export const TheDream = ({ onSize = () => { }, onBreakPoint, onBreakPointWidth, children }: TheDreamProps) => {
    const [viewNode, setViewNode] = React.useState<HTMLDivElement>();
    const viewRef = useCallback(node => {
        if (node !== null) {
            setViewNode(node);
        }
    }, []);
    useLayoutEffect(() => {
        if (viewNode) {
            const measure = () => {
                const rect = viewNode.getBoundingClientRect()
                onSize({
                    width: rect.width,
                    height: rect.height
                })
            }
            measure()
            window.addEventListener("resize", measure);
            return () => {
                window.removeEventListener("resize", measure);
            };
        }
    }, [viewNode])
    return <Box ref={viewRef}><MediaCallbacks onBreakPoint={onBreakPoint} onBreakPointWidth={onBreakPointWidth}>{children}</MediaCallbacks></Box>
}

interface MediaCallbacksProps {
    onBreakPoint?: (breakpoint: Breakpoint) => void;
    onBreakPointWidth?: (width: number) => void;
    children?: React.ReactNode;
}

export const MediaCallbacks = ({ onBreakPoint = () => { }, onBreakPointWidth = () => { }, children }: MediaCallbacksProps) => {
    const theme = useTheme();

    const xs = useMediaQuery(theme.breakpoints.down('sm'));
    const sm = useMediaQuery(theme.breakpoints.down('md'));
    const md = useMediaQuery(theme.breakpoints.down('lg'));
    const lg = useMediaQuery(theme.breakpoints.down('xl'));
    const xl = useMediaQuery(theme.breakpoints.up('xl'));

    if (xs) {
        onBreakPointWidth(Breakpoints.xs)
        onBreakPoint(Breakpoint.xs)
    }
    else if (sm) {
        onBreakPointWidth(Breakpoints.sm)
        onBreakPoint(Breakpoint.sm)
    }
    else if (md) {
        onBreakPointWidth(Breakpoints.md)
        onBreakPoint(Breakpoint.md)
    }
    else if (lg) {
        onBreakPointWidth(Breakpoints.lg)
        onBreakPoint(Breakpoint.lg)
    }
    else if (xl) {
        onBreakPointWidth(Breakpoints.xl)
        onBreakPoint(Breakpoint.xl)
    }

    return <>{children}</>
}
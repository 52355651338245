import { APIService } from '.';
import { RallyCurrency } from '../Models/API/RallyCurrency';
import { Site } from '../Models/Site';

export class SiteService {
    static basePath = "/site";

    static getSite = (domain: string): Promise<Site> => {
        return APIService.requests.get<Site>(`${this.basePath}/GetSite/${domain}`);
    }
    static updateSite = (site: Site): Promise<Site> => {
        return APIService.requests.post<Site>(`${this.basePath}/Update/`, site);
    }
    static getCreatorCurrency = (domain: string): Promise<RallyCurrency> => {
        var currency = APIService.requests.get<RallyCurrency>(`${this.basePath}/GetCreatorCurrency?domain=${domain}`);
        return currency;
    }
}
import { Box, Typography, Stack, Button, ImageList, ImageListItem, useTheme, useMediaQuery, Paper } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStore } from "../../Stores";
import { Delete, Done } from '@mui/icons-material';
import { File as FileModel } from "../../Models/API";
import { DropzoneArea } from 'material-ui-dropzone';
import { LoadingButton } from '@mui/lab';

const Consts = {
    padding: 4,
    rowHeight: 200,
    maxFileSize: 29000000
}

interface CellProps {
    item: FileModel;
    isSelected: boolean;
    onClick: (id: string) => void;
}

const ImageCell = ({ item, isSelected, onClick }: CellProps) => {
    return (
        <ImageListItem
            key={item.id}
            onClick={() => {
                onClick(item.id)
            }}
            sx={{
                border: isSelected ? '3px solid green' : undefined,
                borderRadius: `${Consts.padding}px`,
                padding: `${Consts.padding}px`,
                transition: 'all 0.15s',
                width: `${Consts.rowHeight}px`,
                height: `${Consts.rowHeight}px`,
            }}
        >
            <Paper
                elevation={isSelected ? 4 : 1}
                sx={{
                    width: '100%',
                    height: '100%'
                }}
            >
                <img
                    src={`${process.env.REACT_APP_API_URL}/Image?imageId=${item.id}&width=${Consts.rowHeight}`}
                    alt={item.name}
                    loading="lazy"
                    width='100%'
                    height='100%'
                    style={{
                        objectFit: 'contain',
                        overflow: 'hidden'
                    }}
                />
            </Paper>
        </ImageListItem>
    )
}

interface Props {
    onSelectImage: (id: string) => void;
}


export const ImageGalleryForm = observer(({ onSelectImage }: Props) => {
    const { modalStore, fileStore, toastBarStore } = useStore()

    const [selectedImageId, setSelectedImageId] = React.useState('');
    const [currentFile, setCurrentFile] = React.useState<File | undefined>();
    const [submitting, setSubmitting] = React.useState(false);
    const [preview, setPreview] = React.useState('');

    useEffect(() => {
        refreshImages()
    }, []) // Run once (dont delete it plz)

    const refreshImages = () => {
        fileStore.getSiteImages()
    }

    const handleFileChosen = (file: File) => {
        setCurrentFile(file)
        setPreview(URL.createObjectURL(file))
    };

    const uploadImage = () => {
        if (currentFile) {
            setSubmitting(true)
            const formData = new FormData()
            formData.append('image', currentFile);
            fileStore.uploadFile(currentFile.name, formData)
                .then((response) => {
                    onSelectImage(response.id)
                    modalStore.closeModal();
                })
                .finally(() => {
                    setSubmitting(false)
                })
        }
    }

    const deleteImage = () => {
        fileStore.deleteFile(selectedImageId)
            .then(() => {
                setSelectedImageId('')
                refreshImages()
            })

    }
    const selectImage = (id: string) => {
        onSelectImage(id)
        modalStore.closeModal();
    }

    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true
    });
    const isSM = useMediaQuery(theme.breakpoints.down('md'), {
        defaultMatches: true
    });
    const columns = (() => {
        if (isXS) return 1;
        if (isSM) return 2;
        return 3;
    })();

    return (
        <Stack direction='column' alignItems='center'>
            <Typography component="h1" variant="h5" flex='0 0 auto'>
                Select an Image
            </Typography>
            <Box sx={{ mt: 1, flex: 1 }}>
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent='space-between' m={2} spacing={2} >
                    <Button
                        disabled={selectedImageId === ''}
                        variant='contained'
                        color='error'
                        endIcon={<Delete />}
                        onClick={deleteImage}
                    >
                        Delete
                    </Button>
                    <Button
                        disabled={selectedImageId === ''}
                        variant='contained'
                        color='primary'
                        endIcon={<Done />}
                        onClick={() => selectImage(selectedImageId)}
                    >
                        Use Image
                    </Button>
                </Stack>
                <ImageList
                    className='hide-scrollbar'
                    sx={{
                        width: `${(columns * Consts.rowHeight) + ((columns + 1) * 2 * Consts.padding)}px`,
                        height: '500px',
                    }}
                    cols={columns}
                    rowHeight={Consts.rowHeight}
                >
                    {fileStore.images.map((item) =>
                        <ImageCell item={item}
                            isSelected={selectedImageId === item.id}
                            onClick={() => setSelectedImageId(item.id)}
                        />
                    )}
                </ImageList>
                {
                    preview
                        ?
                        <Paper
                            elevation={1}
                            sx={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} height='100%' flex='1 1 auto' alignItems='center'>
                                <img
                                    src={preview}
                                    alt='Preview'
                                    loading="lazy"
                                    width='100px'
                                    height='100px'
                                    style={{
                                        objectFit: 'contain',
                                        overflow: 'hidden'
                                    }}
                                />
                                <Typography overflow='hidden' sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '250px',
                                    display: 'inline'
                                }}>{currentFile ? currentFile.name : 'No filename'}</Typography>
                            </Stack>
                            <LoadingButton
                                loading={submitting}
                                variant='contained'
                                onClick={uploadImage}
                                sx={{
                                    flex: '0 0 auto',
                                    height: '50px',
                                    marginX: 2,
                                }}
                            >
                                {submitting ? 'Uploading...' : 'Upload image'}
                            </LoadingButton>
                        </Paper>

                        : undefined
                }
                <DropzoneArea
                    filesLimit={1}
                    maxFileSize={Consts.maxFileSize}
                    acceptedFiles={['image/*']}
                    dropzoneText={"Drag and drop an image here or click"}
                    onChange={(files) => {
                        if (files.length > 0) {
                            handleFileChosen(files[0])
                        }
                    }}
                    onDropRejected={(files) => {
                        const file = files[0]
                        if (file.type.startsWith('image/')) {
                            if (file.size > Consts.maxFileSize) {
                                toastBarStore.showMessage(`File is too large (${Math.floor(file.size/1000000)}MB). Please upload an image smaller than 29MB.`, 'error')
                            } else {
                                toastBarStore.showMessage(`There was a problem with that image. Please try another.`, 'error')
                            }
                        } else {
                            toastBarStore.showMessage(`File type not supported. Please upload an image file.`, 'error')
                        }
                    }}
                    showAlerts={false}
                    showPreviewsInDropzone={false}
                />
            </Box >
        </Stack >
    );
});
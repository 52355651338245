import { makeAutoObservable } from "mobx";

export enum PaletteMode {
    dark = "dark",
    light = "light"
}
export class PaletteColor {
    main: string = "#FFF"
    contrastText: string = "#000"

    constructor() {
        makeAutoObservable(this)
    }
}
export class PaletteText {
    primary: string = "#ED0000"
    secondary: string = "#0000ED"

    constructor() {
        makeAutoObservable(this)
    }
}
export class PaletteBackground {
    paper: string = "#FFF"
    default: string = "#FFF"

    constructor() {
        makeAutoObservable(this)
    }
}
export class Palette {
    mode: PaletteMode = PaletteMode.dark
    primary: PaletteColor = new PaletteColor()
    secondary: PaletteColor = new PaletteColor()
    text: PaletteText = new PaletteText()
    background: PaletteBackground = new PaletteBackground()

    constructor() {
        makeAutoObservable(this)
    }
}

export interface PaletteUpdater {
    mode?: PaletteMode;
    primaryMain?: string;
    primaryContrast?: string;
    secondaryMain?: string;
    secondaryContrast?: string;
    textPrimary?: string;
    textSecondary?: string;
    backgroundPaper?: string;
    backgroundDefault?: string;
};
import { Box, Paper, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { Collection, Template } from "../../Models/API/Collections";
import { useStore } from "../../Stores";
import { CollectionSelector } from "./CollectionSelector";
import { TemplateEditor } from "./TemplateEditor";
import { TemplateSelector } from "./TemplateSelector";

export const TemplatesEditor = observer(() => {
    const { collectionStore } = useStore();
    const [collection, setCollection] = React.useState<Collection>();
    const [template, setTemplate] = React.useState<Template>();

    const collectionSelector = (() => {
        return (
            <Stack spacing={1}>
                <Typography>Please select a collection</Typography>
                <CollectionSelector onSelect={setCollection} />
            </Stack>
        )
    })();
    const templateSelector = (() => {
        if (collection) {
            return (
                <Stack spacing={1}>
                    <Typography>Please select a template</Typography>
                    <TemplateSelector collectionId={collection.id} onSelect={setTemplate} />
                </Stack>
            )
        }
        return undefined
    })();
    const templatePreview = (() => {
        if (collection && template) {
            const fullTemplate = collectionStore.fullTemplates[template.id]
            if (fullTemplate) {
                return (
                    // <Stack spacing={2}>
                    // <Typography variant='h3'>{template.name}</Typography>
                    <TemplateEditor collectionId={collection.id} template={fullTemplate} onDelete={() => {
                        collectionStore.deleteTemplate(template.id, collection.id)
                        setTemplate(undefined)
                    }} />
                    // </Stack>
                )
            }
            collectionStore.getTemplateData(collection.id, template.id)
        }
        return undefined
    })();
    return (
        <Stack spacing={4}>
            <Paper sx={{ padding: 2 }}>
                <Stack spacing={2}>
                    {collectionSelector}
                    {templateSelector}
                </Stack>
            </Paper>
            {templatePreview}
        </Stack>
    )
});
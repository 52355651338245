import { APIService } from '.';
import { ActiveReferral, CompactUser, User } from '../Models/API';

export class UserService {
    static basePath = "/user";

    static getUser = (): Promise<User> => {
        return APIService.requests.get<User>(`${this.basePath}/GetUser`);
    }

    static searchUsers = (query: string): Promise<CompactUser[]> => {
        return APIService.requests.get<CompactUser[]>(`/Creator/UserSearch?nameOrEmail=${query}`);
    }

    static activeReferrals = (): Promise<ActiveReferral[]> => {
        return APIService.requests.get<ActiveReferral[]>(`/Rewards/GetActiveReferrals`);
    }

    public static deleteUser() {
        return APIService.requests.del(this.basePath)
    }
}
import { Summarize } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import { Paper } from "@mui/material";
import { CardContent } from "@mui/material";
import { CardHeader } from "@mui/material";
import { Card } from "@mui/material";
import { Grid } from "@mui/material";
import { Stack } from "@mui/material";
import { useState } from "react";

export const AdminData = () => {
    const baseStart = new Date("01/01/2022");
    const baseEnd = new Date();
    const [startValue, setStartValue] = useState<Date | null>(baseStart);
    const [endValue, setEndValue] = useState<Date | null>(baseEnd);
    const baseUrl = process.env.REACT_APP_API_URL;

    const handleStartChange = (value1: Date | null) => {
        setStartValue(value1);
    };
    const handleEndChange = (value2: Date | null) => {
        setEndValue(value2);
    };

    return (

        <Grid container direction='column' alignItems='center'>
            <Grid item>
                <Paper sx={{ maxWidth: 500, minWidth: 500, width: 1000 }}>
                    <Card sx={{ display: 'flex', flexDirection: 'column', mt: 5, alignItems: 'leading' }}>
                        <CardHeader
                            avatar={<Summarize color='primary' />}
                            title="Rewards Report"
                        />
                        <CardContent sx={{ flex: '1 0 auto', display: 'flex', flexDirection: 'column', margin: 5, height: 500 }}>
                            <Stack spacing={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Start Date"
                                        value={startValue}
                                        onChange={handleStartChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="End Date"
                                        value={endValue}
                                        onChange={handleEndChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                                <Button variant="contained" href={baseUrl + `/Admin/GetRecordsCsv?startDate=${startValue?.toLocaleDateString("en-US")}&endDate=${endValue?.toLocaleDateString("en-US")}`}>Download</Button>
                            </Stack>
                        </CardContent>
                    </Card>
                </Paper>
            </Grid>
        </Grid>


    )
}
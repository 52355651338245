import { observer } from "mobx-react-lite"
import { Button, Checkbox, FormControlLabel, Stack, TextField } from '@mui/material';

import { useStore } from '../../../Stores';
import { CMSColorPicker, CMSEditButtons } from '..';
import { Header as HeaderModel } from '../../../Models/Site';
import { ImageGalleryForm, TemplateKeyPicker } from "../../Forms";

interface Props {
    data: HeaderModel;
    collectionId?: string; // indicates template mode
}

export const CMSHeader = observer(({ data, collectionId }: Props) => {
    const { cmsStore, modalStore } = useStore();
    const { working_model } = cmsStore;
    let isEditing = cmsStore.editedItemId === data.id;
    let dataToUse: HeaderModel = isEditing ? (working_model as HeaderModel) : data

    const headingTemplateOptions = (() => {
        if (collectionId) {
            const currentKey = (dataToUse.heading && dataToUse.heading.startsWith('{{')) ? dataToUse.heading : ''
            return (
                <Button
                    variant='outlined'
                    disabled={!isEditing}
                    onClick={() => {
                        modalStore.openModal(<TemplateKeyPicker currentKey={currentKey} collectionId={collectionId} onSelect={(key: string) => {
                            cmsStore.updateHeader({ heading: `{{${key}}}` })
                        }} />)
                    }}
                >
                    Select Heading Parameter
                </Button>
            )
        }
        return undefined
    })();

    const subheadingTemplateOptions = (() => {
        if (collectionId) {
            const currentKey = (dataToUse.subHeading && dataToUse.subHeading.startsWith('{{')) ? dataToUse.subHeading : ''
            return (
                <Button
                    variant='outlined'
                    disabled={!isEditing}
                    onClick={() => {
                        modalStore.openModal(<TemplateKeyPicker currentKey={currentKey} collectionId={collectionId} onSelect={(key: string) => {
                            cmsStore.updateHeader({ subHeading: `{{${key}}}` })
                        }} />)
                    }}
                >
                    Select Sub Heading Parameter
                </Button>
            )
        }
        return undefined
    })();
    return (
        <Stack direction="column" spacing={2} justifyContent="space-between">
            <CMSEditButtons
                id={data.id}
                index={data.index}
            />
            <h5>ID: {data.id}</h5>
            <TextField
                id="name"
                label="Name"
                value={dataToUse.name}
                onChange={(e) => cmsStore.updateHeader({ name: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <Stack direction='row' justifyContent='space-between' >
                <Button
                    variant='outlined'
                    disabled={!isEditing}
                    onClick={() => {
                        modalStore.openModal(<ImageGalleryForm onSelectImage={(id: string) => {
                            cmsStore.updateHeader({ backgroundImageId: id })
                        }} />)
                    }}>
                    {dataToUse.backgroundImageId ? 'Change' : 'Select'} Background Image
                </Button>
                {
                    dataToUse.backgroundImageId ?
                        <Button disabled={!isEditing} onClick={() => cmsStore.updateHeader({ backgroundImageId: '' })}>
                            Clear Background Image
                        </Button>
                        : undefined
                }
            </Stack>
            <TextField
                id="heading"
                label="Heading"
                value={dataToUse.heading}
                onChange={(e) => cmsStore.updateHeader({ heading: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            {headingTemplateOptions}
            <CMSColorPicker
                id='headingColor'
                label="Heading Color"
                value={dataToUse.headingColor}
                onChange={(value) => cmsStore.updateHeader({ headingColor: value })}
                disabled={!isEditing}
            />
            <TextField
                id="subHeading"
                label="Sub Heading"
                value={dataToUse.subHeading}
                onChange={(e) => cmsStore.updateHeader({ subHeading: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            {subheadingTemplateOptions}
            <CMSColorPicker
                id='subHeadingColor'
                label="Sub Heading Color"
                value={dataToUse.subHeadingColor}
                onChange={(value) => cmsStore.updateHeader({ subHeadingColor: value })}
                disabled={!isEditing}
            />
            <FormControlLabel
                disabled={!isEditing}
                control={
                    <Checkbox
                        id="fullScreen"
                        checked={(dataToUse.fullScreen)}
                        onChange={(_, isChecked) => cmsStore.updateHeader({ fullScreen: isChecked })}
                    />
                }
                label="Fullscreen"
            />
        </Stack>
    );
});
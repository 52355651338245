import { LoadingButton } from "@mui/lab";
import { Link, Box, Typography, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../../Stores";
import { LoginForm, MaxWidth } from ".";

interface Props {
    userId: string,
    token: string
}

export const ChangePasswordForm = observer(({ userId, token }: Props) => {
    const { accountStore, modalStore, toastBarStore } = useStore()

    const [error, setError] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [success, setSuccess] = React.useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const password = data.get('password')?.toString()

        if (password) {
            setError(false)
            setSubmitting(true)
            accountStore
                .changePassword({ userId: userId, token: token, newPassword: password })
                .then(() => {
                    toastBarStore.showMessage('Your password has been updated. Please sign in.', 'success')
                    setSuccess(true)
                })
                .catch(() => {
                    setSuccess(false)
                })
                .finally(() => {
                    setSubmitting(false)
                });
        } else {
            setError(true)
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                maxWidth: `${MaxWidth}px`,
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Typography component="h1" variant="h5">
                Change Password
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    error={error}
                    onChange={() => setError(false)}
                    required
                    fullWidth
                    id="password"
                    label="New Password"
                    name="password"
                    autoComplete="new-password"
                    type="password"
                    autoFocus
                />
                <LoadingButton
                    type="submit"
                    loading={submitting}
                    disabled={toastBarStore.open}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    {success ? 'Password updated' : 'Change password'}
                </LoadingButton>
                <Link hidden={accountStore.isLoggedIn} variant="body2" onClick={() => modalStore.openModal(<LoginForm />)}>
                    Go back to Sign In
                </Link>
            </Box>
        </Box>
    );
});
import {observer} from "mobx-react-lite"
import {Autocomplete, Button, Checkbox, FormControlLabel, Stack, TextField} from '@mui/material';

import {useStore} from '../../../Stores';
import {CMSActionBar, CMSColorPicker, CMSEditButtons, CMSWidthField} from '..';
import {ImageGalleryForm} from "../../Forms";
import {
    Color,
    ColorType,
    Container as ContainerModel,
    ContainerAlignment,
    ContainerDirection,
    ContainerJustification,
    Type
} from '../../../Models/Site';

interface Props {
    data: ContainerModel;
    collectionId?: string;
}

export const CMSContainer = observer(({ data, collectionId }: Props) => {
    const { cmsStore, modalStore } = useStore();
    const { working_model } = cmsStore;
    let isEditing = cmsStore.editedItemId === data.id;

    let dataToUse: ContainerModel = isEditing ? (working_model as ContainerModel) : data
    if(!dataToUse.colorCustom) dataToUse.colorCustom = "#000000"; //Ensures that the color picker has a value to display or label gets all messed up.
    const parent = cmsStore.getParent(dataToUse.id)
    const undeletable = collectionId ? parent && parent.type === Type.page : false
    
    return (
        <Stack direction="column" spacing={2} justifyContent="space-between">
            <CMSEditButtons
                id={data.id}
                index={data.index}
                undeletable={undeletable}
            />
            <h5>ID: {data.id}</h5>
            <TextField
                id="name"
                label="Name"
                value={dataToUse.name}
                onChange={(e) => cmsStore.updateContainer({ name: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <CMSWidthField
                value={dataToUse.width.toString()}
                onChange={(newValue) => cmsStore.updateContainer({ width: +(newValue) })}
                disabled={!isEditing}
            />
            <FormControlLabel
                disabled={!isEditing}
                control={
                    <Checkbox
                        id="fullScreen"
                        checked={(dataToUse.fullScreen)}
                        onChange={(_, isChecked) => cmsStore.updateContainer({ fullScreen: isChecked })}
                    />
                }
                label="Fullscreen"
            />
            <Autocomplete
                id="color"
                value={dataToUse.color}
                onChange={(_, newValue) => cmsStore.updateContainer({ color: newValue as Color })}
                options={Object.values(Color)}
                disabled={!isEditing}
                renderInput={(params) => (<TextField {...params} label="Color" />)}
            />
            {(dataToUse.color === Color.primary || dataToUse.color === Color.secondary) ?
                <Autocomplete
                    id="colorType"
                    value={dataToUse.colorType}
                    onChange={(_, newValue) => cmsStore.updateContainer({ colorType: newValue as ColorType })}
                    options={Object.values(ColorType)}
                    disabled={!isEditing}
                    renderInput={(params) => (<TextField {...params} label="Color Type" />)}
                />
                : null
            }
            {dataToUse.color == Color.custom ?
                <CMSColorPicker
                id="colorCustom"
                label="Custom Color"
                value={dataToUse.colorCustom}
                onChange={(c) => cmsStore.updateContainer({ colorCustom: c })}
                disabled={!isEditing || dataToUse.color !== Color.custom}
                />
                : null}
            <TextField
                id="marginBottom"
                label="Bottom Margin"
                value={dataToUse.marginBottom}
                onChange={(e) => cmsStore.updateContainer({ marginBottom: +e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <TextField
                id="cornerRadius"
                label="Corner Radius"
                value={dataToUse.cornerRadius}
                onChange={(e) => cmsStore.updateContainer({ cornerRadius: +e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <TextField
                id="padding"
                label="Padding"
                value={dataToUse.padding}
                onChange={(e) => cmsStore.updateContainer({ padding: +e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <TextField
                id="spacing"
                label="Spacing"
                value={dataToUse.spacing ?? 4}
                onChange={(e) => cmsStore.updateContainer({ spacing: +e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <Stack direction='row' justifyContent='space-between' >
                <Button
                    variant='outlined'
                    disabled={!isEditing}
                    onClick={() => {
                        modalStore.openModal(<ImageGalleryForm onSelectImage={(id: string) => {
                            cmsStore.updateContainer({ backgroundImageId: id })
                        }} />)
                    }}>
                    {dataToUse.backgroundImageId ? 'Change' : 'Select'} Background Image
                </Button>
                {
                    dataToUse.backgroundImageId ?
                        <Button disabled={!isEditing} onClick={() => cmsStore.updateContainer({ backgroundImageId: '' })}>
                            Clear Background Image
                        </Button>
                        : undefined
                }
            </Stack>
            <Autocomplete
                id="direction"
                value={dataToUse.direction}
                onChange={(_, newValue) => cmsStore.updateContainer({ direction: newValue as ContainerDirection })}
                options={Object.values(ContainerDirection)}
                disabled={!isEditing}
                renderInput={(params) => (<TextField {...params} label="Direction" />)}
            />
            <Autocomplete
                id="alignment"
                value={dataToUse.alignment}
                onChange={(_, newValue) => cmsStore.updateContainer({ alignment: newValue as ContainerAlignment })}
                options={Object.values(ContainerAlignment)}
                disabled={!isEditing}
                renderInput={(params) => (<TextField {...params} label="Alignment" />)}
            />
            <Autocomplete
                id="justification"
                value={dataToUse.justification}
                onChange={(_, newValue) => cmsStore.updateContainer({ justification: newValue as ContainerJustification })}
                options={Object.values(ContainerJustification)}
                disabled={!isEditing}
                renderInput={(params) => (<TextField {...params} label="Justification" />)}
            />
            <CMSActionBar id={data.id} />
        </Stack>
    );
});

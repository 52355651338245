import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Card, CardActionArea, CardContent, CardMedia, Collapse, List, ListItemButton, ListItemText, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { PlaylistSection as PlaylistSectionModel } from "../../../../Models/Site";
import { useStore } from "../../../../Stores";

interface Props {
    data: PlaylistSectionModel
}
export const PlaylistSection = observer(({data}: Props) => {
    const { playlistStore } = useStore();
    return (
        <Box key={`Box${data.id}`}>
            <ListItemButton
                key={`ListItemButton${data.id}-${data.index}`}
                onClick={() => playlistStore.toggleSection(data.id)}
                sx={{ 
                    backgroundColor: 'secondary.main',
                    marginBottom: 1
                }}
            >
                <ListItemText 
                    primary={<Typography variant='button' color='primary.main'>{data.title}</Typography>}
                />
                {playlistStore.isSectionOpen(data.id) ? <ExpandLess color='primary' /> : <ExpandMore color='primary' />}
            </ListItemButton>
            <Collapse
                key={`Collapse${data.id}`}
                in={playlistStore.isSectionOpen(data.id)}
            >
                <List key={`List${data.id}`} component="div" disablePadding>
                    {data.videos.map((video)=>{
                        return (
                            <Card
                                key={video.id}
                                sx={{
                                    width: 1,
                                    marginBottom: 1
                                }}
                            >
                                <CardActionArea 
                                    onClick={()=>{
                                        playlistStore.selectVideo(video.id);
                                    }}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start'
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        image={video.thumbnailUrl}
                                        alt={video.title}
                                        sx={{ width: '100px' }}
                                    />
                                    <CardContent sx={{
                                        bgcolor: 'secondary.main',
                                        flexGrow: 1
                                    }}>
                                        <Typography gutterBottom variant="body1" color='secondary.contrastText' component="div">
                                            {video.title}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        );
                    })}
                </List>
            </Collapse>
        </Box>
    );
});
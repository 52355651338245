import { makeAutoObservable } from "mobx";

export class PageCondition {
    id: string = "";
    hasConditions: boolean = false;
    twitch: string = "0";
    patreon: number = 0;
    rally: number = 0;

    constructor() {
        makeAutoObservable(this)
    }
}

export interface PageConditionUpdater {
    hasConditions?: boolean;
    twitch?: string;
    patreon?: number;
    rally?: number;
}
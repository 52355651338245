import { observer } from "mobx-react-lite"

interface Props {
    title: string
    videoId: string
}

export const YouTube = observer(({ title, videoId }: Props) => {
    return (
        <div
            className="video"
            style={{
                position: "relative",
                paddingBottom: "56.25%" /* 16:9 */,
                paddingTop: 25,
                height: 0
            }}
        >
            <iframe
                title={title}
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                }}
                src={`https://www.youtube.com/embed/${videoId}`}
                frameBorder="0"
            />
        </div>
    )
});
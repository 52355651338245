import { Snackbar, Alert, Button } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../Stores";

export const ToastBar = observer(() => {
    const { toastBarStore } = useStore()
    const handleCloseSnackBar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        toastBarStore.closeMenu()
    };
    const button = (() => {
        if (toastBarStore.actionTitle) {
            return (
                <Button color="inherit" size="small" onClick={() => {
                    toastBarStore.action && toastBarStore.action()
                }}>
                    {toastBarStore.actionTitle}
                </Button>
            )
        }
        return undefined
    })();
    return (
        <Snackbar open={toastBarStore.open} autoHideDuration={toastBarStore.autoHideDuration} onClose={handleCloseSnackBar}>
            <Alert
                variant="filled"
                onClose={handleCloseSnackBar}
                severity={toastBarStore.severity}
                action={button}
                sx={{
                    width: '100%'
                }}>
                {toastBarStore.message}
            </Alert>
        </Snackbar>
    )
});
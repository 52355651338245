import {observer} from "mobx-react-lite";
import {useStore} from "../../../Stores";
import {useEffect, useState} from "react";
import {Button, Grid, Paper, Typography} from "@mui/material";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {LoadingComponent} from "../../Util";
import {SplToken} from "../../../Models/API/Spl";
import {AdminAddSplTokenModal} from "./";

const columns: GridColDef[] = [
    {field: 'id', headerName: 'Key', width: 450},
    {
        field: 'name',
        headerName: 'Name',
        width: 150
    },
    {
        field: 'ticker',
        headerName: 'Ticker',
        width: 120
    }
];

const createData = (
        id: string,
        name: string,
        ticker: string
) => {
    return {id, name, ticker};
}

export const AdminSplGrid = observer(() => {
    const {adminStore, modalStore} = useStore();
    const [refreshing, setRefreshing] = useState(false);
    const {getSplTokens, splTokens, selectSplToken, selectedSplToken, deleteSplToken} = adminStore;
    let data: SplToken[] | undefined = splTokens;
    let rows = [];
    if (data) {
        for (let i = 0; i < data.length; i++) {
            rows.push(createData(data[i].key, data[i].name, data[i].ticker))
        }
    }
    const refreshSplTokens = () => {
        if (refreshing) {
            return
        }
        setRefreshing(true)
        getSplTokens().finally(() => {
            setRefreshing(false)
        })
    }
    useEffect(() => {
        refreshSplTokens()
    }, [])
    if (rows && !refreshing) {
        return (
            <Grid container>
                <Grid item component={Paper}>
                    <Typography variant="h4" border={1} borderColor="slategrey" align="center" padding={0.5}>
                        Spl Tokens
                    </Typography>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[5]}
                        sx={{backgroundColor: "paper", width: 725}}
                        autoPageSize
                        autoHeight
                        onSelectionModelChange={(ids) => {
                            if (data) {
                                const selectedIDs = new Set(ids);
                                const selectedRows = data.filter((row) => selectedIDs.has(row.key)
                                );
                                selectSplToken(selectedRows[0]);
                            }
                        }}
                    />
                    <Button onClick={() => modalStore.openModal(<AdminAddSplTokenModal/>)}>Add Token</Button>
                    <Button color="error" onClick={() => deleteSplToken(selectedSplToken?.key)}>Delete Token</Button>

                </Grid>
            </Grid>
        )
    } else {
        return (
            <LoadingComponent/>
        )
    }
})

import { observer } from "mobx-react-lite"
import { Dialog, DialogTitle, List, ListItem, ListItemText} from '@mui/material';
import { ContentFactory, Type } from "../../../Models/Site";
import { useStore } from "../../../Stores";

interface Props {
    id: string;
    open: boolean;
}

const excluded = [
    Type.site,
    Type.theme,
    Type.navBar,
    Type.navBarItem,
    Type.page,
    Type.blocked,
    Type.footer,
    Type.playlistSection
]
const contentTypes = Object.values(Type).filter((e)=>excluded.indexOf(e) === -1);


export const CMSContentPicker = observer(({ id, open }: Props) => {
    const { cmsStore, modalStore } = useStore();

    const handleClose = () => {
        modalStore.closeModal();
    };

    const handleListItemClick = (value: string) => {
        if (value as keyof typeof Type) {
            let content = ContentFactory.model(Type[value as keyof typeof Type])
            if (content) cmsStore.addModel(content, id)
        }
        handleClose();
    };
    return (
        <Dialog onClose={handleClose} open={open}>
          <DialogTitle>Select content to add:</DialogTitle>
          <List sx={{ pt: 0 }}>
            {contentTypes.map((type) => (
                <ListItem button onClick={() => handleListItemClick(type)} key={type}>
                    <ListItemText primary={type} />
                </ListItem>
            ))}
          </List>
        </Dialog>
      );
});
import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react";
import { CollectionDataAllowedCreator } from "../../../Models/API/Collections";
import { useStore } from "../../../Stores";
import { AdminCreatorList } from "../AdminCreators";

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 300 },
    {
        field: 'name',
        headerName: 'Name',
        width: 150
    }
];
const createData = (
    id: string,
    name: string
) => {
    return { id, name };
}

export const AdminCollectionsPermissions = observer(() => {
    const { adminStore, modalStore } = useStore();
    const { selectedCollection, getCreator, selectedCreator, AddCollectionToCreator, RemoveCollectionFromCreator } = adminStore;
    let data: CollectionDataAllowedCreator[] | undefined = selectedCollection?.allowedCreators;
    let rows = [];
    if (data) {
        for (var i = 0; i < data.length; i++) {
            rows.push(createData(data[i].id, data[i].name));
        }
    }

    return (
        <>
            <Grid container direction="row">
                <Grid item>
                    <Grid container component={Paper} direction="column" alignContent="center">
                        <Grid item margin={2}>
                            <Typography align="center" variant="h4">
                                {selectedCollection?.name != null ? selectedCollection.name : "Select Collection"}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TableContainer>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                Title
                                            </TableCell>
                                            <TableCell>
                                                {selectedCollection?.title}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Owner
                                            </TableCell>
                                            <TableCell>
                                                {selectedCollection?.ownerName}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Collection API
                                            </TableCell>
                                            <TableCell>
                                                {selectedCollection?.collectionApiName}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>

                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid item margin={2}>
                                <Typography align="center" variant="h4">
                                    Allowed Creators
                                </Typography>
                            </Grid>
                            <Grid item>
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    pageSize={10}
                                    rowsPerPageOptions={[5]}
                                    sx={{ backgroundColor: "paper", width: 725 }}
                                    autoPageSize
                                    autoHeight
                                    onSelectionModelChange={(ids) => {
                                        if (data) {
                                            const selectedIDs = new Set(ids);
                                            const selectedRows = data.filter((row) => selectedIDs.has(row.id)
                                            );
                                            getCreator(selectedRows[0].id);
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Box component={Paper} padding={2}>
                        <Typography align="center" variant="h4" marginBottom={2}>
                            Creators
                        </Typography>
                        <AdminCreatorList />

                        <Typography align="center" variant="h4" margin={2}>
                            {selectedCreator != null ? selectedCreator.name : "Selected Creator"}
                        </Typography>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            Name
                                        </TableCell>
                                        <TableCell>
                                            {selectedCreator?.name}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            Add To Collection
                                        </TableCell>
                                        <TableCell>
                                            {selectedCreator && selectedCollection ? <Button
                                                variant='contained'
                                                onClick={() => AddCollectionToCreator(selectedCreator?.id, selectedCollection?.id)}>
                                                Add
                                            </Button> : null}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            Remove From Collection
                                        </TableCell>
                                        <TableCell>
                                            {selectedCreator && selectedCollection ? <Button
                                                variant='contained'
                                                onClick={() => RemoveCollectionFromCreator(selectedCreator?.id, selectedCollection?.id)}>
                                                Remove
                                            </Button> : null}
                                        </TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Grid>
            </Grid>
        </>

    )
})
import {Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import {Text as TextModel, TextColor} from "../../../Models/Site"

interface Props {
    data: TextModel
    color: string
}
export const Text = observer(({data, color}: Props) => {
    const textColor = data.textColor == TextColor.default ? color : data.textColorCustom;
    return (
        <Typography key={data.id} variant={data.variant} align={data.align} color={textColor} width='100%'>
            {data.text}
        </Typography>
    )
});

import { observer } from "mobx-react-lite"
import { Button, Stack } from '@mui/material';
import { Save, Edit, Delete } from '@mui/icons-material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { useStore } from '../../../Stores';
import { Site, Type, Model } from "../../../Models/Site";


interface Props {
    id: string;
    index: number;
    undeletable?: boolean;
}

export const CMSEditButtons = observer(({ id, index, undeletable = false }: Props) => {
    const { cmsStore, cmsNavBarStore } = useStore();
    let isEditing = cmsStore.editedItemId === id;

    const editButtons = ((): React.ReactNode => {
        if (isEditing) {
            return (
                <Stack direction="row" spacing={2}>
                    <Button
                        onClick={() => cmsStore.save()}
                        variant="contained"
                        endIcon={<Save />}
                    >
                        Save Changes
                    </Button>
                    <Button
                        onClick={() => cmsStore.setEditing(id, false)}
                        variant="contained"
                        endIcon={<Delete />}
                        color="error"
                    >
                        Discard Changes
                    </Button>

                </Stack >
            )
        } else {
            return (
                <Button
                    onClick={() => cmsStore.setEditing(id, true)}
                    variant="contained"
                    endIcon={<Edit />}
                >
                    Edit
                </Button>
            )
        }
    })();

    const reorderButtons = ((): React.ReactNode => {
        const parent = cmsStore.getParent(id)
        if (parent && !isEditing && !undeletable) {
            const itemCount = parent.type === Type.site ? (parent as Site).pages.length : Model.itemsInModel(parent).length
            return (
                <Stack direction="row" spacing={2}>
                    {index > 0 ?
                        <Button
                            onClick={() => cmsStore.move(id, index - 1)}
                            variant="contained"
                            endIcon={<ArrowUpwardIcon />}
                        >
                            Move Up
                        </Button>
                        : undefined}
                    {index < (itemCount - 1) ?
                        <Button
                            onClick={() => cmsStore.move(id, index + 1)}
                            variant="contained"
                            endIcon={<ArrowDownwardIcon />}
                        >
                            Move Down
                        </Button>
                        : undefined}
                </Stack>
            );
        }
        return (
            <></>
        );
    })();
    const deleteButton = ((): React.ReactNode => {
        if (!undeletable && !isEditing) {
            return (
                <Button
                    onClick={() => {
                        let parentId = cmsStore.delete(id)
                        if (parentId) {
                            cmsNavBarStore.open(parentId, true)
                            cmsStore.select(parentId)
                        }
                    }}
                    variant="contained"
                    endIcon={<Delete />}
                    color="error"
                    disabled={isEditing}
                >
                    Delete
                </Button>
            )
        }
        return (
            <>
            </>
        )
    })();
    return (
        <Stack direction="row" spacing={2} justifyContent="space-between">
            {editButtons}
            {reorderButtons}
            {deleteButton}
        </Stack>
    )
});
import { Stack, Box, Card, CardHeader, CardContent, List, ListItem, IconButton, CircularProgress, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Badge, Delete, Refresh, Add } from '@mui/icons-material';

import { useStore } from "../../Stores";
import { CompactUser } from "../../Models/API";
import { UserSearcher } from "../Common/UserSearcher";
import Consts from "../Common/Consts";

export const CreatorStaff = observer(() => {
    const { creatorStore } = useStore();
    const { staffList } = creatorStore;

    const [addStaffOpen, setAddStaffOpen] = React.useState(false);
    const [refreshing, setRefreshing] = React.useState(false);
    const [staffId, setStaffId] = React.useState('');
    const [addStaffError, setAddStaffError] = React.useState(false);

    const handleCloseDialog = () => {
        setAddStaffOpen(false)
    }
    const openAddStaffDialog = () => {
        setAddStaffOpen(true)
    }
    const addStaff = () => {
        if (staffId) {
            setAddStaffOpen(false)
            setRefreshing(true)
            creatorStore.addStaff(staffId).finally(() => {
                setStaffId('')
                setRefreshing(false)
            })
        }
    }
    const refreshStaff = () => {
        if (refreshing) { return }
        setRefreshing(true)
        creatorStore.getStaff().finally(() => {
            setRefreshing(false)
        })
    }

    useEffect(() => {
        refreshStaff()
    }, []) // Run once (dont delete it plz)

    const deleteStaff = (id: string) => {
        setRefreshing(true)
        creatorStore.deleteStaff(id).finally(() => {
            setRefreshing(false)
        })
    }

    const listItem = (staff: CompactUser) => {
        return (
            <ListItem
                sx={{
                    mb: 2,
                    borderBottom: '1px solid rgba(255, 255, 255, 0.25)'
                }}
                key={staff.id}
                secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={() => deleteStaff(staff.id)}>
                        <Delete />
                    </IconButton>
                }>
                <Stack>
                    <Typography variant='body1'>{staff.userName}</Typography>
                    <Typography variant='body2'>{staff.email}</Typography>
                </Stack>
            </ListItem>
        )
    }

    const items = staffList ? staffList.map(listItem) : []
    const content = (() => {
        if (refreshing) {
            return (
                <Box display='flex' flexDirection='row' paddingX={1} paddingY={2}>
                    <Typography variant='body1' color='primary.main' sx={{ flex: 1 }}>Loading staff</Typography>
                    <CircularProgress sx={{ flex: '0 1 auto' }} size={25} color="primary" />
                </Box>
            )
        } else if (items.length > 0) {
            return (
                <List>
                    {items}
                </List>
            )
        } else {
            return (
                <Typography variant='body1' color='primary.main' sx={{ flex: 1 }}>No staff</Typography>
            )
        }
    })()

    const setUser = (user: CompactUser | null) => {
        if (user && staffList) {
            if (staffList.findIndex((staff) => { return user.id === staff.id }) < 0) {
                setStaffId(user.id)
                setAddStaffError(false)
            } else {
                setStaffId('');
                setAddStaffError(true)
            }
        } else {
            setStaffId('');
            setAddStaffError(false)
        }
    }

    return (
        <Stack direction='column' alignItems='center'>
            <Box sx={Consts.containerSX}>
                <Card sx={{ display: 'flex', flexDirection: 'column', mt: 5, alignItems: 'leading' }} >
                    <CardHeader
                        avatar={<Badge color='primary' />}
                        title="Staff"
                        action={
                            <>
                                <IconButton aria-label="add" onClick={openAddStaffDialog}>
                                    <Add />
                                </IconButton>
                                <IconButton aria-label="settings" onClick={refreshStaff} disabled={refreshing}>
                                    <Refresh />
                                </IconButton>
                            </>
                        }
                    />
                    <CardContent sx={{ flex: '1 0 auto', display: 'flex', flexDirection: 'column' }}>
                        {content}
                    </CardContent>
                </Card>
            </Box>
            <Dialog open={addStaffOpen} onClose={handleCloseDialog}>
                <Box sx={{ width: '400px' }}>
                    <DialogTitle>Add Staff</DialogTitle>
                    <DialogContent>
                        <UserSearcher onChange={setUser} error={addStaffError} errorText={addStaffError ? 'This user is already on your staff list' : undefined} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>Cancel</Button>
                        <Button variant='contained' onClick={addStaff} disabled={staffId === ''}>Add Staff</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </Stack>
    )
});
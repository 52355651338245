import { Stack, Box, Card, CardHeader, CardContent, List, ListItem, IconButton, CircularProgress, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { Link, Delete, Refresh, Add } from '@mui/icons-material';
import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";

import { useStore } from "../../Stores";
import { SocialsLink } from "../../Models/Site";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import Consts from "../Common/Consts";

export const CreatorSocials = observer(() => {
    const { creatorStore } = useStore();
    const { socials } = creatorStore;

    const [addSocialOpen, setAddSocialOpen] = React.useState(false);
    const [refreshing, setRefreshing] = React.useState(false);
    const [socialType, setSocialType] = React.useState('');
    const [socialUrl, setSocialUrl] = React.useState('https://');
    const [urlError, setUrlError] = React.useState(false);

    const handleCloseDialog = () => {
        setAddSocialOpen(false)
    }
    const openAddSocialDialog = () => {
        setAddSocialOpen(true)
    }
    const addSocial = () => {
        if (socialUrl && !urlError) {
            setAddSocialOpen(false)
            setRefreshing(true)
            creatorStore.addSocial(socialType, socialUrl).finally(() => {
                setRefreshing(false)
            })
            setSocialUrl('https://')
            setSocialType('')
        }
    }
    const refreshSocials = () => {
        if (refreshing) { return }
        setRefreshing(true)
        creatorStore.getSocials().finally(() => {
            setRefreshing(false)
        })
    }

    useEffect(() => {
        refreshSocials()
    }, []) // Run once (dont delete it plz)

    const deleteSocial = (socialId: string) => {
        setRefreshing(true)
        creatorStore.deleteSocial(socialId).finally(() => {
            setRefreshing(false)
        })
    }
    const listItem = (social: SocialsLink) => {
        return (
            <ListItem
                sx={{
                    mb: 2,
                    borderBottom: '1px solid rgba(255, 255, 255, 0.25)'
                }}
                key={social.id}
                secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={() => deleteSocial(social.id)}>
                        <Delete />
                    </IconButton>
                }>
                <FontAwesomeIcon icon={SocialsLink.iconProp({ id: "some-id", type: social.type, url: social.url })} size="lg" />
                <Typography variant="body1" marginLeft={2}>
                    {social.url}
                </Typography>
            </ListItem>
        )
    }

    const items = socials ? socials.map(listItem) : []
    const content = (() => {
        if (refreshing) {
            return (
                <Box display='flex' flexDirection='row' paddingX={1} paddingY={2}>
                    <Typography variant='body1' color='primary.main' sx={{ flex: 1 }}>Loading socials</Typography>
                    <CircularProgress sx={{ flex: '0 1 auto' }} size={25} color="primary" />
                </Box>
            )
        } else if (items.length > 0) {
            return (
                <List>
                    {items}
                </List>
            )
        } else {
            return (
                <Typography variant='body1' color='primary.main' sx={{ flex: 1 }}>No socials</Typography>
            )
        }
    })()

    return (
        <Stack direction='column' alignItems='center'>
            <Box sx={Consts.containerSX}>
                <Card sx={{ display: 'flex', flexDirection: 'column', mt: 5, alignItems: 'leading' }} >
                    <CardHeader
                        avatar={<Link color='primary' />}
                        title="Socials"
                        action={
                            <>
                                <IconButton aria-label="add" onClick={openAddSocialDialog}>
                                    <Add />
                                </IconButton>
                                <IconButton aria-label="settings" onClick={refreshSocials} disabled={refreshing}>
                                    <Refresh />
                                </IconButton>
                            </>
                        }
                    />
                    <CardContent sx={{ flex: '1 0 auto', display: 'flex', flexDirection: 'column' }}>
                        {content}
                    </CardContent>
                </Card>
            </Box>
            <Dialog open={addSocialOpen} onClose={handleCloseDialog}>
                <Box sx={{ width: '400px' }}>
                    <DialogTitle>Add Social Account</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="socialUrl"
                            label="URL"
                            fullWidth
                            variant="standard"
                            value={socialUrl}
                            error={urlError}
                            helperText={urlError ? 'Please enter a valid url (e.g. https://www.youtube.com/c/nodia)' : undefined}
                            onChange={(e) => {
                                setSocialUrl(e.target.value);
                                try {
                                    new URL(e.target.value);
                                    setUrlError(false)
                                    const regex = /https:\/\/(?:www.)?(.+?)\./i;
                                    const name = e.target.value.match(regex)
                                    if (name && name.length > 0 && SocialsLink.isValidIcon(name[1])) {
                                        setSocialType(name[1]);
                                    }
                                } catch {
                                    setUrlError(true)
                                }
                            }}
                        />
                        <Stack direction='row' alignItems='center' spacing={2} marginTop={1}>
                            <FontAwesomeIcon icon={SocialsLink.iconProp({ id: "some-id", type: socialType.toLowerCase() as IconName, url: '' })} size="lg" fixedWidth />
                            <TextField
                                margin="dense"
                                id="socialType"
                                label="Icon (Optional)"
                                fullWidth
                                variant="standard"
                                value={socialType}
                                onChange={(e) => setSocialType(e.target.value.toLowerCase())}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>Cancel</Button>
                        <Button variant='contained' onClick={addSocial}>Add Social</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </Stack>
    )
});

import { action, makeAutoObservable, } from "mobx"
import { File } from "../Models/API";
import { FileService } from "../Services";
import { CommonStore, Store } from ".";

export class FileStore {
    commonStore: CommonStore;
    images: File[] = [];
    files: File[] = [];

    constructor(store: Store) {
        this.commonStore = store.commonStore;
        makeAutoObservable(this)
    }

    @action setImages = (images: File[]) => {
        this.images = images;
    }

    @action setFiles = (files: File[]) => {
        if (files) {
            this.files = files;
        }
    }

    getSiteFiles = async () => {
        try {
            if (this.commonStore.currentSite) {
                const response = await FileService.getFileList(this.commonStore.currentSite.id)
                this.setFiles(response)
            } else {
                throw new Error('No Current Site')
            }
        } catch (error) {
            throw error
        }
    }

    getSiteImages = async () => {
        try {
            if (this.commonStore.currentSite) {
                const response = await FileService.getFileList(this.commonStore.currentSite.id, 'image')
                this.setImages(response)
            } else {
                throw new Error('No Current Site')
            }
        } catch (error) {
            throw error
        }
    }

    uploadFile = async (name: string, formData: FormData) => {
        try {
            if (this.commonStore.currentSite) {
                const response = await FileService.uploadFile(name, formData, this.commonStore.currentSite.id)
                return response
            } else {
                throw new Error('No Current Site')
            }
        } catch (error) {
            throw error;
        }
    }

    deleteFile = async (id: string) => {
        try {
            await FileService.deleteFile(id)
        } catch (error) {
            throw error;
        }
    }
}
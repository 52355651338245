import {makeAutoObservable} from "mobx";

export class SupportStore {
    showSupportChat: boolean = true;
    constructor() {
        makeAutoObservable(this)
    }

    toggleSupportChat = () => {
        this.showSupportChat = !this.showSupportChat;
    }

}

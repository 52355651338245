import { APIService } from ".";
import { AdminUser } from "../Models/Admin";
import { AdminCreateCollection } from "../Models/Admin/Collection";
import { Creator } from "../Models/Admin/Creator";
import { EditUserFormValues } from "../Models/Admin";
import { CollectionData, CollectionKey } from "../Models/API/Collections";
import {SplToken, TokenBondingCurveToken} from "../Models/API/Spl";

export class AdminService {
    static basePath = "/admin";
    //Creators
    public static GetAllCreators = (): Promise<Creator[]> => {
        return APIService.requests.get<Creator[]>(`${this.basePath}/GetAllCreators`)
    }
    public static GetCreator = (creatorId: string): Promise<Creator> => {
        return APIService.requests.get<Creator>(`${this.basePath}/GetCreator?creatorId=${creatorId}`)
    }

    //Users
    public static GetAllUsers = (): Promise<AdminUser[]> => {
        return APIService.requests.get<AdminUser[]>(`${this.basePath}/GetAllUsers`)
    }

    public static GetUser = (id: string): Promise<AdminUser> => {
        return APIService.requests.get<AdminUser>(`${this.basePath}/GetUser?userid=${id}`)
    }

    public static SearchUsers = (searchType: string, searchString: string): Promise<AdminUser[]> => {
        return APIService.requests.get<AdminUser[]>(`${this.basePath}/SearchUsers?searchType=${searchType}&searchString=${searchString}`)
    }

    public static UpdateUser = (values: EditUserFormValues): Promise<AdminUser> => {
        return APIService.requests.put<AdminUser>(`${this.basePath}/UpdateUser`, values)
    }

    public static ConfirmEmail = (userId: string): Promise<boolean> => {
        return APIService.requests.post<boolean>(`${this.basePath}/ConfirmEmail?userId=${userId}`, {})
    }

    public static SendPasswordReset = (userId: string): Promise<boolean> => {
        return APIService.requests.post<boolean>(`${this.basePath}/SendPasswordReset?userId=${userId}`, {})
    }

    public static MakeAdmin = (userId: string): Promise<AdminUser> => {
        return APIService.requests.post<AdminUser>(`${this.basePath}/AddUserToRole?userId=${userId}&roleName=Admin`, {})
    }

    public static RemoveAdmin = (userId: string): Promise<AdminUser> => {
        return APIService.requests.post<AdminUser>(`${this.basePath}/RemoveUserFromRole?userId=${userId}&roleName=Admin`, {})
    }

    public static DeleteUser = (userId: string) => {
        return APIService.requests.del(`${this.basePath}/DeleteUser?userId=${userId}`)
    }

    public static CheckRewards = (userId: string): Promise<AdminUser> => {
        return APIService.requests.post<AdminUser>(`${this.basePath}/CheckRewards?userId=${userId}`, {})
    }

    public static ChangeReferralCode = (userId: string, newCode: string): Promise<string> => {
        return APIService.requests.post<string>(`${this.basePath}/ChangeReferralCode?userId=${userId}&newCode=${newCode}`, {})
    }

    //Collections

    public static GetAllCollections = (): Promise<CollectionData[]> => {
        return APIService.requests.get<CollectionData[]>(`${this.basePath}/GetAllCollections`);
    }
    public static GetCollectionKeys = (collectionId: string): Promise<CollectionKey[]> => {
        return APIService.requests.get<CollectionKey[]>(`${this.basePath}/GetCollectionKeys?collectionId=${collectionId}`);
    }

    public static CreateCollection = (collection: AdminCreateCollection): Promise<CollectionData> => {
        return APIService.requests.post<CollectionData>(`${this.basePath}/CreateCollection`, collection)
    }
    public static DeleteCollection = (collectionId: string): Promise<string> => {
        return APIService.requests.del<string>(`${this.basePath}/DeleteCollection?collectionId=${collectionId}`)
    }
    public static AddCollectionToCreator = (creatorId: string, collectionId: string): Promise<string> => {
        return APIService.requests.post<string>(`${this.basePath}/AddCollectionToCreator?creatorId=${creatorId}&collectionId=${collectionId}`, [])
    }
    public static RemoveCollectionFromCreator = (creatorId: string, collectionId: string): Promise<string> => {
        return APIService.requests.del<string>(`${this.basePath}/RemoveCollectionFromCreator?creatorId=${creatorId}&collectionId=${collectionId}`)
    }
    public static BuildAllieCats = () => {
        return APIService.requests.get<boolean>(`${this.basePath}/BuildAllieCats`)
    }

    //Tokens

    public static GetAllSplTokens = (): Promise<SplToken[]> => {
        return APIService.requests.get<SplToken[]>(`/Solana/GetSplTokens`)
    }
    public static CreateSplToken = (splKey: string, splName: string, splTicker: string): Promise<SplToken> => {
        return APIService.requests.post<SplToken>(`${this.basePath}/CreateSplToken`, {Key: splKey, Name: splName, Ticker: splTicker})
    }
    public static DeleteSplToken = (splKey: string): Promise<string> => {
        return APIService.requests.del<string>(`${this.basePath}/DeleteSplToken?splKey=${splKey}`)
    }
    public static GetTbcTokens = (): Promise<TokenBondingCurveToken[]> => {
        return APIService.requests.get<TokenBondingCurveToken[]>(`/Solana/GetTokenBondingCurveTokens`)
    }
    public static CreateTbcToken = (tbcKey: string, token1Key: string, token2Key: string): Promise<TokenBondingCurveToken> => {
        return APIService.requests.post<TokenBondingCurveToken>(`${this.basePath}/CreateTokenBondingCurveToken`, {Key: tbcKey, Token1Key: token1Key, Token2Key: token2Key})
    }
    public static DeleteTbcToken = (tbcKey: string): Promise<string> => {
        return APIService.requests.del<string>(`${this.basePath}/DeleteTokenBondingCurveToken?tbcKey=${tbcKey}`)
    }

}
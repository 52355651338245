import { Button, Grid } from "@mui/material";
import { NavBarItem as NavBarItemModel} from "../../Models/Site"
import { observer } from "mobx-react-lite";
import { history } from "../../App";

interface Props {
    data: NavBarItemModel
}

export const NavBarItem = observer(({ data }: Props) => {
    return (
        <Grid item key={data.id}>
            <Button
                variant="text"
                sx={{ paddingX: 5, color: 'secondary.contrastText', fontSize: 22 }}
                onClick={()=>history.push(data.path)}
            >
                {data.text}
            </Button>
        </Grid>
    )
});
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { store } from '../Stores';
import { history } from '../App';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(config => {
    const token = store.accountStore.token;
    if (token) config.headers.Authorization = `Bearer ${token}`
    return config;
});

axios.interceptors.response.use(async response => {
    return response.data;
}, (error: AxiosError) => {
    if (error.response) {
        const { data, status } = error.response;
        const showError = () => {
            if (data.message) {
                store.toastBarStore.showMessage(data.message, 'error');
            } else {
                store.toastBarStore.showGenericError();
            }
        }
        switch (status) {
            case 400:
                showError()
                break;
            case 401:
                if (error.response.config.url && error.response.config.url.startsWith('/account/login')) {
                    showError()
                    break;
                }
                history.push('/logout')
                break;
            case 403:
                history.push('/youshallnotpass');
                break;
            case 404:
                // history.push('/not-found')
                break;
            case 500:
                // history.push('/server-error');
                break;
        }
    }
    return Promise.reject(error);
});

export class APIService {
    static responseBody = <T>(response: AxiosResponse<T>) => response.data;
    public static requests = {
        get: <T>(url: string) => axios.get<T>(url).then(this.responseBody),
        post: <T>(url: string, body: {}, config?: AxiosRequestConfig) => axios.post<T>(url, body, config).then(this.responseBody),
        put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(this.responseBody),
        del: <T>(url: string) => axios.delete<T>(url).then(this.responseBody),
    }
}

import { makeAutoObservable, observable, runInAction } from "mobx";
import { ServerError } from "../Models/API";
import React from "react";
import { Site, ThemeRenderer } from "../Views/Site";
import { Site as SiteModel } from "../Models/Site";
import v3Json from '../../old/v3.json';
import localJson from '../../old/localv2.json';
import { SiteService } from "../Services";
import { RallyCurrency } from "../Models/API/RallyCurrency";

export class CommonStore {
    @observable navbarHeight: number = 90;
    error: ServerError | null = null;
    currentDomain: string | undefined;
    currentSite: SiteModel | undefined;
    siteContent: React.ReactNode | null = null;
    creatorCurrency: RallyCurrency | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    setNavbarHeight = (height: number) => {
        this.navbarHeight = height
    }

    setServerError = (error: ServerError) => {
        this.error = error;
    }

    loadSite = async (domain: string) => {
        try {
            const mocked = this.mockedSites.indexOf(domain) !== -1
            const response = mocked ? this.mockedSite(domain) : await SiteService.getSite(domain);
            var site = Object.assign(new SiteModel(), response)
            runInAction(() => {
                this.siteContent = React.createElement(
                    Site,
                    {
                        data: site,
                        theme: ThemeRenderer.create(site.theme)
                    },
                    null
                );
                this.currentDomain = domain;
                this.currentSite = site;
            })
        } catch (error) {
            throw error
        }
    }

    mockedSites = [
        "local.localhost.com:3000",
    ]

    mockedSite = (domain: string) => {
        switch (domain) {
            case "local.localhost.com:3000": return localJson;
            default: return v3Json;
        }
    }

    getCreatorCurrency = async (domain: string) => {
        try {
            var currency = await SiteService.getCreatorCurrency(domain);
            runInAction(() => {
                this.creatorCurrency = currency
            })

        } catch (error) {
            throw error
        }
    }
}
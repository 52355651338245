import React, { useEffect } from "react";
import { Prompt } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { CssBaseline, Grid, Box } from "@mui/material";
import { ThemeProvider } from '@mui/material';

import { useStore } from '../../Stores';
import {
    CMSSite, CMSTheme, CMSSiteTree,
    CMSNavBar, CMSNavBarItem, CMSPage,
    CMSHeader, CMSFooter, CMSContainer, CMSText, CMSImage, CMSIFrame, CMSSocials,
    CMSPlaylist, CMSPlaylistSection, CMSVideo, CMSCarousel, CMSPreview, CMSButton, CMSCollection, CMSRallySwap, CMSHTML
} from '.';
import {
    Model, Type,
    Site, Theme,
    NavBar, NavBarItem, Page,
    Header, Footer, Container, Text, Image,
    Playlist, PlaylistSection, Video, Carousel, IFrame, Socials, Button, Collection, RallySwap, HTML
} from '../../Models/Site';
import { ThemeRenderer } from '../Site';

interface Props {
    data: Site;
    collectionTemplateMode?: boolean;
}

export const createCMSComponent = (data?: Model | null, collectionId?: string): React.ReactNode => {
    if (data) {
        switch (data.type) {
            case Type.site: return <CMSSite data={data as Site} />
            case Type.theme: return <CMSTheme data={data as Theme} />
            case Type.navBar: return <CMSNavBar data={data as NavBar} />
            case Type.navBarItem: return <CMSNavBarItem data={data as NavBarItem} />
            case Type.page: return <CMSPage data={data as Page} />
            case Type.header: return <CMSHeader data={data as Header} collectionId={collectionId} />
            case Type.footer: return <CMSFooter data={data as Footer} />
            case Type.container: return <CMSContainer data={data as Container} collectionId={collectionId} />
            case Type.text: return <CMSText data={data as Text} collectionId={collectionId} />
            case Type.image: return <CMSImage data={data as Image} collectionId={collectionId} />
            case Type.playlist: return <CMSPlaylist data={data as Playlist} />
            case Type.playlistSection: return <CMSPlaylistSection data={data as PlaylistSection} />
            case Type.video: return <CMSVideo data={data as Video} />
            case Type.carousel: return <CMSCarousel data={data as Carousel} />
            case Type.iFrame: return <CMSIFrame data={data as IFrame} />
            case Type.socials: return <CMSSocials data={data as Socials} />
            case Type.button: return <CMSButton data={data as Button} />
            case Type.collection: return <CMSCollection data={data as Collection} />
            case Type.rallySwap: return <CMSRallySwap data={data as RallySwap} />
            case Type.html: return <CMSHTML data={data as HTML} />
        }
    }
    return <></>;
}
const padding = 12;

export const CMS = observer(({ data, collectionTemplateMode = false }: Props) => {
    const { cmsStore, cmsNavBarStore } = useStore();
    useEffect(() => {
        cmsStore.setData(data)
    }, [cmsStore, data]);
    const theme = ThemeRenderer.default()
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ display: 'flex' }}>
                <CMSSiteTree data={cmsStore.data} collectionTemplateMode={collectionTemplateMode} />
                <Box component="main" sx={{
                    flexGrow: 1,
                    p: `${padding}px`,
                    width: `calc(100% - ${cmsNavBarStore.drawerOpen ? 240 : 0}px - ${2 * padding}px)`,
                    transition: theme.transitions.create(['margin', 'width'], {
                        easing: theme.transitions.easing.easeOut,
                        duration: theme.transitions.duration.enteringScreen,
                    })
                }}>
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={6} lg={5} xl={4}>
                            {createCMSComponent(cmsStore.selectedItem)}
                        </Grid>
                        <Grid item sm={12} md={6} lg={7} xl={8}>
                            <CMSPreview />
                        </Grid>
                    </Grid>

                </Box>
            </Box>
            <Prompt
                when={cmsStore.hasChanges}
                message="You have unsaved changes that will be discarded. Are you sure you want to leave?"
            />
        </ThemeProvider>
    )
});

import {AnchorProvider, Idl, Program, Wallet as AnchorWallet} from "@project-serum/anchor";
import { TokenSwapLayout } from "./models/tokenSwapLayout";
import idl from "./idl.json";
import { MintInfo } from "./models/mintInfo";
import { TokenAccountInfo } from "./models/tokenAccountInfo";
import {
  getAssociatedTokenAddress,
  TOKEN_PROGRAM_ID,
  createAssociatedTokenAccountInstruction,
  unpackAccount
} from "@solana/spl-token";
import {AccountInfo, Connection, PublicKey, TransactionMessage, VersionedTransaction} from "@solana/web3.js";
import { Wallet } from "@solana/wallet-adapter-react";
import * as buffer from "buffer";
import {SolanaMaths} from "./utils/solanaMaths";

window.Buffer = buffer.Buffer;

export class TokenSwapLib {
  private readonly connection: Connection;
  private readonly swapAuthority: PublicKey;
  private tokenSwapProgram:  Program;
  private readonly walletPublicKey: PublicKey;
  private readonly sourceToken: PublicKey;
  private sourceTokenAccount: PublicKey;
  private readonly destinationToken: PublicKey;
  private destinationTokenAccount: PublicKey;
  private programId = new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA');
  /** Address of the SPL Associated Token Account program */
  private associatedTokenProgramId = new PublicKey('ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL');
  private readonly amountIn: number;
  private readonly amountOut: number;
  private readonly wallet: Wallet;
  private readonly curveId: PublicKey;
  private readonly provider: AnchorProvider;
  private sourceTokenDecimals: number;
  private destinationTokenDecimals: number;
  constructor(wallet: Wallet, curveId: string, sourceToken: string, destinationToken: string, amountIn: number, token: string) {
    //Set up specifics for the swap
    this.sourceToken = new PublicKey(sourceToken);
    this.destinationToken = new PublicKey(destinationToken);
    this.curveId = new PublicKey(curveId);
    //Set up connection specifics
    this.connection = new Connection('https://white-quick-arrow.solana-mainnet.quiknode.pro/',
        {
          httpHeaders: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
    //Set Amounts
    this.amountIn = amountIn;
    this.amountOut = 0;
    //Set up token Program
    this.wallet = wallet;
    this.walletPublicKey = this.wallet.adapter.publicKey as PublicKey;
    this.provider = new AnchorProvider(this.connection, wallet as unknown as AnchorWallet, AnchorProvider.defaultOptions())
    const TBCId = new PublicKey("TBCwReYDDw8SvwVVKJHgatzeXKrLHnaTPyDGwkUoBsq");
    this.tokenSwapProgram = new Program(idl as Idl, TBCId, this.provider);

    const [swapAuth] = PublicKey.findProgramAddressSync([this.curveId.toBuffer()],this.tokenSwapProgram.programId);
    this.swapAuthority = swapAuth;
  }
  async executeSwap(transaction: VersionedTransaction) {
    try {
      await this.wallet.adapter.sendTransaction(transaction, this.connection);
      return true;
    } catch {
      return false;
    }
  }

  async estimateSwap() {
    const transaction = await this.buildTransaction();
    const accountArray = [this.sourceTokenAccount.toString(), this.destinationTokenAccount.toString()];
    const result = await this.connection.simulateTransaction(transaction, {
        accounts: {encoding: 'base64', addresses: accountArray},
        commitment: "confirmed"
    });
    // console.log(result);
    if(!result.value.err && result.value.accounts) {
      console.log("Success")
      // const inAccount = await this.accountInfoFromSim(result.value.accounts[0]);
      const outAccount = await this.accountInfoFromSim(result.value.accounts[1]);
      //GET AMOUNTS
      // const sourceAmount = await this.getAmount(this.sourceTokenAccount, inAccount.amount, true);
      const destinationAmount = await this.getAmount(this.destinationTokenAccount, outAccount.amount, false);
      return {destinationAmount , transaction};
    } else {
      const error = result.value.err != null ? result.value.err : "Error";
      console.log(error);
    }
  }

  private async getAmount(accountReply: PublicKey, amount: number, source: boolean) {
    const tokenInfo = await this.getTokenAccountInfo(accountReply);
    if(source) {
      return this.getAmountPositive(SolanaMaths.decToBase(tokenInfo.amount - amount, this.sourceTokenDecimals));
    } else {
      return this.getAmountPositive(SolanaMaths.decToBase(amount - tokenInfo.amount, this.destinationTokenDecimals));
    }
  }
  private getAmountPositive(value: number) : number {
    if(Math.floor(value) === value){
      return Number(Math.abs(value).toFixed(0));
    }
    const decimals = value.toString().split(".")[1].length || 0;
    return Number(Math.abs(value).toFixed(decimals));
  }

  private async getTokenAccountInfo (address: PublicKey) {
    const info = await this.connection.getAccountInfo(address);
    if(!info) {
      throw new Error('Failed to find token account');
    }
    const data = Buffer.from(info.data);
    return new TokenAccountInfo(data);
  };
  private async accountInfoFromSim (account: any) {
    let data = account.data;
    data = Buffer.from(data[0], data[1]);
    return new TokenAccountInfo(data);
  };

  private async buildTransaction() {
    //Get Associated Token Account Addressed for source and destination tokens using the wallet
    const sourceAtA = await this.getAtA(this.sourceToken, this.walletPublicKey);
    const destinationAtA = await this.getAtA(this.destinationToken, this.walletPublicKey);
    //Gets all the Account Info that we need in one bundled request
    const accounts = await this.connection.getMultipleAccountsInfo([this.curveId, sourceAtA, destinationAtA, this.sourceToken, this.destinationToken]);
    //Checks that all the accounts are valid
    if(!accounts[0] || !accounts[3]|| !accounts[4]) throw new Error("Could not get accounts");
    //Gets all of the accounts and info we need for the transaction.
    const tokenSwapInfo = await this.getTokenSwapInfo(accounts[0]);
    const userSourceTokenAccount = await this.getAssociatedTokenAccountAddress(sourceAtA, accounts[1], this.sourceToken, this.walletPublicKey);
    const userDestinationTokenAccount = await this.getAssociatedTokenAccountAddress(destinationAtA, accounts[2],this.destinationToken, this.walletPublicKey);
    const sourceInfo = await this.getMintInfo(accounts[3]);
    const destinationInfo = await this.getMintInfo(accounts[4]);
    if(!sourceInfo || !destinationInfo || !userSourceTokenAccount || !userDestinationTokenAccount) {
      throw new Error("Could not get mint info");
    }

    this.sourceTokenDecimals = sourceInfo.decimals;
    this.destinationTokenDecimals = destinationInfo.decimals;

    const amountInBN = SolanaMaths.baseToDec(this.amountIn, this.sourceTokenDecimals);
    const amountOutBN = SolanaMaths.baseToDec(this.amountOut, this.destinationTokenDecimals);

    this.sourceTokenAccount = tokenSwapInfo.sourceTokenAccount;
    this.destinationTokenAccount = tokenSwapInfo.destinationTokenAccount;
    // const instruction = SwapInstruction.swapInstruction(
    //     this.curveId,
    //     this.swapAuthority,
    //     this.walletPublicKey,
    //     userSourceTokenAccount.address,
    //     this.sourceTokenAccount,
    //     this.destinationTokenAccount,
    //     userDestinationTokenAccount.address,
    //     tokenSwapInfo.poolToken,
    //     tokenSwapInfo.feeAccount,
    //     null,
    //     TOKEN_PROGRAM_ID,
    //     this.tokenSwapProgram.programId,
    //     this.tokenSwapProgram.programId,
    //     this.tokenSwapProgram.programId,
    //     Number(amountInBN),
    //     Number(amountOutBN)
    // )
    // //@ts-ignore
    const instruction = this.tokenSwapProgram.instruction.swap(amountInBN, amountOutBN, {
      accounts: {
        tokenSwap: this.curveId,
        swapAuthority: this.swapAuthority,
        userTransferAuthority: this.walletPublicKey,
        source: userSourceTokenAccount.address,
        destination: userDestinationTokenAccount.address,
        swapSource: this.sourceTokenAccount,
        swapDestination: this.destinationTokenAccount,
        poolMint: tokenSwapInfo.poolToken,
        poolFee: tokenSwapInfo.feeAccount,
        tokenProgram: TOKEN_PROGRAM_ID
      }
    });
    let instructionArray = [instruction];
    if(userSourceTokenAccount.instruction)
    {
      instructionArray.unshift(userSourceTokenAccount.instruction)
    }
    if(userDestinationTokenAccount.instruction)
    {
      instructionArray.unshift(userDestinationTokenAccount.instruction)
    }

    const getBlockHash = await this.connection.getLatestBlockhash();
    const message = new TransactionMessage({
      payerKey: this.walletPublicKey,
      recentBlockhash: getBlockHash.blockhash,
      instructions: instructionArray
    }).compileToV0Message();
    return new VersionedTransaction(message);
  }

  private async getTokenSwapInfo(accountInfo: AccountInfo<Buffer>) {
    if (accountInfo === null) {
      throw new Error('Failed to find token swap account');
    }
    if (!(accountInfo.owner.toString() === this.tokenSwapProgram.programId.toString())) {
      throw new Error(`Invalid owner: ${JSON.stringify(accountInfo.owner)}`);
    }
    const data = Buffer.from(accountInfo.data);
    const tokenSwapData = new TokenSwapLayout(data, this.sourceToken);
    if (!tokenSwapData.isInitialized) {
      throw new Error(`Invalid token swap state`);
    }
    return tokenSwapData;
  }
  private async getAtA(mintKey: PublicKey, ownerKey: PublicKey) {
    return await getAssociatedTokenAddress(
        mintKey,
        ownerKey,
        false,
        this.programId,
        this.associatedTokenProgramId
    );
  }
  private async getAssociatedTokenAccountAddress(associatedTokenAccountAddress: PublicKey, accountInfo: AccountInfo<Buffer> | null, mintKey: PublicKey, ownerKey: PublicKey) {
    try {
      const account = unpackAccount(associatedTokenAccountAddress, accountInfo, TOKEN_PROGRAM_ID);
      if(account.isInitialized) {
        const accountAddress = account.address;
        return {transaction: undefined ,address: accountAddress};
      } else {
        return {transaction: undefined ,address: associatedTokenAccountAddress}
      }

    } catch {
      const instruction = createAssociatedTokenAccountInstruction(
          this.walletPublicKey,
          associatedTokenAccountAddress,
          ownerKey,
          mintKey,
          this.programId,
          this.associatedTokenProgramId
      )
      return {instruction: instruction , address: associatedTokenAccountAddress};
    }

  }

  private async getMintInfo(info: AccountInfo<Buffer>) {
    if(info)
    {
      const data = Buffer.from(info.data);
      return new MintInfo(data);
    }
  }
}

import { Autocomplete, Box, TextField, ThemeProvider } from "@mui/material";
import Container from "@mui/material/Container";
import { observer } from "mobx-react-lite";
import React from "react";
import { Container as ContainerModel, Content, Type, Page } from "../../../Models/Site";
import { Template } from "../../../Models/Templates";
import { useStore } from "../../../Stores";
import { ElementRenderer, NavBar } from "../../Site";

export const CMSPreview = observer(() => {
    const { cmsStore } = useStore();
    const { working_model } = cmsStore;

    let isEditing = cmsStore.editedItemId === cmsStore.selectedItemId;
    let dataToUse = isEditing ? working_model : cmsStore.selectedItem
    if (!dataToUse) {
        return <></>
    }

    const parent = cmsStore.getParent(dataToUse.id);
    var backgroundColor = cmsStore.theme.palette.background.default;
    var textColor = undefined;
    switch (parent?.type) {
        case Type.container:
            const container = parent as ContainerModel
            backgroundColor = ContainerModel.colorParam(container);
            textColor = ContainerModel.textColorParam(container);
            break;
        case Type.navBar:
            backgroundColor = 'secondary.main';
            break;
    }
    return (
        <ThemeProvider theme={cmsStore.theme}>
            <Container sx={{
                backgroundColor: backgroundColor,
                paddingTop: 5,
                paddingBottom: 5,
                borderRadius: 1,
                border: 1,
                borderColor: 'grey.600'
            }} >
                {dataToUse.type === Type.theme ? <ThemePreview />
                    : ElementRenderer.build({ model: dataToUse, parent: parent as Content, color: textColor, isPreview: true })}
            </Container>
        </ThemeProvider>
    );
});

class PreviewOption {
    id: string;
    label: string;

    constructor(page: Page) {
        this.id = page.id
        this.label = page.name
    }
}

const ThemePreview = observer(() => {
    const [currentPreviewId, setCurrentPreviewId] = React.useState('preview-page');
    const { cmsStore, commonStore } = useStore();
    const pages = [
        Template.previewPage,
        ...cmsStore.data.pages
    ]
    const options = pages.map((page) => new PreviewOption(page))
    const currentPage = pages.find(option => option.id === currentPreviewId) ?? Template.previewPage
    return (
        <>
            <Autocomplete
                id="previewpage"
                value={options.find(option => option.id === currentPreviewId)}
                onChange={(_, newValue) => setCurrentPreviewId(newValue ? newValue.id : 'preview-page')}
                options={options}
                isOptionEqualToValue={(option: PreviewOption, value: PreviewOption) => option === value}
                renderInput={(params) => (<TextField {...params} label="Preview Page"
                    sx={{ mb: 2 }}
                />)} />
            <Box sx={{ height: `calc(100vh - ${commonStore.navbarHeight + 150}px)`, overflowY: 'scroll', pt: 2 }}>
                <NavBar data={cmsStore.data.navBar} isPreview={true} />
                {ElementRenderer.build({ model: currentPage, isPreview: true })}
            </Box>
        </>
    )
})
import * as Layout from "@solana/buffer-layout";
import { PublicKey } from "@solana/web3.js";

interface BufferLayoutInterface {
  version: Layout.UInt
  isInitialized: Layout.UInt
  bumpSeed: Layout.UInt
  tokenProgramId: Layout.Blob
  tokenAccountA: Layout.Blob
  tokenAccountB: Layout.Blob
  tokenPool: Layout.Blob
  mintA: Layout.Blob
  mintB: Layout.Blob
  feeAccount: Layout.Blob
  tradeFeeNumerator: Layout.Blob
  tradeFeeDenominator: Layout.Blob
  ownerTradeFeeNumerator: Layout.Blob
  ownerTradeFeeDenominator: Layout.Blob
  ownerWithdrawFeeNumerator: Layout.Blob
  ownerWithdrawFeeDenominator: Layout.Blob
  hostFeeNumerator: Layout.Blob
  hostFeeDenominator: Layout.Blob
  curveType: Layout.UInt
  curveParameters: Layout.Blob
}
export class TokenSwapLayout {
  poolToken: PublicKey;
  feeAccount: PublicKey;
  sourceTokenAccount: PublicKey;
  destinationTokenAccount: PublicKey;
  sourceMint: PublicKey;
  destinationMint: PublicKey;
  tokenProgramId: PublicKey;
  isInitialized: any; // TODO: Find type for this

  constructor(data: Buffer, sourceMintKey: PublicKey) {
    const layout = this.tokenSwapLayout.decode(data);
    this.poolToken = new PublicKey(layout.tokenPool);
    this.feeAccount = new PublicKey(layout.feeAccount);
    //Sets up the direction of the swap based on the source token
    if(sourceMintKey.toString() === new PublicKey(layout.mintA).toString()) {
      this.sourceTokenAccount = new PublicKey(layout.tokenAccountA);
      this.destinationTokenAccount = new PublicKey(layout.tokenAccountB);
      this.sourceMint = new PublicKey(layout.mintA);
      this.destinationMint = new PublicKey(layout.mintB);
    } else {
      this.sourceTokenAccount = new PublicKey(layout.tokenAccountB);
      this.destinationTokenAccount = new PublicKey(layout.tokenAccountA);
      this.sourceMint = new PublicKey(layout.mintB);
      this.destinationMint = new PublicKey(layout.mintA);
    }
    this.isInitialized = layout.isInitialized;
    this.tokenProgramId = new PublicKey(layout.tokenProgramId);
  }
  private publicKeyLayout = (property: string = "publicKey"): any => {
    return Layout.blob(32, property);
  };
  private uint64Layout = (property: string = "uint64"): any => {
    return Layout.blob(8, property);
  };

  private tokenSwapLayout = Layout.struct<BufferLayoutInterface>([
    Layout.u8("version"),
    Layout.u8("isInitialized"),
    Layout.u8("bumpSeed"),
    this.publicKeyLayout("tokenProgramId"),
    this.publicKeyLayout("tokenAccountA"),
    this.publicKeyLayout("tokenAccountB"),
    this.publicKeyLayout("tokenPool"),
    this.publicKeyLayout("mintA"),
    this.publicKeyLayout("mintB"),
    this.publicKeyLayout("feeAccount"),
    this.uint64Layout("tradeFeeNumerator"),
    this.uint64Layout("tradeFeeDenominator"),
    this.uint64Layout("ownerTradeFeeNumerator"),
    this.uint64Layout("ownerTradeFeeDenominator"),
    this.uint64Layout("ownerWithdrawFeeNumerator"),
    this.uint64Layout("ownerWithdrawFeeDenominator"),
    this.uint64Layout("hostFeeNumerator"),
    this.uint64Layout("hostFeeDenominator"),
    Layout.u8("curveType"),
    Layout.blob(32, "curveParameters"),
  ]);

}

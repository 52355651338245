import {observer} from "mobx-react-lite";
import { useStore } from "../../../Stores";
import {RallySwap as RallySwapModel} from "../../../Models/Site";
import {CMSEditButtons, CMSWidthField} from "../Components";
import {Stack, TextField} from "@mui/material";

interface Props {
    data: RallySwapModel;
}

export const CMSRallySwap = observer(({ data }: Props) => {
    const {cmsStore} = useStore();
    const {working_model} = cmsStore;
    let isEditing = cmsStore.editedItemId === data.id;
    let dataToUse: RallySwapModel = isEditing ? (working_model as RallySwapModel) : data
    return (
        <Stack direction="column" spacing={2} justifyContent="space-between">
            <CMSEditButtons
                id={data.id}
                index={data.index}
            />
            <h5>ID: {data.id}</h5>
            <TextField
                id="name"
                label="Name"
                value={dataToUse.name}
                onChange={(e) => cmsStore.updateRallySwap({ name: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <CMSWidthField
                value={dataToUse.width.toString()}
                onChange={(newValue)=>cmsStore.updateRallySwap({ width: +(newValue) })}
                disabled={!isEditing}
            />
        </Stack>
    )
})

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import { Box, IconButton } from "@mui/material";

import { Socials as SocialsModel, SocialsColor, SocialsLink } from "../../../Models/Site"
import { useStore } from "../../../Stores";

interface IconProps {
    data: SocialsLink
    color: SocialsColor
    hoverColor: SocialsColor
}

interface Props {
    data: SocialsModel
}

export const SocialIconButton = observer(({ data, color, hoverColor }: IconProps) => {
    const { commonStore } = useStore()
    const hexHoverColor = (() => {
        if (commonStore.currentSite) {
            switch (hoverColor) {
                case SocialsColor.primary:
                    return commonStore.currentSite.theme.palette.primary.main
                case SocialsColor.secondary:
                    return commonStore.currentSite.theme.palette.secondary.main
            }
        }
        return 'white'
    })();
    return (
        <IconButton
            color={color}
            sx={{
                transition: 'transform 0.2s',
                ":hover": {
                    color: hexHoverColor,
                    transform: 'scale(1.2)'
                }
            }}
            aria-label={data.type ?? "Link"}
            onClick={() => window.open(data.url, "_blank")}>
            <FontAwesomeIcon icon={SocialsLink.iconProp(data)} size="2x" />
        </IconButton>
    )
});

export const Socials = observer(({ data }: Props) => {
    return (
        <Box textAlign={data.alignment} width='100%'>
            {data.links.map(link => <SocialIconButton data={link} color={data.color} hoverColor={data.hoverColor} />)}
        </Box>
    )
});
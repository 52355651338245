import { Menu, Card, CardContent, CardHeader, Box, Typography, TextField, MenuItem, IconButton, Stack, Link, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { observer } from "mobx-react-lite";
import { MoreVert, Security, AccountCircle, Verified, DeleteForever } from "@mui/icons-material";
import React from "react";
import { useStore } from "../../Stores";
import { LoadingButton } from "@mui/lab";
import { User } from "../../Models/API";

const maxWidth = 350

interface Props {
    user: User
}

export const AccountOverview = observer(({ user }: Props) => {
    const [anchorElYourDetailsMenu, setAnchorElYourDetailsMenu] = React.useState<null | HTMLElement>(null);
    const openYourDetailsMenu = Boolean(anchorElYourDetailsMenu);

    const [resetLoading, setResetLoading] = React.useState(false);
    const [deleteLoading, setDeleteLoading] = React.useState(false);
    const [deleteOpen, setDeleteOpen] = React.useState(false);

    const { accountStore, toastBarStore } = useStore();

    const resetPassword = async () => {
        setResetLoading(true);
        accountStore.generatePasswordReset(user.email)
            .then(() => toastBarStore.showMessage('A password reset link has been sent. Please check your email!', 'success'))
            .finally(() => setResetLoading(false))
    }

    const deleteUser = async () => {
        setDeleteOpen(false)
        setDeleteLoading(true);
        accountStore.deleteUser()
            .then(() => toastBarStore.showMessage('Your account has been deleted.', 'success'))
            .finally(() => setDeleteLoading(false))
    }

    const handleClickYourDetailsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElYourDetailsMenu(event.currentTarget);
    };
    const handleCloseYourDetailsMenu = () => {
        setAnchorElYourDetailsMenu(null);
    };

    const closeDialog = () => {
        setDeleteOpen(false)
    }

    const styledTextField = ((id: string, label: string, value: string, defaultValue: string, onChange: (s: string) => void) => {
        return (
            <TextField
                id={id}
                label={label}
                defaultValue={defaultValue}
                disabled={true}
                onChange={(e) => onChange(e.target.value)}
                margin="normal"
                sx={{ maxWidth: `${maxWidth}px`, flex: 1 }}
            />
        )
    });

    return (
        <Box>
            <Card sx={{ display: 'flex', flexDirection: 'column', mt: 5, alignItems: 'leading' }} >
                <CardHeader
                    avatar={<AccountCircle color='primary' />}
                    title="Your Details"
                    action={
                        <IconButton aria-label="settings" onClick={handleClickYourDetailsMenu}>
                            <MoreVert />
                        </IconButton>
                    }
                />
                <Menu
                    id="basic-menu"
                    anchorEl={anchorElYourDetailsMenu}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={openYourDetailsMenu}
                    onClose={handleCloseYourDetailsMenu}
                    MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                >
                    <MenuItem onClick={handleCloseYourDetailsMenu}>Edit (Disabled)</MenuItem>
                </Menu>
                <CardContent sx={{ flex: '1 0 auto', display: 'flex', flexDirection: 'column' }}>
                    <Stack direction='row' alignItems='center' spacing={2}>
                        {styledTextField('username', 'Username', '', user.userName, (s) => console.log(s))}
                        <Stack direction='row' spacing={1}>
                            <Verified color={user.emailConfirmed ? "success" : "error"} />
                            <Typography color={user.emailConfirmed ? "success.main" : "error.main"}>{user.emailConfirmed ? "" : "Not "}Verified</Typography>
                        </Stack>
                    </Stack>
                    {!user.emailConfirmed ? <Link variant="body2" onClick={() => {
                        accountStore.generateConfirmEmail()
                    }}>Resend confirmation email</Link> : undefined}
                    {styledTextField('email', 'Email', '', user.email, (s) => console.log(s))}
                    {styledTextField('phone', 'Phone Number', '', user.phoneNumber, (s) => console.log(s))}
                </CardContent>
            </Card>
            <Card sx={{ display: 'flex', flexDirection: 'column', mt: 5, alignItems: 'leading' }} >
                <CardHeader
                    avatar={<Security color='primary' />}
                    title="Security"
                />
                <CardContent sx={{ flex: '1 0 auto', display: 'flex', flexDirection: 'column' }}>
                    <LoadingButton
                        variant='contained'
                        disabled={toastBarStore.open}
                        loading={resetLoading}
                        sx={{ maxWidth: `${maxWidth}px` }}
                        onClick={resetPassword}
                    >
                        Reset Password
                    </LoadingButton>
                </CardContent>
            </Card>
            <Card sx={{ display: 'flex', flexDirection: 'column', mt: 5, alignItems: 'leading' }} >
                <CardHeader
                    avatar={<DeleteForever color='primary' />}
                    title="Delete Account (PERMANENT)"
                />
                <CardContent sx={{ flex: '1 0 auto', display: 'flex', flexDirection: 'column' }}>
                    <LoadingButton
                        variant='contained'
                        loading={deleteLoading}
                        color='error'
                        sx={{ maxWidth: `${maxWidth}px` }}
                        onClick={() => setDeleteOpen(true)}
                    >
                        Delete Account
                    </LoadingButton>
                    <Dialog
                        open={deleteOpen}
                        onClose={closeDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Are you sure you want to delete your account?
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                This action is irreversible and cannot be undone.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button color='success' variant='contained' onClick={closeDialog}>Cancel</Button>
                            <Button color='error' variant='outlined' onClick={deleteUser}>Delete Account</Button>
                        </DialogActions>
                    </Dialog>
                </CardContent>
            </Card>
        </Box>
    );
});
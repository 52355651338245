import { observer } from "mobx-react-lite"
import { Stack, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { useStore } from '../../Stores';
import { CMSActionBar, CMSEditButtons } from '.';
import { Site as SiteModel, Page as PageModel } from '../../Models/Site';

interface Props {
    data: SiteModel;
}

export const CMSSite = observer(({ data }: Props) => {
    const { cmsStore } = useStore();
    const { working_model } = cmsStore;
    let isEditing = cmsStore.editedItemId === data.id;
    let dataToUse: SiteModel = isEditing ? (working_model as SiteModel) : data

    return (
        <Stack direction="column" spacing={2} justifyContent="space-between">
            <CMSEditButtons
                id={data.id}
                index={data.index}
                undeletable
            />
            <h5>ID: {data.id}</h5>
            <TextField
                id="name"
                label="Name"
                value={dataToUse.name}
                onChange={(e) => cmsStore.updateSite({ name: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <TextField
                id="description"
                label="Description"
                value={dataToUse.description}
                onChange={(e) => cmsStore.updateSite({ description: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <CMSActionBar
                id={data.id}
                addContent={false}
                buttons={[
                    {
                        text: "Add Page",
                        action: () => cmsStore.addModel(new PageModel(), data.id),
                        icon: <AddIcon />
                    }
                ]}
            />
        </Stack>
    );
});
import { LoadingButton } from "@mui/lab"
import { Alert, Box, Button, Snackbar, Stack } from "@mui/material"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { observer } from "mobx-react-lite"
import * as Yup from 'yup'
import { EditUserFormValues } from "../../../Models/Admin"
import { useStore } from "../../../Stores"
import { AddCreatorForm, ChangeReferralCodeForm } from "../../Forms"
import MyTextInput from "../../Forms/MyTextInput"
import { AdminDateTimePicker } from "./AdminDateTimePicker"

export const AdminUsersForm = observer(() => {
    const { adminStore, modalStore, creatorStore } = useStore();
    const { editUser, confirmEmail, sendPasswordReset, makeAdmin, removeAdmin, deleteUser, checkRewards } = adminStore;
    const { removeCreatorByUserId } = creatorStore;
    const validationSchema = Yup.object({
        userName: Yup.string().required(),
        email: Yup.string().required().email(),
    });


    if (editUser !== undefined) {

        const initialValues: EditUserFormValues = {
            id: editUser?.id,
            userName: editUser?.userName,
            email: editUser?.email,
            emailConfirmed: editUser?.emailConfirmed,
            referredByCode: editUser?.referral.referredByCode,
            referredBySite: editUser?.referral.referredFrom.domain != null ? editUser.referral.referredFrom.domain : undefined,
            referralCode: editUser?.referral.userReferralCode,
            created: editUser?.created,
            error: null
        }

        return (

            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setErrors }) => adminStore.updateUser(values).catch((error: any) => setErrors({ error: 'Invalid Registration' }))}
                validationSchema={validationSchema}
                enableReinitialize={true}
            >
                {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
                    <Form className='ui form error' onSubmit={handleSubmit} autoComplete="off">
                        <Stack spacing={2} sx={{ marginTop: 1 }} >
                            <Field autoComplete="new-password" name="id" label="Id" component={MyTextInput} disabled={true} />
                            <Field autoComplete="new-password" name="userName" label="UserName" component={MyTextInput} />
                            <Field autoComplete="new-password" name="email" label="Email" component={MyTextInput} />
                            <Field autoComplete="new-password" name="emailConfirmed" label="emailConfirmed" type="boolean" disabled component={MyTextInput} />
                            <Field autoComplete="new-password" name="referredByCode" label="Referred By Code" disabled component={MyTextInput} />
                            <Field autoComplete="new-password" name="referredBySite" label="Referred By Site" disabled component={MyTextInput} />
                            <Field autoComplete="new-password" name="referralCode" label="Referral Code" disabled component={MyTextInput} />
                            <Field autoComplete="new-password" name="created" label="Account Created" component={AdminDateTimePicker} />
                            <LoadingButton disabled={!isValid || isSubmitting} loading={isSubmitting} variant="contained" type='submit'>Update User</LoadingButton>
                        </Stack>
                        <Box marginTop={1}>
                            <Button onClick={() => confirmEmail(editUser.id)} variant="contained">Confirm Email</Button>
                            <Button onClick={() => sendPasswordReset(editUser.id)} variant="contained">Send Password Reset</Button>
                            <Button onClick={() => makeAdmin(editUser.id)} variant="contained">Make Admin</Button>
                            <Button onClick={() => removeAdmin(editUser.id)} variant="contained">Remove Admin</Button>
                            <Button onClick={() => modalStore.openModal(<AddCreatorForm initialUser={editUser} />)} variant='contained'>Make Creator</Button>
                            <Button onClick={() => modalStore.openModal(<ChangeReferralCodeForm />)} color="success" variant="contained">Change Referral Code</Button>
                            <Button onClick={() => checkRewards(editUser.id)} color="success" variant="contained">Check Rewards</Button>
                            <Button onClick={() => removeCreatorByUserId(editUser.id)} color="error" variant="contained">Remove Creator</Button>
                            <Button onClick={() => deleteUser(editUser.id)} color="error" variant="contained">Delete User</Button>
                        </Box>
                        <ErrorMessage name='error' render={() =>
                            <Snackbar open={true} autoHideDuration={6000}>
                                <Alert variant='filled' severity="error">{errors.error}</Alert>
                            </Snackbar>
                        } />
                    </Form>
                )}
            </Formik>
        )
    }
    return null;
})
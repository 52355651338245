import {Group, Palette, AutoGraph, CurrencyExchange} from '@mui/icons-material';

import { MasterDetail, Page, Section } from '../Common/MasterDetail';
import {AdminCollections, AdminCreators, AdminData, AdminTokens, AdminUsers} from "./";

export const Admin = () => {
    const userPage = new Page("Users", "", <Group />, <AdminUsers />);
    const creatorPage = new Page("Creators", "", <Palette />, <AdminCreators />);
    const dataPage = new Page("Data", "", <AutoGraph />, <AdminData />);
    const collectionsPage = new Page("Collections", "", <AutoGraph />, <AdminCollections />);
    const tokensPage = new Page("Tokens", "", <CurrencyExchange />, <AdminTokens />);
    const sections = [
        new Section([userPage]),
        new Section([creatorPage]),
        new Section([dataPage]),
        new Section([collectionsPage]),
        new Section([tokensPage])
    ]

    return (
        <MasterDetail sections={sections} />
    )
};
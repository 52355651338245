import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, TextField, Typography } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';

import { Collection, NFTCollectionDTO } from "../../Models/API/Collections";
import { useStore } from "../../Stores";


interface Props {
    initialCollectionId?: string;
    enabled?: boolean
    onSelect?: (collectio?: Collection) => void;
}

export const CollectionSelector = observer(({ initialCollectionId, enabled = true, onSelect = () => { } }: Props) => {
    const [refreshing, setRefreshing] = React.useState(false);
    const [selectedCollection, setSelectedCollection] = React.useState({ id: "", label: "" });

    const [newName, setNewName] = React.useState('')
    const [newWalletAddress, setNewWalletAddress] = React.useState('')
    const [dialogOpen, setDialogOpen] = React.useState(false)

    const { collectionStore, creatorStore } = useStore()

    const refreshCollections = () => {
        setRefreshing(true)
        collectionStore.getCollections()
            .finally(() => {
                setRefreshing(false)
            })
    }

    useEffect(() => {
        refreshCollections()
    }, []) // Run once (dont delete it plz)

    const disabled = !enabled || refreshing

    const createCollection = () => {
        setDialogOpen(false)
        setRefreshing(true)
        if (!creatorStore.creator) { return }
        let dto: NFTCollectionDTO = {
            name: newName,
            title: newName,
            creatorCollection: true,
            blockChain: 'Solana',
            nftMintAddressList: []
        }
        collectionStore
            .createNFTCollection(creatorStore.creator.id, newWalletAddress, dto)
        setNewName('')
        setNewWalletAddress('')
    }

    const addNewDialog = (() => {
        return (
            <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
                <Box sx={{ width: '320px' }}>
                    <DialogTitle>Please enter a name</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="collectionName"
                            label="Collection Name"
                            fullWidth
                            variant="standard"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="walletId"
                            label="Wallet ID"
                            fullWidth
                            variant="standard"
                            value={newWalletAddress}
                            onChange={(e) => setNewWalletAddress(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
                        <Button variant='contained' onClick={createCollection}>Add Collection</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        )
    })()

    interface TemplateProps {
        children?: React.ReactNode
    }

    const TemplatePaper = ({ children }: TemplateProps) => {
        return (
            <Paper>
                {children}
                <Button onMouseDown={() => setDialogOpen(true)} fullWidth > + Add New</Button>
            </Paper>
        )
    };

    if (refreshing) {
        return (
            <Box display='flex' flexDirection='row' paddingX={1} paddingY={2}>
                <Typography variant='body1' color='primary.main' sx={{ flex: 1 }}>Loading collections</Typography>
                <CircularProgress sx={{ flex: '0 1 auto' }} size={25} color="primary" />
            </Box>
        )
    } else if (collectionStore.collections) {
        if (initialCollectionId && !selectedCollection.id) {
            const collection = collectionStore.collections.find(c => c.id === initialCollectionId)
            if (collection) {
                setSelectedCollection({ id: initialCollectionId, label: collection?.name })
            }
        }
        const options = collectionStore.collections.map((collection) => { return { id: collection.id, label: collection.name, collection: collection } })
        return (
            <Box display='flex' flexDirection='row'>
                <Autocomplete
                    id="collectionId"
                    options={options}
                    sx={{ flex: 1 }}
                    disabled={disabled}
                    value={selectedCollection}
                    onInputChange={(_, newInputValue, reason) => {
                        const newCollection = options.find(o => o.label === newInputValue)
                        if (reason === 'clear') {
                            setSelectedCollection({ id: "", label: "" })
                            onSelect()
                        } else if (newCollection) {
                            setSelectedCollection(newCollection)
                            onSelect(newCollection.collection)
                        }
                    }}
                    isOptionEqualToValue={(a, b) => a.id === b.id}
                    renderInput={(params) => (<TextField {...params} label="Collection" />)}
                    PaperComponent={TemplatePaper}
                />
                <IconButton
                    disabled={disabled}
                    onClick={refreshCollections}
                    sx={{
                        p: 2, flex: '0 1 auto'
                    }}>
                    <RefreshIcon />
                </IconButton>
                {addNewDialog}
            </Box>
        )
    } else {
        return (
            <Box display='flex' flexDirection='row' paddingX={1} paddingY={2} alignItems='center' >
                <Typography variant='body1' color='primary.main' sx={{ flex: 1 }}>No templates</Typography>
                <IconButton
                    disabled={disabled}
                    onClick={refreshCollections}
                    sx={{
                        px: 1, flex: '0 1 auto'
                    }}
                >
                    <RefreshIcon />
                </IconButton>
            </Box>
        )
    }
});
import { Type, Content } from "..";
import { v4 as uuid } from "uuid";
import {makeAutoObservable} from "mobx";

export class RallySwap implements Content {
    constructor() {
        makeAutoObservable(this)
    }

    id: string = uuid();
    index: number = 0;
    name: string = "RallySwap";
    type: Type = Type.rallySwap;
    width: number = 12;
}

export interface RallySwapUpdater {
    name?: string;
    width?: number;
}
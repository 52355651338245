import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import { observer } from "mobx-react-lite";
import React from "react";
import { Image as ImageModel } from "../../../Models/Site"
import placeholder from "../../../Assets/placeholder.jpeg";


interface Props {
    data: ImageModel
    parentWidth: number
}

export enum ImageSize {
    xs, sm, md, lg, xl
}

export const ImageWidths = {
    xs: 600.0,
    sm: 900.0,
    md: 1200.0,
    lg: 1536.0,
    xl: 2000.0
}

export const Image = observer(({ data, parentWidth }: Props) => {
    const [width, setWidth] = React.useState(0)
    const theme = useTheme();

    const checkAndSetWidth = (size: ImageSize) => {
        const newWidth = Math.round((() => {
            const pWidth = parentWidth / 12
            const cWidth = data.width / 12
            const tWidth = pWidth * cWidth
            switch (size) {
                case ImageSize.xs: return ImageWidths.xs * tWidth;
                case ImageSize.sm: return ImageWidths.sm * tWidth;
                case ImageSize.md: return ImageWidths.md * tWidth;
                case ImageSize.lg: return ImageWidths.lg * tWidth;
                case ImageSize.xl: return ImageWidths.xl * tWidth;
                default: return 0
            }
        })())
        if (width < newWidth) {
            setWidth(newWidth)
        }
    }
    const xs = useMediaQuery(theme.breakpoints.down('sm'));
    const sm = useMediaQuery(theme.breakpoints.down('md'));
    const md = useMediaQuery(theme.breakpoints.down('lg'));
    const lg = useMediaQuery(theme.breakpoints.down('xl'));
    const xl = useMediaQuery(theme.breakpoints.up('xl'));

    if (xs) { checkAndSetWidth(ImageSize.xs) }
    else if (sm) { checkAndSetWidth(ImageSize.sm) }
    else if (md) { checkAndSetWidth(ImageSize.md) }
    else if (lg) { checkAndSetWidth(ImageSize.lg) }
    else if (xl) { checkAndSetWidth(ImageSize.xl) }

    const src = data.imageId.startsWith('{{') ? placeholder : `${process.env.REACT_APP_API_URL}/Image?imageId=${data.imageId}&width=${width}`

    return (
        <img
            key={data.id}
            src={src}
            alt={data.name}
            loading="lazy"
            height='100%'
            width='100%'
        />
    )
});
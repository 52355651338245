import { Autocomplete, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";

interface Props {
    value: string;
    onChange: (newValue: string) => void;
    disabled: boolean;
}

export const CMSWidthField = observer((props: Props) => {
    return (
        <Autocomplete
            id="width"
            value={props.value}
            onChange={(_, newValue)=>props.onChange(newValue ?? "12")}
            options={["1","2","3","4","5","6","7","8","9","10","11","12"]}
            disabled={props.disabled}
            renderInput={(params) => (<TextField {...params} label="Width" />)}
        />
    )
});
import {observer} from "mobx-react-lite";
import {Button as MUIButton} from "@mui/material";

import {Button as ButtonModel, ButtonColor, ButtonTextColor, LinkType} from "../../../Models/Site"
import {history} from "../../../App";
import {FileService} from "../../../Services";

interface Props {
    data: ButtonModel
}
export const Button = observer(({ data }: Props) => {
    const openLink = () => {
        switch (data.linkType) {
            case LinkType.file:
                FileService.getFile(data.file)
                    .then(response => {
                        const url = window.URL.createObjectURL(response);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', data.file.name);
                        document.body.appendChild(link);
                        link.click();
                    })
                return;
            default:
                try {
                    const url = new URL(data.url);
                    if (data.openInNewTab) {
                        window.open(url, "_blank")
                    } else {
                        window.location.href = url.href;
                    }
                } catch {
                    history.push(data.url)
                }
                return;
        }
    }
    if(!data.textColor) data.textColor = ButtonTextColor.default; // Ensures old sites don't break with null for new textColor field;
    if(data.color !== ButtonColor.custom && data.textColor === ButtonTextColor.default) {
        return (
            <MUIButton
                variant={data.variant}
                color={data.color}
                onClick={openLink}
                fullWidth
            >
                <div dangerouslySetInnerHTML={{ __html: data.title }} />
            </MUIButton>
        )
    } else if (data.color === ButtonColor.custom && data.textColor === ButtonTextColor.default) {
        if(data.variant === "outlined")
        {
            return (
                <MUIButton
                    variant={data.variant}
                    sx={{
                        borderColor: data.colorCustom,
                        ':hover': {
                            borderColor: ColorHover(data.colorCustom, +0.5),
                        }
                     }}
                    onClick={openLink}
                    fullWidth
                >
                    <div dangerouslySetInnerHTML={{ __html: data.title }} />
                </MUIButton>
            )
        }
        return (
            <MUIButton
                variant={data.variant}
                sx={{
                    backgroundColor: data.colorCustom,
                    ':hover': {
                        backgroundColor: ColorHover(data.colorCustom, -0.3),
                    }
                }}
                onClick={openLink}
                fullWidth
            >
                <div dangerouslySetInnerHTML={{ __html: data.title }} />
            </MUIButton>
        )
    } else if (data.color !== ButtonColor.custom && data.textColor === ButtonTextColor.custom) {
        return (
            <MUIButton
                variant={data.variant}
                color={data.color}
                sx={{color: data.textColorCustom}}
                onClick={openLink}
                fullWidth
            >
                <div dangerouslySetInnerHTML={{ __html: data.title }} />
            </MUIButton>
        )
    } else {
        if(data.variant === "outlined")
        {
            return (
                <MUIButton
                    variant={data.variant}
                    sx={{
                        borderColor: data.colorCustom,
                        color:data.textColorCustom,
                        ':hover': {
                            borderColor: ColorHover(data.colorCustom, +0.5),
                        }
                }}
                    onClick={openLink}
                    fullWidth
                >
                    <div dangerouslySetInnerHTML={{ __html: data.title }} />
                </MUIButton>
            )
        }
        return (
            <MUIButton
                variant={data.variant}
                sx={{
                    backgroundColor: data.colorCustom,
                    color: data.textColorCustom,
                    ':hover': {
                        backgroundColor: ColorHover(data.colorCustom, -0.3),
                    },
            }}
                onClick={openLink}
                fullWidth
            >
                <div dangerouslySetInnerHTML={{ __html: data.title }} />
            </MUIButton>
        )
    }

});

const ColorHover = (hex: string, luminosity: number) => {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
        hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
    }
    let rgb = "#", c, i;
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substring(i*2,i*2+2), 16);
        c = Math.round(Math.min(Math.max(0, c + (c * luminosity)), 255)).toString(16);
        rgb += ("00"+c).substring(c.length);
    }
    return rgb;
}

import './Layout/App.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { observer } from 'mobx-react-lite';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createBrowserHistory } from 'history';
import { useStore } from './Stores';
import { LoadingComponent } from './Views/Util';
import { CookieService, Cookie } from './Services';

export const history = createBrowserHistory();

library.add(fab);

export default observer(function App() {
	const { commonStore } = useStore();
	const domain = window.location.host
	if (CookieService.getCookie(Cookie.httpReferrer) === undefined) {
		CookieService.setCookie(Cookie.httpReferrer, window.document.referrer ? window.document.referrer : 'no referrer')
	}
	if (commonStore.currentDomain !== domain) {
		commonStore.loadSite(domain)
			.catch(() => window.location.href = `https://${process.env.REACT_APP_BASE_URL}/`)
	}
	return (
		<>
			{commonStore.siteContent ?? <LoadingComponent />}
		</>
	);
});




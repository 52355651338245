import { IconName } from "@fortawesome/fontawesome-svg-core";
import { makeAutoObservable } from "mobx";
import { v4 as uuid } from "uuid";

import { fab } from '@fortawesome/free-brands-svg-icons'
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faLink } from '@fortawesome/free-solid-svg-icons'

import { Content, Type, Alignment } from ".."

export enum SocialsColor {
    primary = "primary",
    secondary = "secondary"
}

export class SocialsLink {
    id: string = "";
    type?: IconName;
    url: string = "";
    
    constructor() {
        makeAutoObservable(this)
    }

    static isValidIcon = (name: string): boolean => {
        if (name.length > 1) {
            const fullName = `fa${name[0].toUpperCase() + name.slice(1)}`
            return fab[fullName] !== undefined
        }
        return false
    }

    static iconProp = (link: SocialsLink): IconProp  => {
        if (link.type) {
            if (this.isValidIcon(link.type)) {
                return ["fab", link.type]
            }
        }
        return faLink
    }
}

export class Socials implements Content {
    id: string = uuid();
    name: string = "Socials";
    type: Type = Type.socials;
    index: number = 0;
    width: number = 12;
    color: SocialsColor = SocialsColor.secondary;
    hoverColor: SocialsColor = SocialsColor.primary;
    alignment: Alignment = Alignment.center;
    links: SocialsLink[] = [];

    constructor() {
        makeAutoObservable(this)
    }
};

export interface SocialsUpdater {
    name?: string;
    width?: number;
    alignment?: Alignment;
    color?: SocialsColor;
    hoverColor?: SocialsColor;
}
import { PublicKey } from "@solana/web3.js";
import { MintLayout } from "@solana/spl-token";

export class MintInfo {
  address: PublicKey;
  mintAuthority: PublicKey | null;
  supply: number;
  decimals: number;
  isInitialized: boolean;
  freezeAuthority: PublicKey | null;
  constructor(data: Buffer)
  {
    const rawMint = MintLayout.decode(data);
    if (rawMint.mintAuthorityOption === 0) {
      this.mintAuthority = null;
    } else {
      this.mintAuthority = new PublicKey(rawMint.mintAuthority);
    }
    this.supply = Number(rawMint.supply);
    this.isInitialized = rawMint.isInitialized;

    if (rawMint.freezeAuthorityOption === 0) {
      this.freezeAuthority = null;
    } else {
      this.freezeAuthority = new PublicKey(rawMint.freezeAuthority);
    }
    this.decimals = rawMint.decimals;
  }
}

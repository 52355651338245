import React from 'react';
import { observer } from "mobx-react-lite"
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ListItemText from '@mui/material/ListItemText';
import { Box, Button, ClickAwayListener, Menu, IconButton, ListItemButton, ListItemIcon, MenuItem, Divider, Stack, TextField, Paper } from '@mui/material';
import { Delete, CopyAll, ContentCopy, ContentPaste, ExpandLess, ExpandMore } from '@mui/icons-material';

import { useStore } from '../../Stores';
import { Content, Model } from '../../Models/Site';
import { ConfirmationDialog } from '../Util';
import { TemplateData } from '../../Models/API/Collections';

interface Props {
    template: TemplateData;
    onSave: (template: TemplateData) => void;
    onDelete?: () => void;
}

export const TemplateTree = observer(({ template, onSave, onDelete = () => { } }: Props) => {
    const { cmsStore, cmsNavBarStore } = useStore();

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [menuItem, setMenuItem] = React.useState<Model | undefined>();
    const menuOpen = Boolean(anchorEl);
    const [confirmationOpen, setConfirmationOpen] = React.useState(false);

    const [id, setId] = React.useState(template.id);
    const [name, setName] = React.useState(template.name);
    const [width, setWidth] = React.useState(template.width);
    const [height, setHeight] = React.useState(template.height);

    const data = cmsStore.data.pages.length > 0 ? cmsStore.data.pages[0].elements[0] : template.content
    if (!data) {
        return <></>
    }
    if (id !== template.id) {
        setName(template.name)
        setWidth(template.width)
        setHeight(template.height)
        setId(template.id)
    }

    const saveTemplate = () => {
        const newTemplate = Object.assign({}, template)
        newTemplate.name = name
        newTemplate.width = width
        newTemplate.height = height
        newTemplate.content = cmsStore.data.pages[0].elements[0]
        onSave(newTemplate)
    }

    const downloadTemplate = () => {
        const fileData = JSON.stringify(data, null, 4);
        const blob = new Blob([fileData], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = `${data.name}.json`;
        link.href = url;
        link.click();
    }

    const handleContextMenu = (event: React.MouseEvent<HTMLElement>, model: Model) => {
        event.preventDefault();
        setMenuItem(model)
        setAnchorEl(event.currentTarget)
    }

    const closeMenu = () => {
        setAnchorEl(null)
        setMenuItem(undefined)
    }

    const createMenuItem = (title: string, icon: JSX.Element, action: () => void, disabled?: boolean) => {
        return (
            <MenuItem
                sx={{ pointerEvents: 'visiblePainted' }}
                onClick={() => {
                    action()
                    closeMenu()
                }}
                disabled={disabled}
            >
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText>{title}</ListItemText>
            </MenuItem>
        );
    }


    const expandButton = (id: string): React.ReactElement => (
        <IconButton
            key={`IconButton${id}`}
            onClick={(e) => {
                e.stopPropagation();
                cmsNavBarStore.open(id, !cmsNavBarStore.isOpen(id));
            }}>
            {cmsNavBarStore.isOpen(id) ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
    );

    const buildItem = (model: Model, inset: number): React.ReactElement => {
        if (model && model.id.length > 0) {
            return (
                <Box key={`Box${model.id}`}>
                    <ListItemButton
                        key={`ListItemButton${model.id}-${model.index}`}
                        onClick={() => cmsStore.select(model.id)}
                        sx={{ pl: inset }}
                        disabled={cmsStore.editedItemId !== undefined}
                        selected={cmsStore.selectedItemId === model.id}
                        onContextMenu={(e) => handleContextMenu(e, model)}
                    >
                        <ListItemText>{model.name}</ListItemText>
                        {Model.itemsInModel(model).length > 0 ? expandButton(model.id) : null}
                    </ListItemButton>
                    <Collapse
                        key={`Collapse${model.id}`}
                        in={cmsNavBarStore.isOpen(model.id)}
                    >
                        <List key={`List${model.id}`} component="div" disablePadding>
                            {Model.itemsInModel(model).map(
                                function (x) {
                                    return buildItem(x, inset + 4)
                                }
                            )}
                        </List>
                    </Collapse>
                </Box>
            );
        }
        return (
            <></>
        );
    }
    const drawerWidth = 240;

    let fileReader: FileReader
    const handleFileRead = () => {
        const content = fileReader.result;
        const uploadedTemplate: Content = JSON.parse(content as string)
        cmsStore.setCollectionTemplateData(uploadedTemplate)
        cmsStore.save()
    };
    const handleFileChosen = (file: Blob) => {
        fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        fileReader.readAsText(file);
    };

    const uploadButton = (
        <>
            <input
                accept="application/json"
                style={{ display: "none" }}
                id="uploadJSON"
                name="Site JSON"
                type="file"
                onChange={(t) => {
                    if (t.target.files && t.target.files.length > 0) {
                        handleFileChosen(t.target.files[0])
                    }
                }}
            />
            <Button
                sx={{ mx: 2, my: 1 }}
                variant="outlined"
                onClick={() => {
                    document.getElementById("uploadJSON")?.click()
                }}
            >
                Upload Template
            </Button>
        </>
    )
    const canPaste = menuItem
        && cmsNavBarStore.pasteboard
        && Model.canAdd(cmsNavBarStore.pasteboard.type, menuItem.type)
    const canCopy = menuItem
        && Model.canCopy(menuItem.type)
    const canDuplicate = menuItem
        && canCopy
        && menuItem.id !== data.id
    const canDelete = menuItem
        && Model.canDelete(menuItem)
        && menuItem.id !== data.id
    const showMenu = (model: Model) => {
        // Can add
        if (Model.canCopy(model.type)) return true

        // Can paste
        if (canPaste) return true

        // Can delete
        if (Model.canDelete(model)) return true
        return false
    };

    return (
        <Paper sx={{
            height: `100%`,
            padding: 2
        }}>
            <Stack spacing={2}>
                <TextField
                    id="name"
                    label="Template Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                />
                <TextField
                    id="width"
                    label="Width"
                    value={width}
                    onChange={(e) => setWidth(+e.target.value)}
                    fullWidth
                />
                <TextField
                    id="height"
                    label="Height"
                    value={height}
                    onChange={(e) => setHeight(+e.target.value)}
                    fullWidth
                />
                <List
                    key="CMSNavBarList"
                    sx={{ bgcolor: 'background.paper' }}
                    component="nav"
                >
                    {buildItem(data, 2)}
                </List>
                <Button variant='contained' disabled={!cmsStore.hasChanges} onClick={saveTemplate}>Save Template</Button>
                <Button variant='outlined' onClick={downloadTemplate}>Download Template</Button>
                {uploadButton}
                <Button variant='outlined' color='error' onClick={() => setConfirmationOpen(true)}>Delete Template</Button>
                {menuItem && showMenu(menuItem) ?
                    <ClickAwayListener onClickAway={closeMenu}>
                        <Menu
                            id="mouse-over-popover"
                            sx={{
                                width: '100%',
                                pointerEvents: 'none',
                                justifyItems: 'flex-end'
                            }}
                            anchorEl={anchorEl}
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            transformOrigin={{ vertical: "top", horizontal: "right" }}
                            open={menuOpen}
                            onClose={closeMenu}
                            MenuListProps={{
                                'aria-labelledby': 'account-button',
                            }}
                            PaperProps={{
                                style: {
                                    minWidth: 200,
                                    maxWidth: 400
                                }
                            }}
                            elevation={1}
                            disableAutoFocusItem
                            disableRestoreFocus
                            variant='selectedMenu'
                        >
                            {canDuplicate ? createMenuItem('Duplicate', <CopyAll />, () => {
                                cmsStore.duplicate(menuItem.id)
                            }) : undefined}
                            {canDuplicate ? <Divider /> : undefined}
                            {canCopy ? createMenuItem('Copy', <ContentCopy />, () => {
                                let copy = Model.copy(menuItem);
                                cmsNavBarStore.setPasteboard(copy)
                            }) : undefined}
                            {canPaste ? createMenuItem('Paste', <ContentPaste />, () => {
                                let copy = cmsNavBarStore.pasteboard
                                if (copy) {
                                    cmsStore.addModel(copy, menuItem.id);
                                    cmsNavBarStore.setPasteboard(Model.copy(copy))
                                }
                            }) : undefined}
                            {canCopy && canDelete ? <Divider /> : undefined}
                            {canDelete ? createMenuItem('Delete', <Delete />, () => {
                                let parentId = cmsStore.delete(menuItem.id)
                                if (parentId) {
                                    cmsNavBarStore.open(parentId, true)
                                    cmsStore.select(parentId)
                                }
                            }) : undefined}
                        </Menu>
                    </ClickAwayListener>
                    : undefined}
                <ConfirmationDialog
                    open={confirmationOpen}
                    message='The template will be permanently deleted'
                    confirmText='Delete template'
                    onClose={() => setConfirmationOpen(false)}
                    onConfirm={onDelete}
                />
            </Stack>
        </Paper>
    );
});
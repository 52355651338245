import { Content } from "../../Site";

export class Collection {
    id: string = "";
    name: string = "";
}

export class Template {
    id: string = "";
    name: string = "";
    width: number = 300;
    height: number = 485;
}

export class TemplateData {
    id: string = "";
    name: string = "";
    width: number = 300;
    height: number = 485;
    content: Content | undefined;
}

// No time for backend changes so wanging this in there
export class TemplateDataDTO {
    id: string = "";
    name: string = "";
    width: number = 300;
    height: number = 485;
    content: string = "";
}

export class NFTCollectionDTO {
    name: string = "";
    title: string = "";
    creatorCollection: boolean = true;
    blockChain: string = "";
    nftMintAddressList: string[] = [];
  }

export class InflatedCollection {
    id: string = "";
    name: string = "";
    title: string = "";
    cardSize: { width: number, height: number } = { width: 300, height: 400 }
    totalPages: number = 0;
    cards: Content[] = [];
}

export enum CollectionKeyType {
    string = 'string',
    boolean = 'boolean',
    int = 'int',
    double = 'double',
    float = 'float',
    datetime = 'datetime'
}

export class CollectionKey {
    id: string = "";
    name: string = "";
    type: CollectionKeyType = CollectionKeyType.string;
    minimum: string | undefined;
    maximum: string | undefined;
    starred: boolean = false;
    allowedValues: string[] = [];
}

export class CollectionSearchFilter {
    key: string = "";
    type: CollectionKeyType = CollectionKeyType.string
    value: string = "";
    include: boolean = true;
}

export class InflatedCollectionSearchParam {
    searchKey?: string;
    searchValue?: string;
    orderBy?: string;
    filters: CollectionSearchFilter[] = [];
}
import { Container, Box } from "@mui/material";
import Vimeo from '@u-wave/react-vimeo';
import { observer } from "mobx-react-lite";

import { YouTube } from ".";
import { Video as VideoModel, VideoService } from "../../../../Models/Site";
import { useStore } from "../../../../Stores";

interface Props {
    fullScreen: boolean
    data: VideoModel
}

export const Video = observer(({ fullScreen, data }: Props) => {
    const { commonStore } = useStore();
    const player = () => {
        switch (data.service) {
            case VideoService.vimeo:
                return <Vimeo video={data.videoId} width='100%' responsive />
            case VideoService.youtube:
                return <YouTube title={data.title} videoId={data.videoId} />
        }
    }
    return (
        <Container
            disableGutters
            sx={{
                backgroundColor: 'background.default',
                height: fullScreen ? `calc(100vh - ${commonStore.navbarHeight}px)` : '100%',
                overflowY: 'scroll',
                padding: 2,
            }}
            className='hide-scrollbar'
        >
            <Box>
                {player()}
                <h3>{data.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: data.description }} />
            </Box>
        </Container>
    )
});
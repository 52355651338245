import { makeAutoObservable } from "mobx";
import { NavBarItem, Model, Type } from "."

export class NavBar implements Model {
    id: string = "";
    name: string = "";
    type: Type = Type.navBar;
    index: number = 0;
    routes: NavBarItem[] = [];

    constructor() {
        makeAutoObservable(this)
    }
}

export interface NavBarUpdater {
    name?: string;
}
import {Input, InputAdornment, List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {Search} from "@mui/icons-material";
import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../../Stores";
import {SplToken} from "../../../../Models/API";

export interface Props {
    source : boolean
}
export const RallySwapMenuList = observer(({source}: Props) => {
    const {swapStore, modalStore} = useStore();
    const {closeModal} = modalStore;
    const {allTokens, selectedTokens, setTokenA, setTokenB} = swapStore;
    const tokens = source ? allTokens.slice(1) : selectedTokens;
    const [searchTerm, setSearchTerm] = useState('');
    const selectToken = async (token: SplToken) => {
        if (source) {
           await setTokenA(token);
        } else {
            await setTokenB(token);
        }
        closeModal();
    }
    return (
        <>

            <Input
                placeholder="Search..."
                inputRef={input => input && input.focus()}
                onChange={event => {setSearchTerm(event.target.value)}}
                startAdornment={
                    <InputAdornment position="start" >
                        <Search />
                    </InputAdornment>}
            />

            <List>
                {tokens.filter((option)=> {
                        if (searchTerm == "") {
                            return option
                        } else if (option.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return option
                        }
                    }
                ).map((option) => (
                    <ListItem>
                        <ListItemButton
                            key={option.key}

                            onClick={()=> selectToken(option)}

                        >
                            <ListItemText primary={option.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </>
    )
});

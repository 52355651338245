import {Connection, PublicKey} from "@solana/web3.js";
import {MintInfo} from "./models/mintInfo";
import {TokenAccountInfo} from "./models/tokenAccountInfo";
import {SolanaMaths} from "./utils/solanaMaths";
import {Wallet} from "@solana/wallet-adapter-react";

export class AccountBalance {
    wallet: Wallet;
    connection: Connection;
    private programId = new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA');
    /** Address of the SPL Associated Token Account program */
    private associatedTokenProgramId = new PublicKey('ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL');

    constructor(wallet: Wallet, token: string) {
        this.connection = new Connection('https://white-quick-arrow.solana-mainnet.quiknode.pro/',
            {
                httpHeaders: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
        this.wallet = wallet;
    }

    async getAccountBalance(mint: string) : Promise<number | undefined> {
        console.log(mint);
        const mintKey = new PublicKey(mint);
        const decimals = await this.getMintInfo(mintKey);
        if(!decimals || !this.wallet.adapter.publicKey) return undefined;
        const reply = await this.getAssociatedTokenAddress(mintKey, this.wallet.adapter.publicKey);
        console.log(reply)
        try {
            if(reply)
            {
                const tokenInfo = await this.getTokenAccountInfo(reply);
                return SolanaMaths.decToBase(Number(tokenInfo.amount), decimals.decimals);
            }
        } catch {
            return undefined;
        }
        return undefined;
    }
    private async getMintInfo(mintAddress: PublicKey) {
        const info = await this.connection.getAccountInfo(mintAddress);
        if(info)
        {
            const data = Buffer.from(info.data);
            // const rawMint = MintLayout.decode<RawMint>(data);
            return new MintInfo(data);
        }
    }

    async getAssociatedTokenAddress (
        mint: PublicKey,
        owner: PublicKey
    ) {
        try {
            const [address] = PublicKey.findProgramAddressSync(
                [owner.toBuffer(), this.programId.toBuffer(), mint.toBuffer()],
                this.associatedTokenProgramId
            );
            return address;
        } catch (e) {
            console.log(e);
        }
    }
    private async getTokenAccountInfo (address: PublicKey) {
        const info = await this.connection.getAccountInfo(address);
        if(!info) {
            throw new Error('Failed to find token account');
        }
        const data = Buffer.from(info.data);
        return new TokenAccountInfo(data);
    };
}

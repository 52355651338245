import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Container, Grid, List, useMediaQuery } from "@mui/material";

import { Playlist as PlaylistModel } from "../../../../Models/Site"
import { useStore } from '../../../../Stores';
import { PlaylistSection, Video } from '..';
import { useTheme } from '@mui/material/styles';

interface Props {
    data: PlaylistModel
}

export const Playlist = observer(({ data }: Props) => {
    const { playlistStore } = useStore();

    useEffect(() => {
        playlistStore.setPlaylist(data);
    }, [data, playlistStore]);

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('lg'), {
        defaultMatches: true
    });

    const upNext = (() => {
        return (
            <Container
                disableGutters
                sx={{
                    backgroundColor: 'background.default',
                    overflowY: 'scroll',
                    paddingTop: 1,
                    paddingLeft: isSmall ? 2 : 0,
                    width: '100%'
                }}>
                <List>
                    {data.sections.map((section) => {
                        return (
                            <PlaylistSection key={section.id} data={section} />
                        )
                    })}
                </List>
            </Container>
        );
    })();
    return (
        <Grid container direction={isSmall ? 'column' : 'row'}>
            <Grid item md={6} lg={9}>
                {playlistStore.currentVideo ?
                    <Video fullScreen={true} data={playlistStore.currentVideo} />
                    : null}
            </Grid>
            <Grid item md={6} lg={3}>
                {upNext}
            </Grid>
        </Grid>
    );
});


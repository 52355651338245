import { AlertColor } from "@mui/material";
import { makeAutoObservable, } from "mobx"

export class ToastBarStore {
    open: boolean = false;
    message: string = "";
    severity: AlertColor = "info";
    autoHideDuration: number = 6000;
    action?: () => void;
    actionTitle?: string;
    
    constructor() {
        makeAutoObservable(this)
    }

    showMessage = (message: string, severity: AlertColor = "info", autoHideDuration: number = 6000, action?: ()=>void, actionTitle?: string) => {
        this.open = true;
        this.message = message
        this.severity = severity
        this.autoHideDuration = autoHideDuration
        this.action = action
        this.actionTitle = actionTitle
    }

    showGenericError = () => {
        this.showMessage('Something went wrong. Please try again.', 'error')
    }

    closeMenu = () => {
        this.open = false;
    }
}
import { Box, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";

import { Header as HeaderModel } from "../../../Models/Site"
import { useStore } from "../../../Stores";
import { ImageSize, ImageWidths } from ".."

interface Props {
    data: HeaderModel
    parentWidth: number
}

export const Header = observer(({ data, parentWidth }: Props) => {
    const { commonStore } = useStore();

    const [width, setWidth] = React.useState(0)
    const theme = useTheme();

    const checkAndSetWidth = (size: ImageSize) => {
        const newWidth = Math.round((() => {
            const pWidth = parentWidth / 12
            const cWidth = data.width / 12
            const tWidth = pWidth * cWidth
            switch (size) {
                case ImageSize.xs: return ImageWidths.xs * tWidth;
                case ImageSize.sm: return ImageWidths.sm * tWidth;
                case ImageSize.md: return ImageWidths.md * tWidth;
                case ImageSize.lg: return ImageWidths.lg * tWidth;
                case ImageSize.xl: return ImageWidths.xl * tWidth;
                default: return 0
            }
        })())
        if (width < newWidth) {
            setWidth(newWidth)
        }
    }
    const xs = useMediaQuery(theme.breakpoints.down('sm'));
    const sm = useMediaQuery(theme.breakpoints.down('md'));
    const md = useMediaQuery(theme.breakpoints.down('lg'));
    const lg = useMediaQuery(theme.breakpoints.down('xl'));
    const xl = useMediaQuery(theme.breakpoints.up('xl'));

    if (xs) { checkAndSetWidth(ImageSize.xs) }
    else if (sm) { checkAndSetWidth(ImageSize.sm) }
    else if (md) { checkAndSetWidth(ImageSize.md) }
    else if (lg) { checkAndSetWidth(ImageSize.lg) }
    else if (xl) { checkAndSetWidth(ImageSize.xl) }

    const bgImage = data.backgroundImageId ? {
        backgroundImage: `url(${process.env.REACT_APP_API_URL}/Image?imageId=${data.backgroundImageId}&width=${width})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    } : { }
    return (
        <Box
            key={data.id}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
                ...bgImage,
                padding: 5,
                minHeight: data.fullScreen ? `calc(100vh - ${commonStore.navbarHeight}px)` : undefined
            }}
        >
            <Container sx={{ width: '60%', overflow: 'hidden', wordWrap: 'break-word' }}>
                <Typography variant="h1" sx={{ textAlign: 'center', color: data.headingColor }} >
                    {data.heading}
                </Typography>
                <Typography variant="h3" sx={{ textAlign: 'center', color: data.subHeadingColor }} >
                    {data.subHeading}
                </Typography>
            </Container>
        </Box>
    )
});
import React from 'react';
import { observer } from "mobx-react-lite"
import { Stack, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { useStore } from "../../Stores";
import { CMSActionBar, CMSEditButtons } from '.';
import { NavBar as NavBarModel, NavBarItem } from '../../Models/Site'

interface Props {
    data: NavBarModel;
}

export const CMSNavBar = observer(({ data }: Props) => {
    const { cmsStore } = useStore();
    const { working_model } = cmsStore;
    let isEditing = cmsStore.editedItemId === data.id;
    let dataToUse: NavBarModel = isEditing ? (working_model as NavBarModel) : data
    return (
        <Stack direction="column" spacing={2} justifyContent="space-between">
            <CMSEditButtons
                id={data.id}
                index={data.index}
                undeletable
            />
            <h5>ID: {data.id}</h5>
            <TextField
                id="name"
                label="Name"
                value={dataToUse.name}
                onChange={(e) => cmsStore.updateNavBar({ name: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <CMSActionBar
                id={data.id}
                addContent={false}
                buttons={[
                    {
                        text: "Add Item",
                        action: () => cmsStore.addModel(new NavBarItem(), data.id),
                        icon: <AddIcon />
                    }
                ]}
            />
        </Stack>
    );
});
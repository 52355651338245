import { observer } from "mobx-react-lite"
import { Autocomplete, Button, Stack, TextField } from '@mui/material';

import { useStore } from "../../../Stores";
import {CMSColorPicker, CMSEditButtons, CMSWidthField} from '..';
import {Text as TextModel, Variant, Alignment, TextColor} from '../../../Models/Site'
import { TemplateKeyPicker } from "../../Forms";

interface Props {
    data: TextModel;
    collectionId?: string; // indicates template mode
}

export const CMSText = observer(({ data, collectionId }: Props) => {
    const { cmsStore, modalStore } = useStore();
    const { working_model } = cmsStore;
    let isEditing = cmsStore.editedItemId === data.id;
    let dataToUse: TextModel = isEditing ? (working_model as TextModel) : data
    if(!dataToUse.textColor) dataToUse.textColor = TextColor.default;
    if(!dataToUse.textColorCustom) dataToUse.textColorCustom = "#000000";

    const templateOptions = (() => {
        if (collectionId) {
            const currentKey = (dataToUse.text && dataToUse.text.startsWith('{{')) ? dataToUse.text : ''
            return (
                <Button
                    variant='outlined'
                    disabled={!isEditing}
                    onClick={() => {
                        modalStore.openModal(<TemplateKeyPicker currentKey={currentKey} collectionId={collectionId} onSelect={(key: string) => {
                            cmsStore.updateText({ text: `{{${key}}}` })
                        }} />)
                    }}
                >
                    Select Text Parameter
                </Button>
            )
        }
        return undefined
    })();
    
    return (
        <Stack direction="column" spacing={2} justifyContent="space-between">
            <CMSEditButtons
                id={data.id}
                index={data.index}
            />
            <h5>ID: {data.id}</h5>
            <TextField
                id="name"
                label="Name"
                value={dataToUse.name}
                onChange={(e) => cmsStore.updateText({ name: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <CMSWidthField 
                value={dataToUse.width.toString()}
                onChange={(newValue)=>cmsStore.updateText({ width: +(newValue) })}
                disabled={!isEditing}
            />
            <Autocomplete
                id="variant"
                value={dataToUse.variant}
                onChange={(_, newValue)=>cmsStore.updateText({ variant: newValue as Variant })}
                options={Object.values(Variant)}
                disabled={!isEditing}
                renderInput={(params) => (<TextField {...params} label="Variant" />)}
            />
            <Autocomplete
                id="align"
                value={dataToUse.align}
                onChange={(_, newValue)=>cmsStore.updateText({ align: newValue as Alignment })}
                options={Object.values(Alignment)}
                disabled={!isEditing}
                renderInput={(params) => (<TextField {...params} label="Align" />)}
            />
            <Autocomplete
                id="textColor"
                value={dataToUse.textColor}
                onChange={(_, newValue)=>cmsStore.updateText({ textColor: newValue as TextColor })}
                options={Object.values(TextColor)}
                disabled={!isEditing}
                renderInput={(params) => (<TextField {...params} label="Text Color" />)}
            />
            <CMSColorPicker
                id="textColorCustom"
                label="Custom Color"
                value={dataToUse.textColorCustom}
                onChange={(c) => cmsStore.updateText({ textColorCustom: c })}
                disabled={!isEditing || dataToUse.textColor !== TextColor.custom}
            />
            <TextField
                id="text"
                label="Text"
                value={dataToUse.text}
                onChange={(e) => cmsStore.updateText({ text: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            {templateOptions}
        </Stack>
    );
});

import { observer } from "mobx-react-lite"
import { Autocomplete, Box, Checkbox, CircularProgress, FormControlLabel, IconButton, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';

import { useStore } from '../../Stores';
import { CMSActionBar, CMSEditButtons } from '.';
import { Page as PageModel } from '../../Models/Site';

interface Props {
    data: PageModel;
}

class Option {
    label: string
    value: string

    constructor(label: string, value: string) {
        this.label = label;
        this.value = value;
    }

    static isEqual(lhs: Option, rhs: Option): boolean {
        if (lhs && rhs) {
            return lhs.value === rhs.value;
        }
        return false;
    }
}

const twitchOptions: Option[] = [
    new Option("Not a Subscriber", "0"),
    new Option("Tier 1 Subscriber", "1000"),
    new Option("Tier 2 Subscriber", "2000"),
    new Option("Tier 3 Subscriber", "3000")
]

export const CMSPage = observer(({ data }: Props) => {
    const { cmsStore, creatorStore } = useStore();
    const { working_model } = cmsStore;
    let isEditing = cmsStore.editedItemId === data.id;
    let dataToUse: PageModel = isEditing ? (working_model as PageModel) : data

    const [hasLoaded, setHasLoaded] = React.useState(false);
    const [refreshingPatreonTiers, setRefreshingPatreonTiers] = React.useState(false);

    const refreshPatreonTiers = () => {
        if (cmsStore.data) {
            setRefreshingPatreonTiers(true)
            creatorStore.getPatreonCampaign(cmsStore.data.id)
                .finally(() => {
                    setHasLoaded(true)
                    setRefreshingPatreonTiers(false)
                })
        }
    }

    function currentPatreonOption(): Option | undefined {
        const patreonCondition = dataToUse.conditions.patreon as number;
        if (creatorStore.campaign) {
            let data = creatorStore.campaign.tiers.find((option) => option.order === patreonCondition)
            if (data) {
                return new Option(`${data.order} - ${data.title}`, data.order.toString())
            }
        }
        return undefined;
    }

    const refreshButton = () => {
        return (
            <IconButton
                disabled={refreshingPatreonTiers}
                onClick={refreshPatreonTiers}
                sx={{
                    p: 2, flex: '0 1 auto'
                }}>
                <RefreshIcon />
            </IconButton>
        )
    }
    const patreonPicker = (() => {
        if (refreshingPatreonTiers) {
            return (
                <Box display='flex' flexDirection='row' paddingX={1} paddingY={2}>
                    <Typography variant='body1' color='primary.main' sx={{ flex: 1 }}>Loading tiers</Typography>
                    <CircularProgress sx={{ flex: '0 1 auto' }} size={25} color="primary" />
                </Box>
            )
        } else if (creatorStore.campaign) {
            const options = creatorStore.campaign.tiers.map((tier) => { return new Option(`${tier.order} - ${tier.title}`, tier.order.toString()) })
            return (
                <Box display='flex' flexDirection='row'>
                    <Autocomplete
                        id="Patreon"
                        options={options}
                        sx={{ flex: 1 }}
                        disabled={!isEditing}
                        value={currentPatreonOption()}
                        onChange={(_, newValue) => cmsStore.updatePageCondition({ patreon: newValue ? +newValue.value : 0 })}
                        isOptionEqualToValue={Option.isEqual}
                        renderInput={(params) => (<TextField {...params} label="Patreon" />)}
                    />
                    {refreshButton()}
                </Box>
            )
        } else if (hasLoaded) {
            return (
                <Box display='flex' flexDirection='row' alignItems='center'>
                    <Typography variant='body1' color='primary.main' sx={{ pl: 2, flex: 1 }}>No Patreon Campaign</Typography>
                    {refreshButton()}
                </Box>
            )
        } else if (dataToUse.conditions.hasConditions) {
            refreshPatreonTiers()
            return <></>;
        }
    })();

    function currentTwitchOption(): Option | undefined {
        const twitchCondition = dataToUse.conditions.twitch as string;
        return twitchOptions.find((option) => option.value === twitchCondition);
    }
    const pageConditions = ((): React.ReactNode => {
        const conditionsElements = (() => {
            if (dataToUse.conditions.hasConditions) {
                return (
                    <>
                        <Autocomplete
                            id="twitch"
                            value={currentTwitchOption()}
                            onChange={(_, newValue) => cmsStore.updatePageCondition({ twitch: newValue?.value })}
                            options={twitchOptions}
                            isOptionEqualToValue={Option.isEqual}
                            disabled={!isEditing}
                            renderInput={(params) => (<TextField {...params} label="Twitch" />)}
                        />
                        {patreonPicker}
                        <TextField
                            id="rallyCondition"
                            label="Rally"
                            value={dataToUse.conditions.rally}
                            onChange={(e) => cmsStore.updatePageCondition({ rally: +e.target.value })}
                            disabled={!isEditing}
                            fullWidth
                            margin="normal"
                        />
                    </>
                )
            }
            return (
                <></>
            )
        })();

        return (
            <>
                <FormControlLabel
                    disabled={!isEditing}
                    control={
                        <Checkbox
                            id="hasConditions"
                            checked={dataToUse.conditions.hasConditions}
                            onChange={(_, isChecked) => cmsStore.updatePageCondition({ hasConditions: isChecked })}
                        />
                    }
                    label="Requires Conditions"
                />
                {conditionsElements}
            </>
        )
    })();
    return (
        <Stack direction="column" spacing={2} justifyContent="space-between">
            <CMSEditButtons
                id={data.id}
                index={data.index}
            />
            <h5>ID: {data.id}</h5>
            <TextField
                id="name"
                label="Name"
                value={dataToUse.name}
                onChange={(e) => cmsStore.updatePage({ name: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <TextField
                id="path"
                label="Path"
                value={dataToUse.path}
                onChange={(e) => cmsStore.updatePage({ path: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            {pageConditions}
            <CMSActionBar id={data.id} />
        </Stack>
    );
});
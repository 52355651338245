import {observer} from "mobx-react-lite";
import {Box, Grid, TextField} from "@mui/material";
import React from "react";
import {useStore} from "../../../Stores";
import {LoadingButton} from "@mui/lab";


export const AdminAddSplTokenModal = observer(() => {
    const {adminStore, modalStore} = useStore();
    const {createSplToken} = adminStore;
    const [submitting, setSubmitting] = React.useState(false);
    const [splKeyError, setSplKeyError] = React.useState(false);
    const [splNameError, setSplNameError] = React.useState(false);
    const [splTickerError, setSplTickerError] = React.useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const splKey = data.get('splKey')?.toString()
        const splName = data.get('splName')?.toString()
        const splTicker = data.get('splTicker')?.toString()

        setSplKeyError(!splKey)
        setSplNameError(!splName)
        setSplTickerError(!splTicker)

        if (splKey && splName && splTicker) {
            setSubmitting(true)
            createSplToken(
                splKey,splName,splTicker
            ).then(() => {
                modalStore.closeModal()
            }).finally(() => {
                setSubmitting(false)
            })
        }
    }

    return (
        <Box component="form"  onSubmit={handleSubmit}>
            <Grid container direction="column">
                <Grid item>
                    <TextField
                        margin="normal"
                        error={splKeyError}
                        onChange={() => setSplKeyError(false)}
                        required
                        fullWidth
                        id="splKey"
                        label="Key"
                        name="splKey"
                        autoComplete="splKey"
                        autoFocus
                    />
                </Grid>
                <Grid item>
                    <TextField
                        margin="normal"
                        error={splNameError}
                        onChange={() => setSplNameError(false)}
                        required
                        fullWidth
                        id="splName"
                        label="Name"
                        name="splName"
                        autoComplete="splName"
                        autoFocus
                    />
                </Grid>
                <Grid item>
                    <TextField
                        margin="normal"
                        error={splTickerError}
                        onChange={() => setSplTickerError(false)}
                        required
                        fullWidth
                        id="splTicker"
                        label="Ticker"
                        name="splTicker"
                        autoComplete="splTicker"
                        autoFocus
                    />
                </Grid>
                <LoadingButton
                    type="submit"
                    loading={submitting}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Add Spl Token
                </LoadingButton>
            </Grid>
        </Box>
    )
})
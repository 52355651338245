import { observer } from "mobx-react-lite"
import { Autocomplete, Button, Checkbox, FormControl, FormControlLabel, FormLabel, Paper, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';

import { useStore } from "../../../Stores";
import {CMSColorPicker, CMSEditButtons, CMSWidthField} from '..';
import {
    Button as ButtonModel,
    ButtonColor,
    ButtonTextColor,
    ButtonVariant,
    LinkType
} from '../../../Models/Site';
import { File as FileModel } from "../../../Models/API";
import { FilePickerForm } from "../../Forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
    data: ButtonModel;
}

export const CMSButton = observer(({ data }: Props) => {
    const { cmsStore, modalStore } = useStore();
    const { working_model } = cmsStore;
    let isEditing = cmsStore.editedItemId === data.id;
    let dataToUse: ButtonModel = isEditing ? (working_model as ButtonModel) : data

    const urlFields = () => {
        const isExternalUrl = (() => {
            try {
                new URL(dataToUse.url);
                return true
            } catch {
                return false
            }
        })()
        return (
            <>
                <Autocomplete
                    id="url"
                    freeSolo
                    value={dataToUse.url}
                    onChange={(_, newValue) => cmsStore.updateButton({ url: newValue ?? "" })}
                    options={cmsStore.data.pages.map((page) => page.path)}
                    renderInput={(params) => <TextField {...params} label="Link URL" onChange={(e) => {
                        cmsStore.updateButton({ url: e.target.value ?? "" })
                    }} />}
                    noOptionsText="External URL"
                    disabled={!isEditing}
                />
                {isExternalUrl ?
                    <FormControlLabel
                        disabled={!isEditing}
                        control={
                            <Checkbox
                                id="openInNewTab"
                                checked={dataToUse.openInNewTab}
                                onChange={(_, isChecked) => cmsStore.updateButton({ openInNewTab: isChecked })}
                            />
                        }
                        label="Opens in New Tab" />
                    : undefined
                }
            </>
        )
    }

    const fileFields = () => {
        return (
            <>
                <Button
                    variant='outlined'
                    disabled={!isEditing}
                    onClick={() => {
                        modalStore.openModal(<FilePickerForm onSelectFile={(file: FileModel) => {
                            cmsStore.updateButton({ file: file })
                        }} />)
                    }}>
                    Select File
                </Button>
                {
                    dataToUse.file.id ?
                        <Paper
                            elevation={1}
                            sx={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                opacity: isEditing ? 1 : 0.4
                            }}
                        >
                            <Stack
                                direction={{ xs: 'column', md: 'row' }}
                                spacing={2}
                                height='100%'
                                flex='1 1 auto'
                                alignItems='center'
                                padding={2}>
                                <FontAwesomeIcon icon={FileModel.iconProp(dataToUse.file)} size='lg' />
                                <Typography overflow='hidden' sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '250px',
                                    display: 'inline'
                                }}>{dataToUse.file.name}</Typography>
                            </Stack>
                        </Paper>
                        : undefined
                }
            </>
        )
    }

    return (
        <Stack direction="column" spacing={2} justifyContent="space-between">
            <CMSEditButtons
                id={data.id}
                index={data.index}
            />
            <h5>ID: {data.id}</h5>
            <TextField
                id="name"
                label="Name"
                value={dataToUse.name}
                onChange={(e) => cmsStore.updateButton({ name: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <CMSWidthField
                value={dataToUse.width.toString()}
                onChange={(newValue) => cmsStore.updateButton({ width: +(newValue) })}
                disabled={!isEditing}
            />
            <TextField
                id="title"
                label="Title"
                value={dataToUse.title}
                onChange={(e) => cmsStore.updateButton({ title: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />

            <FormControl disabled={!isEditing}>
                <FormLabel id="link-type-label">Button Type:</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="link-type"
                    name="link-type-group"
                    value={dataToUse.linkType}
                    onChange={(e) => cmsStore.updateButton({ linkType: e.target.value as LinkType })}
                >
                    <FormControlLabel value={LinkType.url} control={<Radio />} label="Url" />
                    <FormControlLabel value={LinkType.file} control={<Radio />} label="File" />
                </RadioGroup>
            </FormControl>
            {dataToUse.linkType === LinkType.file ? fileFields() : urlFields()}
            <Autocomplete
                id="variant"
                value={dataToUse.variant}
                onChange={(_, newValue) => cmsStore.updateButton({ variant: newValue as ButtonVariant })}
                options={Object.values(ButtonVariant)}
                disabled={!isEditing}
                renderInput={(params) => (<TextField {...params} label="Variant" />)}
            />
            <Autocomplete
                id="color"
                value={dataToUse.color}
                filterOptions={dataToUse.variant === ButtonVariant.text ? (options) => options.filter((option) => option !== ButtonColor.custom) : undefined}
                onChange={(_, newValue) => cmsStore.updateButton({ color: newValue as ButtonColor })}
                options={Object.values(ButtonColor)}
                disabled={!isEditing}
                renderInput={(params) => (<TextField {...params} label="Color" />)}
            />
            {
                dataToUse.color === ButtonColor.custom ?
                    <CMSColorPicker
                        id="colorCustom"
                        label="Custom Button Color"
                        value={dataToUse.colorCustom}
                        onChange={(c) => cmsStore.updateButton({ colorCustom: c })}
                        disabled={!isEditing || dataToUse.color !== ButtonColor.custom}
                    />
                    : null
            }

            <Autocomplete
                id="textColor"
                value={dataToUse.textColor}
                onChange={(_, newValue)=>cmsStore.updateButton({ textColor: newValue as ButtonTextColor })}
                options={Object.values(ButtonTextColor)}
                disabled={!isEditing}
                renderInput={(params) => (<TextField {...params} label="Text Color" />)}
            />
            {
                dataToUse.textColor === ButtonTextColor.custom ?
                    <CMSColorPicker
                        id="textColorCustom"
                        label="Custom Color"
                        value={dataToUse.textColorCustom}
                        onChange={(c) => cmsStore.updateButton({ textColorCustom: c })}
                        disabled={!isEditing || dataToUse.textColor !== ButtonTextColor.custom}
                    />
                    : null
            }

        </Stack>
    );
});

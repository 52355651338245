
import { Box, CircularProgress, Grid, Pagination, Paper, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";

import { CollectionSearchFilter } from "../../../../Models/API/Collections"
import { Collection as CollectionModel } from "../../../../Models/Site"
import { useStore } from "../../../../Stores";
import { ElementRenderer } from "../../Renderers";
import { CollectionFilter } from ".";
import { TheDream } from "../../../Util";

interface Props {
    data: CollectionModel
}

export const Collection = observer(({ data }: Props) => {
    const { collectionStore, modalStore } = useStore()
    const [filters, setFilters] = React.useState<CollectionSearchFilter[]>([])
    const [page, setPage] = React.useState(1);

    const key = data.collectionId + data.templateId
    const inflatedCollection = collectionStore.inflatedCollections[key]
    const keys = collectionStore.collectionKeys[data.collectionId]

    const filtersDidChange = (newFilters: CollectionSearchFilter[]) => {
        setFilters(newFilters)
        setPage(1)
        collectionStore.inflatedCollections[key] = undefined;
    }

    const pageDidChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
        collectionStore.inflatedCollections[key] = undefined
    }

    const filter = (() => {
        if (!keys) {
            collectionStore.getCollectionKeys(data.collectionId)
            return <CircularProgress color="inherit" />
        }
        return <CollectionFilter keys={keys} filters={filters} onChange={filtersDidChange} />
    })();

    const collectionView = (() => {
        if (!inflatedCollection) {
            const pageToGet = page > 0 ? (page - 1) : 0
            collectionStore.getInflatedCollection(data.collectionId, data.templateId, pageToGet, 10, { filters: filters })
            return <CircularProgress color="inherit" />
        }
        return (
            <Grid
                container
                spacing={4}
                columns={{ xs: 12, sm: 24, md: 36, lg: 48, xl: 60 }}
                direction='row'
                justifyContent='center'
                alignItems='center'
            >
                {inflatedCollection.cards ?
                    inflatedCollection.cards.map((item, index) => {
                        return (
                            <Grid container item key={index.toString()} xs={item.width}>
                                <Box onClick={() => {
                                    modalStore.openModal(<ModalCollectionItem cardId={item.id} data={data} />)
                                }}>
                                    {ElementRenderer.build({ model: item, parent: data })}
                                </Box>
                            </Grid>
                        )
                    })
                    :
                    <Grid item>
                        <Typography variant="h3" >No Cards</Typography>
                    </Grid>
                }
            </Grid>
        )
    })();

    const pagination = (()=> {
        if (!inflatedCollection) { return undefined }
        return <Pagination count={inflatedCollection.totalPages} page={page} onChange={pageDidChange} color='secondary' />
    })();

    return (
        <Stack alignItems='center' spacing={2}>
            {inflatedCollection ? <Typography variant="h3" >{inflatedCollection.title}</Typography> : undefined}
            <Grid container direction='row' columns={20} spacing={1}>
                <Grid item xs={3}>
                    {filter}
                </Grid>
                <Grid item xs={17}>
                    {collectionView}
                </Grid>
            </Grid>
            {pagination}
        </Stack>
    );
});

interface ModalProps {
    cardId: string;
    data: CollectionModel
}

const ModalCollectionItem = observer(({ cardId, data }: ModalProps) => {
    const { collectionStore } = useStore();
    const key = cardId + data.collectionId + data.modalTemplateId
    const card = collectionStore.inflatedCards[key]
    if (!card) {
        collectionStore.getInflatedCard(cardId, data.collectionId, data.modalTemplateId)
        return (
            <Box display='flex' flexDirection='row' paddingX={1} paddingY={2}>
                <Typography variant='body1' color='primary.main' sx={{ flex: 1 }}>Loading card</Typography>
                <CircularProgress sx={{ flex: '0 1 auto', ml: 2 }} size={25} color="primary" />
            </Box>
        )
    }
    return (
        <Box>
            {ElementRenderer.build({ model: card, parent: data })}
        </Box>
    )
});
import { AccountCircle, AdminPanelSettings, Settings, Construction, Logout, Collections } from "@mui/icons-material";
import { Stack, ClickAwayListener, Menu, MenuItem, ListItemIcon, ListItemText, Divider, Avatar, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

import { useStore } from '../../Stores';
import { history } from '../../App';

export const NavBarMenu = observer((() => {
    const { menuStore, accountStore } = useStore();
    const { open, anchorEl, closeMenu } = menuStore;

    const menuItem = (title: string, path: string, icon: JSX.Element) => {
        return (
            <MenuItem
                sx={{ pointerEvents: 'visiblePainted' }}
                onClick={() => {
                    history.push(path)
                    closeMenu()
                }}
            >
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText>{title}</ListItemText>
            </MenuItem>
        );
    }

    return (
        <ClickAwayListener onClickAway={closeMenu}>
            <Menu
                id="mouse-over-popover"
                sx={{
                    width: '100%',
                    pointerEvents: 'none',
                    justifyItems: 'flex-end'
                }}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                onClose={closeMenu}
                MenuListProps={{
                    'aria-labelledby': 'account-button',
                }}
                PaperProps={{
                    style: {
                        minWidth: 200,
                        maxWidth: 400
                    }
                }}
                elevation={1}
                disableAutoFocusItem
                disableRestoreFocus
                variant='selectedMenu'
            >
                <MenuItem sx={{ pointerEvents: 'none', py: 1, px: 2, whiteSpace: 'normal' }}>
                    <Stack justifyItems='space-between' alignItems='center' spacing={1} sx={{ width: '100%', wordWrap: "break-word" }} >
                        <Avatar sx={{ bgcolor: 'primary.main', color: 'primary.contrastText' }} >
                            {accountStore.user ? accountStore.user.userName[0] : undefined}
                        </Avatar>
                        <Typography variant='h5' align='center' style={{ width: '100%' }}>{accountStore.user ? accountStore.user.userName : "No Username"}</Typography>
                    </Stack>
                </MenuItem>
                <Divider />
                {menuItem('Profile', '/profile', <AccountCircle fontSize="small" />)}
                {accountStore.isCreator ? menuItem('Creator Profile', '/creator-profile', <AdminPanelSettings fontSize="small" />) : undefined}
                <Divider />
                {accountStore.isAdmin || accountStore.isEditor ? menuItem('Collections', '/collections-overview', <Collections fontSize="small" />) : undefined}
                {accountStore.isAdmin || accountStore.isEditor ? menuItem('CMS', '/cms', <Construction fontSize="small" />) : undefined}
                {accountStore.isAdmin ? menuItem('Admin', '/admin', <Settings fontSize="small" />) : undefined}
                {accountStore.isEditor || accountStore.isAdmin ? <Divider /> : undefined}
                {menuItem('Logout', '/logout', <Logout fontSize="small" />)}
            </Menu>
        </ClickAwayListener>
    );
}));
import { observer } from "mobx-react-lite"
import { Stack, TextField } from '@mui/material';

import { useStore } from "../../../Stores";
import { CMSActionBar, CMSEditButtons, CMSWidthField } from '..';
import { Carousel as CarouselModel } from '../../../Models/Site';

interface Props {
    data: CarouselModel;
}

export const CMSCarousel = observer(({ data }: Props) => {
    const { cmsStore } = useStore();
    const { working_model } = cmsStore;
    let isEditing = cmsStore.editedItemId === data.id;

    let dataToUse: CarouselModel = isEditing ? (working_model as CarouselModel) : data
    return (
        <Stack direction="column" spacing={2} justifyContent="space-between">
            <CMSEditButtons
                id={data.id}
                index={data.index}
            />
            <h5>ID: {data.id}</h5>
            <TextField
                id="name"
                label="Name"
                value={dataToUse.name}
                onChange={(e) => cmsStore.updateCarousel({ name: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <CMSWidthField 
                value={dataToUse.width.toString()}
                onChange={(newValue)=>cmsStore.updateCarousel({ width: +(newValue) })}
                disabled={!isEditing}
            />
            <CMSActionBar id={data.id} />
        </Stack>
    );
});
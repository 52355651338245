import { Avatar, Box, Grid, Link, TextField, Typography } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { observer } from "mobx-react-lite";
import React from "react";

import { useStore } from "../../Stores";
import { RegisterForm, ResetPasswordForm, MaxWidth, ServiceLoginButton } from ".";
import { Service } from "../../Models/Site";
import { AuthRedirect } from "../../Services";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

export const LoginForm = observer(() => {
    const { accountStore, modalStore } = useStore()

    const [emailError, setEmailError] = React.useState(false);
    const [passwordError, setPasswordError] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [recaptchaCode, setRecaptchaCode] = React.useState('');

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get('email')?.toString()
        const password = data.get('password')?.toString()

        if (email && password) {
            setEmailError(false)
            setPasswordError(false)
            setSubmitting(true)
            accountStore.login({
                email: email,
                password: password
            }, recaptchaCode).then(() => {
                modalStore.closeModal()
            }).finally(() => {
                setSubmitting(false)
            })
            return
        }
        if (!email) {
            setEmailError(true)
        }
        if (!password) {
            setPasswordError(true)
        }
    };
    return (
        <Box
            sx={{
                display: 'flex',
                maxWidth: `${MaxWidth}px`,
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Sign in
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    error={emailError}
                    onChange={() => setEmailError(false)}
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    error={passwordError}
                    onChange={() => setPasswordError(false)}
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                />
                <LoadingButton
                    type="submit"
                    loading={submitting}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Sign In
                </LoadingButton>
                <Grid container>
                    <Grid item xs>
                        <Link variant="body2" onClick={() => modalStore.openModal(<ResetPasswordForm />)}>
                            Forgot password?
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link variant="body2" onClick={() => modalStore.openModal(<RegisterForm />)}>
                            {"Don't have an account? Sign Up"}
                        </Link>
                    </Grid>
                </Grid>
                <Grid container direction='column' marginTop={1} spacing={1} alignItems='stretch'>
                    <Grid item>
                        <ServiceLoginButton service={Service.twitch} redirect={AuthRedirect.login} title='Log in with Twitch' />
                    </Grid>
                    <Grid item>
                        <ServiceLoginButton service={Service.google} redirect={AuthRedirect.login} title='Log in with Google' />
                    </Grid>
                    <Grid item>
                        <ServiceLoginButton service={Service.patreon} redirect={AuthRedirect.login} title='Log in with Patreon' />
                    </Grid>
                </Grid>
                <Grid container marginTop={1}>
                    <Typography component="caption" variant="caption">
                        This site is protected by reCAPTCHA and the Google
                        <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
                        <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
                    </Typography>
                </Grid>
            </Box>
            <GoogleReCaptcha onVerify={setRecaptchaCode} />
        </Box>
    );
});

import { Delete, Done, AttachFile, Download } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Stack, Typography, Box, Button, Paper, Icon, List, ListItem, ListItemText, ListItemIcon, IconButton, ListItemButton } from "@mui/material";
import { DropzoneArea } from "material-ui-dropzone";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

import { useStore } from "../../Stores";
import { File as FileModel } from "../../Models/API";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileService } from "../../Services";

interface CellProps {
    item: FileModel;
    isSelected: boolean;
    onClick: (file: FileModel) => void;
    onDownloadFile: (file: FileModel) => void;
}

const FileCell = ({ item, isSelected, onClick, onDownloadFile }: CellProps) => {
    return (
        <ListItem
            key={item.id}
            selected={isSelected}
            sx={{ width: '80vw', maxWidth: '600px', minWidth: '200px' }}
            secondaryAction={
                <IconButton edge="end" aria-label="download" onClick={() => onDownloadFile(item)}>
                    <Download />
                </IconButton>
            }
        >
            <ListItemButton onClick={() => onClick(item)} sx={{ ml: '-20px', width: '80%' }}>
                <ListItemIcon sx={{ justifyContent: 'center' }}>
                    <FontAwesomeIcon icon={FileModel.iconProp(item)} size='lg' />
                </ListItemIcon>
                <ListItemText id={item.id} primary={item.name} />
            </ListItemButton>
        </ListItem>
    )
}

interface Props {
    onSelectFile: (file: FileModel) => void;
}

export const FilePickerForm = observer(({ onSelectFile }: Props) => {
    const { modalStore, fileStore } = useStore()
    const [selectedFile, setSelectedFile] = React.useState<FileModel | undefined>();
    const [currentFile, setCurrentFile] = React.useState<File | undefined>();
    const [submitting, setSubmitting] = React.useState(false);
    const [preview, setPreview] = React.useState('');

    useEffect(() => {
        refreshFiles()
    }, []) // Run once (dont delete it plz)

    const refreshFiles = () => {
        fileStore.getSiteFiles()
    }

    const handleFileChosen = (file: File) => {
        setCurrentFile(file)
        setPreview(URL.createObjectURL(file))
    };

    const uploadFile = () => {
        if (currentFile) {
            setSubmitting(true)
            const formData = new FormData()
            formData.append('image', currentFile);
            fileStore.uploadFile(currentFile.name, formData)
                .then((response) => useFile(response))
                .finally(() => setSubmitting(false))
        }
    }

    const deleteFile = () => {
        if (selectedFile) {
            fileStore.deleteFile(selectedFile.id)
                .then(() => {
                    setSelectedFile(undefined)
                    refreshFiles()
                })
        }
    }
    const useFile = (file: FileModel) => {
        onSelectFile(file)
        modalStore.closeModal();
    }

    const downloadFile = (file: FileModel) => {
        FileService.getFile(file)
            .then((response) => {
                const url = window.URL.createObjectURL(response);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file.name);
                document.body.appendChild(link);
                link.click();
            })
    }

    return (
        <Stack direction='column' alignItems='center'>
            <Typography component="h1" variant="h5" flex='0 0 auto'>
                Select a File
            </Typography>
            <Box sx={{ mt: 1, flex: 1 }}>
                {selectedFile ?
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        justifyContent='space-between'
                        m={2}
                        spacing={2}
                    >
                        <Button
                            variant='contained'
                            color='error'
                            endIcon={<Delete />}
                            onClick={deleteFile}
                        >
                            Delete
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            endIcon={<Done />}
                            onClick={() => useFile(selectedFile)}
                        >
                            Use File
                        </Button>
                    </Stack>
                    : undefined}
                <List>
                    {fileStore.files.map((item) =>
                        <FileCell
                            key={item.id}
                            item={item}
                            isSelected={selectedFile ? selectedFile.id === item.id : false}
                            onClick={() => setSelectedFile(item)}
                            onDownloadFile={downloadFile}
                        />
                    )}
                </List>
                {
                    preview
                        ?
                        <Paper elevation={1} sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                            <Stack
                                direction={{ xs: 'column', md: 'row' }}
                                spacing={2}
                                height='100%'
                                flex='1 1 auto'
                                alignItems='center'
                                padding={2}>
                                <Icon color="primary">
                                    <AttachFile />
                                </Icon>
                                <Typography overflow='hidden' sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '250px',
                                    display: 'inline'
                                }}>{currentFile ? currentFile.name : 'No filename'}</Typography>
                            </Stack>
                            <LoadingButton
                                loading={submitting}
                                variant='contained'
                                onClick={uploadFile}
                                sx={{
                                    flex: '0 1 auto',
                                    marginX: 2,
                                }}
                            >
                                {submitting ? 'Uploading...' : 'Upload file'}
                            </LoadingButton>
                        </Paper>

                        : undefined
                }
                <DropzoneArea
                    filesLimit={1}
                    // acceptedFiles={['image/*']}
                    dropzoneText={"Drag and drop a file here"}
                    onChange={(files) => {
                        if (files.length > 0) {
                            handleFileChosen(files[0])
                        }
                    }}
                    showAlerts={false}
                    showPreviewsInDropzone={false}
                />
            </Box >
        </Stack >
    )
})
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Grid, Paper, Tab, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { observer } from "mobx-react-lite"
import { SyntheticEvent, useEffect, useState } from "react";
import { CollectionData } from "../../../Models/API/Collections";
import { useStore } from "../../../Stores";
import { LoadingComponent } from "../../Util";
import {AdminCollectionsData, AdminCollectionsPermissions, AdminCreateCollectionModal} from "./";

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 300 },
    {
        field: 'name',
        headerName: 'Name',
        width: 150
    },
    {
        field: 'apiName',
        headerName: 'Api Name',
        width: 120
    }
    ,
    {
        field: 'ownerName',
        headerName: 'Owner',
        width: 150
    }
];

const createData = (
    id: string,
    name: string,
    apiName: string,
    ownerName: string
) => {
    return { id, name, apiName, ownerName };
}

export const AdminCollections = observer(() => {
    const [value, setValue] = useState('1');

    const handleChange = (event: SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const { adminStore, modalStore } = useStore();
    const [refreshing, setRefreshing] = useState(false);
    const { collectionList, getCollections, selectCollection, buildAllieCats } = adminStore;
    let data: CollectionData[] | undefined = collectionList;

    let rows = [];
    if (data) {
        for (var i = 0; i < data.length; i++) {
            rows.push(createData(data[i].id, data[i].name, data[i].collectionApiName, data[i].ownerName))
        }
    }
    const refreshCollections = () => {
        if (refreshing) { return }
        setRefreshing(true)
        getCollections().finally(() => {
            setRefreshing(false)
        })
    }
    useEffect(() => {
        refreshCollections()
    }, [])
    if (rows && !refreshing) {
        return (

            <Grid container >
                <Grid item component={Paper}>
                    <Typography variant="h4" border={1} borderColor="slategrey" align="center" padding={0.5}>
                        Collections
                    </Typography>
                    <DataGrid

                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[5]}
                        sx={{ backgroundColor: "paper", width: 725 }}
                        autoPageSize
                        autoHeight
                        onSelectionModelChange={(ids) => {
                            if (data) {
                                const selectedIDs = new Set(ids);
                                const selectedRows = data.filter((row) => selectedIDs.has(row.id)
                                );
                                selectCollection(selectedRows[0]);
                            }

                        }} />
                    <Button onClick={buildAllieCats}> Build AllieCats </Button>
                    <Button onClick={() => modalStore.openModal(<AdminCreateCollectionModal/>)}> Create Collection </Button>
                </Grid>
                <Grid item marginLeft={2}>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Data" value="1" />
                                    <Tab label="Edit" value="2" />
                                    <Tab label="Permissions" value="3" />
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <AdminCollectionsData />
                            </TabPanel>
                            <TabPanel value="2">
                                Item 2
                            </TabPanel>
                            <TabPanel value="3">
                                <AdminCollectionsPermissions />
                            </TabPanel>
                        </TabContext>
                    </Box>
                </Grid>
            </Grid>
        )
    }
    else {
        return (
            <LoadingComponent />
        )
    }

})
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Grid, Tab, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { SyntheticEvent, useState } from "react";
import { AdminCreatorList, AdminCreatorReferrals } from ".";
import { useStore } from "../../../Stores";
import { AddCreatorForm } from "../../Forms";

export const AdminCreators = observer(() => {
    const [value, setValue] = useState('1');

    const handleChange = (event: SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    const { adminStore, modalStore } = useStore();
    const { selectedCreator } = adminStore;
    return (
        <Grid container direction="row">

            <Grid item>
                <Typography variant="h4" border={1} borderColor="slategrey" align="center" padding={0.5}>
                    Select Creator
                </Typography>
                <AdminCreatorList />
            </Grid>
            <Grid item marginLeft={2}>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Add Creator" value="1" />
                                <Tab label="Check Referrals" value="2" />
                                {/* <Tab label="Item Three" value="3" /> */}
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Button
                                variant='contained'
                                onClick={() => modalStore.openModal(<AddCreatorForm />)}>
                                Add Creator
                            </Button>
                        </TabPanel>
                        <TabPanel value="2">
                            {selectedCreator ? <AdminCreatorReferrals /> : null}
                        </TabPanel>
                        {/* <TabPanel value="3">
                            Item Three
                        </TabPanel> */}
                    </TabContext>
                </Box>
            </Grid>
            <Grid item>

            </Grid>

        </Grid>
    )
})


import {observer} from "mobx-react-lite";
import {
    Button,
    Grid,
    InputAdornment,
    Link,
    Paper,
    TextField,
    Typography,
    Popover,
    Stack,
    IconButton,
} from "@mui/material";
import {useWallet, Wallet} from "@solana/wallet-adapter-react";
import React, {useEffect, useState} from "react";
import {useStore} from "../../../../Stores";
import {WalletDialogProvider, WalletMultiButton} from "@solana/wallet-adapter-material-ui";
import { LoadingComponent } from "../../../Util";
import { RallySwap as RallySwapModel } from "../../../../Models/Site"
import {RallySwapPriceInfo} from "./RallySwapPriceInfo";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import {KeyboardArrowDown} from "@mui/icons-material";
import {RallySwapMenuList} from "./RallySwapMenuList";

interface Props {
    data: RallySwapModel
}

export const RallySwap = observer(({ data }: Props) => {
    const {swapStore, modalStore} = useStore();
    const {openModal} = modalStore;
    const {loading,getCurves,swapTokenFields, getTokens, estimated, amountIn, amountOut,sourceToken, destinationToken,executeSwap, setAmountIn, setWallet, estimateSwapValues, accountBalanceA, accountBalanceB} = swapStore;

    // const { connection } = useConnection();
    const solWallet = useWallet().wallet


    //popover constants
    const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<HTMLElement | null>(null);
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setPopoverAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setPopoverAnchorEl(null);
    };

    const popoverOpen = Boolean(popoverAnchorEl);


    useEffect(() => {

        if(loading)
        {
            const fetchData = async () => {
                await getCurves();
                await getTokens();
            }
            fetchData().catch(console.error);
        }

        if (solWallet) {
            setWallet(solWallet).then();
        }
    },[ setWallet, solWallet, getCurves, getTokens, loading]);
    if(!loading)
    {

        return (

            <>
                <WalletDialogProvider>
                    <Grid container sx={{justifyContent: "center"}}>
                        <Stack direction="column">

                            <Typography sx={{alignSelf: "center", my:4}} variant="h4">
                                Swap Tokens
                            </Typography>

                            {!solWallet ? (
                                    <WalletMultiButton sx={{fontSize: 22}}/>

                                )
                                : (
                                    <>
                                        <WalletMultiButton sx={{fontSize: 22, mb: 4}}/>
                                        <Paper elevation={3} >

                                            {/*Top Swap Boxes*/}

                                            <Grid item sx={{mx:3, mt:4}}>
                                                <TextField label="You pay"
                                                           value={(sourceToken.ticker == "") ? "Select Token" : sourceToken.ticker}
                                                           onClick={()=>{openModal(<RallySwapMenuList source={true}/>)}}
                                                           sx={{m: 1, width: '25ch'}}
                                                           InputProps={{
                                                               readOnly:true,
                                                               endAdornment: <InputAdornment position="end">
                                                                   <KeyboardArrowDown />
                                                               </InputAdornment>
                                                           }}
                                                >
                                                </TextField>
                                                <TextField
                                                    sx={{m: 1, width: '25ch'}}
                                                    type="number"
                                                    inputProps={{min: 0, max: Number(accountBalanceA), step: 1}}
                                                    InputProps={{
                                                        startAdornment:
                                                            <InputAdornment position="start">${sourceToken.ticker}</InputAdornment>
                                                    }}
                                                    value={amountIn}
                                                    label={accountBalanceA ? `Balance: $${sourceToken.ticker} ${accountBalanceA}` : ""}
                                                    onChange={setAmountIn}/>
                                            </Grid>

                                            {/*Swap Image*/}

                                            <Grid item sx={{width:"25ch", justifyContent:"center", display: "grid", ml:4}}>
                                                <IconButton onClick={swapTokenFields} >
                                                    <SwapVertIcon fontSize="large"/>
                                                </IconButton>
                                            </Grid>

                                            {/*Bottom Swap Boxes*/}

                                            <Grid item sx={{ml:3}}>
                                                <TextField value={(destinationToken.ticker == "") ? "Select Token" : destinationToken.ticker}
                                                           onClick={()=>{openModal(<RallySwapMenuList source={false}/>)}}
                                                           label="You want"
                                                           sx={{m: 1, width: '25ch'}}
                                                    InputProps={{
                                                    readOnly:true,
                                                    endAdornment: <InputAdornment position="end">
                                                        <KeyboardArrowDown />
                                                    </InputAdornment>
                                                }}>
                                                </TextField>
                                                <TextField
                                                    disabled
                                                    type="number"
                                                    sx={{m: 1, width: '25ch'}}
                                                    inputProps={{step: 1}}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment
                                                            position="start">${destinationToken.ticker}</InputAdornment>
                                                    }}
                                                    value={amountOut}
                                                    label={accountBalanceB ? `Balance: $${destinationToken.ticker} ${accountBalanceB}` : ""}/>
                                            </Grid>

                                            {/*Popover Section (Disclaimer)*/}

                                            <Grid item sx={{justifyContent:"center", alignContent:"center", display: "grid"}}>
                                                <Typography align="center" sx={{mt:2}}>
                                                    To Estimate Swap Values, click the button below.{<br/>}{<br/>}
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={{justifyContent:"center", display: "grid"}}>
                                                <Typography
                                                    aria-owns={popoverOpen ? 'mouse-over-popover' : undefined}
                                                    aria-haspopup="true"
                                                    onMouseEnter={handlePopoverOpen}
                                                    onMouseLeave={handlePopoverClose}
                                                    sx={{alignSelf: "center", mb:4}}
                                                >
                                                    <Link target="_blank"
                                                          rel="noopener"
                                                          href="https://spl.solana.com/associated-token-account">
                                                        Will I be charged to swap?
                                                    </Link>
                                                </Typography>
                                                <Popover
                                                    id="mouse-over-popover"
                                                    sx={{
                                                        pointerEvents: 'none',
                                                    }}
                                                    open={popoverOpen}
                                                    anchorEl={popoverAnchorEl}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    onClose={handlePopoverClose}
                                                    disableRestoreFocus>
                                                    <Typography sx={{p: 1}}>
                                                        If you do not have an account for the token you are swapping to, this will incur
                                                        a one time charge of 0.00204 SOL.{<br/>}{<br/>}

                                                        This is so we can set up an Associated account for the new token.{<br/>}{<br/>}

                                                        Follow the link to read why this is needed.
                                                    </Typography>
                                                </Popover>
                                            </Grid>
                                        </Paper>
                                        {/*Swap Button*/}
                                        {!(estimated) ? (
                                                ( Number(accountBalanceA) > amountIn) || !sourceToken.key ? (
                                                    <Button onClick={estimateSwapValues}
                                                            disabled={!amountIn || !sourceToken || !destinationToken}
                                                            variant="contained"
                                                            sx={{fontSize: 22, my: 4}}> Estimate </Button>
                                                ) : (
                                                    <Button onClick={estimateSwapValues}
                                                            disabled={true}
                                                            variant="contained"
                                                            sx={{fontSize: 22, my: 4}}> Insufficient Balance </Button>
                                                )
                                        ) : (
                                            <Button onClick={executeSwap} variant="contained" sx={{fontSize: 22, my: 4}}>Swap</Button>
                                        )}

                                        {/*Table for Price Info box*/}

                                        <RallySwapPriceInfo />
                                    </>
                                )}
                        </Stack>
                    </Grid>
                </WalletDialogProvider></>
        )
    } else {
        return <LoadingComponent/>
    }
})

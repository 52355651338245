import { Theme as MUITheme, createTheme, responsiveFontSizes } from "@mui/material";
import { PaletteMode, Theme as ThemeModel } from "../../../Models/Site";

export class ThemeRenderer {
    static default = (): MUITheme => {
        let model = new ThemeModel()
        model.palette.mode = PaletteMode.dark
        model.palette.primary.main = "#ffa600"
        model.palette.primary.contrastText = "#000"
        model.palette.secondary.main = "#0e131d"
        model.palette.secondary.contrastText = "#fff"
        model.palette.text.primary = "#FFF"
        model.palette.text.secondary = "#00FFA6"
        model.palette.background.paper = "#23272f"
        model.palette.background.default = "#23272f"
        model.typography.fontFamily = "Nunito"
        return this.create(model);
    }
    static create = (model: ThemeModel): MUITheme => {
        return responsiveFontSizes(createTheme({
            palette: {
                mode: model.palette.mode,
                primary: {
                    main: model.palette.primary.main,
                    contrastText: model.palette.primary.contrastText
                },
                secondary: {
                    main: model.palette.secondary.main,
                    contrastText: model.palette.secondary.contrastText
                },
                text: {
                    primary: model.palette.text.primary,
                    secondary: model.palette.text.secondary,
                },
                background: {
                    paper: model.palette.background.paper,
                    default: model.palette.background.default
                }
            },
            typography: {
                fontFamily: model.typography.fontFamily
            },
            components: {
                MuiCssBaseline: {
                    styleOverrides: {
                        ".MuiDropzoneArea-root": {
                            backgroundColor: `${model.palette.background.paper} !important`
                        },
                        ".MuiDropzoneArea-text": {
                            color: `${model.palette.text.primary} !important`,
                            padding: '0 20px 0 20px'
                        },
                        ".MuiDropzoneArea-icon": {
                            color: `${model.palette.text.primary} !important`,
                        },
                        a: {
                            color: model.palette.primary.main
                        },
                        body: {
                            scrollbarColor: "#6b6b6b #2b2b2b",
                            "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                                backgroundColor: model.palette.background.default,
                            },
                            "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                                borderRadius: 8,
                                backgroundColor: "#6b6b6b",
                                minHeight: 24,
                                border: "3px solid #2b2b2b",
                            },
                            "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                                backgroundColor: "#959595",
                            },
                            "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                                backgroundColor: "#959595",
                            },
                            "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                                backgroundColor: "#959595",
                            },
                            "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                                backgroundColor: "#2b2b2b",
                            },
                        },
                    },
                },
            },
        }));
    }
}
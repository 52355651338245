import { makeAutoObservable } from "mobx";

export class Typography {
    fontFamily: string = ""

    constructor() {
        makeAutoObservable(this)
    }
}

export interface TypographyUpdater {
    fontFamily?: string;
};
import { LoadingButton, LoadingButtonProps } from "@mui/lab"
import React from "react";
import { Service } from "../../Models/Site";
import { AccountService, AuthRedirect } from "../../Services";

interface Props {
    service: Service
    title?: string
    props?: LoadingButtonProps
    redirect: AuthRedirect
    redirectUrl?: string
}
export const ServiceLoginButton = ({ service, title, props = { variant: 'outlined', fullWidth: true }, redirect, redirectUrl }: Props) => {
    const [loading, setLoading] = React.useState(false)
    const login = () => {
        switch (service) {
            case Service.twitch:
                window.location.href = AccountService.twitchLoginUrl(redirect, redirectUrl);
                break;
            case Service.patreon:
                window.location.href = AccountService.patreonLoginUrl(redirect, redirectUrl);
                break;
            case Service.google:
                window.location.href = AccountService.googleLoginUrl(redirect, redirectUrl);
                break;
            // case Service.instagram:
            //     window.location.href = AccountService.instagramLoginUrl(redirect, redirectUrl);
            //     break;
            // case Service.facebook:
            //     window.location.href = AccountService.facebookLoginUrl(redirect, redirectUrl);
            //     break;
            // case Service.rally:
            //     setLoading(true)
            //     AccountService.rallyLoginUrl(redirect, redirectUrl).then((redirect: RedirectUrl) => {
            //         window.location.href = redirect.url;
            //     }).finally(() => {
            //         setLoading(false)
            //     });
            //     break
        }
    }
    return (
        <>
            <LoadingButton
                {...props}
                loading={loading}
                onClick={login}>
                {title}
            </LoadingButton>
        </>
    )
}

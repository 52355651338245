import {
    Box,
    CssBaseline,
    Grid,
    Stack,
    TextField,
    ThemeProvider,
    Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffect } from "react";
import { Prompt } from "react-router";
import { TemplateData } from "../../Models/API/Collections";
import {
    Container,
    ContentFactory,
    Type,
    Text,
    Theme,
} from "../../Models/Site";
import { useStore } from "../../Stores";
import { CMSPreview, createCMSComponent } from "../CMS";
import { ThemeRenderer } from "../Site";
import { TemplateTree } from "./TemplateTree";

interface Props {
    collectionId: string;
    template: TemplateData;
    onDelete?: () => void;
}

export const TemplateEditor = observer(({ collectionId, template, onDelete = () => { } }: Props) => {
    const {
        cmsStore,
        commonStore,
        collectionStore,
        toastBarStore,
        cmsNavBarStore,
    } = useStore();

    const themeModel = (() => {
        if (commonStore.currentSite) {
            return commonStore.currentSite.theme;
        }
        return new Theme();
    })();

    const container = (() => {
        if (template.content) return template.content;
        const newContainer = ContentFactory.model(
            Type.container
        ) as Container;
        newContainer.name = "Template Name";
        const text = ContentFactory.model(Type.text) as Text;
        text.text = "{{NAME}}";
        newContainer.content.push(text);
        return newContainer;
    })();

    const theme = ThemeRenderer.create(themeModel);

    useEffect(() => {
        cmsStore.setCollectionTemplateData(container);
        cmsStore.select(container.id);
    }, [cmsStore, template]);

    const saveTemplate = (newTemplate: TemplateData) => {
        collectionStore
            .updateTemplate(collectionId, newTemplate)
            .then(() => {
                cmsStore.setHasChanges(false);
                toastBarStore.showMessage(
                    "Your changes have been saved.",
                    "success"
                );
                cmsNavBarStore.open(container.id, true);
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ display: "flex" }} width="100%">
                <TemplateTree
                    template={template}
                    onSave={saveTemplate}
                    onDelete={onDelete}
                />
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        p: `12px`,
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={6} lg={5} xl={4}>
                            {createCMSComponent(
                                cmsStore.selectedItem,
                                collectionId
                            )}
                        </Grid>
                        <Grid item sm={12} md={6} lg={7} xl={8}>
                            <CMSPreview />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Prompt
                when={cmsStore.hasChanges}
                message="You have unsaved changes that will be discarded. Are you sure you want to leave?"
            />
        </ThemeProvider>
    );
}
);

import { EmojiEvents, ManageAccounts, Link } from '@mui/icons-material';
import { ListItem, Button } from '@mui/material';
import { observer } from 'mobx-react-lite';


import { Rewards, AccountOverview, ConnectedAccounts } from ".";
import { MasterDetail, Page, Section } from '../Common/MasterDetail';
import { history } from '../../App';
import { LoadingComponent } from '../Util';
import { useStore } from '../../Stores';


export const Profile = observer(() => {

    const { accountStore } = useStore();
    const { user } = accountStore;

    if (!user) {
        accountStore.getUser()
        return (
            <LoadingComponent />
        );
    }

    // const rewardsPage = new Page("Rewards", "Earn Free $RLY", <EmojiEvents />, <Rewards user={user} />);
    const overviewPage = new Page("Account Overview", "", <ManageAccounts />, <AccountOverview user={user} />);
    const connectedPage = new Page("Connected Accounts", "", <Link />, <ConnectedAccounts />);
    const sections = [
        // new Section([rewardsPage]),
        new Section([overviewPage, connectedPage])
    ]

    return (
        <MasterDetail sections={sections}>
            <ListItem key='logout' sx={{ my: 2 }} >
                <Button fullWidth variant="contained" onClick={() => history.push('/logout')} >
                    Logout
                </Button>
            </ListItem>
        </MasterDetail>
    )
});
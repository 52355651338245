import { observer } from "mobx-react-lite"
import AddIcon from '@mui/icons-material/Add';
import { Button, Stack } from '@mui/material';
import { useStore } from "../../../Stores";
import { CMSContentPicker } from "..";

export interface CMSActionButton {
    text: string;
    action: () => void;
    icon: JSX.Element
}
interface Props {
    id: string,
    addContent?: boolean,
    buttons?: Array<CMSActionButton>
}

export const CMSActionBar = observer(({ id, addContent = true, buttons = []}: Props) => {
    const { cmsStore, modalStore } = useStore();
    const isEditing = cmsStore.editedItemId === id;
    const allButtons = ((): React.ReactNode => {
        return buttons.map(button => {
            return (
                <Button 
                    key={button.text}
                    onClick={button.action}
                    variant="contained"
                    endIcon={button.icon}
                    disabled={isEditing}
                >
                {button.text}
                </Button>
            )
        });
    })();
    return (
        <Stack direction="row" spacing={2} >
            {addContent ? 
                <Button 
                    key={'addContentButton'}
                    onClick={()=>{
                        modalStore.openModal(<CMSContentPicker id={id} open={true} />, 'Add Content') 
                    }}
                    variant="contained"
                    endIcon={<AddIcon />}
                    disabled={isEditing}
                >
                    Add Content
                </Button> 
            : undefined
            }
            {allButtons}
        </Stack>
    )
});
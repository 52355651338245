import React from "react";
import { Route } from "react-router";
import { useStore } from "../../../Stores";
import { LoadingComponent } from "../../Util";
import { history } from "../../../App";
import { CookieService } from "../../../Services";
import { Service } from "../../../Models/Site";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

export const SSORoutes = () => {
    const { accountStore } = useStore();
    const [loading, setLoading] = React.useState(false);
    const [recaptchaCode, setRecaptchaCode] = React.useState('');

    const getCode = () => {
        const query = new URLSearchParams(history.location.search);
        return query.get('code') ?? "";
    }

    const ssoLogin = async (service: Service, code: string) => {
        setLoading(true)
        try {
            await accountStore.externalLogin(service, code, recaptchaCode)
            window.location.href = CookieService.redirectUrl()
        } catch {
            history.push(`/login?redirectUrl=${CookieService.redirectUrl()}`)
        }
    }
    const ssoRegister = async (service: Service, code: string) => {
        setLoading(true)
        try {
            await accountStore.externalRegister(service, code, recaptchaCode)
            window.location.href = CookieService.redirectUrl()
        } catch {
            history.push(`/login?redirectUrl=${CookieService.redirectUrl()}`)
        }
    }

    const ssoConnect = async (service: Service, code: string) => {
        setLoading(true)
        try {
            await accountStore.externalConnect(service, code)
            window.location.href = CookieService.redirectUrl()
        } catch {
            history.push('/profile')
        }
    }

    const services = [
        Service.twitch,
        Service.patreon,
        // Service.rally,
        Service.google,
        // Service.instagram,
        // Service.facebook
    ]

    const routesForService = (service: Service) => {
        return (
            <>
                <Route key={`/${service}-login`} path={`/${service}-login`} render={() => {
                    if (!loading) ssoLogin(service, getCode())
                    return <LoadingComponent />
                }} />
                <Route key={`/${service}-register`} path={`/${service}-register`} render={() => {
                    if (!loading) ssoRegister(service, getCode())
                    return <LoadingComponent />
                }} />
                <Route key={`/${service}-connect`} path={`/${service}-connect`} render={() => {
                    if (!loading) ssoConnect(service, getCode())
                    return <LoadingComponent />
                }} />
            </>
        )
    }

    return (
        <>
            {recaptchaCode ? services.map(routesForService) : <GoogleReCaptcha onVerify={setRecaptchaCode} /> }
        </>
    )
}

import { Box, Grid, Link, TextField, Typography } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { observer } from "mobx-react-lite";
import { useStore } from "../../Stores";
import { ServiceLoginButton, LoginForm, MaxWidth } from '.';
import React from "react";
import { AuthRedirect, Cookie, CookieService } from "../../Services";
import { Service } from "../../Models/Site";
import { history } from "../../App";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

export const RegisterForm = observer(() => {
    const { accountStore, modalStore, commonStore } = useStore()

    const [emailError, setEmailError] = React.useState(false);
    const [passwordError, setPasswordError] = React.useState(false);
    const [usernameError, setUsernameError] = React.useState(false);
    const [recaptchaCode, setRecaptchaCode] = React.useState('');

    const [submitting, setSubmitting] = React.useState(false);

    const redirectUrl = `https://${commonStore.currentDomain}/profile`

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get('email')?.toString()
        const password = data.get('password')?.toString()
        const username = data.get('username')?.toString()
        const referral = data.get('referralCode')?.toString() ?? ""

        setEmailError(!email)
        setPasswordError(!password)
        setUsernameError(!username)

        if (email && password && username) {
            setSubmitting(true)
            accountStore
                .register(
                    {
                        email: email,
                        password: password,
                        userName: username
                    },
                    referral,
                    recaptchaCode
                )
                .then(() => {
                    modalStore.closeModal()
                    history.push(redirectUrl)
                })
                .finally(() => {
                    setSubmitting(false)
                })
        }

    }
    return (
        <Box
            sx={{
                display: 'flex',
                maxWidth: `${MaxWidth}px`,
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Link variant="body2" sx={{ alignSelf: 'start', mb: 1 }} onClick={() => modalStore.openModal(<LoginForm />)}>
                Have an account? Sign In
            </Link>
            <Typography component="h1" variant="h5">
                Register
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    error={usernameError}
                    onChange={() => setUsernameError(false)}
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    autoComplete="username"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    error={emailError}
                    onChange={() => setEmailError(false)}
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                />
                <TextField
                    margin="normal"
                    helperText="Password Requires: 1 Upper, 1 Lower, 1 Symbol, 1 Number and minimum of 6 characters."
                    error={passwordError}
                    onChange={() => setPasswordError(false)}
                    required
                    fullWidth
                    id="password"
                    label="Password"
                    name="password"
                    autoComplete="new-password"
                    type='password'
                />
                <TextField
                    margin="normal"
                    fullWidth
                    id="referralCode"
                    label="Optional : Referral Code"
                    name="referralCode"
                    sx={{ mb: 2 }}
                    defaultValue={CookieService.getCookie(Cookie.referral)}
                />
                <LoadingButton
                    type="submit"
                    loading={submitting}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Create account
                </LoadingButton>
                <Grid container direction='column' marginTop={1} spacing={1} alignItems='stretch'>
                    <Grid item>
                        <ServiceLoginButton redirect={AuthRedirect.register} service={Service.twitch} title='Sign up with Twitch' redirectUrl={redirectUrl} />
                    </Grid>
                    <Grid item>
                        <ServiceLoginButton redirect={AuthRedirect.register} service={Service.google} title='Sign up with Google' redirectUrl={redirectUrl} />
                    </Grid>
                    <Grid item>
                        <ServiceLoginButton redirect={AuthRedirect.register} service={Service.patreon} title='Sign up with Patreon' redirectUrl={redirectUrl} />
                    </Grid>
                </Grid>
                <Grid container marginTop={1}>
                    <Typography component="caption" variant="caption">
                        This site is protected by reCAPTCHA and the Google
                        <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
                        <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
                    </Typography>
                </Grid>

            </Box>
            <GoogleReCaptcha onVerify={setRecaptchaCode} />
        </Box>
    );
})

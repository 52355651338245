import { Button, Grid, Paper, Stack, Typography } from "@mui/material"
import { observer } from "mobx-react-lite"
import Consts from "../Common/Consts"
import {useEffect} from "react";

export const Support = observer(() => {
    useEffect(() => {
        const script = document.createElement('script');
        script.id = "ze-snippet";
        script.src = "https://static.zdassets.com/ekr/snippet.js?key=58ae7f73-0593-4126-9c0b-cdb885c85d06";
        document.head.appendChild(script);

        return () => {
            const element = document.querySelector('[title="Button to launch messaging window"]');
            element?.parentElement?.remove()
            script.remove();
        }
    }, [])


    return (


        <Grid container direction="column" justifyContent="center" alignItems="center" sx={{pt:4}}>

            <Typography paddingBottom={3} variant="h3" align="center">Nodia User Support</Typography>


            <Paper sx={{ p: 4, my: 2, ...Consts.containerSX }}>
                <Stack direction='column' alignItems='center'  >

                    <Typography align="center">{<br />}For the fastest support, try chatting with our Nodia Support Squad in the bottom right of your window.</Typography>
                    <Typography align="center">{<br />}{<br />}
                                Alternatively, you can email us!
                        {<br />}We will respond to your email within 48 hours.
                    </Typography>
                    <Stack paddingTop={4} direction='column' alignItems='center'>
                        <Button sx={{ textTransform: 'none', my:2 }} size="large" variant="contained" href={"mailto:alex@tokenmanagement.io"}>
                            Send Us an Email
                        </Button>
                        <Button sx={{ textTransform: 'none', my:2 }} size="large" variant="contained" href='/privacy-policy'>
                            Privacy Policy
                        </Button>
                    </Stack>
                </Stack >
            </Paper >

        </Grid>

    )
})

import { makeAutoObservable } from "mobx";
import { Model, Type, Palette, Typography } from ".."

export class Theme implements Model {
    id: string = "";
    name: string = "";
    type: Type = Type.theme;
    index: number = 0;
    palette: Palette = new Palette();
    typography: Typography = new Typography();

    constructor() {
        makeAutoObservable(this)
    }
};

export interface ThemeUpdater {
    name?: string;
};
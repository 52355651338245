import { createContext, useContext } from "react";
import {
    CreatorStore, ToastBarStore, AdminStore, PlaylistStore,
    AccountStore, ModalStore, MenuStore, ListStore, CommonStore,
    CMSStore, CMSNavBarStore, FileStore, CollectionStore, WidgetStore, SwapStore
} from '.';
import {SupportStore} from "./SupportStore";

export class Store {
    menuStore: MenuStore;
    commonStore: CommonStore;
    accountStore: AccountStore;
    modalStore: ModalStore;
    listStore: ListStore;
    cmsStore: CMSStore;
    cmsNavBarStore: CMSNavBarStore;
    playlistStore: PlaylistStore;
    adminStore: AdminStore;
    toastBarStore: ToastBarStore;
    creatorStore: CreatorStore;
    fileStore: FileStore;
    collectionStore: CollectionStore;
    swapStore: SwapStore;
    widgetStore: WidgetStore;
    supportStore: SupportStore;

    constructor() {
        this.menuStore = new MenuStore();
        this.modalStore = new ModalStore();
        this.listStore = new ListStore();
        this.cmsNavBarStore = new CMSNavBarStore();
        this.playlistStore = new PlaylistStore();
        this.adminStore = new AdminStore();
        this.toastBarStore = new ToastBarStore();
        this.creatorStore = new CreatorStore();
        this.commonStore = new CommonStore();
        this.swapStore = new SwapStore();
        this.widgetStore = new WidgetStore();
        this.supportStore = new SupportStore();

        // Do these last as they rely on existing stores
        this.accountStore = new AccountStore(this);
        this.fileStore = new FileStore(this);
        this.cmsStore = new CMSStore(this);
        this.collectionStore = new CollectionStore(this);
    }
}
export const store = new Store();
export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}

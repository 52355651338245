import axios from 'axios';
import { APIService } from '.';
import { File } from '../Models/API';

export class FileService {
    static basePath = "/File";

    static getFile = (file: File): Promise<Blob> => {
        return axios.get(`${this.basePath}?fileId=${file.id}`, {
            responseType: 'blob',
        })
    }

    static uploadFile = (name: string, file: FormData, siteId: string) => {
        return APIService.requests.post<File>(`${this.basePath}?fileName=${name}&siteId=${siteId}`, file, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (event) => { }
        })
    }

    static deleteFile = (id: string) => {
        return APIService.requests.del(`${this.basePath}?fileId=${id}`)
    }

    static getFileList = (siteId: string, type?: string) => {
        return APIService.requests.get<File[]>(`${this.basePath}/GetList?${type ? `type=${type}&` : '' }siteId=${siteId}`);
    }
}
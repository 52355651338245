import { makeAutoObservable } from "mobx";
import { v4 as uuid } from "uuid";
import { Type, Content } from ".."

export enum Color {
    primary = "primary",
    secondary = "secondary",
    custom = "custom",
    none = "none"
}

export enum ColorType {
    main = "main",
    light = "light",
    dark = "dark"
}

export enum ContainerAlignment {
    flexStart = "flex-start",
    center = "center",
    flexEnd = "flex-end",
    stretch = "stretch",
    baseline = "baseline"
}

export enum ContainerJustification {
    flexStart = "flex-start",
    center = "center",
    flexEnd = "flex-end",
    spaceBetween = "space-between",
    spaceAround = "space-around",
    spaceEvenly = "space-evenly",
}

export enum ContainerDirection {
    row = "row",
    rowReverse = "row-reverse",
    column = "column",
    columnReverse = "column-reverse"
}

export class Container implements Content {
    id: string = uuid();
    name: string = "Container";
    type: Type = Type.container; 
    index: number = 0;
    width: number = 12;
    fullScreen: boolean = false;
    color: Color = Color.primary;
    colorType: ColorType = ColorType.main;
    colorCustom: string;
    marginBottom: number = 0;
    cornerRadius: number = 0;
    padding: number = 0;
    spacing: number = 0;
    backgroundImageId: string = '';
    direction: ContainerDirection = ContainerDirection.row;
    alignment: ContainerAlignment = ContainerAlignment.center;
    justification: ContainerJustification = ContainerJustification.spaceEvenly;
    content: Content[] = [];

    constructor() {
        makeAutoObservable(this)
    }

    static colorParam(container: Container): string {
        switch (container.color) {
            case Color.none:
                return 'inherit';
            case Color.custom:
                return container.colorCustom;
            default:
                return `${container.color}.${container.colorType}`;
        }
    }

    static textColorParam(container: Container): string {
        switch (container.color) {
            case Color.none: return 'text.primary'
            default: return `${container.color}.contrastText`;
        }
    }
}

export interface ContainerUpdater {
    name?: string;
    width?: number;
    fullScreen?: boolean;
    color?: Color;
    colorType?: ColorType;
    colorCustom?: string;
    marginBottom?: number;
    cornerRadius?: number;
    padding?: number;
    spacing?: number;
    backgroundImageId?: string;
    direction?: ContainerDirection;
    alignment?: ContainerAlignment;
    justification?: ContainerJustification;
}

import { makeAutoObservable } from "mobx";
import { v4 as uuid } from "uuid";
import { Type, Content } from "..";

export class Image implements Content {
    id: string = uuid();
    name: string = "Image";
    type: Type = Type.image;
    index: number = 0;
    imageId: string = "";
    width: number = 12;

    constructor() {
        makeAutoObservable(this)
    }
}

export interface ImageUpdater {
    name?: string;
    imageId?: string;
    width?: number;
}
import { Content, Type } from ".."

export class Blocked implements Content {
    id: string = "";
    name: string = "";
    type: Type = Type.blocked;
    index: number = 0;
    width: number = 12;
    conditions: Condition[] = [];
}

export enum Service {
    twitch = "twitch",
    patreon = "patreon",
    // rally = "rally",
    google = "google",
    // instagram = "instagram",
    // facebook = "facebook"
}

export class Condition {
    id: string = "";
    service: Service = Service.twitch;
    creatorPath: string = "";
    isConnected: boolean = false;
    condition: string = "";
    userState: string = "";
}

import {
    Button,
    Carousel,
    Collection,
    Container,
    Header,
    HTML,
    IFrame,
    Image,
    Model,
    Playlist,
    PlaylistSection,
    Socials,
    Text,
    Type,
    Video,
    RallySwap
} from '..'

export * from "./Html"
export * from "./Header";
export * from "./Container";
export * from "./Blocked";
export * from './Text';
export * from './Image';
export * from './Playlist';
export * from './Carousel';
export * from './IFrame';
export * from './Socials';
export * from './Button';
export * from './Collection';
export * from './RallySwap';

export interface Content extends Model {
    width: number
}

export class ContentFactory {
    static model = (type: Type): Content | undefined => {
        switch (type) {
            case Type.container:
                return new Container()
            case Type.header:
                return new Header()
            case Type.text:
                return new Text()
            case Type.image:
                return new Image()
            case Type.playlist:
                return new Playlist()
            case Type.playlistSection:
                return new PlaylistSection()
            case Type.video:
                return new Video()
            case Type.carousel:
                return new Carousel()
            case Type.iFrame:
                return new IFrame()
            case Type.socials:
                return new Socials()
            case Type.button:
                return new Button()
            case Type.collection:
                return new Collection()
            case Type.html:
                return new HTML()
            case Type.rallySwap:
                return new RallySwap()
        }
        return undefined;
    }
}
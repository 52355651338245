import { Stack, Card, CardContent, CardHeader, Box, TextField } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";

import { Creator } from "../../Models/Admin/Creator";
import Consts from "../Common/Consts";

interface Props {
    creator: Creator
}

export const CreatorOverview = ({ creator }: Props) => {
    const styledTextField = ((id: string, label: string, defaultValue: string, onChange: (s: string) => void) => {
        return (
            <TextField
                id={id}
                label={label}
                defaultValue={defaultValue}
                disabled={true}
                onChange={(e) => onChange(e.target.value)}
                margin="normal"
                fullWidth
            />
        )
    });
    return (
        <Stack direction='column' alignItems='center'>
            <Box sx={Consts.containerSX}>
                <Card sx={{ display: 'flex', flexDirection: 'column', mt: 5, alignItems: 'leading' }} >
                    <CardHeader
                        avatar={<AccountCircle color='primary' />}
                        title="Creator Details"
                    />
                    <CardContent sx={{ flex: '1 0 auto', display: 'flex', flexDirection: 'column' }}>
                        {styledTextField('name', 'Name', creator.name, (s) => console.log(s))}
                        {styledTextField('siteId', 'Site ID', creator.siteId, (s) => console.log(s))}
                        {styledTextField('rallyCurrency', 'Rally Currency', creator.rallyCurrency, (s) => console.log(s))}
                    </CardContent>
                </Card>
            </Box>
        </Stack>
    );
};
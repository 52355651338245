import { makeAutoObservable, } from "mobx"
import { Model } from "../Models/Site";

export class CMSNavBarStore {
    drawerOpen: boolean = true;
    items: { [id: string]: boolean; };
    pasteboard: Model | undefined;

    constructor(initialState: { [id: string]: boolean; } = {}) {
        makeAutoObservable(this)
        this.items = initialState;
    }

    toggleDrawer = () => {
        this.drawerOpen = !this.drawerOpen
    }

    open = (id: string, open: boolean) => {
        this.items[id] = open;
    }

    isOpen = (id: string): boolean => {
        return this.items[id];
    }

    setPasteboard = (model: Model | undefined) => {
        this.pasteboard = model
    }
}
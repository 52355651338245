import {observer} from "mobx-react-lite";
import React from "react";
import {Grid, IconButton, List, ListItem, Paper, Table, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import Consts from "../Common/Consts";
import { ArrowCircleLeft } from "@mui/icons-material";

export const PrivacyPolicy = observer(() => {
    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" sx={{pt:4}}>
            <Typography paddingBottom={3} variant="h3" align="center" sx={{fontWeight:"bold"}}>
                PRIVACY NOTICE
            </Typography>
            <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ px: 4, maxWidth: '1100px', minWidth: '450px', width: '75%'}}>
<Grid item xs={0.5}></Grid>
                <Grid item xs={11}>
            <Typography align="center">
                Last updated January 12, 2023
            </Typography>
                </Grid>
                <Grid item xs={0.5} sx={{alignSelf:'right'}}>
                    <IconButton href='/support' size="large" color='primary'>
                        <ArrowCircleLeft sx={{ fontSize: "48px"}} />
                    </IconButton>
                </Grid>
            </Grid>
            <Paper sx={{ p: 4, my: 2, ...Consts.containerSX }}>
                <Typography>
                    <br/>
                    This privacy notice for Nodia ("<strong>Company</strong>," "<strong>we</strong>,"
                    "<strong>us</strong>," or "<strong>our</strong>" ), describes how and why we
                    might collect, store, use, and/or share ( "process" ) your information when you use our services
                    ( "<strong>Services</strong>"), such as when you:
                </Typography>
                <List
                    sx = {{
                        listStyleType: 'disc',
                        pl: 2,
                    }} />
            <ListItem
                sx = {{
                    display: 'list-item',
                }}>
                Visit our website at https://www.nodia.space , or any website of ours that links to this privacy notice
            </ListItem>
                <ListItem
                    sx = {{
                        display: 'list-item',
                    }}>
                    Engage with us in other related ways, including any sales, marketing, or events
                </ListItem>
                <Typography>
                    <br />
                    <strong>Questions or concerns?</strong>  Reading this privacy notice will help you understand your
                    privacy rights and choices. If you do not agree with our policies and practices, please do not use
                    our Services. If you still have any questions or concerns, please contact us at alex@tokenmanagement.io.
                    <br /><br />
                </Typography>
                <Typography variant={"h6"} sx={{fontWeight:"bold"}}>SUMMARY OF KEY POINTS</Typography>
                <Typography>
                    <br />
                    <em><strong>
                    This summary provides key points from our privacy notice, but you can find out more details about
                    any of these topics by clicking the link following each key point or by using our table of contents
                    below to find the section you are looking for. You can also click <a href={"#toc"}>here</a> to go
                        directly to our table of contents.
                    </strong></em>
                    <br/><br/>
                    <strong>What personal information do we process?</strong> When you visit, use, or navigate our
                    Services, we may process personal information depending on how you interact with Nodia and the
                    Services, the choices you make, and the products and features you use. Click <a href={"#personalinfo"}>
                    here</a> to learn more.
                    <br/><br/>
                    <strong>Do we process any sensitive personal information?</strong> We do not process sensitive
                    personal information.
                    <br/><br/>
                    <strong>Do we receive any information from third parties?</strong> We do not receive any information
                    from third parties.
                    <br/><br/>
                    <strong>How do we process your information?</strong> We process your information to provide, improve,
                    and administer our Services, communicate with you, for security and fraud prevention, and to comply
                    with law. We may also process your information for other purposes with your consent. We process your
                    information only when we have a valid legal reason to do so. Click  <a href={"#infouse"}>
                    here</a> to learn more.
                    <br/><br/>
                    <strong>In what situations and with which parties do we share personal information?</strong> We may
                    share information in specific situations and with specific third parties. Click <a href={"#whoshare"}>
                    here</a> to learn more.
                    <br/><br/>
                    <strong>How do we keep your information safe?</strong> We have organizational and technical
                    processes and procedures in place to protect your personal information. However, no electronic
                    transmission over the internet or information storage technology can be guaranteed to be 100% secure,
                    so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties
                    will not be able to defeat our security and improperly collect, access, steal, or modify your
                    information. Click <a href={"#infosafe"}>here</a> to learn more.
                    <br/><br/>
                        <strong>What are your rights?</strong> Depending on where you are located geographically, the
                    applicable privacy law may mean you have certain rights regarding your personal information.
                    Click <a href={"#privacyrights"}>here</a> to learn more.
                    <br/><br/>
                        <strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by
                    filling out our data subject request form
                    available <a href={"https://app.termly.io/notify/599ee56f-0af4-4154-b48a-78ef96d126f2"}>here</a>,
                    or by contacting us. We will consider and act upon any request in accordance with applicable data
                    protection laws.
                    <br/><br/>
                        Want to learn more about what Nodia does with any information we collect? Click <a href={"#toc"}>
                    here</a> to review the notice in full.
                    <br/><br/><br/>
                </Typography>
                <Typography variant={"h6"} id={"toc"} sx={{fontWeight: "bold"}}>TABLE OF CONTENTS</Typography>
                <Typography>
                    <br/>
                    <a href={"#infocollect"}>
                        1. WHAT INFORMATION DO WE COLLECT?
                    </a>
                    <br/>
                    <a href={"#infouse"}>
                        2. HOW DO WE PROCESS YOUR INFORMATION?
                    </a>
                    <br/>
                    <a href={"#legalbases"}>
                        3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?
                    </a>
                    <br/>
                    <a href={"#whoshare"}>
                        4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                    </a>
                    <br/>
                    <a href={"#cookies"}>
                        5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    </a>
                    <br/>
                    <a href={"#sociallogins"}>
                        6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                    </a>
                    <br/>
                    <a href={"#inforetain"}>
                        7. HOW LONG DO WE KEEP YOUR INFORMATION?
                    </a>
                    <br/>
                    <a href={"#infosafe"}>
                        8. HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </a>
                    <br/>
                    <a href={"#infominors"}>
                        9. DO WE COLLECT INFORMATION FROM MINORS?
                    </a>
                    <br/>
                    <a href={"#privacyrights"}>
                        10. WHAT ARE YOUR PRIVACY RIGHTS?
                    </a>
                    <br/>
                    <a href={"#DNT"}>
                        11. CONTROLS FOR DO-NOT-TRACK FEATURES
                    </a>
                    <br/>
                    <a href={"#caresidents"}>
                        12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    </a>
                    <br/>
                    <a href={"#policyupdates"}>
                        13. DO WE MAKE UPDATES TO THIS NOTICE?
                    </a>
                    <br/>
                    <a href={"#contact"}>
                        14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                    </a>
                    <br/>
                    <a href={"#request"}>
                        15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                    </a>
                    <br/><br/><br/>
                </Typography>
                <Typography variant={"h6"} id={"infocollect"} sx={{fontWeight: "bold"}}>
                    1. WHAT INFORMATION DO WE COLLECT?
                </Typography>
                <Typography variant={"h6"} sx={{fontWeight: "bold"}} id={"personalinfo"}>
                    <br/>
                    Personal information you disclose to us
                    <br/>
                </Typography>
                <Typography>
                    <br/><em><strong>
                    In Short:</strong>   We collect personal information that you provide to us.</em>
                    <br/><br/>
                    We collect personal information that you voluntarily provide to us when you register on the Services,
                    express an interest in obtaining information about us or our products and Services, when you
                    participate in activities on the Services, or otherwise when you contact us.
                    <br/><br/>
                    <strong>Personal Information Provided by You.</strong> The personal information that we collect
                    depends on the context of your interactions with us and the Services, the choices you make, and the
                    products and features you use. The personal information we collect may include the following:
                    <br/>
                </Typography>
                    <List
                        sx = {{
                            listStyleType: 'disc',
                            pl: 2,
                        }} />

                    <ListItem
                        sx = {{
                            display: 'list-item',
                        }}>
                        names
                    </ListItem>
                    <ListItem
                        sx = {{
                            display: 'list-item',
                        }}>
                        phone numbers
                    </ListItem>
                    <ListItem
                        sx = {{
                            display: 'list-item',
                        }}>
                        email addresses
                    </ListItem>
                    <ListItem
                        sx = {{
                            display: 'list-item',
                        }}>
                        mailing addresses
                    </ListItem>
                    <ListItem
                        sx = {{
                            display: 'list-item',
                        }}>
                        usernames
                    </ListItem>
                    <ListItem
                        sx = {{
                            display: 'list-item',
                        }}>
                        passwords
                    </ListItem>
                <Typography>
                    <br />
                    <strong>Sensitive Information.</strong> We do not process sensitive information.
                    <br /><br />
                    <strong>Social Media Login Data.</strong>  We may provide you with the option to register with us
                    using your existing social media account details, like your Facebook, Twitter, or other social
                    media account. If you choose to register in this way, we will collect the information described in
                    the section called " <a href={"#sociallogins"}>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a> " below.
                    <br /><br />
                    All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
                    <br /><br /><br />
                </Typography>
                <Typography variant={"h6"} id={"infouse"} sx={{fontWeight: "bold"}}>
                    2. HOW DO WE PROCESS YOUR INFORMATION?
                </Typography>
                <Typography>
                    <br /><em>
                    <strong>In Short:</strong> We process your information to provide, improve, and administer our
                    Services, communicate with you, for security and fraud prevention, and to comply with law. We may
                    also process your information for other purposes with your consent.
                </em>
                    <br /><br />
                    <strong>We process your personal information for a variety of reasons, depending on how you interact with
                    our Services, including:</strong>

                        <List
                            sx = {{
                                listStyleType: 'disc',
                                pl: 2,
                            }} />

                        <ListItem
                            sx = {{
                                display: 'list-item',
                            }}>
                            <strong>
                                To facilitate account creation and authentication and otherwise manage user accounts.
                            </strong>
                            {" "}We may process your information so you can create and log in to your account, as well as
                            keep your account in working order.
                        </ListItem>
                        <ListItem
                            sx = {{
                                display: 'list-item',
                            }}>
                            <strong>
                                To deliver and facilitate delivery of services to the user.
                            </strong>
                            {" "}We may process your information to provide you with the requested service.
                        </ListItem>
                        <ListItem
                            sx = {{
                                display: 'list-item',
                            }}>
                            <strong>
                                To respond to user inquiries/offer support to users.
                            </strong>
                            {" "}We may process your information to respond to your inquiries and solve any potential
                            issues you might have with the requested service.
                        </ListItem>
                        <ListItem
                            sx = {{
                                display: 'list-item',
                            }}>
                            <strong>
                                To save or protect an individual's vital interest.
                            </strong>
                            {" "}We may process your information when necessary to save or protect an individual’s vital
                            interest, such as to prevent harm.
                        </ListItem>
                    <br/><br/>
                </Typography>
                <Typography variant={"h6"} id={"legalbases"} sx={{fontWeight: "bold"}}>
                    3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
                </Typography>
                <Typography>
                    <br/>
                    <em>
                    <strong>In Short:</strong> We only process your personal information when we believe it is necessary
                        and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with
                        your consent, to comply with laws, to provide you with services to enter into or fulfill our
                        contractual obligations, to protect your rights, or to fulfill our legitimate business interests.
                    </em>
                    <br/><br/>
                    <em><u><strong>
                        If you are located in the EU or UK, this section applies to you.
                    </strong></u></em>

                    <br/><br/>
                    The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:
                    <br/>
                        <List
                            sx = {{
                                listStyleType: 'disc',
                                pl: 2,
                            }} />

                        <ListItem
                            sx = {{
                                display: 'list-item',
                            }}>
                            <strong>
                                Consent.
                            </strong>
                            {" "}We may process your information if you have given us permission (i.e., consent) to use
                            your personal information for a specific purpose. You can withdraw your consent at any time.
                            Click <a href={"#withdrawconsent"}>here</a> to learn more.
                        </ListItem>
                        <ListItem
                            sx = {{
                                display: 'list-item',
                            }}>
                            <strong>
                                Performance of a Contract.
                            </strong>
                            {" "}We may process your personal information when we believe it is necessary to fulfill our
                            contractual obligations to you, including providing our Services or at your request prior to
                            entering into a contract with you.
                        </ListItem>
                        <ListItem
                            sx = {{
                                display: 'list-item',
                            }}>
                            <strong>
                                Legal Obligations.
                            </strong>
                            {" "}We may process your information where we believe it is necessary for compliance with
                            our legal obligations, such as to cooperate with a law enforcement body or regulatory
                            agency, exercise or defend our legal rights, or disclose your information as evidence in
                            litigation in which we are involved.
                        </ListItem>
                        <ListItem
                            sx = {{
                                display: 'list-item',
                            }}>
                            <strong>
                                Vital Interests.
                            </strong>
                            {" "}We may process your information where we believe it is necessary to protect your vital
                            interests or the vital interests of a third party, such as situations involving potential
                            threats to the safety of any person.
                        </ListItem>
                    <br />
                    <em><strong><u>
                        If you are located in Canada, this section applies to you.
                    </u></strong></em>
                    <br/><br/>
                    We may process your information if you have given us specific permission (i.e., express consent) to
                    use your personal information for a specific purpose, or in situations where your permission can be
                    inferred (i.e. , implied consent). You can withdraw your consent at any time.
                    Click <a href={"#withdrawconsent"}>here</a> to learn more.
                    <br/><br/>
                    In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:
                    <br/>
                        <List
                            sx = {{
                                listStyleType: 'disc',
                                pl: 2,
                            }} />
                        <ListItem
                            sx = {{
                                display: 'list-item',
                            }}>
                            If collection is clearly in the interests of an individual and consent cannot be obtained in
                            a timely way
                        </ListItem>
                        <ListItem
                            sx = {{
                                display: 'list-item',
                            }}>
                            For investigations and fraud detection and prevention
                        </ListItem>
                        <ListItem
                            sx = {{
                                display: 'list-item',
                            }}>
                            For business transactions provided certain conditions are met
                        </ListItem>
                        <ListItem
                            sx = {{
                                display: 'list-item',
                            }}>
                            If it is contained in a witness statement and the collection is necessary to assess,
                            process, or settle an insurance claim
                        </ListItem>
                        <ListItem
                            sx = {{
                                display: 'list-item',
                            }}>
                            For identifying injured, ill, or deceased persons and communicating with next of kin
                        </ListItem>
                        <ListItem
                            sx = {{
                                display: 'list-item',
                            }}>
                            If we have reasonable grounds to believe an individual has been, is, or may be victim of
                            financial abuse
                        </ListItem>
                        <ListItem
                            sx = {{
                                display: 'list-item',
                            }}>
                            If it is reasonable to expect collection and use with consent would compromise the
                            availability or the accuracy of the information and the collection is reasonable for
                            purposes related to investigating a breach of an agreement or a contravention of the laws
                            of Canada or a province
                        </ListItem>
                        <ListItem
                            sx = {{
                                display: 'list-item',
                            }}>
                            If disclosure is required to comply with a subpoena, warrant, court order, or rules of
                            the court relating to the production of records
                        </ListItem>
                        <ListItem
                            sx = {{
                                display: 'list-item',
                            }}>
                            If it was produced by an individual in the course of their employment, business, or
                            profession and the collection is consistent with the purposes for which the information
                            was produced
                        </ListItem>
                        <ListItem
                            sx = {{
                                display: 'list-item',
                            }}>
                            If the collection is solely for journalistic, artistic, or literary purposes
                        </ListItem>
                        <ListItem
                            sx = {{
                                display: 'list-item',
                            }}>
                            If the information is publicly available and is specified by the regulations
                        </ListItem>
                    <br /><br />
                </Typography>
                <Typography variant={"h6"} id={"whoshare"} sx={{fontWeight: "bold"}}>
                    4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </Typography>
                <Typography>
                    <br />
                    <em>
                        <strong>In Short:</strong> We may share information in specific situations described in this
                        section and/or with the following third parties.
                    </em>
                    <br /><br />
                    We may need to share your personal information in the following situations:
                    <br /><br />
                </Typography>
                    <List
                        sx = {{
                            listStyleType: 'disc',
                            pl: 2,
                        }} />

                    <ListItem
                        sx = {{
                            display: 'list-item',
                        }}>
                        <strong>
                            Business Partners.
                        </strong>
                        {" "}We may share your information with our business partners to offer you certain products,
                        services, or promotions.
                    </ListItem>
                    <ListItem
                        sx = {{
                            display: 'list-item',
                        }}>
                        <strong>
                            Consent.
                        </strong>
                        {" "}We may process your information if you have given us permission (i.e., consent) to use
                        your personal information for a specific purpose. You can withdraw your consent at any time.
                        Click <a href={"#withdrawconsent"}>here</a> to learn more.
                    </ListItem>
                <br /><br />
                <Typography variant={"h6"} id={"cookies"} sx={{fontWeight: "bold"}}>
                    5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </Typography>
                <Typography>
                    <br />
                    <em>
                        <strong>In Short:</strong> We may use cookies and other tracking technologies to collect and
                        store your information.
                    </em>
                    <br /><br />
                    We may use cookies and similar tracking technologies (like web beacons and pixels) to access or
                    store information. Specific information about how we use such technologies and how you can refuse
                    certain cookies is set out in our Cookie Notice.
                </Typography>
                <br /><br />
                <Typography variant={"h6"} id={"sociallogins"} sx={{fontWeight: "bold"}}>
                    6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                </Typography>
                <Typography>
                    <br />
                    <em>
                        <strong>In Short:</strong> If you choose to register or log in to our Services using a social
                        media account, we may have access to certain information about you.
                    </em>
                    <br /><br />
                    Our Services offer you the ability to register and log in using your third-party social media
                    account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive
                    certain profile information about you from your social media provider. The profile information we
                    receive may vary depending on the social media provider concerned, but will often include your name,
                    email address, friends list, and profile picture, as well as other information you choose to make
                    public on such a social media platform.
                    <br /><br />
                    We will use the information we receive only for the purposes that are described in this privacy
                    notice or that are otherwise made clear to you on the relevant Services. Please note that we do not
                    control, and are not responsible for, other uses of your personal information by your third-party
                    social media provider. We recommend that you review their privacy notice to understand how they
                    collect, use, and share your personal information, and how you can set your privacy preferences on
                    their sites and apps.
                    <br /><br /><br />
                </Typography>
                <Typography variant={"h6"} id={"inforetain"} sx={{fontWeight: "bold"}}>
                    7. HOW LONG DO WE KEEP YOUR INFORMATION?
                </Typography>
                <Typography>
                    <br />
                    <em>
                        <strong>In Short:</strong> We keep your information for as long as necessary to fulfill the
                        purposes outlined in this privacy notice unless otherwise required by law.
                    </em>
                    <br /><br />
                    We will only keep your personal information for as long as it is necessary for the purposes set
                    out in this privacy notice, unless a longer retention period is required or permitted by law (such
                    as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping
                    your personal information for longer than the period of time in which users have an account with us.
                    <br /><br />
                    When we have no ongoing legitimate business need to process your personal information, we will
                    either delete or anonymize such information, or, if this is not possible (for example, because your
                    personal information has been stored in backup archives), then we will securely store your personal
                    information and isolate it from any further processing until deletion is possible.
                    <br /><br /><br />
                </Typography>
                <Typography variant={"h6"} id={"infosafe"} sx={{fontWeight: "bold"}}>
                    8. HOW DO WE KEEP YOUR INFORMATION SAFE?
                </Typography>
                <Typography>
                    <br />
                    <em>
                        <strong>In Short:</strong> We aim to protect your personal information through a system of
                        organizational and technical security measures.
                    </em>
                    <br /><br />
                    We have implemented appropriate and reasonable technical and organizational security measures
                    designed to protect the security of any personal information we process. However, despite our
                    safeguards and efforts to secure your information, no electronic transmission over the Internet or
                    information storage technology can be guaranteed to be 100% secure, so we cannot promise or
                    guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to
                    defeat our security and improperly collect, access, steal, or modify your information. Although we
                    will do our best to protect your personal information, transmission of personal information to and
                    from our Services is at your own risk. You should only access the Services within a secure
                    environment.
                    <br /><br /><br />
                </Typography>
                <Typography variant={"h6"} id={"infominors"} sx={{fontWeight: "bold"}}>
                    9. DO WE COLLECT INFORMATION FROM MINORS?
                </Typography>
                <Typography>
                    <br />
                    <em>
                        <strong>In Short:</strong> We do not knowingly collect data from or market to children under
                        18 years of age.
                    </em>
                    <br /><br />
                    We do not knowingly solicit data from or market to children under 18 years of age. By using the
                    Services, you represent that you are at least 18 or that you are the parent or guardian of such a
                    minor and consent to such minor dependent’s use of the Services. If we learn that personal
                    information from users less than 18 years of age has been collected, we will deactivate the account
                    and take reasonable measures to promptly delete such data from our records. If you become aware of
                    any data we may have collected from children under age 18, please contact us at
                    alex@tokenmanagement.io.
                    <br /><br /><br />
                </Typography>
                <Typography variant={"h6"} id={"privacyrights"} sx={{fontWeight: "bold"}}>
                    10. WHAT ARE YOUR PRIVACY RIGHTS?
                </Typography>
                <Typography>
                    <br />
                    <em>
                        <strong>In Short:</strong> In some regions, such as the European Economic Area (EEA), United
                        Kingdom (UK), and Canada, you have rights that allow you greater access to and control over your
                        personal information. You may review, change, or terminate your account at any time.
                    </em>
                    <br /><br />
                    In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data
                    protection laws. These may include the right (i) to request access and obtain a copy of your
                    personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of
                    your personal information; and (iv) if applicable, to data portability. In certain circumstances,
                    you may also have the right to object to the processing of your personal information. You can make
                    such a request by contacting us by using the contact details provided in the
                    section "<a href={"#contact"}>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>" below.
                    <br /><br />
                    We will consider and act upon any request in accordance with applicable data protection laws.
                    <br /><br />
                    If you are located in the EEA or UK and you believe we are unlawfully processing your personal
                    information, you also have the right to complain to your local data protection supervisory authority.
                    You can find their contact details here:{" "}
                    <a href={"https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"}>
                        https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.
                    <br /><br />
                    If you are located in Switzerland, the contact details for the data protection authorities are
                    available here: <a href={"https://www.edoeb.admin.ch/edoeb/en/home.html"}>https://www.edoeb.admin.ch/edoeb/en/home.html</a>.
                    <br /><br />
                    <div id={"withdrawconsent"}>
                    <strong><u>Withdrawing your consent:</u></strong>{" "}
                     If we are relying on your consent to process your personal information, which may be express and/or
                    implied consent depending on the applicable law, you have the right to withdraw your consent at any
                    time. You can withdraw your consent at any time by contacting us by using the contact details
                    provided in the section "<a href={"#contact"}>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>" below.
                    </div>
                    <br /><br />
                    However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
                    <br /><br />
                </Typography>
                <Typography variant={"h6"} sx={{fontWeight:"bold"}}>Account Information</Typography>
                <Typography>
                    If you would at any time like to review or change the information in your account or terminate your
                    account, you can:
                    <List
                    sx = {{
                    listStyleType: 'disc',
                    pl: 2,
                }} />
                    <ListItem
                        sx = {{
                            display: 'list-item',
                        }}>
                        Log in to your account settings and update your user account.
                    </ListItem>
                    <br />
                    Upon your request to terminate your account, we will deactivate or delete your account and
                    information from our active databases. However, we may retain some information in our files to
                    prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or
                    comply with applicable legal requirements.
                    <br /><br />
                    <u><strong>Cookies and similar technologies:</strong></u>{" "}
                     Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. To opt out of interest-based advertising by advertisers on our Services visit http://www.aboutads.info/choices/.
                    <br /><br />
                    If you have questions or comments about your privacy rights, you may email us at alex@tokenmanagement.io.
                    <br /><br /><br />
                </Typography>
                <Typography variant={"h6"} id={"DNT"} sx={{fontWeight: "bold"}}>
                    11. CONTROLS FOR DO-NOT-TRACK FEATURES
                </Typography>
                <Typography>
                    <br />
                    Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track
                    ("DNT") feature or setting you can activate to signal your privacy preference not to have data about
                    your online browsing activities monitored and collected. At this stage no uniform technology
                    standard for recognizing and implementing DNT signals has been finalized. As such, we do not
                    currently respond to DNT browser signals or any other mechanism that automatically communicates your
                    choice not to be tracked online. If a standard for online tracking is adopted that we must follow in
                    the future, we will inform you about that practice in a revised version of this privacy notice.
                    <br /><br /><br />
                </Typography>
                <Typography variant={"h6"} id={"caresidents"} sx={{fontWeight: "bold"}}>
                    12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </Typography>
                <Typography>
                    <br />
                    <em>
                        <strong>In Short:</strong> Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.
                    </em>
                    <br /><br />
                    California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users
                    who are California residents to request and obtain from us, once a year and free of charge,
                    information about categories of personal information (if any) we disclosed to third parties for
                    direct marketing purposes and the names and addresses of all third parties with which we shared
                    personal information in the immediately preceding calendar year. If you are a California resident
                    and would like to make such a request, please submit your request in writing to us using the contact
                    information provided below.
                    <br /><br />
                    If you are under 18 years of age, reside in California, and have a registered account with Services,
                    you have the right to request removal of unwanted data that you publicly post on the Services.
                    To request removal of such data, please contact us using the contact information provided below and
                    include the email address associated with your account and a statement that you reside in California.
                    We will make sure the data is not publicly displayed on the Services, but please be aware that the
                    data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).
                    <br /><br />
                </Typography>
                <Typography variant={"h6"} sx={{fontWeight:"bold"}}>CCPA Privacy Notice</Typography>
                <Typography>
                    <br />
                    The California Code of Regulations defines a "resident" as:
                    <br />
                    <List
                        sx = {{
                            listStyleType: 'disc',
                            pl: 2,
                        }} />

                    <ListItem
                        sx = {{
                            display: 'list-item',
                        }}>
                        (1) every individual who is in the State of California for other than a temporary or transitory
                        purpose and
                    </ListItem>
                    <ListItem
                        sx = {{
                            display: 'list-item',
                        }}>
                        (2) every individual who is domiciled in the State of California who is outside the State of
                        California for a temporary or transitory purpose
                    </ListItem>
                    <br />
                    All other individuals are defined as "non-residents."
                    <br /><br />
                    If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.
                    <br /><br />
                    <strong>
                    What categories of personal information do we collect?
                    </strong>
                    <br /><br />
                    We have collected the following categories of personal information in the past twelve (12) months:
                    <br /><br />
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{fontWeight:"bold"}}>
                                    Category
                                </TableCell>
                                <TableCell sx={{fontWeight:"bold"}}>
                                    Examples
                                </TableCell>
                                <TableCell sx={{fontWeight:"bold"}}>
                                    Collected
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableRow>
                            <TableCell>
                                A. Identifiers
                            </TableCell>
                            <TableCell>
                                Contact details, such as real name, alias, postal address, telephone or mobile contact
                                number, unique personal identifier, online identifier, Internet Protocol address, email
                                address, and account name
                            </TableCell>
                            <TableCell align={"center"}>
                                NO
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                B. Personal information categories listed in the California Customer Records statute
                            </TableCell>
                            <TableCell>
                                Name, contact information, education, employment, employment history, and
                                financial information
                            </TableCell>
                            <TableCell align={"center"}>
                                NO
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                C. Protected classification characteristics under California or federal law
                            </TableCell>
                            <TableCell>
                                Gender and date of birth
                            </TableCell>
                            <TableCell align={"center"}>
                                NO
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                D. Commercial information
                            </TableCell>
                            <TableCell>
                                Transaction information, purchase history, financial details, and payment information
                            </TableCell>
                            <TableCell align={"center"}>
                                NO
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                E. Biometric information
                            </TableCell>
                            <TableCell>
                                Fingerprints and voiceprints
                            </TableCell>
                            <TableCell align={"center"}>
                                NO
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                F. Internet or other similar network activity
                            </TableCell>
                            <TableCell>
                                Browsing history, search history, online behavior, interest data, and interactions
                                with our and other websites, applications, systems, and advertisements
                            </TableCell>
                            <TableCell align={"center"}>
                                NO
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                G. Geolocation data
                            </TableCell>
                            <TableCell>
                                Device location
                            </TableCell>
                            <TableCell align={"center"}>
                                NO
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                H. Audio, electronic, visual, thermal, olfactory, or similar information
                            </TableCell>
                            <TableCell>
                                Images and audio, video or call recordings created in connection with our business activities
                            </TableCell>
                            <TableCell align={"center"}>
                                NO
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                I. Professional or employment-related information
                            </TableCell>
                            <TableCell>
                                Business contact details in order to provide you our Services at a business level or
                                job title, work history, and professional qualifications if you apply for a job with us
                            </TableCell>
                            <TableCell align={"center"}>
                                NO
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                J. Education Information
                            </TableCell>
                            <TableCell>
                                Student records and directory information
                            </TableCell>
                            <TableCell align={"center"}>
                                NO
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                K. Inferences drawn from other personal information
                            </TableCell>
                            <TableCell>

                                Inferences drawn from any of the collected personal information listed above to create
                                a profile or summary about, for example, an individual’s preferences and characteristics
                            </TableCell>
                            <TableCell align={"center"}>
                                NO
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                L. Sensitive Personal Information
                            </TableCell>
                            <TableCell>

                            </TableCell>
                            <TableCell align={"center"}>
                                NO
                            </TableCell>
                        </TableRow>
                    </Table>
                    <br/><br/>
                    We may also collect other personal information outside of these categories through instances where
                    you interact with us in person, online, or by phone or mail in the context of:
                    <List
                        sx = {{
                            listStyleType: 'disc',
                            pl: 2,
                        }} />

                    <ListItem
                        sx = {{
                            display: 'list-item',
                        }}>
                        Receiving help through our customer support channels;
                    </ListItem>
                    <ListItem
                        sx = {{
                            display: 'list-item',
                        }}>
                        Participation in customer surveys or contests; and
                    </ListItem>
                    <ListItem
                        sx = {{
                            display: 'list-item',
                        }}>
                        Facilitation in the delivery of our Services and to respond to your inquiries.
                    </ListItem>
                    <br/>
                    <strong>
                    How do we use and share your personal information?
                    </strong>
                    <br/><br/>
                    More information about our data collection and sharing practices can be found in this privacy notice.
                    <br/><br/>
                    You may contact us by email at alex@tokenmanagement.io, or by referring to the contact details at
                    the bottom of this document.
                    <br/><br/>
                    If you are using an authorized agent to exercise your right to opt out we may deny a request if the
                    authorized agent does not submit proof that they have been validly authorized to act on your behalf.
                    <br/><br/>
                    <strong>
                    Will your information be shared with anyone else?
                    </strong>
                    <br/><br/>
                    We may disclose your personal information with our service providers pursuant to a written contract
                    between us and each service provider. Each service provider is a for-profit entity that processes
                    the information on our behalf, following the same strict privacy protection obligations mandated by
                    the CCPA.
                    <br/><br/>
                    We may use your personal information for our own business purposes, such as for undertaking internal
                    research for technological development and demonstration. This is not considered to be "selling" of
                    your personal information.
                    <br/><br/>
                    Nodia has not disclosed, sold, or shared any personal information to third parties for a business
                    or commercial purpose in the preceding twelve (12) months. Nodia will not sell or share personal
                    information in the future belonging to website visitors, users, and other consumers.
                    <br/><br/>
                    <strong>
                    Your rights with respect to your personal data
                    </strong>
                    <br/><br/>
                    <u>
                    Right to request deletion of the data — Request to delete
                    </u>
                    <br/><br/>
                    You can ask for the deletion of your personal information. If you ask us to delete your personal
                    information, we will respect your request and delete your personal information, subject to certain
                    exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or
                    her right to free speech, our compliance requirements resulting from a legal obligation, or any
                    processing that may be required to protect against illegal activities.
                    <br/><br/>
                    <u>
                    Right to be informed — Request to know
                    </u>
                    <br/><br/>
                    Depending on the circumstances, you have a right to know:
                    <List
                        sx = {{
                            listStyleType: 'disc',
                            pl: 2,
                        }} />

                    <ListItem
                        sx = {{
                            display: 'list-item',
                        }}>
                        whether we collect and use your personal information;
                    </ListItem>
                    <ListItem
                        sx = {{
                            display: 'list-item',
                        }}>
                        the categories of personal information that we collect;
                    </ListItem>
                    <ListItem
                        sx = {{
                            display: 'list-item',
                        }}>
                        the purposes for which the collected personal information is used;
                    </ListItem>
                    <ListItem
                        sx = {{
                            display: 'list-item',
                        }}>
                        whether we sell or share personal information to third parties;
                    </ListItem>
                    <ListItem
                        sx = {{
                            display: 'list-item',
                        }}>
                        the categories of personal information that we sold, shared, or disclosed for a business purpose;
                    </ListItem>
                    <ListItem
                        sx = {{
                            display: 'list-item',
                        }}>
                        the categories of third parties to whom the personal information was sold, shared, or disclosed
                        for a business purpose;
                    </ListItem>
                    <ListItem
                        sx = {{
                            display: 'list-item',
                        }}>
                        the business or commercial purpose for collecting, selling, or sharing personal information; and
                    </ListItem>
                    <ListItem
                        sx = {{
                            display: 'list-item',
                        }}>
                        the specific pieces of personal information we collected about you.
                    </ListItem>
                    <br/>
                    In accordance with applicable law, we are not obligated to provide or delete consumer information
                    that is de-identified in response to a consumer request or to re-identify individual data to verify
                    a consumer request.
                    <br/><br/>
                    <u>
                    Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights
                    </u>
                    <br/><br/>
                    We will not discriminate against you if you exercise your privacy rights.
                    <br/><br/>
                    <u>
                    Right to Limit Use and Disclosure of Sensitive Personal Information
                    </u>
                    <br/><br/>
                    We do not process consumer's sensitive personal information.
                    <br/><br/>
                    <u>
                    Verification process
                    </u>
                    <br/><br/>
                    Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g., phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.
                    <br/><br/>
                    We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.
                    <br/><br/>
                    <u>
                    Other privacy rights
                    </u>
                    <List
                        sx = {{
                            listStyleType: 'disc',
                            pl: 2,
                        }} />

                    <ListItem
                        sx = {{
                            display: 'list-item',
                        }}>
                        You may object to the processing of your personal information.
                    </ListItem>
                    <ListItem
                        sx = {{
                            display: 'list-item',
                        }}>
                        You may request correction of your personal data if it is incorrect or no longer relevant, or
                        ask to restrict the processing of the information.
                    </ListItem>
                    <ListItem
                        sx = {{
                            display: 'list-item',
                        }}>
                        You can designate an authorized agent to make a request under the CCPA on your behalf. We may
                        deny a request from an authorized agent that does not submit proof that they have been validly
                        authorized to act on your behalf in accordance with the CCPA.
                    </ListItem>
                    <ListItem
                        sx = {{
                            display: 'list-item',
                        }}>
                        You may request to opt out from future selling or sharing of your personal information to third
                        parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly
                        possible, but no later than fifteen (15) days from the date of the request submission.
                    </ListItem>
                    <br/>
                    To exercise these rights, you can contact us by email at alex@tokenmanagement.io, or by referring
                    to the contact details at the bottom of this document. If you have a complaint about how we handle
                    your data, we would like to hear from you.
                    <br/><br/><br/>
                </Typography>
                <Typography variant={"h6"} id={"policyupdates"} sx={{fontWeight: "bold"}}>
                    13. DO WE MAKE UPDATES TO THIS NOTICE?
                </Typography>
                <Typography>
                    <br />
                    <em>
                        <strong>In Short:</strong> Yes, we will update this notice as necessary to stay compliant with relevant laws.
                    </em>
                    <br /><br />
                    We may update this privacy notice from time to time. The updated version will be indicated by an
                    updated "Revised" date and the updated version will be effective as soon as it is accessible. If we
                    make material changes to this privacy notice, we may notify you either by prominently posting a
                    notice of such changes or by directly sending you a notification. We encourage you to review this
                    privacy notice frequently to be informed of how we are protecting your information.
                    <br /><br /><br />
                </Typography>
                <Typography variant={"h6"} id={"contact"} sx={{fontWeight: "bold"}}>
                    14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </Typography>
                <Typography>
                    <br />
                    If you have questions or comments about this notice, you may email us at alex@tokenmanagement.io or by post to:
                    <br /><br />
                    Nodia
                    <br />
                    2716 Juniper Ave
                    <br />
                    Apt 59
                    <br />
                    Boulder, CO 80304
                    <br />
                    United States
                    <br /><br /><br />
                </Typography>
                <Typography variant={"h6"} id={"request"} sx={{fontWeight: "bold"}}>
                    15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                </Typography>
                <Typography>
                    <br />
                    You have the right to request access to the personal information we collect from you, change that
                    information, or delete it. To request to review, update, or delete your personal information,
                    please submit a request form
                    by clicking <a href={"https://app.termly.io/notify/599ee56f-0af4-4154-b48a-78ef96d126f2"}>here</a>.
                    <br /><br />
                    This privacy policy was created using
                    Termly's <a href={"https://termly.io/products/privacy-policy-generator/"}>Privacy Policy Generator</a>.
                    <br /><br /><br />
                </Typography>
            </Paper>

        </Grid>
    )
})
import { Autocomplete, Box, TextField, Typography, IconButton, CircularProgress } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { observer } from "mobx-react-lite";
import { useStore } from "../../Stores";
import { MaxWidth } from '.';
import React from "react";
import { CreatorService } from "../../Services";
import RefreshIcon from '@mui/icons-material/Refresh';
import { AdminUser } from "../../Models/Admin";

interface Props {
    initialUser?: AdminUser
}

export const AddCreatorForm = observer(({ initialUser }: Props) => {
    const { adminStore, modalStore, toastBarStore } = useStore()

    const [selectedUser, setSelectedUser] = React.useState(initialUser ? { id: initialUser.id, label: initialUser.userName } : { id: "", label: "" });
    const [userIdError, setUserIdError] = React.useState(false);
    const [nameError, setNameError] = React.useState(false);
    const [domainError, setDomainError] = React.useState(false);
    const [rallyCurrencyError, setRallyCurrencyError] = React.useState(false);

    const [submitting, setSubmitting] = React.useState(false);
    const [refreshing, setRefreshing] = React.useState(false);

    const refreshUsers = () => {
        setRefreshing(true)
        adminStore.getAllUsers()
            .finally(() => {
                setRefreshing(false)
            })
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const userId = selectedUser.id
        const name = data.get('name')?.toString()
        const domain = data.get('domain')?.toString()
        const rallyCurrency = data.get('rallyCurrency')?.toString()

        setUserIdError(!userId)
        setNameError(!name)
        setDomainError(!domain)
        setRallyCurrencyError(!rallyCurrency)

        if (userId && name && domain && rallyCurrency) {
            setSubmitting(true)
            CreatorService.addCreator(userId, {
                name: name,
                domain: domain,
                rallyCurrency: rallyCurrency
            }).then(() => {
                toastBarStore.showMessage(
                    `Creator ${name} was added. Check out their site`,
                    'success',
                    10000, () => {
                        window.open(`https://${domain}`, "_blank")
                    },
                    'Open site'
                )
                modalStore.closeModal()
            }).finally(() => {
                setSubmitting(false)
            })
        }
    }

    const userPicker = (() => {
        if (refreshing) {
            return (
                <Box display='flex' flexDirection='row' paddingX={1} paddingY={2}>
                    <Typography variant='body1' color='primary.main' sx={{ flex: 1 }}>Loading users</Typography>
                    <CircularProgress sx={{ flex: '0 1 auto' }} size={25} color="primary" />
                </Box>
            )
        } else if (adminStore.userList && adminStore.userList.length > 0) {
            const options = adminStore.userList.map((user) => { return { id: user.id, label: user.userName } })
            return (
                <Box display='flex' flexDirection='row'>
                    <Autocomplete
                        id="userId"
                        options={options}
                        sx={{ flex: 1 }}
                        disabled={refreshing}
                        value={selectedUser}
                        onInputChange={(_, newInputValue, reason) => {
                            const newUser = options.find(o => o.label === newInputValue)
                            if (reason === 'clear') {
                                setSelectedUser({ id: "", label: "" })
                            } else if (newUser) {
                                setSelectedUser(newUser)
                                setUserIdError(false)
                            }
                        }}
                        isOptionEqualToValue={(a, b) => a.id === b.id}
                        renderInput={(params) => (<TextField {...params} required label="User" error={userIdError} />)}
                    />
                    <IconButton
                        disabled={refreshing}
                        onClick={refreshUsers}
                        sx={{
                            p: 2, flex: '0 1 auto'
                        }}>
                        <RefreshIcon />
                    </IconButton>
                </Box>
            )
        } else {
            refreshUsers()
            return <></>
        }
    })();

    return (
        <Box
            sx={{
                display: 'flex',
                maxWidth: `${MaxWidth}px`,
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Typography component="h1" variant="h5">
                Add Creator
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                {userPicker}
                <TextField
                    margin="normal"
                    error={nameError}
                    onChange={() => setNameError(false)}
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    name="name"
                />
                <TextField
                    margin="normal"
                    error={domainError}
                    onChange={() => setDomainError(false)}
                    required
                    fullWidth
                    id="domain"
                    label="Domain"
                    name="domain"
                />
                <TextField
                    margin="normal"
                    error={rallyCurrencyError}
                    onChange={() => setRallyCurrencyError(false)}
                    required
                    fullWidth
                    id="rallyCurrency"
                    label="Rally Ticker"
                    name="rallyCurrency"
                />
                <LoadingButton
                    type="submit"
                    loading={submitting}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Add Creator
                </LoadingButton>
            </Box>
        </Box>
    );
})
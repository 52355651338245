import { makeAutoObservable } from "mobx";
import { v4 as uuid } from "uuid";
import { Type, Content } from ".."

export class Collection implements Content {
    id: string = uuid();
    name: string = "Collection";
    type: Type = Type.collection; 
    index: number = 0;
    width: number = 12;
    collectionId: string = "";
    templateId: string = "";
    modalTemplateId: string = "";

    constructor() {
        makeAutoObservable(this)
    }
}

export interface CollectionUpdater {
    name?: string;
    width?: number;
    collectionId?: string;
    templateId?: string;
    modalTemplateId?: string;
}
import { observer } from "mobx-react-lite"
import { Stack, TextField } from '@mui/material';

import { useStore } from "../../../Stores";
import { CMSActionBar, CMSEditButtons, CMSWidthField } from '..';
import { Collection as CollectionModel } from '../../../Models/Site';
import { CollectionSelector, TemplateSelector } from "../../Collections";

interface Props {
    data: CollectionModel;
}

export const CMSCollection = observer(({ data }: Props) => {
    const { cmsStore } = useStore();
    const { working_model } = cmsStore;
    let isEditing = cmsStore.editedItemId === data.id;

    let dataToUse: CollectionModel = isEditing ? (working_model as CollectionModel) : data
    return (
        <Stack direction="column" spacing={2} justifyContent="space-between">
            <CMSEditButtons
                id={data.id}
                index={data.index}
            />
            <h5>ID: {data.id}</h5>
            <TextField
                id="name"
                label="Name"
                value={dataToUse.name}
                onChange={(e) => cmsStore.updateCollection({ name: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <CMSWidthField
                value={dataToUse.width.toString()}
                onChange={(newValue) => cmsStore.updateCollection({ width: +(newValue) })}
                disabled={!isEditing}
            />
            <CollectionSelector
                initialCollectionId={dataToUse.collectionId}
                enabled={isEditing}
                onSelect={collection => collection ? cmsStore.updateCollection({ collectionId: collection.id }) : undefined}
            />
            {
                dataToUse.collectionId ?
                    <TemplateSelector
                        initialTemplateId={dataToUse.templateId}
                        enabled={isEditing}
                        collectionId={dataToUse.collectionId}
                        onSelect={template => template ? cmsStore.updateCollection({ templateId: template.id }) : undefined}
                    />
                    :
                    undefined
            }
            {
                dataToUse.collectionId ?
                    <TemplateSelector
                        label="Modal Template"
                        initialTemplateId={dataToUse.modalTemplateId}
                        enabled={isEditing}
                        collectionId={dataToUse.collectionId}
                        onSelect={template => template ? cmsStore.updateCollection({ modalTemplateId: template.id }) : undefined}
                    />
                    :
                    undefined
            }
            <CMSActionBar id={data.id} />
        </Stack>
    );
});
import {HTML as HTMLModel} from "../../../Models/Site";
import {observer} from "mobx-react-lite";
import {Markup} from "interweave";

interface Props {
    data: HTMLModel
}


export const Html = observer(({ data }: Props) => {

    return (
        <Markup content = {data.rawhtml} />
    )
})

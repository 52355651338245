import { observer } from "mobx-react-lite";
import { Box, Stack } from "@mui/material";

import { Page as PageModel, Footer as FooterModel } from "../../Models/Site"
import { ElementRenderer, Footer } from "."
import { useStore } from "../../Stores";

interface Props {
    data: PageModel
    footer?: FooterModel
}

export const Page = observer(({ data, footer }: Props) => {
    const { commonStore } = useStore();
    return (
        <Stack sx={{ minHeight: `calc(100vh - ${commonStore.navbarHeight}px)` }} justifyContent='space-between'>
            <Stack alignItems='center'>
                {data.elements.map((e, index) => {
                    return (
                        <Box key={index.toString()} width={`${(e.width * 100) / 12}%`}>
                            {ElementRenderer.build({ model: e })}
                        </Box>
                    )
                })}
            </Stack>
            {footer ? <Footer data={footer} /> : undefined}
        </Stack>
    );
});
import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Autocomplete, Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, TextField, Typography } from "@mui/material";
import { Refresh, Add } from '@mui/icons-material';

import { Template } from "../../Models/API/Collections";
import { useStore } from "../../Stores";


interface Props {
    label?: string;
    collectionId: string;
    enabled?: boolean
    initialTemplateId?: string;
    onSelect?: (template?: Template) => void;
}

export const TemplateSelector = observer(({ label = "Template", collectionId, enabled = true, initialTemplateId, onSelect = () => { } }: Props) => {
    const [refreshing, setRefreshing] = React.useState(false);
    const [selectedTemplate, setSelectedTemplate] = React.useState({ id: "", label: "" });

    const [newName, setNewName] = React.useState('')
    const [dialogOpen, setDialogOpen] = React.useState(false)

    const { collectionStore, commonStore } = useStore()
    const { currentSite } = commonStore;

    const refreshTemplates = () => {
        setRefreshing(true)
        collectionStore.getTemplates(currentSite != null ? currentSite.id : "", collectionId)
            .finally(() => {
                setRefreshing(false)
            })
    }

    useEffect(() => {
        refreshTemplates()
    }, []) // Run once (dont delete it plz)

    const templates = collectionStore.templates[collectionId]
    if (selectedTemplate.id && templates.findIndex(t => t.id === selectedTemplate.id) === -1) {
        setSelectedTemplate({ id: "", label: "" })
    }
    const disabled = !enabled || refreshing

    const createTemplate = () => {
        setDialogOpen(false)
        setRefreshing(true)
        collectionStore
            .createTemplate(currentSite != null ? currentSite.id : "", collectionId, newName)
            .then((template) => {
                setSelectedTemplate({ id: template.id, label: template.name })
                refreshTemplates()
            })
        setNewName('')
    }

    const addNewDialog = (() => {
        return (
            <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
                <Box sx={{ width: '320px' }}>
                    <DialogTitle>Please enter a name</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="templateName"
                            label="Template Name"
                            fullWidth
                            variant="standard"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
                        <Button variant='contained' onClick={createTemplate}>Add Template</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        )
    })()

    interface TemplateProps {
        children?: React.ReactNode
    }

    const TemplatePaper = ({ children }: TemplateProps) => {
        return (
            <Paper>
                {children}
                <Button onMouseDown={() => setDialogOpen(true)} fullWidth > + Add New</Button>
            </Paper>
        )
    };

    if (refreshing) {
        return (
            <Box display='flex' flexDirection='row' paddingX={1} paddingY={2}>
                <Typography variant='body1' color='primary.main' sx={{ flex: 1 }}>Loading collections</Typography>
                <CircularProgress sx={{ flex: '0 1 auto' }} size={25} color="primary" />
            </Box>
        )
    } else if (templates) {
        if (initialTemplateId && !selectedTemplate.id) {
            const template = templates.find(t => t.id === initialTemplateId)
            if (template) {
                setSelectedTemplate({ id: initialTemplateId, label: template.name })
            }
        }
        const options = templates.map((template) => { return { id: template.id, label: template.name, template: template } })
        return (
            <Box display='flex' flexDirection='row'>
                <Autocomplete
                    id="templateId"
                    options={options}
                    sx={{ flex: 1 }}
                    disabled={disabled}
                    value={selectedTemplate}
                    onInputChange={(_, newInputValue, reason) => {
                        const newTemplate = options.find(o => o.label === newInputValue)
                        if (reason === 'clear') {
                            setSelectedTemplate({ id: "", label: "" })
                            onSelect()
                        } else if (newTemplate) {
                            setSelectedTemplate(newTemplate)
                            onSelect(newTemplate.template)
                        }
                    }}
                    isOptionEqualToValue={(a, b) => a.id === b.id}
                    renderInput={(params) => (<TextField {...params} label={label} />)}
                    PaperComponent={TemplatePaper}
                />
                <IconButton
                    disabled={disabled}
                    onClick={refreshTemplates}
                    sx={{
                        p: 2, flex: '0 1 auto'
                    }}>
                    <Refresh />
                </IconButton>
                {addNewDialog}
            </Box>
        )
    } else {
        return (
            <Box display='flex' flexDirection='row' paddingX={1} paddingY={2} alignItems='center' >
                <Typography variant='body1' color='primary.main' sx={{ flex: 1 }}>No templates</Typography>
                <IconButton
                    disabled={disabled}
                    onClick={() => setDialogOpen(true)}
                    sx={{
                        px: 1, flex: '0 1 auto'
                    }}
                >
                    <Add />
                </IconButton>
                <IconButton
                    disabled={disabled}
                    onClick={refreshTemplates}
                    sx={{
                        px: 1, flex: '0 1 auto'
                    }}
                >
                    <Refresh />
                </IconButton>
                {addNewDialog}
            </Box>
        )
    }
});
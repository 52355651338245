import { Carousel as RRCarousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Carousel as CarouselModel } from '../../../Models/Site';
import { ElementRenderer } from ".."
import { observer } from 'mobx-react-lite';

interface Props {
    data: CarouselModel
}

export const Carousel = observer(({ data }: Props) => {
    return (
        <RRCarousel
            showStatus={false}
            swipeable={true}
            emulateTouch={true}
            showThumbs={false}
        >
            {data.content.map((model) => {
                return (
                    <>
                        {ElementRenderer.build({ model: model, parent: data })}
                    </>
                );
            })}
        </RRCarousel>
    )
});
import { Button } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Creator } from "../../../Models/Admin/Creator";
import { useStore } from "../../../Stores";
import { AddCreatorForm } from "../../Forms";
import { LoadingComponent } from "../../Util";

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 300 },
    {
        field: 'name',
        headerName: 'Name',
        width: 150
    },
    {
        field: 'rallyCurrency',
        headerName: 'Rally Currency',
        width: 120
    }
];
const createData = (
    id: string,
    name: string,
    rallyCurrency: string
) => {
    return { id, name, rallyCurrency };
}

export const AdminCreatorList = observer(() => {
    const { adminStore, modalStore } = useStore();
    const { creatorList, getCreators, selectCreator } = adminStore;
    const [refreshing, setRefreshing] = useState(false);
    let data: Creator[] | undefined = creatorList;

    let rows = [];
    if (data) {
        for (var i = 0; i < data.length; i++) {
            rows.push(createData(data[i].id, data[i].name, data[i].rallyCurrency));
        }
    }

    const refreshCreators = () => {
        if (refreshing) { return }
        setRefreshing(true)
        getCreators().finally(() => {
            setRefreshing(false)
        })
    }

    useEffect(() => {
        refreshCreators()
    }, [])

    if (rows && !refreshing) {
        return (
            <>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    sx={{ backgroundColor: "paper", width: 574 }}
                    autoPageSize
                    autoHeight
                    onSelectionModelChange={(ids) => {
                        if (data) {
                            const selectedIDs = new Set(ids);
                            const selectedRows = data.filter((row) => selectedIDs.has(row.id)
                            );
                            selectCreator(selectedRows[0]);
                        }

                    }} />

            </>
        )
    }
    else {
        return (
            <LoadingComponent />
        )
    }
})
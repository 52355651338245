import {FC, ReactNode, useEffect, useState} from "react";
import {QuickNodeAuthService} from "../../Services/QuickNodeAuthService";
import {ConnectionProvider} from "@solana/wallet-adapter-react";
type CustomConnectionProviderProps = {
    children: ReactNode;
};
export const CustomConnectionProvider : FC<CustomConnectionProviderProps> = ({children}) => {
    const [headers, setHeaders] = useState<{ [key: string]: string } | undefined>();

    useEffect(() => {
        async function setAuthHeader() {
            const token = await QuickNodeAuthService.getToken();
            setHeaders({
                "Accept": 'application/json',
                "Content-Type": 'application/json',
                "Authorization": `Bearer ${token}`,
            });
        }

        setAuthHeader();
    }, []);

    const config = {
        httpHeaders: headers
    };

    return <ConnectionProvider endpoint={'https://white-quick-arrow.solana-mainnet.quiknode.pro/'} config = {config}>{children}</ConnectionProvider>;

}

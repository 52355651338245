import {action, makeAutoObservable} from "mobx";
import {ChangeEvent} from "react";
// import {
//     getMintInfo,
//     getTokenAccountInfo,
//     getTokenSwapInfo,
//     tokenSwapProgram
// } from "rly-js";
import {VersionedTransaction} from "@solana/web3.js";
import { Wallet } from "@solana/wallet-adapter-react";
// @ts-ignore
import {createAssociatedTokenAccountInstruction, AccountLayout} from "@solana/spl-token";
import {SolanaService} from "../Services/SolanaService";
import {SplToken, TokenBondingCurveToken} from "../Models/API";
import {TokenSwapLib} from "../Solana/tokenSwapLib";
import {AccountBalance} from "../Solana/accountBalance";
import {QuickNodeAuthService} from "../Services/QuickNodeAuthService";

export class SwapStore {
    selectedTokens: SplToken[] = [
        {name: "", key: "", ticker: ""},
    ]
    sourceTokenPrice: number = 0;
    destinationTokenPrice: number = 0;
    loading: boolean = true;
    // estimateEnabled= false;

    allTokens: SplToken[] = [];
    curves: TokenBondingCurveToken[] = [];

    wallet : Wallet;

    sourceToken: SplToken = {key: "",name: "",  ticker: ""};
    destinationToken: SplToken  = {key: "",name: "",  ticker: ""};
    amountIn: number = 0;
    amountOut: number = 0;
    curveId: string = "";

    accountBalanceA: string = "0";
    accountBalanceB: string = "0";
    estimated: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }
    @action setWallet = async (wallet: Wallet) => {
        this.wallet = wallet;
    }
    @action setEstimated = (trueOrFalse: boolean) => {
        this.estimated = trueOrFalse;
    }
    @action getTokens = async () => {
        const tokens = await SolanaService.getSplTokens();
        this.setTokens(tokens);
    }
    @action setTokens = (tokens : SplToken[]) => {
        tokens.unshift({key: "",name: "",  ticker: ""});
        this.allTokens = tokens;
        if(this.curves.length !== 0 && this.allTokens.length !== 0) this.loading = false;
    }
    @action getCurves = async () => {
        const curves = await SolanaService.getTokenBondingCurveTokens();
        this.setCurves(curves);
    }
    @action setCurves = (curves: TokenBondingCurveToken[]) => {
        this.curves = curves;
        if(this.curves.length !== 0 && this.allTokens.length !== 0) this.loading = false;
    }
    @action setAmountInNumber = (amountIn: number) => {
        this.amountIn = amountIn;
    }
    @action setAmountOutNumber = (amountOut: number) => {
        this.amountOut = amountOut;
    }

    @action swapTokenFields = async () => {
        this.setSwapTransaction(undefined)
        this.tokenSwapLib = undefined;
        this.setEstimated(false);
        this.setTokenPrice(0, "Source");
        this.setTokenPrice(0, "Destination");
        const token1 = this.sourceToken;
        const token2 = this.destinationToken;
        this.destinationToken = token1;
        this.sourceToken = token2;
        this.setAccountBalance("0", "B");
        this.setAccountBalance("0", "A");
        this.setAmountInNumber(this.amountOut);
        this.setAmountOutNumber(0);
        await this.getAccountTokenBalance(this.sourceToken.key, "A");
        await this.getAccountTokenBalance(this.destinationToken.key, "B");
        this.setTokenSwapInfo();
        this.setTokenPrice(await this.getTokenPrice(this.sourceToken), "Source");
        this.setTokenPrice(await this.getTokenPrice(this.destinationToken), "Destination");
    }
    @action setTokenA = async (selectedToken: SplToken) => {
        // this.estimateEnabled = false;
        this.setEstimated(false);
        this.setTokenPrice(0, "Source");
        this.setTokenPrice(0, "Destination");
        this.selectedTokens = [{name: "", key: "", ticker: ""}];
        this.destinationToken = this.selectedTokens[0];
        this.sourceToken = this.allTokens.find(token => token.name === selectedToken.name) as SplToken;
        this.setAccountBalance("0", "B");
        this.setAccountBalance("0", "A");
        this.setAmountInNumber(0);
        this.setAmountOutNumber(0);
        await this.getAccountTokenBalance(this.sourceToken.key, "A");
        this.setTokenSwapInfo();
        this.setTokenPrice(await this.getTokenPrice(this.sourceToken), "Source")
        // this.setTokenAccountAtoB(true);
    }
    @action setTokenB = async (selectedToken: SplToken) => {
        this.setEstimated(false);
        this.destinationToken = this.selectedTokens.find(token => token.name === selectedToken.name) as SplToken;
        this.setTokenPrice(0, "Destination");
        this.setAmountOutNumber(0);
        this.setAccountBalance("0", "B");
        await this.getAccountTokenBalance(this.destinationToken.key, "B")
        this.setTokenSwapInfo()

        this.setTokenPrice(await this.getTokenPrice(this.destinationToken), "Destination")
        // this.setTokenAccountAtoB(true);
        // this.estimateEnabled = true;
    }
    @action setTokenSwapInfo = () =>{
        const selectedCurve = this.curves.find(c => c.tokens.includes(this.sourceToken && this.destinationToken));
        if(selectedCurve)
        {
            this.curveId = selectedCurve.key;
        }
        const curves = this.curves.filter(c => c.tokens.some(g => g.key === this.sourceToken.key));
        const tokens = [...new Set(curves.map(c => c.tokens).flat(1))].filter(c => c.key !== this.sourceToken.key);
        this.setSelectedTokens(tokens);
    }
    @action setSelectedTokens = (tokens : SplToken[]) => {
        this.selectedTokens = tokens;
    }


    @action setAmountIn = async (event: ChangeEvent<HTMLInputElement>) => {
        this.setEstimated(false);
        this.setAmountOutNumber(0);
        // this.setTokenAccountAtoB(true);
        this.amountIn = Number(event.target.value);
    }

    @action setAccountBalance = (balance: string, whichBalance: string) => {
        if(whichBalance === "A") {
            this.accountBalanceA = balance;
        } else if(whichBalance === "B"){
            this.accountBalanceB = balance;
        }
    }
    private tokenSwapLib: TokenSwapLib | undefined;
    private swapTransaction: VersionedTransaction | undefined;
    @action estimateSwapValues = async () => {
        this.tokenSwapLib = undefined;
        this.setSwapTransaction(undefined);
        const token = await QuickNodeAuthService.getToken();
        this.tokenSwapLib = new TokenSwapLib(this.wallet, this.curveId, this.sourceToken.key, this.destinationToken.key, this.amountIn, token);
        const estimated = await this.tokenSwapLib.estimateSwap();
        if(estimated)
        {
            this.swapTransaction = estimated.transaction;
            this.setAmountOutNumber(estimated.destinationAmount);
            this.setEstimated(true);
        }
    }
    @action setSwapTransaction = (transaction: VersionedTransaction | undefined) => {
        this.swapTransaction = transaction;
    }

    @action executeSwap = async () => {
        if(this.swapTransaction && this.tokenSwapLib)
        {
            await this.tokenSwapLib.executeSwap(this.swapTransaction);
        }
    }
    @action getAccountTokenBalance = async (mint: string, whichBalance: string) => {
        const token = await QuickNodeAuthService.getToken();
        const accountBalance = new AccountBalance(this.wallet, token);

        const balance = await accountBalance.getAccountBalance(mint);
        if(balance)
        {
            if(whichBalance === "A")
            {
                this.setAccountBalance(balance.toString(), "A");
            } else {
                this.setAccountBalance(balance.toString(), "B");
            }
            return balance;
        }
    }

    //Prices

    @action getTokenPrice = async (token: SplToken) => {
        const mintAddress = token.key;
        const price = await SolanaService.GetSplTokenPrice(mintAddress);
        if(price && price > 0) return price;
        return 0;
    }

    @action setTokenPrice = (price: number, whichPrice: "Source" | "Destination") => {
        if(whichPrice == "Source") {
            this.sourceTokenPrice = price;
        } else {
            this.destinationTokenPrice = price;
        }
    }

}




import { makeAutoObservable } from "mobx";

import { 
    Site, Page, Footer, NavBar, NavBarItem, 
    Playlist, PlaylistSection, Container, Carousel 
} from ".";
import { v4 as uuid } from "uuid";

export * from "./Site";
export * from "./Page";
export * from "./PageCondition";
export * from "./Content";
export * from "./Footer";
export * from "./NavBar";
export * from "./NavBarItem";
export * from "./Theme";

export enum Type {
    site = "site",
    theme = "theme",
    page = "page",
    blocked = "blocked",
    header = "header",
    footer = "footer",
    container = "container",
    navBar = "navbar",
    navBarItem = "navbaritem",
    text = "text",
    image = "image",
    playlist = "playlist",
    playlistSection = "playlistSection",
    video = "video",
    carousel = "carousel",
    iFrame = "iFrame",
    socials = "socials",
    button = "button",
    collection = "collection",
    html = "html",
    rallySwap = "rallySwap",
}

export const ContentTypes = [
    Type.header,
    Type.footer,
    Type.container,
    Type.text,
    Type.image,
    Type.playlist,
    Type.playlistSection,
    Type.video,
    Type.carousel,
    Type.iFrame,
    Type.socials,
    Type.button,
    Type.collection,
    Type.html,
    Type.rallySwap
]

export class Model {
    id: string = "";
    name: string = "";
    type: Type = Type.site;
    index: number = 0;

    constructor() {
        makeAutoObservable(this)
    }

    static itemsInModel = (model: Model): Model[] => {
        switch (model.type) {
            case Type.site:
                let site = model as Site;
                return [site.theme, site.navBar, ...site.pages, site.footer];
            case Type.navBar:
                let navBar = model as NavBar;
                return navBar.routes;
            case Type.navBarItem:
                let navBarItem = model as NavBarItem;
                return navBarItem.subRoutes;
            case Type.page:
                let page = model as Page;
                return page.elements;
            case Type.container:
                let container = model as Container
                return container.content;
            case Type.playlist:
                let playlist = model as Playlist
                return playlist.sections;
            case Type.playlistSection:
                let section = model as PlaylistSection
                return section.videos;
            case Type.carousel:
                let carousel = model as Carousel
                return carousel.content;
            case Type.footer:
                let footer = model as Footer
                return footer.content ?? [];
            default:
                return [];
        }
    };

    static canAdd = (type: Type, parentType: Type): boolean => {
        switch (parentType) {
            case Type.site:
                return type === Type.page
            case Type.navBar:
                return type === Type.navBarItem
            case Type.navBarItem:
                return type === Type.navBarItem
            case Type.playlist:
                return type === Type.playlistSection
            case Type.playlistSection:
                return type === Type.video
            case Type.page:
            case Type.container:
            case Type.carousel:
            case Type.footer:
                return ContentTypes.indexOf(type) !== -1
        }
        return false
    }

    static canCopy = (type: Type): boolean => {
        switch (type) {
            case Type.site:
            case Type.footer:
            case Type.navBar:
            case Type.theme:
                return false
        }
        return true
    }

    static copy = (model: Model | undefined): Model | undefined => {
        if (model) {
            var copy: Model = JSON.parse(JSON.stringify(model))
            copy = this.resetIds(copy)
            return copy
        }
        return undefined
    }

    static canDelete = (model: Model): boolean => {
        switch (model.type) {
            case Type.site:
            case Type.theme:
            case Type.navBar:
            case Type.footer:
                return false
            case Type.page:
                return (model as Page).path !== '/'
        }
        return true;
    }

    static resetIds = (model: Model): Model => {
        model.id = uuid()
        this.itemsInModel(model).forEach(this.resetIds)
        return model
    }

    static templateIds = (model: Model, isTopLevel: boolean): Model => {
        var newModel = Object.assign(new Model(), model)
        newModel.id = isTopLevel ? "{{Id}}" : "{{RandomId}}"
        this.itemsInModel(newModel).forEach(item => this.templateIds(item, false))
        return newModel
    }
}
import { APIService } from '.';
import { PatreonCampaign, Creator, CreatorDomain, CreatorReferral } from '../Models/Admin/Creator';
import { AddCreatorFormValues } from '../Models/Admin/Creator';
import {CompactUser} from '../Models/API';
import { SocialsLink } from '../Models/Site';
import {SwappableSplToken} from "../Models/API/Spl/SwappableToken";

export class CreatorService {
    static basePath = "/creator"

    // Creators
    public static getAllCreators() {
        return APIService.requests.get<Creator[]>(`${this.basePath}/GetAllCreators`);
    }
    public static getCreatorById(creatorId: string) {
        return APIService.requests.get<Creator>(`${this.basePath}/GetCreatorById?creatorId=${creatorId}`);
    }
    public static getCreatorByUserId(userId: string) {
        return APIService.requests.get<Creator>(`${this.basePath}/GetCreatorByUserId?userId=${userId}`);
    }
    public static getCreatorBySiteId(siteId: string) {
        return APIService.requests.get<Creator>(`${this.basePath}/GetCreatorBySiteId?siteId=${siteId}`);
    }
    public static getCreatorCampaigns(siteId: string) {
        return APIService.requests.get<PatreonCampaign>(`${this.basePath}/GetPatreonCampaign?siteId=${siteId}`);
    }
    public static addCreator(userId: string, creatorFormValues: AddCreatorFormValues) {
        return APIService.requests.post<Creator>(`${this.basePath}/AddCreator?userId=${userId}`, creatorFormValues);
    }
    public static deleteCreator(creatorId: string) {
        return APIService.requests.del<Creator>(`${this.basePath}/DeleteCreator?creatorId=${creatorId}`);
    }

    // Staff
    public static getCreatorStaff(creatorId: string) {
        return APIService.requests.get<CompactUser[]>(`${this.basePath}/GetCreatorStaff?creatorId=${creatorId}`);
    }
    public static addCreatorStaff(creatorId: string, userId: string) {
        return APIService.requests.post<CompactUser[]>(`${this.basePath}/AddCreatorStaff?creatorId=${creatorId}&userId=${userId}`, {})
    }
    public static removeCreatorStaff(creatorId: string, userId: string) {
        return APIService.requests.del<CompactUser[]>(`${this.basePath}/RemoveCreatorStaff?creatorId=${creatorId}&userId=${userId}`)
    }

    // Domains 
    public static getCreatorDomains(creatorId: string) {
        return APIService.requests.get<CreatorDomain[]>(`${this.basePath}/GetCreatorDomains?creatorId=${creatorId}`);
    }
    public static addCreatorDomain(creatorId: string, domain: string) {
        return APIService.requests.post<CreatorDomain[]>(`${this.basePath}/AddCreatorDomain?creatorId=${creatorId}&domain=${domain}`, {})
    }
    public static removeCreatorDomain(creatorId: string, domain: string) {
        return APIService.requests.del<CreatorDomain[]>(`${this.basePath}/RemoveCreatorDomain?creatorId=${creatorId}&domain=${domain}`)
    }

    // Referrals
    public static getCreatorReferrals(creatorId: string) {
        return APIService.requests.get<CreatorReferral[]>(`${this.basePath}/GetCreatorReferrals?creatorId=${creatorId}`);
    }

    // Socials
    public static getSocials(creatorId: string) {
        return APIService.requests.get<SocialsLink[]>(`${this.basePath}/GetSocials?creatorId=${creatorId}`);
    }
    public static addSocial(creatorId: string, type: string, url: string) {
        return APIService.requests.post<SocialsLink[]>(`${this.basePath}/AddSocial?creatorId=${creatorId}&type=${type}&url=${url}`, {})
    }
    public static removeSocial(creatorId: string, socialId: string) {
        return APIService.requests.del<SocialsLink[]>(`${this.basePath}/RemoveSocial?creatorId=${creatorId}&socialId=${socialId}`)
    }

    // Spl Tokens

    public static getSwappableTokens(creatorId: string) {
        return APIService.requests.get<SwappableSplToken[]>(`${this.basePath}/GetSwappableTokens?creatorId=${creatorId}`)
    }
    public static setSwappableTokens(creatorId: string, tokenIds: SwappableSplToken[]) {
        return APIService.requests.post<SwappableSplToken[]>(`${this.basePath}/SetSwappableTokens?creatorId=${creatorId}`, tokenIds)
        // TODO: Sends the list of ALL swappable tokens the creator has selected to the API
    }
}

import { action, makeAutoObservable } from "mobx";
import { ChangeEvent } from "react";
import { AdminUser } from "../Models/Admin";
import { Creator, CreatorReferral } from "../Models/Admin/Creator";
import { EditUserFormValues } from "../Models/Admin";
import { CollectionData, CollectionDataCard, CollectionDataCardDetail, CollectionKey } from "../Models/API/Collections";
import { AdminService, CreatorService } from "../Services";
import {AdminCreateCollection} from "../Models/Admin/Collection";
import {SplToken, TokenBondingCurveToken} from "../Models/API/Spl";

export class AdminStore {
    userList: AdminUser[] | undefined;
    editUser: AdminUser | undefined;
    searchType: string = "ByUserName";
    searchString: string = "";
    //Creators
    creatorList: Creator[] | undefined;
    selectedCreator: Creator | undefined;
    referrals: CreatorReferral[] | undefined;
    //Collections
    collectionList: CollectionData[] | undefined;
    selectedCollection: CollectionData | undefined;
    collectionCardList: CollectionDataCard[] | undefined;
    selectedCollectionCard: CollectionDataCard | undefined;
    collectionCardDetailList: CollectionDataCardDetail[] | undefined;
    collectionKeys: CollectionKey[] | undefined;
    //Tokens
    splTokens: SplToken[] | undefined;
    selectedSplToken: SplToken | undefined;
    tbcTokens: TokenBondingCurveToken[] | undefined;
    selectedTbcToken: TokenBondingCurveToken | undefined;

    constructor() {
        makeAutoObservable(this)
    }

    //Admin Creators
    @action getCreators = async () => {
        const creators = await AdminService.GetAllCreators();
        this.setCreators(creators);
    }
    @action getCreator = async (creatorId: string) => {
        const creator = await AdminService.GetCreator(creatorId);
        this.selectCreator(creator);
    }

    @action setCreators = (creators: Creator[] | undefined) => {
        this.creatorList = creators;
    }

    @action selectCreator = (creator: Creator | undefined) => {
        this.selectedCreator = creator;
        this.getReferrals();
    }

    @action setReferrals = (referrals: CreatorReferral[]) => {
        this.referrals = referrals;
    }

    @action getReferrals = async () => {
        if (this.selectedCreator) {
            const response = await CreatorService.getCreatorReferrals(this.selectedCreator.id);
            this.setReferrals(response);

        } else {
            this.setReferrals([])
            throw new Error('No creator')
        }
    }

    //Admin Users
    @action getAllUsers = async () => {
        const allUsers = await AdminService.GetAllUsers();
        this.setUsers(allUsers);
    }

    @action setUsers = (users: AdminUser[] | undefined) => {
        this.userList = users;
    }

    @action getUser = async (id: string) => {
        const user = await AdminService.GetUser(id);
        return this.setUser(user);
    }

    @action setUser = (user: AdminUser) => {
        this.editUser = undefined;
        this.editUser = user;
    }

    //Search

    @action setSearchType = (searchType: string) => {
        this.searchType = searchType;
    }

    @action changeSearchType = (event: ChangeEvent<HTMLInputElement>) => {
        this.setSearchType((event.target as HTMLInputElement).value);
    }

    @action SearchUser = async (searchType: string, searchString: string) => {
        const searchedUsers = await AdminService.SearchUsers(searchType, searchString);
        return this.setUsers(searchedUsers);
    }

    @action setSearchString = (searchString: string) => {
        this.searchString = searchString;
    }

    @action changeSearchString = (event: ChangeEvent<HTMLInputElement>) => {
        this.setSearchString((event.target as HTMLInputElement).value);
    }

    //Edit User

    @action updateUser = async (values: EditUserFormValues) => {
        try {
            // var valuesJson = JSON.stringify(values);
            const edittedUser = await AdminService.UpdateUser(values);
            return this.setUser(edittedUser);

        } catch (error) {
            throw error;
        }
    }

    @action setCreatedDate = async (newValue: Date | null | undefined) => {
        if (newValue && this.editUser) {
            this.editUser.created = newValue.toDateString();
        }

    }

    @action confirmEmail = async (userId: string) => {
        try {
            const response = await AdminService.ConfirmEmail(userId);
            if (response) {
                return this.getUser(userId);
            }
            else {
                throw new Error("Email not Confirmed");
            }
        } catch (error) {
            throw error;
        }
    }
    @action sendPasswordReset = async (userId: string) => {
        try {
            const response = await AdminService.SendPasswordReset(userId);
            if (response) {
                return this.getUser(userId);
            }
            else {
                throw new Error("Password reset not Sent");
            }
        } catch (error) {
            throw error;
        }
    }
    @action makeAdmin = async (userId: string) => {
        try {
            const response = await AdminService.MakeAdmin(userId);
            if (response) {
                return this.setUser(response);
            }
            else {
                throw new Error("User Cannot be Made Admin");
            }
        } catch (error) {
            throw error;
        }
    }
    @action removeAdmin = async (userId: string) => {
        try {
            const response = await AdminService.RemoveAdmin(userId);
            if (response) {
                return this.setUser(response);
            }
            else {
                throw new Error("User Cannot be Made Admin");
            }
        } catch (error) {
            throw error;
        }
    }

    @action deleteUser = async (userId: string) => {
        try {
            await AdminService.DeleteUser(userId);
            this.editUser = undefined;
        } catch (error) {
            throw error;
        }
    }

    @action changeReferralCode = async (userId: string, newCode: string) => {
        try {
            await AdminService.ChangeReferralCode(userId, newCode);
        } catch (error) {
            throw error;
        }
    }

    //Rewards

    @action checkRewards = async (userId: string) => {
        try {
            await AdminService.CheckRewards(userId);
        } catch (error) {
            throw error;
        }
    }



    //Admin Data

    //Collections


    @action getCollections = async () => {
        const collections = await AdminService.GetAllCollections();
        this.setCollections(collections);
    }

    @action setCollections = (collections: CollectionData[] | undefined) => {
        this.collectionList = collections;
    }

    @action selectCollection = (collection: CollectionData | undefined) => {
        this.selectedCollection = collection;
        this.collectionCardList = collection?.cards;
        if (collection != null) {
            this.getCollectionKeys(collection.id);
        }
    }

    @action selectCollectionCard = (collectionCard: CollectionDataCard | undefined) => {
        this.selectedCollectionCard = collectionCard;
        this.collectionCardDetailList = collectionCard?.details;
    }

    @action getCollectionKeys = async (collectionId: string) => {
        const keys = await AdminService.GetCollectionKeys(collectionId);
        this.setCollectionKeys(keys);
    }

    @action setCollectionKeys = (keys: CollectionKey[] | undefined) => {
        this.collectionKeys = keys;
    }

    @action AddCollectionToCreator = async (creatorId: string, collectionId: string) => {
        await AdminService.AddCollectionToCreator(creatorId, collectionId);
        await this.getCollections();
    }
    @action RemoveCollectionFromCreator = async (creatorId: string, collectionId: string) => {
        await AdminService.RemoveCollectionFromCreator(creatorId, collectionId);
        await this.getCollections();
    }
    @action createCollection = async (collectionName: string, collectionTitle: string, collectionApi: string) => {
        const collection = new AdminCreateCollection();
        collection.name = collectionName;
        collection.title = collectionTitle;
        collection.collectionApi = collectionApi;
        await AdminService.CreateCollection(collection);
    }

    //AllieCats

    @action buildAllieCats = async () => {
        await AdminService.BuildAllieCats();
    }

    //Tokens

    @action getSplTokens = async () => {
        const tokens = await AdminService.GetAllSplTokens();
        this.setSplTokens(tokens);
    }
    @action setSplTokens = (splTokens:SplToken[] | undefined) => {
        this.splTokens = splTokens;
    }
    @action createSplToken = async (splKey:string, splName:string, splTicker:string) => {
        await AdminService.CreateSplToken(splKey, splName, splTicker);
        await this.getSplTokens();
    }
    @action deleteSplToken = async (splKey:string | undefined) => {
        if(splKey){
            await AdminService.DeleteSplToken(splKey);
            await this.getSplTokens();
        }
    }
    @action selectSplToken = (splToken: SplToken | undefined) => {
        this.selectedSplToken = splToken;
    }

    @action getTbcTokens = async () => {
        const tokens = await AdminService.GetTbcTokens();
        this.setTbcTokens(tokens);
    }
    @action setTbcTokens = (tbcTokens:TokenBondingCurveToken[] | undefined) => {
        this.tbcTokens = tbcTokens;
    }
    @action createTbcToken = async (tbcKey:string, token1Key:string, token2Key:string) => {
        await AdminService.CreateTbcToken(tbcKey, token1Key, token2Key);
        await this.getTbcTokens();
    }
    @action deleteTbcToken = async (tbcKey:string | undefined) => {
        if(tbcKey){
            await AdminService.DeleteTbcToken(tbcKey);
            await this.getTbcTokens();
        }
    }
    @action selectTbcToken = (tbcToken: TokenBondingCurveToken | undefined) => {
        this.selectedTbcToken = tbcToken;
    }

}
import { Stack, Typography, Button } from "@mui/material"
import ReportIcon from '@mui/icons-material/Report';
import { useStore } from "../../Stores";
import { observer } from "mobx-react-lite";

export const AccessDenied = observer(() => {
    const { modalStore } = useStore();
    return (
        <Stack direction='column' alignItems='center' spacing={2}>
            <ReportIcon color='primary' sx={{ width: 75, height: 75 }} />
            <Typography variant='h6'>
                You do not have permission to access this page.
            </Typography>
            <Button variant='outlined' onClick={modalStore.closeModal}>Return Home</Button>
        </Stack>
    )
});
import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { CollectionKey } from "../../Models/API/Collections";
import { useStore } from "../../Stores";

class TemplateOption {
    id: string;
    label: string;

    constructor(collectionKey: CollectionKey) {
        this.id = collectionKey.name
        this.label = collectionKey.name
    }
}

interface Props {
    currentKey?: string;
    collectionId: string;
    onSelect: (key: string) => void;
}

export const TemplateKeyPicker = observer(({ currentKey = '', collectionId, onSelect }: Props) => {
    const { collectionStore, modalStore } = useStore();
    const keys = collectionStore.collectionKeys[collectionId]

    const regex = /{{(.+)}}/i;
    const matches = currentKey.match(regex)
    const trueKey = matches ? matches[1] : ''
    const [selectedKey, setSelectedKey] = React.useState(trueKey)

    if (!keys) {
        collectionStore.getCollectionKeys(collectionId)
        return <CircularProgress color="inherit" />
    }

    const onClose = () => {
        modalStore.closeModal()
    }

    const options = keys.map((key) => new TemplateOption(key))
    return (
        <Dialog onClose={onClose} open>
            <Box sx={{ width: '320px' }}>
                <DialogTitle>Select parameter to use</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        id="key"
                        value={options.find(o => o.id === selectedKey) ?? null}
                        onChange={(_, newValue) => setSelectedKey(newValue ? newValue.id : '')}
                        options={options}
                        isOptionEqualToValue={(a, b) => a.id === b.id}
                        renderInput={(params) => (<TextField margin="dense" {...params} label="Key" />)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={onClose}>Cancel</Button>
                    <Button variant='contained' disabled={selectedKey === ''} onClick={() => {
                        onSelect(selectedKey)
                        onClose()
                    }}>Select</Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
});
import {observer} from "mobx-react-lite";
import * as React from 'react';
import {useStore} from "../../Stores";
import {useEffect, useState} from "react";
import {LoadingComponent} from "../Util";
import {
    Box,
    Button,
    Card,
    CardHeader,
    Checkbox, Grid, Input, InputAdornment,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
} from "@mui/material";
import {Search, SwapHoriz} from "@mui/icons-material";

export const CreatorTrading = observer(() => {
    const {creatorStore} = useStore();
    const {toggleSwappable,loading,setSwappableTokens, swappableTokens, getCurrentSwappableTokens
    } = creatorStore;

    //This is for the Searchbar
    const [searchTerm, setSearchTerm] = useState('')

    const fetchData = () => {
        if(!loading) {
            return
        }
        getCurrentSwappableTokens();
    };

    useEffect(() => {
        fetchData();
    }, []);

    if(!loading && swappableTokens
    ) {
        return (
            <>
                <Stack
                    direction='column'
                    alignItems='center'
                >
                    <Card
                        sx={{
                        mt: 5,
                        maxWidth: '1100px',
                        minWidth: '450px',
                        maxLength: 'auto'
                    }}>
                        <Grid container
                              direction='row'
                              justifyContent='space-between'
                        >
                            <Grid item >
                                <CardHeader
                                    avatar={<SwapHoriz color={'primary'}/>}
                                    title="Swappable Currency"
                                />
                            </Grid>
                            <Grid item>
                                <Box
                                    sx={{
                                        mx: 2,
                                        mt: 1
                                }}>
                                    <Input
                                        placeholder="Search..."
                                        onChange={event => {setSearchTerm(event.target.value)}}
                                        startAdornment={
                                            <InputAdornment position="start" >
                                                <Search />
                                            </InputAdornment>}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container
                              direction='row'
                              justifyContent='left'
                              alignItems='left'
                              sx={{
                                  p:0,
                                  m:0,
                              }}>
                            {swappableTokens.filter((token)=> {
                                    if (searchTerm == "") {
                                        return token
                                    } else if (token.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                                        return token
                                    }
                                }
                            ).map((token) => {
                                const labelId = token.key;
                                return (
                                    <Grid item
                                          sx={{
                                              p:0,
                                              m:0,
                                              width:150
                                    }}>
                                        <ListItem
                                            key={token.key}
                                            disablePadding
                                        >
                                            <ListItemButton role={undefined} onClick={() => toggleSwappable(token)} dense>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={token.swappable}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={token.name} />
                                            </ListItemButton>
                                        </ListItem>
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <Box
                            mb={3}
                            mt={1}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Button
                                onClick = {() => {
                                  setSwappableTokens(swappableTokens);
                                }
                                } variant="contained"
                            >
                                Save
                            </Button>
                        </Box>
                    </Card>
                </Stack>
            </>
        );
    } else {
        return <LoadingComponent/>
    }
});

import { PublicKey } from "@solana/web3.js";
import { AccountLayout } from "@solana/spl-token";

export class TokenAccountInfo {
  mint: PublicKey;
  owner: PublicKey;
  amount: number;
  constructor(data: Buffer) {
    const accountInfo = AccountLayout.decode(data);
    this.mint = accountInfo.mint;
    this.owner = accountInfo.owner;
    this.amount = Number(accountInfo.amount);
  }
}

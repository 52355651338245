import { makeAutoObservable, reaction } from "mobx";
import { CommonStore, Store } from ".";
import { history } from "../App";
import { AccessCode, User, Token, UserFormValues, ChangePasswordForm, ActiveReferral } from "../Models/API";
import { Service } from "../Models/Site";
import { AccountService, Cookie, CookieService } from "../Services";
import { UserService } from "../Services/UserService";
import jwtDecode from "jwt-decode";

interface Permissions {
    role: string[];
    StaffCreatorSiteId: string[]
}

export class AccountStore {
    commonStore: CommonStore;
    user?: User;
    referrals: ActiveReferral[] | undefined;
    token: string | undefined = CookieService.getCookie(Cookie.token);

    get decodedToken() {
        if (this.token) {
            return jwtDecode<Permissions>(this.token);
        }
        return undefined
    }

    get isEditor(): boolean {
        if (this.decodedToken && this.commonStore.currentSite && this.decodedToken.StaffCreatorSiteId) {
            return this.decodedToken.StaffCreatorSiteId.indexOf(this.commonStore.currentSite.id) !== -1
        }
        return false
    }
    get isCreator(): boolean {
        return this.hasRole('Creator')
    }
    get isAdmin(): boolean {
        return this.hasRole('Admin')
    }

    hasRole = (role: string): boolean => {
        if (this.decodedToken && this.decodedToken.role) {
            return this.decodedToken.role.indexOf(role) !== -1
        }
        return false
    }

    constructor(store: Store) {
        this.commonStore = store.commonStore;
        makeAutoObservable(this)
        reaction(
            () => this.token,
            token => {
                if (token) {
                    CookieService.setCookie(Cookie.token, token);
                } else {
                    CookieService.deleteCookie(Cookie.token);
                }
            }
        )
    }

    get isLoggedIn() {
        return this.token !== undefined;
    }

    setToken = (token: Token | undefined) => {
        if (token) {
            this.token = token.token;
            CookieService.setCookie(Cookie.token, token.token);
        } else {
            this.token = undefined;
            CookieService.deleteCookie(Cookie.token);
        }
    }

    setUser = (user: User | undefined) => {
        this.user = user;
    }

    setReferrals = (activeReferrals: ActiveReferral[] | undefined) => {
        this.referrals = activeReferrals
    }

    login = async (creds: UserFormValues, recaptchaCode: string) => {
        try {
            const token = await AccountService.login(creds, recaptchaCode);
            this.setToken(token);
            this.getUser();

            if (this.commonStore.currentDomain) {
                this.commonStore.loadSite(this.commonStore.currentDomain)
            }
        } catch (error) {
            throw error;
        }
    }

    logout = async () => {
        this.setToken(undefined);
        this.setUser(undefined);
        CookieService.deleteCookie(Cookie.token)

        if (this.commonStore.currentDomain) {
            this.commonStore.loadSite(this.commonStore.currentDomain)
        }
        history.push('/')
    }

    refreshToken = async () => {
        try {
            const token = await AccountService.current();
            this.setToken(token)
        } catch (error) {
            throw error;
        }
    };

    getUser = async () => {
        try {
            const user = await UserService.getUser();
            this.setUser(user)
        } catch (error) {
            throw error;
        }
    }

    deleteUser = async () => {
        try {
            await UserService.deleteUser();
            this.logout();
        } catch (error) {
            throw error;
        }
    }

    private creatorDomain = () => {
        const value = CookieService.getCookie(Cookie.creatorDomain)
        return value ?? window.location.host
    }

    register = async (creds: UserFormValues, referralCode: string, recaptchaCode: string) => {
        try {
            const token = await AccountService.register(creds, referralCode, this.creatorDomain(), recaptchaCode);
            this.setToken(token);
            this.getUser();
        } catch (error) {
            throw error;
        }
    }

    unlinkService = async (service: Service) => {
        try {
            const newUser =  await AccountService.unlinkService(service)
            this.setUser(newUser);
        } catch (error) {
            throw error
        }
    }

    externalRegister = async (service: string, code: string, recaptchaCode: string) => {
        try {
            const referral = CookieService.getCookie(Cookie.referral) ?? "";
            const response = await AccountService.externalRegister(new AccessCode(code), service, this.creatorDomain(), referral, recaptchaCode);
            this.setToken(response);
            this.getUser();
        } catch (error) {
            throw error;
        }
    }

    externalLogin = async (service: string, code: string, recaptchaCode: string) => {
        try {
            const response = await AccountService.externalLogin(new AccessCode(code), service, this.creatorDomain(), recaptchaCode);
            this.setToken(response);
            this.getUser();
        } catch (error) {
            throw error;
        }
    }

    externalConnect = async (service: string, code: string) => {
        try {
            await AccountService.externalConnect(new AccessCode(code), service, this.creatorDomain());
            this.getUser();
        } catch (error) {
            throw error
        }
    }

    generatePasswordReset = async (email: string) => {
        try {
            await AccountService.generatePasswordReset(email)
        } catch (error) {
            throw error
        }
    }

    changePassword = async (form: ChangePasswordForm) => {
        try {
            await AccountService.changePassword(form)
        } catch (error) {
            throw error
        }
    }

    generateConfirmEmail = async () => {
        try {
            await AccountService.generateConfirmationEmail()
        } catch (error) {
            throw error
        }
    }

    confirmEmail = async (userId: string, token: string) => {
        try {
            await AccountService.confirmEmail(userId, token)
            this.getUser();
        } catch (error) {
            throw error
        }
    }

    unsubscribe = async (userId: string) => {
        try {
            await AccountService.unsubscribe(userId)
            this.getUser();
        } catch (error) {
            throw error
        }
    }

    getActiveReferrals = async () => {
        try {
            const referrals = await UserService.activeReferrals()
            this.setReferrals(referrals);
        } catch (error) {
            throw error
        }
    }
}
import { Stack } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ElementRenderer } from ".";
import { Footer as FooterModel } from "../../Models/Site"

interface Props {
    data: FooterModel
}

export const Footer = observer(({ data }: Props) => {
    return (
        <Stack>
            {data.content.map(e => ElementRenderer.build({ model: e, parent: data }))}
        </Stack>
    )
});
import {observer} from "mobx-react-lite";
import {HTML as HTMLModel} from "../../../Models/Site";
import {CMSEditButtons, CMSWidthField} from "../Components";
import {Stack, TextField, Typography} from "@mui/material";
import {useStore} from "../../../Stores";

interface Props {
    data: HTMLModel;
}

export const CMSHTML = observer(({data}: Props) => {
    const {cmsStore} = useStore();
    const {working_model} = cmsStore;
    let isEditing = cmsStore.editedItemId === data.id;
    let dataToUse: HTMLModel = isEditing ? (working_model as HTMLModel) : data
    return (
        <Stack direction="column" spacing={2} justifyContent="space-between">
            <CMSEditButtons
                id={data.id}
                index={data.index}
            />
            <h5>ID: {data.id}</h5>
            <Typography>This Component is for advanced users only and can cause site rendering issues.</Typography>
            <TextField
                id="name"
                label="Name"
                value={dataToUse.name}
                onChange={(e) => cmsStore.updateHTML({name: e.target.value})}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <CMSWidthField
                value={dataToUse.width.toString()}
                onChange={(newValue) => cmsStore.updateHTML({width: +(newValue)})}
                disabled={!isEditing}
            />
            <TextField
                id="rawhtml"
                label="HTML"
                value={dataToUse.rawhtml}
                onChange={(e) => cmsStore.updateHTML({rawhtml: e.target.value})}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
        </Stack>
    )
})

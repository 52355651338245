import { makeAutoObservable } from "mobx";
import { v4 as uuid } from "uuid";
import { Type, Content } from "..";

export class IFrame implements Content {
    id: string = uuid();
    name: string = "IFrame";
    type: Type = Type.iFrame;
    index: number = 0;
    width: number = 12;

    title: string = "New IFrame"
    url: string = "https://nodia.space";
    
    constructor() {
        makeAutoObservable(this)
    }
}

export interface IFrameUpdater {
    name?: string;
    width?: number;
    title?: string;
    url?: string;
}

import { makeAutoObservable } from "mobx";
import { v4 as uuid } from "uuid";
import { Type, Content } from ".."

export class Carousel implements Content {
    id: string = uuid();
    name: string = "Carousel";
    type: Type = Type.carousel; 
    index: number = 0;
    width: number = 12;
    content: Content[] = [];

    constructor() {
        makeAutoObservable(this)
    }
}

export interface CarouselUpdater {
    name?: string;
    width?: number;
}
import BN from "bn.js";

export class SolanaMaths {
    static baseToDec (price: number, dec: number) {
        return new BN(price * Math.pow(10, dec));
    }

    static decToBase (decVal: number, dec: number) {
        return decVal / Math.pow(10, dec)
    }

}

import { action, computed, makeObservable, observable, } from "mobx"
import { Theme as MUITheme } from "@mui/material";
import { SiteService } from "../Services";

import {
    Site, SiteUpdater,
    Theme, ThemeUpdater, PaletteUpdater, TypographyUpdater,
    Page, PageUpdater, PageConditionUpdater,
    NavBar, NavBarUpdater,
    NavBarItem, NavBarItemUpdater,
    Container, ContainerUpdater,
    Header, HeaderUpdater,
    Footer, FooterUpdater,
    Text, TextUpdater,
    Image, ImageUpdater,
    Playlist, PlaylistUpdater, PlaylistSection, PlaylistSectionUpdater, Video, VideoUpdater,
    Carousel, CarouselUpdater,
    IFrame, IFrameUpdater,
    Socials, SocialsUpdater,
    Button, ButtonUpdater,
    Model, Content, Type,
    CollectionUpdater, Collection,
    RallySwap, RallySwapUpdater,
    HTML, HTMLUpdater, ButtonVariant, ButtonColor
} from '../Models/Site';
import { ThemeRenderer } from '../Views/Site';
import { Store, CMSNavBarStore, CommonStore } from ".";

export class CMSStore {
    @observable data: Site = new Site();
    @observable theme: MUITheme = ThemeRenderer.default();
    @observable working_model?: Model | undefined;
    @observable hasChanges: boolean = false;
    @observable private editingId?: string | undefined;
    @observable private selectedId: string | undefined;

    private commonStore: CommonStore;
    private cmsNavBarStore: CMSNavBarStore;

    constructor(store: Store) {
        this.commonStore = store.commonStore;
        this.cmsNavBarStore = store.cmsNavBarStore;
        makeObservable(this)
    }

    // Data

    @action setData = (data: Site) => {
        this.data = data;
        this.theme = ThemeRenderer.create(data.theme);
        this.select(data.id)
    }

    @action setCollectionTemplateData = (data: Content) => {
        const fakeSite = new Site()
        if (this.commonStore.currentSite) {
            fakeSite.theme = this.commonStore.currentSite.theme
        }
        const page = new Page()
        page.elements.push(data)
        fakeSite.pages.push(page)
        this.setData(fakeSite)
    }

    @action setHasChanges = (changes: boolean) => {
        this.hasChanges = changes
    }

    // Edited Item

    @action setEditing = (id: string, editing: boolean) => {
        this.editingId = editing ? id : undefined
        this.working_model = editing ? JSON.parse(JSON.stringify(this.editedItem)) : undefined;
        this.theme = ThemeRenderer.create(this.data.theme);
    }

    @computed get editedItemId(): string | undefined {
        return this.editingId;
    }

    @computed get editedItem(): Model | undefined {
        if (this.editingId) {
            return this.getComponent(this.editingId);
        }
        return undefined
    }

    // Selected Item

    @action select = (id: string) => {
        this.selectedId = id;
    }

    @computed get selectedItemId(): string | undefined {
        return this.selectedId;
    }

    @computed get selectedItem(): Model | undefined {
        if (this.selectedId) {
            return this.getComponent(this.selectedId);
        }
        return undefined
    }

    // Site
    @action updateSite = (changes: SiteUpdater) => {
        let component = this.working_model as Site;
        if (component) {
            if (changes.name !== undefined) {
                component.name = changes.name
            }
            if (changes.description !== undefined) {
                component.description = changes.description
            }
        }
    }

    @action saveSite = () => {
        let changes = this.working_model as Site;
        if (changes) {
            let component = this.getComponent(changes.id) as Site;
            component.name = changes.name;
            component.description = changes.description;
            this.setEditing(changes.id, false);
        }
    }

    // Theme

    @action updateTheme = (changes: ThemeUpdater) => {
        let component = this.working_model as Theme
        if (component) {
            if (changes.name !== undefined) {
                component.name = changes.name
            }
            this.theme = ThemeRenderer.create(component);
        }
    }

    @action saveTheme = () => {
        let changes = this.working_model as Theme;
        if (changes) {
            let component = this.getComponent(changes.id) as Theme;
            component.name = changes.name;
            component.palette = changes.palette;
            component.typography = changes.typography;
            this.setEditing(changes.id, false);
        }
    }

    @action updatePalette = (changes: PaletteUpdater) => {
        let component = this.working_model as Theme
        if (component) {
            if (changes.mode !== undefined) {
                component.palette.mode = changes.mode
            }
            if (changes.primaryMain !== undefined) {
                component.palette.primary.main = changes.primaryMain
            }
            if (changes.primaryContrast !== undefined) {
                component.palette.primary.contrastText = changes.primaryContrast
            }
            if (changes.secondaryMain !== undefined) {
                component.palette.secondary.main = changes.secondaryMain
            }
            if (changes.secondaryContrast !== undefined) {
                component.palette.secondary.contrastText = changes.secondaryContrast
            }
            if (changes.textPrimary !== undefined) {
                component.palette.text.primary = changes.textPrimary
            }
            if (changes.textSecondary !== undefined) {
                component.palette.text.secondary = changes.textSecondary
            }
            if (changes.backgroundPaper !== undefined) {
                component.palette.background.paper = changes.backgroundPaper
            }
            if (changes.backgroundDefault !== undefined) {
                component.palette.background.default = changes.backgroundDefault
            }
            this.theme = ThemeRenderer.create(component);
        }
    }

    @action updateTypography = (changes: TypographyUpdater) => {
        let component = this.working_model as Theme
        if (component) {
            if (changes.fontFamily !== undefined) {
                component.typography.fontFamily = changes.fontFamily
            }
            this.theme = ThemeRenderer.create(component);
        }
    }

    // Nav Bar

    @action updateNavBar = (changes: NavBarUpdater) => {
        let component = this.working_model as NavBar;
        if (component) {
            if (changes.name !== undefined) {
                component.name = changes.name
            }
        }
    }

    @action saveNavBar = () => {
        let changes = this.working_model as NavBar;
        if (changes) {
            let component = this.getComponent(changes.id) as NavBar;
            component.name = changes.name;
            this.setEditing(changes.id, false);
        }
    }

    @action updateNavBarItem = (changes: NavBarItemUpdater) => {
        let component = this.working_model as NavBarItem;
        if (component) {
            if (changes.name !== undefined) {
                component.name = changes.name
            }
            if (changes.path !== undefined) {
                component.path = changes.path
            }
            if (changes.text !== undefined) {
                component.text = changes.text
            }
        }
    }

    @action saveNavBarItem = () => {
        let changes = this.working_model as NavBarItem;
        if (changes) {
            let component = this.getComponent(changes.id) as NavBarItem;
            component.name = changes.name;
            component.path = changes.path;
            component.text = changes.text;
            this.setEditing(changes.id, false);
        }
    }

    // Pages

    @action updatePage = (changes: PageUpdater) => {
        let component = this.working_model as Page;
        if (component) {
            if (changes.name !== undefined) {
                component.name = changes.name;
            }
            if (changes.path !== undefined) {
                component.path = changes.path;
            }
        }
    }

    @action updatePageCondition = (changes: PageConditionUpdater) => {
        let component = this.working_model as Page;
        if (component) {
            if (changes.hasConditions !== undefined) {
                component.conditions.hasConditions = changes.hasConditions
            }
            if (changes.twitch !== undefined) {
                component.conditions.twitch = changes.twitch;
            }
            if (changes.patreon !== undefined) {
                component.conditions.patreon = changes.patreon;
            }
            if (changes.rally !== undefined) {
                component.conditions.rally = changes.rally;
            }
        }
    }

    @action savePage = () => {
        let changes = this.working_model as Page;
        if (changes) {
            let component = this.getComponent(changes.id) as Page;
            component.name = changes.name;
            component.path = changes.path;
            component.conditions = changes.conditions;
            this.setEditing(changes.id, false);
        }
    }

    // MARK: - Content

    // Header

    @action updateHeader = (changes: HeaderUpdater) => {
        let component = this.working_model as Header;
        if (component) {
            if (changes.name !== undefined) {
                component.name = changes.name;
            }
            if (changes.fullScreen !== undefined) {
                component.fullScreen = changes.fullScreen
            }
            if (changes.backgroundImageId !== undefined) {
                component.backgroundImageId = changes.backgroundImageId;
            }
            if (changes.heading !== undefined) {
                component.heading = changes.heading;
            }
            if (changes.subHeading !== undefined) {
                component.subHeading = changes.subHeading;
            }
            if (changes.headingColor !== undefined) {
                component.headingColor = changes.headingColor;
            }
            if (changes.subHeadingColor !== undefined) {
                component.subHeadingColor = changes.subHeadingColor;
            }
        }
    }

    @action saveHeader = () => {
        let changes = this.working_model as Header;
        if (changes) {
            let component = this.getComponent(changes.id) as Header;
            component.name = changes.name;
            component.backgroundImageId = changes.backgroundImageId;
            component.heading = changes.heading;
            component.subHeading = changes.subHeading;
            component.fullScreen = changes.fullScreen;
            component.headingColor = changes.headingColor;
            component.subHeadingColor = changes.subHeadingColor;
            this.setEditing(changes.id, false);
        }
    }

    // Container

    @action updateContainer = (changes: ContainerUpdater) => {
        let component = this.working_model as Container;
        if (component) {
            if (changes.name !== undefined) {
                component.name = changes.name;
            }
            if (changes.fullScreen !== undefined) {
                component.fullScreen = changes.fullScreen
            }
            if (changes.color !== undefined) {
                component.color = changes.color;
            }
            if (changes.colorType !== undefined) {
                component.colorType = changes.colorType;
            }
            if(changes.colorCustom !== undefined) {
                component.colorCustom = changes.colorCustom;
            }
            if (changes.marginBottom !== undefined) {
                component.marginBottom = changes.marginBottom;
            }
            if (changes.cornerRadius !== undefined) {
                component.cornerRadius = changes.cornerRadius;
            }
            if (changes.padding !== undefined) {
                component.padding = changes.padding;
            }
            if (changes.spacing !== undefined) {
                component.spacing = changes.spacing;
            }
            if (changes.backgroundImageId !== undefined) {
                component.backgroundImageId = changes.backgroundImageId;
            }
            if (changes.direction !== undefined) {
                component.direction = changes.direction
            }
            if (changes.alignment !== undefined) {
                component.alignment = changes.alignment
            }
            if (changes.justification !== undefined) {
                component.justification = changes.justification
            }
            if (changes.width !== undefined) {
                component.width = changes.width
            }
        }
    }

    @action saveContainer = () => {
        let changes = this.working_model as Container;
        if (changes) {
            let component = this.getComponent(changes.id) as Container;
            component.name = changes.name;
            component.color = changes.color;
            component.colorType = changes.colorType;
            component.colorCustom = changes.colorCustom;
            component.marginBottom = changes.marginBottom;
            component.cornerRadius = changes.cornerRadius;
            component.padding = changes.padding;
            component.spacing = changes.spacing;
            component.backgroundImageId = changes.backgroundImageId;
            component.direction = changes.direction;
            component.alignment = changes.alignment;
            component.justification = changes.justification;
            component.width = changes.width;
            component.fullScreen = changes.fullScreen;
            this.setEditing(changes.id, false);
        }
    }

    // Text

    @action move = (id: string, index: number) => {
        let parent = this.getParent(id)
        let model = this.getComponent(id)
        if (model) {
            this.setHasChanges(true)
            switch (parent?.type) {
                case Type.site:
                    let site = parent as Site;
                    site.pages = this.moveToIndex(model, index, site.pages) as Page[];
                    break;
                case Type.navBar:
                    let navBar = parent as NavBar;
                    navBar.routes = this.moveToIndex(model, index, navBar.routes) as NavBarItem[];
                    break;
                case Type.navBarItem:
                    let navBarItem = parent as NavBarItem;
                    navBarItem.subRoutes = this.moveToIndex(model, index, navBarItem.subRoutes) as NavBarItem[];
                    break;
                case Type.page:
                    let page = parent as Page
                    page.elements = this.moveToIndex(model, index, page.elements) as Content[];
                    break;
                case Type.container:
                    let container = parent as Container
                    container.content = this.moveToIndex(model, index, container.content) as Content[];
                    break;
                case Type.carousel:
                    let carousel = parent as Carousel
                    carousel.content = this.moveToIndex(model, index, carousel.content) as Content[];
                    break;
                case Type.playlist:
                    let playlist = parent as Playlist
                    playlist.sections = this.moveToIndex(model, index, playlist.sections) as PlaylistSection[];
                    break;
                case Type.playlistSection:
                    let playlistSection = parent as PlaylistSection
                    playlistSection.videos = this.moveToIndex(model, index, playlistSection.videos) as Video[];
                    break;
                case Type.footer:
                    let footer = parent as Footer
                    footer.content = this.moveToIndex(model, index, footer.content) as Content[];
                    break;
            }
        }
    }

    @action duplicate = (id: string): string => {
        let parent = this.getParent(id)
        let model = this.getComponent(id)
        let copy = Model.copy(model);

        if (parent && model && copy) {
            this.addModel(copy, parent.id)
            this.move(copy.id, model.index + 1)
            return copy.id
        }
        return id
    }

    @action delete = (id: string): string => {
        let parent = this.getParent(id)
        switch (parent?.type) {
            case Type.site:
                let site = parent as Site;
                site.pages = this.deleteModel(id, site.pages) as Page[];
                break;
            case Type.navBar:
                let navBar = parent as NavBar;
                navBar.routes = this.deleteModel(id, navBar.routes) as NavBarItem[];
                break;
            case Type.navBarItem:
                let navBarItem = parent as NavBarItem;
                navBarItem.subRoutes = this.deleteModel(id, navBarItem.subRoutes) as NavBarItem[];
                break;
            case Type.page:
                let page = parent as Page
                page.elements = this.deleteModel(id, page.elements) as Content[];
                break;
            case Type.container:
                let container = parent as Container
                container.content = this.deleteModel(id, container.content) as Content[];
                break;
            case Type.carousel:
                let carousel = parent as Carousel
                carousel.content = this.deleteModel(id, carousel.content) as Content[];
                break;
            case Type.playlist:
                let playlist = parent as Playlist
                playlist.sections = this.deleteModel(id, playlist.sections) as PlaylistSection[];
                break;
            case Type.playlistSection:
                let playlistSection = parent as PlaylistSection
                playlistSection.videos = this.deleteModel(id, playlistSection.videos) as Video[];
                break;
            case Type.footer:
                let footer = parent as Footer
                footer.content = this.deleteModel(id, footer.content) as Video[];
                break;
        }
        this.setHasChanges(true)
        return parent?.id ?? ""
    }

    @action updateText = (changes: TextUpdater) => {
        let component = this.working_model as Text;
        if (component) {
            if (changes.name !== undefined) {
                component.name = changes.name
            }
            if (changes.variant !== undefined) {
                component.variant = changes.variant
            }
            if (changes.align !== undefined) {
                component.align = changes.align
            }
            if (changes.text !== undefined) {
                component.text = changes.text
            }
            if (changes.width !== undefined) {
                component.width = changes.width
            }
            if (changes.textColor !== undefined) {
                component.textColor = changes.textColor
            }
            if (changes.textColorCustom !== undefined) {
                component.textColorCustom = changes.textColorCustom
            }
        }
    }

    @action saveText = () => {
        let changes = this.working_model as Text;
        if (changes) {
            let component = this.getComponent(changes.id) as Text;
            component.name = changes.name;
            component.variant = changes.variant;
            component.align = changes.align;
            component.text = changes.text;
            component.width = changes.width;
            component.textColor = changes.textColor;
            component.textColorCustom = changes.textColorCustom;
            this.setEditing(changes.id, false);
        }
    }

    // Image

    @action updateImage = (changes: ImageUpdater) => {
        let component = this.working_model as Image;
        if (component) {
            if (changes.name !== undefined) {
                component.name = changes.name
            }
            if (changes.imageId !== undefined) {
                component.imageId = changes.imageId
            }
            if (changes.width !== undefined) {
                component.width = changes.width
            }
        }
    }

    @action saveImage = () => {
        let changes = this.working_model as Image;
        if (changes) {
            let component = this.getComponent(changes.id) as Image;
            component.name = changes.name;
            component.imageId = changes.imageId;
            component.width = changes.width;
            this.setEditing(changes.id, false);
        }
    }

    // Socials

    @action updateSocials = (changes: SocialsUpdater) => {
        let component = this.working_model as Socials;
        if (component) {
            if (changes.name !== undefined) {
                component.name = changes.name
            }
            if (changes.width !== undefined) {
                component.width = changes.width
            }
            if (changes.alignment !== undefined) {
                component.alignment = changes.alignment
            }
            if (changes.color !== undefined) {
                component.color = changes.color;
            }
            if (changes.hoverColor !== undefined) {
                component.hoverColor = changes.hoverColor;
            }
        }
    }

    @action saveSocials = () => {
        let changes = this.working_model as Socials;
        if (changes) {
            let component = this.getComponent(changes.id) as Socials;
            component.name = changes.name;
            component.width = changes.width;
            component.alignment = changes.alignment;
            component.color = changes.color;
            component.hoverColor = changes.hoverColor;
            this.setEditing(changes.id, false);
        }
    }

    // Button

    @action updateButton = (changes: ButtonUpdater) => {
        let component = this.working_model as Button;
        if (component) {
            if (changes.name !== undefined) {
                component.name = changes.name
            }
            if (changes.width !== undefined) {
                component.width = changes.width
            }
            if (changes.title !== undefined) {
                component.title = changes.title
            }
            if (changes.linkType !== undefined) {
                component.linkType = changes.linkType
            }
            if (changes.url !== undefined) {
                component.url = changes.url
            }
            if (changes.file !== undefined) {
                component.file = changes.file
            }
            if (changes.variant !== undefined) {
                component.variant = changes.variant
            }
            if (changes.color !== undefined) {
                component.color = changes.color
            }
            if(changes.colorCustom !== undefined) {
                component.colorCustom = changes.colorCustom
            }
            if(changes.textColor !== undefined) {
                component.textColor = changes.textColor
            }
            if(changes.textColorCustom !== undefined) {
                component.textColorCustom = changes.textColorCustom
            }

            if (changes.openInNewTab !== undefined) {
                component.openInNewTab = changes.openInNewTab
            }

            //Disallow custom colors for text variant
            if(changes.variant === ButtonVariant.text) {
                const workingModel = this.working_model as Button;
                if(workingModel.color === ButtonColor.custom)
                {
                    component.color = ButtonColor.primary;
                }
            }
        }
    }

    @action saveButton = () => {
        let changes = this.working_model as Button;
        if (changes) {
            let component = this.getComponent(changes.id) as Button;
            component.name = changes.name;
            component.width = changes.width;
            component.title = changes.title;
            component.linkType = changes.linkType;
            component.url = changes.url;
            component.file = changes.file;
            component.variant = changes.variant;
            component.color = changes.color;
            component.colorCustom = changes.colorCustom;
            component.textColor = changes.textColor;
            component.textColorCustom = changes.textColorCustom;
            component.openInNewTab = changes.openInNewTab
            this.setEditing(changes.id, false);
        }
    }

    // Carousel

    @action updateCarousel = (changes: CarouselUpdater) => {
        let component = this.working_model as Carousel;
        if (component) {
            if (changes.name !== undefined) {
                component.name = changes.name
            }
            if (changes.width !== undefined) {
                component.width = changes.width
            }
        }
    }

    @action saveCarousel = () => {
        let changes = this.working_model as Carousel;
        if (changes) {
            let component = this.getComponent(changes.id) as Carousel;
            component.name = changes.name;
            component.width = changes.width;
            this.setEditing(changes.id, false);
        }
    }

    // Collection

    @action updateCollection = (changes: CollectionUpdater) => {
        let component = this.working_model as Collection;
        if (component) {
            if (changes.name !== undefined) {
                component.name = changes.name
            }
            if (changes.width !== undefined) {
                component.width = changes.width
            }
            if (changes.collectionId !== undefined) {
                component.collectionId = changes.collectionId
            }
            if (changes.templateId !== undefined) {
                component.templateId = changes.templateId
            }
            if (changes.modalTemplateId !== undefined) {
                component.modalTemplateId = changes.modalTemplateId
            }
        }
    }

    @action saveCollection = () => {
        let changes = this.working_model as Collection;
        if (changes) {
            let component = this.getComponent(changes.id) as Collection;
            component.name = changes.name;
            component.width = changes.width;
            component.collectionId = changes.collectionId;
            component.templateId = changes.templateId;
            component.modalTemplateId = changes.modalTemplateId;
            this.setEditing(changes.id, false);
        }
    }

    // IFrame

    @action updateIFrame = (changes: IFrameUpdater) => {
        let component = this.working_model as IFrame;
        if (component) {
            if (changes.name !== undefined) {
                component.name = changes.name
            }
            if (changes.width !== undefined) {
                component.width = changes.width
            }
            if (changes.title !== undefined) {
                component.title = changes.title
            }
            if (changes.url !== undefined) {
                component.url = changes.url
            }
        }
    }

    @action saveIFrame = () => {
        let changes = this.working_model as IFrame;
        if (changes) {
            let component = this.getComponent(changes.id) as IFrame;
            component.name = changes.name;
            component.width = changes.width;
            component.title = changes.title;
            component.url = changes.url;
            this.setEditing(changes.id, false);
        }
    }

    // Playlist

    @action updatePlaylist = (changes: PlaylistUpdater) => {
        let component = this.working_model as Playlist;
        if (component) {
            if (changes.name !== undefined) {
                component.name = changes.name
            }
            if (changes.width !== undefined) {
                component.width = changes.width
            }
        }
    }

    @action savePlaylist = () => {
        let changes = this.working_model as Playlist;
        if (changes) {
            let component = this.getComponent(changes.id) as Playlist;
            component.name = changes.name;
            component.width = changes.width;
            this.setEditing(changes.id, false);
        }
    }

    // PlaylistSection

    @action updatePlaylistSection = (changes: PlaylistSectionUpdater) => {
        let component = this.working_model as PlaylistSection;
        if (component) {
            if (changes.name !== undefined) {
                component.name = changes.name
            }
            if (changes.width !== undefined) {
                component.width = changes.width
            }
            if (changes.title !== undefined) {
                component.title = changes.title
            }
        }
    }

    @action savePlaylistSection = () => {
        let changes = this.working_model as PlaylistSection;
        if (changes) {
            let component = this.getComponent(changes.id) as PlaylistSection;
            component.name = changes.name;
            component.width = changes.width;
            component.title = changes.title;
            this.setEditing(changes.id, false);
        }
    }
    // RallySwap

    @action updateRallySwap = (changes: RallySwapUpdater) => {
        let component = this.working_model as RallySwap;
        if (component) {
            if (changes.name !== undefined) {
                component.name = changes.name
            }
            if (changes.width !== undefined) {
                component.width = changes.width
            }
        }
    }

    @action saveRallySwap = () => {
        let changes = this.working_model as RallySwap;
        if (changes) {
            let component = this.getComponent(changes.id) as RallySwap;
            component.name = changes.name;
            component.width = changes.width;
            this.setEditing(changes.id, false);
        }
    }
    // Html

    @action updateHTML = (changes: HTMLUpdater) => {
        let component = this.working_model as HTML;
        if (component) {
            if (changes.name !== undefined) {
                component.name = changes.name
            }
            if (changes.width !== undefined) {
                component.width = changes.width
            }
            if(changes.rawhtml !== undefined){
                console.log(changes.rawhtml);
                component.rawhtml = changes.rawhtml
            }
        }
    }

    @action saveHTML = () => {
        let changes = this.working_model as HTML;
        if (changes) {
            let component = this.getComponent(changes.id) as HTML;
            component.name = changes.name;
            component.width = changes.width;
            component.rawhtml = changes.rawhtml;
            this.setEditing(changes.id, false);
        }
    }

    // Video

    @action updateVideo = (changes: VideoUpdater) => {
        let component = this.working_model as Video;
        if (component) {
            if (changes.name !== undefined) {
                component.name = changes.name
            }
            if (changes.width !== undefined) {
                component.width = changes.width
            }
            if (changes.title !== undefined) {
                component.title = changes.title
            }
            if (changes.description !== undefined) {
                component.description = changes.description
            }
            if (changes.service !== undefined) {
                component.service = changes.service
            }
            if (changes.videoId !== undefined) {
                component.videoId = changes.videoId
            }
            if (changes.thumbnailUrl !== undefined) {
                component.thumbnailUrl = changes.thumbnailUrl
            }
        }
    }

    @action saveVideo = () => {
        let changes = this.working_model as Video;
        if (changes) {
            let component = this.getComponent(changes.id) as Video;
            component.name = changes.name;
            component.width = changes.width;
            component.title = changes.title;
            component.description = changes.description;
            component.service = changes.service;
            component.videoId = changes.videoId;
            component.thumbnailUrl = changes.thumbnailUrl;
            this.setEditing(changes.id, false);
        }
    }

    // Footer

    @action updateFooter = (changes: FooterUpdater) => {
        let component = this.working_model as Footer;
        if (component) {
            if (changes.name !== undefined) {
                component.name = changes.name
            }
        }
    }

    @action saveFooter = () => {
        let changes = this.working_model as Footer;
        if (changes) {
            let component = this.getComponent(changes.id) as Footer;
            component.name = changes.name;
            this.setEditing(changes.id, false);
        }
    }

    // General
    getComponent = (componentId: string, currentComponent: Model = this.data): Model | undefined => {
        if (currentComponent.id === componentId) {
            return currentComponent
        }
        let items = Model.itemsInModel(currentComponent);
        if (items) {
            for (var i = 0; i < items.length; i++) {
                let component = this.getComponent(componentId, items[i])
                if (component) {
                    return component
                }
            }
        }
        return undefined;
    }

    getParent = (componentId: string, currentComponent: Model = this.data, parentComponent?: Model): Model | undefined => {
        if (parentComponent && currentComponent.id === componentId) {
            return parentComponent
        }
        let items = Model.itemsInModel(currentComponent);
        if (items) {
            for (var i = 0; i < items.length; i++) {
                let parentId = this.getParent(componentId, items[i], currentComponent)
                if (parentId) {
                    return parentId
                }
            }
        }
        return undefined
    }

    @action addModel = (model: Model, parentId: string) => {
        let parent = this.getComponent(parentId)
        if (parent) {
            switch (parent.type) {
                case Type.site:
                    let site = parent as Site;
                    model.index = site.pages.length
                    site.pages.push(model as Page)
                    break;
                case Type.navBar:
                    let navBar = parent as NavBar;
                    model.index = navBar.routes.length
                    navBar.routes.push(model as NavBarItem)
                    break;
                case Type.navBarItem:
                    let navBarItem = parent as NavBarItem;
                    model.index = navBarItem.subRoutes.length
                    navBarItem.subRoutes.push(model as NavBarItem);
                    break;
                case Type.page:
                    let page = parent as Page
                    model.index = page.elements.length
                    page.elements.push(model as Content);
                    break;
                case Type.container:
                    let container = parent as Container
                    model.index = container.content.length
                    container.content.push(model as Content);
                    break;
                case Type.carousel:
                    let carousel = parent as Carousel
                    model.index = carousel.content.length
                    carousel.content.push(model as Content);
                    break;
                case Type.playlist:
                    let playlist = parent as Playlist
                    model.index = playlist.sections.length
                    playlist.sections.push(model as PlaylistSection);
                    break;
                case Type.playlistSection:
                    let playlistSection = parent as PlaylistSection
                    model.index = playlistSection.videos.length
                    playlistSection.videos.push(model as Video);
                    break;
                case Type.footer:
                    let footer = parent as Footer
                    model.index = footer.content.length
                    footer.content.push(model as Content);
                    break;
            }
            this.cmsNavBarStore.open(parent.id, true)
            this.cmsNavBarStore.open(model.id, true)
            this.select(model.id);
            this.setEditing(model.id, true)
        }
        return model.id
    }

    @action save = () => {
        this.setHasChanges(true)
        switch (this.working_model?.type) {
            case Type.site: return this.saveSite();
            case Type.theme: return this.saveTheme();
            case Type.page: return this.savePage();
            case Type.header: return this.saveHeader();
            case Type.footer: return this.saveFooter();
            case Type.container: return this.saveContainer();
            case Type.navBar: return this.saveNavBar();
            case Type.navBarItem: return this.saveNavBarItem();
            case Type.text: return this.saveText();
            case Type.image: return this.saveImage();
            case Type.playlist: return this.savePlaylist();
            case Type.playlistSection: return this.savePlaylistSection();
            case Type.video: return this.saveVideo();
            case Type.carousel: return this.saveCarousel();
            case Type.iFrame: return this.saveIFrame();
            case Type.socials: return this.saveSocials();
            case Type.button: return this.saveButton();
            case Type.collection: return this.saveCollection();
            case Type.rallySwap: return this.saveRallySwap();
            case Type.html: return this.saveHTML();
        }
    };

    @action deleteModel = (id: string, array: Model[]): Model[] => {
        let filtered = array.filter(comp => comp.id !== id);
        this.updateIndexes(filtered);
        return filtered;
    }

    @action moveToIndex = (model: Model, index: number, array: Model[]): Model[] => {
        if (index < array.length) {
            array.splice(index, 0, array.splice(model.index, 1)[0]);
        }
        this.updateIndexes(array);
        return array;
    }

    @action updateIndexes = (array: Model[]) => {
        for (var i = 0; i < array.length; i++) {
            array[i].index = i;
        }
    }

    saveSiteAPI = async () => {
        try {
            console.log(this.data);
            const response = SiteService.updateSite(this.data);
            this.hasChanges = false;
            return response
        } catch (error) {
            throw error
        }
    }
}

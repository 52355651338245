import { action, makeAutoObservable, observable, } from "mobx";
import { Playlist, PlaylistSection, Video } from '../Models/Site';

const defaultOpenState = false

export class PlaylistStore {
    @observable playlist: Playlist = new Playlist();
    @observable currentVideo?: Video;
    @observable currentSection?: PlaylistSection;
    @observable openSections: { [id: string]: boolean } = {};

    constructor() {
        makeAutoObservable(this)
    }

    isSectionOpen = (id: string): boolean => {
        return this.openSections[id];
    }

    toggleSection = (id: string) => {
        this.openSections[id] = !this.openSections[id]
        this.currentSection = this.openSections[id] ? this.playlist.sections.find(s=>s.id === id) : undefined;
    }

    @action setPlaylist = (playlist: Playlist) => {
        this.playlist = playlist;
        playlist.sections.forEach(s => this.openSections[s.id] = defaultOpenState)
        if (playlist.sections.length > 0 && playlist.sections[0].videos.length > 0) {
            this.currentSection = playlist.sections[0];
            this.currentVideo = playlist.sections[0].videos[0];
        }
    }

    @action selectVideo = (id: string) => {
        let video = this.playlist.sections.map(s=>s.videos).flat().find(v=>v.id === id);
        if (video) {
            this.currentVideo = video;
        }
    };
}
import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../Stores";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from "@mui/material";

export const AdminDateTimePicker = observer(() => {
    const { adminStore } = useStore();
    const { editUser, setCreatedDate } = adminStore;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
                label="Account Created"
                value={editUser?.created}
                onChange={setCreatedDate}
                renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>
    );
}
)
import { makeAutoObservable } from "mobx"

interface Modal {
    open: boolean;
    body: JSX.Element | null;
    title: string | null;
    onClose: () => void;
}

export class ModalStore {
    modal: Modal = {
        open: false,
        body: null,
        title: null,
        onClose: () => {}
    }

    constructor() {
        makeAutoObservable(this)
    }

    openModal = (content: JSX.Element, title: string = "", onClose: () => void = ()=>{}) => {
        this.modal.open = true;
        this.modal.body = content;
        this.modal.title = title;
        this.modal.onClose = onClose;
    }

    closeModal = () => {
        this.modal.open = false;
        this.modal.body = null;
        this.modal.title = null;
        this.modal.onClose();
    }
}
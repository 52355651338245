import { makeAutoObservable } from "mobx"
import { faFileVideo, faFileAudio, faFile, faImage } from '@fortawesome/free-solid-svg-icons'

export enum FileType {
    image = "image",
    video = "video",
    audio = "audio",
    generic = "generic"
}

export class File {
    id: string = "";
    extension: string = "";
    name: string = "";
    type: FileType = FileType.generic;
    mimeType: string = "";

    constructor() {
        makeAutoObservable(this)
    }

    static iconProp = (file: File) => {
        switch (file.type) {
            case FileType.image: return faImage;
            case FileType.audio: return faFileAudio;
            case FileType.video: return faFileVideo;
            case FileType.generic: return faFile;
        }
    }
}
import React from 'react';
import {
    Page as PageModel,
    NavBar as NavBarModel,
    NavBarItem as NavBarItemModel,
    Header as HeaderModel,
    Container as ContainerModel,
    Blocked as BlockedModel,
    Text as TextModel,
    Image as ImageModel,
    Playlist as PlaylistModel,
    PlaylistSection as PlaylistSectionModel,
    Video as VideoModel,
    Carousel as CarouselModel,
    IFrame as IFrameModel,
    Footer as FooterModel,
    Socials as SocialsModel,
    Button as ButtonModel,
    Collection as CollectionModel,
    RallySwap as RallySwapModel,
    HTML as HTMLModel,
    Content, Model, Type
} from '../../../Models/Site';
import {
    Page, NavBar, NavBarItem,
    Header, Container, Blocked,
    Text, Image, Footer, Carousel,
    Playlist, PlaylistSection, Video,
    IFrame, Socials, Button, Collection, RallySwap, Html
} from '..';

export interface ElementBuilderConfig {
    model: Model
    parent?: Content
    color?: string
    isPreview?: boolean
}

export class ElementRenderer {
    static build = (config: ElementBuilderConfig): React.ReactNode => {
        switch (config.model.type) {
            case Type.page:
                return <Page key={config.model.id} data={config.model as PageModel} />
            case Type.navBar:
                return <NavBar key={config.model.id} data={config.model as NavBarModel} isPreview={config.isPreview} />
            case Type.navBarItem:
                return <NavBarItem key={config.model.id} data={config.model as NavBarItemModel} />
            case Type.header:
                return <Header key={config.model.id} data={config.model as HeaderModel} parentWidth={config.parent ? config.parent.width : 12} />
            case Type.container:
                return <Container key={config.model.id} data={config.model as ContainerModel} parentWidth={config.parent ? config.parent.width : 12} />
            case Type.blocked:
                return <Blocked key={config.model.id} data={config.model as BlockedModel} />
            case Type.text:
                return <Text key={config.model.id} data={config.model as TextModel} color={config.color ?? 'text.primary'} />
            case Type.image:
                return <Image key={config.model.id} data={config.model as ImageModel} parentWidth={config.parent ? config.parent.width : 12} />
            case Type.playlist:
                return <Playlist key={config.model.id} data={config.model as PlaylistModel} />
            case Type.playlistSection:
                return <PlaylistSection key={config.model.id} data={config.model as PlaylistSectionModel} />
            case Type.video:
                return <Video key={config.model.id} fullScreen={false} data={config.model as VideoModel} />
            case Type.footer:
                return <Footer key={config.model.id} data={config.model as FooterModel} />
            case Type.carousel:
                return <Carousel key={config.model.id} data={config.model as CarouselModel} />
            case Type.iFrame:
                return <IFrame key={config.model.id} data={config.model as IFrameModel} />
            case Type.socials:
                return <Socials key={config.model.id} data={config.model as SocialsModel} />
            case Type.button:
                return <Button key={config.model.id} data={config.model as ButtonModel} />
            case Type.collection:
                return <Collection key={config.model.id} data={config.model as CollectionModel} />
            case Type.rallySwap:
                return <RallySwap key ={config.model.id} data={config.model as RallySwapModel} />
            case Type.html:
                return <Html key={config.model.id} data={config.model as HTMLModel} />
            default:
                return <></>
        }
    }
}
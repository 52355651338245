import { makeAutoObservable } from "mobx";
import { CommonStore, Store } from ".";
import { Collection, Template, TemplateData, CollectionKey, InflatedCollection, InflatedCollectionSearchParam, CollectionData, NFTCollectionDTO } from "../Models/API/Collections";
import { Content, ContentFactory, Type } from "../Models/Site";
import { CollectionService } from "../Services";

export class CollectionStore {
    commonStore: CommonStore;
    collections: Collection[] | undefined;
    templates: { [collectionId: string]: Template[]; } = {};
    fullTemplates: { [id: string]: TemplateData | undefined; } = {};
    collectionData: { [id: string]: CollectionData; } = {};
    inflatedCollections: { [id: string]: InflatedCollection | undefined; } = {};
    inflatedCards: { [id: string]: Content | undefined } = {};
    collectionKeys: { [collectionId: string]: CollectionKey[]; } = {};

    constructor(store: Store) {
        this.commonStore = store.commonStore;
        makeAutoObservable(this)
    }

    setCollections = (collections: Collection[] | undefined) => {
        this.collections = collections
    }

    setCollectionKeys = async (collectionId: string, keys: CollectionKey[]) => {
        this.collectionKeys[collectionId] = keys
    }

    updateCollectionKey = (collectionId: string, index: number, key: CollectionKey) => {
        this.collectionKeys[collectionId][index] = key
    }

    setTemplates = (collectionId: string, templates: Template[]) => {
        this.templates[collectionId] = templates
    }

    setFullTemplate = (templateId: string, template: TemplateData | undefined) => {
        this.fullTemplates[templateId] = template
    }

    setCollectionData = (collectionId: string, data: CollectionData) => {
        this.collectionData[collectionId] = data;
    }

    getCollections = async () => {
        try {
            if (this.commonStore.currentSite) {
                const response = await CollectionService.getList(this.commonStore.currentSite.id)
                this.setCollections(response)
            } else {
                throw new Error('No site')
            }
        } catch (error) {
            this.setCollections([])
            throw error;
        }
    }

    setInflatedCollection = (key: string, collection: InflatedCollection) => {
        this.inflatedCollections[key] = collection;
    }

    getInflatedCollection = async (collectionId: string, templateId: string, page: number, perPage: number = 10, param: InflatedCollectionSearchParam) => {
        const key = collectionId + templateId
        try {
            if (this.commonStore.currentSite) {
                const response = await CollectionService.getInflated(collectionId, templateId, this.commonStore.currentSite.id, page, perPage, param)
                this.setInflatedCollection(key, response)
            }
        } catch (error) {
            throw error;
        }
    }

    setInflatedCard = (key: string, card: Content | undefined) => {
        this.inflatedCards[key] = card
    }

    getInflatedCard = async (cardId: string, collectionId: string, templateId: string) => {
        const key = cardId + collectionId + templateId
        try {
            if (this.commonStore.currentSite) {
                const response = await CollectionService.getInflatedCard(cardId, collectionId, templateId, this.commonStore.currentSite.id)
                this.setInflatedCard(key, response)
            }
        } catch (error) {
            throw error;
        }
    }

    getCollectionData = async (collectionId: string) => {
        try {
            if (this.commonStore.currentSite) {
                const response = await CollectionService.getData(collectionId, this.commonStore.currentSite.id)
                this.setCollectionData(collectionId, response)
            } else {
                throw new Error('No site')
            }
        } catch (error) {
            this.setCollections([])
            throw error;
        }
    }

    getCollectionKeys = async (collectionId: string) => {
        try {
            if (this.commonStore.currentSite) {
                const response = await CollectionService.getKeys(this.commonStore.currentSite.id, collectionId)
                await this.setCollectionKeys(collectionId, response)
            } else {
                throw new Error('No site')
            }
        } catch (error) {
            this.setCollectionKeys(collectionId, [])
            throw error;
        }
    }

    makeKeyStarred = async (id: string, key: string, starred: boolean, collectionId: string) => {
        try {
            if (this.commonStore.currentSite) {
                const response = await CollectionService.updateKey(this.commonStore.currentSite.id, collectionId, { id: id, name: key, starred: starred })
                const index = this.collectionKeys[collectionId].findIndex((v) => { return v.name === key })
                this.updateCollectionKey(collectionId, index, response)
            } else {
                throw new Error('No site')
            }
        } catch (error) {
            throw error;
        }
    }

    getTemplates = async (siteId: string, collectionId: string) => {
        const response = await CollectionService.getTemplateList(siteId, collectionId)
        this.setTemplates(collectionId, response)
    }

    getTemplateData = async (collectionId: string, templateId: string) => {
        try {
            if (this.commonStore.currentSite) {
                const response = await CollectionService.getTemplateData(this.commonStore.currentSite.id, collectionId, templateId)
                this.setFullTemplate(templateId, response)
            } else {
                throw new Error('No site')
            }
        } catch (error) {
            this.setFullTemplate(templateId, undefined)
            throw error;
        }
    }

    createTemplate = async (siteId: string, collectionId: string, templateName: string) => {
        try {
            const basicTemplate = ContentFactory.model(Type.container)
            if (this.commonStore.currentSite && basicTemplate) {
                const resposne = await CollectionService.createTemplate(siteId, collectionId, templateName, basicTemplate)
                return resposne;
            } else {
                throw new Error('No site')
            }
        } catch (error) {
            throw error;
        }
    }

    updateTemplate = async (collectionId: string, template: TemplateData) => {
        try {
            if (this.commonStore.currentSite) {
                const resposne = await CollectionService.updateTemplate(this.commonStore.currentSite.id, collectionId, template)
                this.getTemplateData(collectionId, template.id)
            } else {
                throw new Error('No site')
            }
        } catch (error) {
            throw error;
        }
    }

    deleteTemplate = async (templateId: string, collectionId: string) => {
        await CollectionService.deleteTemplate(templateId)
        const newTemplates = this.templates[collectionId].filter(temp => temp.id !== templateId)
        this.setTemplates(collectionId, newTemplates)
        this.setFullTemplate(templateId, undefined)
    }

    createCollection = async () => {
        try {
            if (this.commonStore.currentSite) {
                const response = await CollectionService.createCollection(this.commonStore.currentSite.id)
                // this.setCollections(response)
            } else {
                throw new Error('No site')
            }
        } catch (error) {
            this.setCollections([])
            throw error;
        }
    }

    createNFTCollection = async (creatorId: string, walletAddress: string, dto: NFTCollectionDTO) => {
        await CollectionService.createNFTCollection(creatorId, walletAddress, dto)
    }
}
import {makeAutoObservable, toJS} from "mobx"
import { Creator, CreatorDomain, CreatorReferral, PatreonCampaign } from "../Models/Admin/Creator"
import { CompactUser } from "../Models/API";
import { SocialsLink } from "../Models/Site";
import { CreatorService } from "../Services";
import {SwappableSplToken} from "../Models/API/Spl/SwappableToken";

export interface FetchOptions {
    userId?: string,
    siteId?: string,
    creatorId?: string
}

export class CreatorStore {
    loading: boolean = true;
    creator: Creator | undefined;
    domains: CreatorDomain[] | undefined;
    referrals: CreatorReferral[] | undefined;
    staffList: CompactUser[] | undefined;
    campaign: PatreonCampaign | undefined;
    socials: SocialsLink[] | undefined;
    //Spl tokens
    swappableTokens: SwappableSplToken[] | undefined;

    constructor() {
        makeAutoObservable(this)
    }
    setLoading = (isLoading: boolean) => {
        this.loading = isLoading;
    }

    setCreator = (creator: Creator) => {
        this.creator = creator
        this.setDomains(creator.domains)
    }

    getCreator = async (options: FetchOptions) => {
        if (options.userId) {
            const creator = await CreatorService.getCreatorByUserId(options.userId);
            this.setCreator(creator)
        } else if (options.siteId) {
            const creator = await CreatorService.getCreatorBySiteId(options.siteId);
            this.setCreator(creator)
        }
    }

    removeCreatorByUserId = async (userId: string) => {
        const creator = await CreatorService.getCreatorByUserId(userId);
        await CreatorService.deleteCreator(creator.id);
    }

    // Domains

    setDomains = (domains: CreatorDomain[]) => {
        this.domains = domains
    }

    getDomains = async () => {
        if (this.creator) {
            const response = await CreatorService.getCreatorDomains(this.creator.id);
            this.setDomains(response)
        } else {
            this.setDomains([])
            throw new Error('No creator')
        }
    }

    addDomain = async (domain: string) => {
        if (this.creator) {
            const response = await CreatorService.addCreatorDomain(this.creator.id, domain)
            this.setDomains(response)
        } else {
            throw new Error('No creator')
        }
    }

    deleteDomain = async (domain: string) => {
        if (this.creator) {
            const response = await CreatorService.removeCreatorDomain(this.creator.id, domain);
            this.setDomains(response)
        } else {
            throw new Error('No creator')
        }
    }
    // Referrals

    setReferrals = (referrals: CreatorReferral[]) => {
        this.referrals = referrals
    }

    getReferrals = async () => {
        if (this.creator) {
            const response = await CreatorService.getCreatorReferrals(this.creator.id);
            this.setReferrals(response)
        } else {
            this.setReferrals([])
            throw new Error('No creator')
        }
    }

    // Campaigns

    setPatreonCampaign = (campaign: PatreonCampaign) => {
        this.campaign = campaign
    }

    getPatreonCampaign = async (siteId: string) => {
        const response = await CreatorService.getCreatorCampaigns(siteId);
        this.setPatreonCampaign(response);
    }

    // Staff

    setStaffList = (staffList: CompactUser[]) => {
        this.staffList = staffList
    }

    getStaff = async () => {
        if (this.creator) {
            const response = await CreatorService.getCreatorStaff(this.creator.id);
            this.setStaffList(response)
        } else {
            this.setStaffList([])
            throw new Error('No creator')
        }
    }

    addStaff = async (staffId: string) => {
        if (this.creator) {
            const response = await CreatorService.addCreatorStaff(this.creator.id, staffId)
            this.setStaffList(response)
        } else {
            throw new Error('No creator')
        }
    }

    deleteStaff = async (staffId: string) => {
        if (this.creator) {
            const response = await CreatorService.removeCreatorStaff(this.creator.id, staffId);
            this.setStaffList(response)
        } else {
            throw new Error('No creator')
        }
    }

    // Socials

    setSocials = (socials: SocialsLink[]) => {
        this.socials = socials;
    }

    getSocials = async () => {
        if (this.creator) {
            const response = await CreatorService.getSocials(this.creator.id);
            this.setSocials(response)
        } else {
            this.setStaffList([])
            throw new Error('No creator')
        }
    }

    addSocial = async (type: string, url: string) => {
        if (this.creator) {
            const response = await CreatorService.addSocial(this.creator.id, type, url)
            this.setSocials(response)
        } else {
            throw new Error('No creator')
        }
    }

    deleteSocial = async (socialId: string) => {
        if (this.creator) {
            const response = await CreatorService.removeSocial(this.creator.id, socialId);
            this.setSocials(response)
        } else {
            throw new Error('No creator')
        }
    }

    //Swap Tokens
    getCurrentSwappableTokens = async () => {
        if(this.creator) {
            const response =  await CreatorService.getSwappableTokens(this.creator.id);
            this.setCurrentSwappableTokens(response);
        } else {
            throw new Error('No creator')
        }
        //Create a new API call in the Creator Service
    }
    setCurrentSwappableTokens = (tokenList: SwappableSplToken[]) => {
        this.swappableTokens = tokenList;
        this.setLoading(false);
    }

    toggleSwappable = (token: SwappableSplToken) => {

        if(this.swappableTokens)
        {
            this.swappableTokens.find((t) => t.key === token.key)!.swappable = !token.swappable;
        }
    }

    setSwappableTokens = async (tokenList : SwappableSplToken[]) => {
        console.log("This is the result from Creator Store");
        console.log(toJS(tokenList));
        if (this.creator) {
            const response = await CreatorService.setSwappableTokens(this.creator.id, tokenList);
            this.setCurrentSwappableTokens(response)
        } else {
            throw new Error('No creator')
        }
        //TODO: Sends the list of swappable tokens to the API
        //Create a new API call in the Creator Service
    }

}

import {action, makeAutoObservable} from "mobx";
import {WidgetService} from "../Services/WidgetService";

export class WidgetStore {
    alliePrice: number = 0;
    loading: boolean = true;

    constructor() {
        makeAutoObservable(this);
    }
    @action getAlliePrice = async () => {
        const price = await WidgetService.getAlliePrice();
        this.setAlliePrice(price);
    }
    @action setAlliePrice = (price: number) => {
        this.alliePrice = price;
        this.loading = false;
    }
}
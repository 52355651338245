import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../Stores";


export const ModalContainer = observer(() => {
    const { modalStore } = useStore();

    return (
        <Dialog open={modalStore.modal.open} onClose={modalStore.closeModal} maxWidth={"md"}>
            {modalStore.modal.title ? <DialogTitle>{modalStore.modal.title}</DialogTitle> : undefined}
            <DialogContent className='hide-scrollbar'>
                {modalStore.modal.body}
            </DialogContent>
        </Dialog>
    )
});
import { Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { AdminUser } from "../../../Models/Admin";
import { useStore } from "../../../Stores";
import { DataGrid, GridColDef } from '@mui/x-data-grid';

const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 300 },
    { field: 'userName', headerName: 'UserName', width: 200 },
    { field: 'email', headerName: 'Email', width: 250 },
];

const createData = (
    id: string,
    userName: string,
    email: string
) => {
    return { id, userName, email };
}


export const AdminSearchUsers = observer(() => {
    const { adminStore } = useStore();
    const { getAllUsers, getUser, userList, searchType, changeSearchType, SearchUser, changeSearchString, searchString } = adminStore;
    let data: AdminUser[] | undefined = userList;

    let rows = [];
    if (data != null) {
        for (var i = 0; i < data.length; i++) {

            rows.push(createData(data[i].id, data[i].userName, data[i].email));
        }
    }

    return (
        <>
            <Grid item md={2}>
                <h3>Search Users</h3>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Search Type</FormLabel>
                    <RadioGroup
                        aria-label="SearchType"
                        defaultValue="ByUserName"
                        name="use-radio-group"
                        onChange={changeSearchType}
                    >
                        <FormControlLabel value="ByUserName" control={<Radio />} label="By UserName" />
                        <FormControlLabel value="ByEmail" control={<Radio />} label="By Email" />
                        <FormControlLabel value="ByReferralCode" control={<Radio />} label="By Referral Code" />
                        <FormControlLabel value="ByCreator" control={<Radio />} label="By Creator" />
                        <FormControlLabel value="WithRally" control={<Radio />} label="With Rally" />
                        <FormControlLabel value="WithoutRally" control={<Radio />} label="Without Rally" />
                        <FormControlLabel value="WithPatreon" control={<Radio />} label="With Patreon" />
                        <FormControlLabel value="WithoutPatreon" control={<Radio />} label="Without Patreon" />
                        <FormControlLabel value="WithTwitch" control={<Radio />} label="With Twitch" />
                        <FormControlLabel value="WithoutTwitch" control={<Radio />} label="Without Twitch" />
                        <FormControlLabel value="ByRole" control={<Radio />} label="By Role" />
                    </RadioGroup>
                </FormControl>
                <br />
                <TextField id="searchUsers" value={searchString} label="Search" variant="outlined" onChange={changeSearchString} />
                <br />
                <Button variant="contained" color="secondary" onClick={() => SearchUser(searchType, searchString)}>Search</Button>
                <Button variant="contained" onClick={() => getAllUsers()}>All Users</Button>
            </Grid>
            <Grid item md={4}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    onSelectionModelChange={(selection) => {
                        if (selection.length !== 0) {
                            getUser(selection.toString());
                        }
                    }} />
            </Grid>
        </>
    )
})
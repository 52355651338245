import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Stack, Chip, Button, Popover, Autocomplete, TextField, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, Slider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { CollectionSearchFilter, CollectionKey, CollectionKeyType } from "../../../../Models/API/Collections";

interface CollectionFilterChipsProps {
    filters: CollectionSearchFilter[]
    onDelete: (filter: CollectionSearchFilter) => void;
}

export const CollectionFilterChips = ({ filters, onDelete }: CollectionFilterChipsProps) => {
    return (
        <Stack direction='column' spacing={2} paddingX={2}>
            {filters.map(filter => <Chip label={`${filter.key}: ${filter.value}`} onDelete={() => onDelete(filter)} />)}
        </Stack>
    )
}

interface CollectionKeyFilterProps {
    hasFilter: boolean;
    data: CollectionKey;
    onChange?: (newFilter: CollectionSearchFilter | undefined) => void;
}

interface CollectionFilterProps {
    keys: CollectionKey[];
    filters: CollectionSearchFilter[];
    onChange?: (filters: CollectionSearchFilter[]) => void;
}

export const CollectionFilter = ({ keys, filters, onChange = () => { } }: CollectionFilterProps) => {

    const addFilter = (filter: CollectionSearchFilter) => {
        const newFilters = filters.concat([filter])
        onChange(newFilters)
    }
    const deleteFilter = (filter: CollectionSearchFilter) => {
        const newFilters = filters.filter(f => filter.key !== f.key)
        onChange(newFilters)
    }

    return (
        <Paper>
            <Stack spacing={2} padding={2}>
                <Typography variant="h5" align="center">Filters</Typography>
                {keys.filter(key => key.starred).map(key => {
                    const hasFilter = filters.find((f) => f.key === key.name) !== undefined
                    return (
                        <Grid item>
                            <CollectionKeyFilter key={key.name} hasFilter={hasFilter} data={key} onChange={(newFilter: CollectionSearchFilter | undefined) => {
                                if (newFilter) { addFilter(newFilter) }
                            }} />
                        </Grid>
                    );
                })}
                <CollectionFilterChips filters={filters} onDelete={deleteFilter} />
            </Stack>
        </Paper>
    )
}

export const CollectionKeyFilter = ({ hasFilter, data, onChange = () => { } }: CollectionKeyFilterProps) => {
    const [filterData, setFilterData] = React.useState<CollectionSearchFilter | undefined>()
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

    // Popover
    const filterOpen = Boolean(anchorEl);

    const openFilter = (event: React.MouseEvent<HTMLButtonElement>, key: CollectionKey) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const addFilter = () => {
        onChange(filterData)
        handleClose()
    }

    const control = (() => {
        switch (data.type) {
            case CollectionKeyType.string:
                return <CollectionStringFilter data={data} onChange={newValue => setFilterData(newValue)} />
            case CollectionKeyType.boolean:
                return <CollectionBooleanFilter data={data} onChange={newValue => setFilterData(newValue)} />
            case CollectionKeyType.int:
                return <CollectionIntFilter data={data} onChange={newValue => setFilterData(newValue)} />
            case CollectionKeyType.double:
                return <CollectionDoubleFilter data={data} onChange={newValue => setFilterData(newValue)} />
            case CollectionKeyType.float:
                return <CollectionFloatFilter data={data} onChange={newValue => setFilterData(newValue)} />
            case CollectionKeyType.datetime:
                return <CollectionDateFilter data={data} onChange={newValue => setFilterData(newValue)} />
        }
    })()
    return (
        <>
            <Button
                variant={hasFilter ? 'contained' : 'contained'}
                disabled={hasFilter}
                onClick={event => openFilter(event, data)}
                endIcon={filterOpen ? <ExpandLess /> : <ExpandMore />}
            >
                {data.name}
            </Button>
            <Popover
                id={data.name}
                open={filterOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Stack spacing={2} margin={2} direction='row' justifyContent='space-between' alignItems='center' width={320}>
                    {control}
                    <Button disabled={filterData === undefined} variant='contained' sx={{ height: '40px' }} onClick={addFilter}>Add</Button>
                </Stack>
            </Popover>
        </>
    )
}

interface CollectionKeyGenericFilterProps {
    data: CollectionKey;
    onChange?: (newFilter: CollectionSearchFilter | undefined) => void;
}

export const CollectionStringFilter = ({ data, onChange = () => { } }: CollectionKeyGenericFilterProps) => {
    const [value, setValue] = React.useState('')
    return <Autocomplete
        id={data.name}
        options={data.allowedValues}
        sx={{ flex: 1 }}
        value={value}
        freeSolo
        onChange={(_, newValue) => {
            onChange(newValue ? { key: data.name, type: data.type, value: newValue, include: true } : undefined)
            setValue(newValue ?? '')
        }}
        renderInput={(params) => (<TextField {...params} label={data.name} onChange={event => {
            onChange(event.target.value ? { key: data.name, type: data.type, value: event.target.value, include: true } : undefined)
            setValue(event.target.value ?? '')
        }} />)}
    />
}

// Yeeehaw
export const CollectionBooleanFilter = ({ data, onChange = () => { } }: CollectionKeyGenericFilterProps) => {
    const [yesValue, setYesValue] = React.useState(false)
    const [noValue, setNoValue] = React.useState(false)
    const setValue = (newValue: boolean, isYesValue: boolean) => {
        setYesValue(isYesValue ? newValue : false)
        setNoValue(!isYesValue ? newValue : false)
        onChange(newValue ? { key: data.name, type: data.type, value: `${isYesValue}`, include: true } : undefined)
    }
    return (
        <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">{data.name}</FormLabel>
            <FormGroup>
                <FormControlLabel control={<Checkbox checked={yesValue} onChange={(_, newValue) => setValue(newValue, true)} />} label="Yes" />
                <FormControlLabel control={<Checkbox checked={noValue} onChange={(_, newValue) => setValue(newValue, false)} />} label="No" />
            </FormGroup>
        </FormControl>
    )
}

export const CollectionIntFilter = ({ data, onChange = () => { } }: CollectionKeyGenericFilterProps) => {
    const [useRange, setUseRange] = React.useState(false)
    const [intValue, setIntValue] = React.useState(0)
    const [rangeValue, setRangeValue] = React.useState<number[]>([+(data.minimum ?? "0"), +(data.maximum ?? "0")])

    const toggleRange = (enable: boolean) => {
        setUseRange(enable)
        onChange({
            key: data.name,
            type: data.type,
            value: enable ? `${rangeValue[0]}:${rangeValue[1]}` : `${intValue}`,
            include: true
        })
    }

    const handleChange = (event: Event, newValue: number | number[]) => {
        if (useRange) {
            const newRange = newValue as number[]
            setRangeValue(newRange);
            onChange({ key: data.name, type: data.type, value: `${newRange[0]}:${newRange[1]}`, include: true })
        } else {
            const newInt = newValue as number
            setIntValue(newInt);
            onChange({ key: data.name, type: data.type, value: `${newInt}`, include: true })
        }
    };
    return (
        <FormControl component="fieldset" variant="standard" fullWidth>
            <FormLabel component="legend">{data.name}</FormLabel>
            <FormGroup>
                <FormControlLabel control={<Checkbox checked={useRange} onChange={(_, newValue) => toggleRange(newValue)} />} label="Use range" />
                <Slider valueLabelDisplay="auto" value={useRange ? rangeValue : intValue} onChange={handleChange} min={+(data.minimum ?? "0")} max={+(data.maximum ?? "0")} />
            </FormGroup>
        </FormControl>
    )
}

export const CollectionDoubleFilter = ({ data, onChange = () => { } }: CollectionKeyGenericFilterProps) => {
    const [useRange, setUseRange] = React.useState(false)
    const [doubleValue, setDoubleValue] = React.useState(0)
    const [rangeValue, setRangeValue] = React.useState<number[]>([+(data.minimum ?? "0"), +(data.maximum ?? 0)])

    const toggleRange = (enable: boolean) => {
        setUseRange(enable)
        onChange({
            key: data.name,
            type: data.type,
            value: enable ? `${rangeValue[0] / 10}:${rangeValue[1] / 10}` : `${doubleValue / 10}`,
            include: true
        })
    }

    const handleChange = (event: Event, newValue: number | number[]) => {
        if (useRange) {
            const newRange = newValue as number[]
            setRangeValue(newRange);
            onChange({ key: data.name, type: data.type, value: `${newRange[0] / 10}:${newRange[1] / 10}`, include: true })
        } else {
            const newDouble = newValue as number
            setDoubleValue(newDouble);
            onChange({ key: data.name, type: data.type, value: `${newDouble / 10}`, include: true })
        }
    };
    return (
        <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">{data.name}</FormLabel>
            <FormGroup>
                <FormControlLabel control={<Checkbox checked={useRange} onChange={(_, newValue) => toggleRange(newValue)} />} label="Use range" />
                <Slider valueLabelDisplay="auto" value={useRange ? rangeValue : doubleValue} onChange={handleChange} min={data.minimum ? +data.minimum * 10 : undefined} max={data.maximum ? +data.maximum * 10 : undefined} scale={x => (x * 100) / 1000} />
            </FormGroup>
        </FormControl>
    )
}

export const CollectionFloatFilter = ({ data, onChange = () => { } }: CollectionKeyGenericFilterProps) => {
    const [useRange, setUseRange] = React.useState(false)
    const [floatValue, setFloatValue] = React.useState(+(data.minimum ?? "0"))
    const [rangeValue, setRangeValue] = React.useState<number[]>([+(data.minimum ?? "0"), +(data.maximum ?? "0")])

    const toggleRange = (enable: boolean) => {
        setUseRange(enable)
        onChange({
            key: data.name,
            type: data.type,
            value: enable ? `${rangeValue[0] / 10}:${rangeValue[1] / 10}` : `${floatValue / 10}`,
            include: true
        })
    }

    const handleChange = (event: Event, newValue: number | number[]) => {
        if (useRange) {
            const newRange = newValue as number[]
            setRangeValue(newRange);
            onChange({ key: data.name, type: data.type, value: `${newRange[0] / 10}:${newRange[1] / 10}`, include: true })
        } else {
            const newFloat = newValue as number;
            setFloatValue(newFloat);
            onChange({ key: data.name, type: data.type, value: `${newFloat / 10}`, include: true })
        }
    };
    return (
        <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">{data.name}</FormLabel>
            <FormGroup>
                <FormControlLabel control={<Checkbox checked={useRange} onChange={(_, newValue) => toggleRange(newValue)} />} label="Use range" />
                <Slider valueLabelDisplay="auto" value={useRange ? rangeValue : floatValue} onChange={handleChange} min={data.minimum ? +data.minimum * 10 : undefined} max={data.maximum ? +data.maximum * 10 : undefined} scale={x => (x * 100) / 1000} />
            </FormGroup>
        </FormControl>
    )
}

export const CollectionDateFilter = ({ data, onChange = () => { } }: CollectionKeyGenericFilterProps) => {
    const [fromDate, setFromDate] = React.useState(data.minimum as string ?? "2000-01-01");
    const [toDate, setToDate] = React.useState(data.maximum as string ?? new Date().toISOString());
    const setDate = (newValue: string, isFromDate: boolean) => {
        if (isFromDate) {
            setFromDate(newValue)
            setToDate(newValue)
            onChange({
                key: data.name,
                type: data.type,
                value: `${newValue.split('T')[0]}:${newValue.split('T')[0]}`,
                include: true
            })
        } else {
            setToDate(newValue)
            onChange({
                key: data.name,
                type: data.type,
                value: `${fromDate.split('T')[0]}:${newValue.split('T')[0]}`,
                include: true
            })
        }
    }
    return (
        <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">{data.name}</FormLabel>
            <FormGroup>
                <Stack spacing={3} marginTop={3}>
                    <TextField
                        id={`${data.name}-fromDate`}
                        label='From date'
                        type="date"
                        value={fromDate.split('T')[0]}
                        onChange={(event) => setDate(event.target.value, true)}
                    />
                    <TextField
                        id={`${data.name}-toDate`}
                        label="To date"
                        type="date"
                        value={toDate.split('T')[0]}
                        onChange={(event) => setDate(event.target.value, false)}
                    />
                </Stack>
            </FormGroup>
        </FormControl>
    )
}
import { observer } from "mobx-react-lite"
import { Stack, TextField } from '@mui/material';

import { useStore } from "../../../Stores";
import { CMSEditButtons, CMSWidthField } from '..';
import { IFrame as IFrameModel } from '../../../Models/Site';

interface Props {
    data: IFrameModel;
}

export const CMSIFrame = observer(({ data }: Props) => {
    const { cmsStore } = useStore();
    const { working_model } = cmsStore;
    let isEditing = cmsStore.editedItemId === data.id;
    let dataToUse: IFrameModel = isEditing ? (working_model as IFrameModel) : data

    return (
        <Stack direction="column" spacing={2} justifyContent="space-between">
            <CMSEditButtons
                id={data.id}
                index={data.index}
            />
            <h5>ID: {data.id}</h5>
            <TextField
                id="name"
                label="Name"
                value={dataToUse.name}
                onChange={(e) => cmsStore.updateIFrame({ name: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <CMSWidthField 
                value={dataToUse.width.toString()}
                onChange={(newValue)=>cmsStore.updateIFrame({ width: +(newValue) })}
                disabled={!isEditing}
            />
            <TextField
                id="title"
                label="Title"
                value={dataToUse.title}
                onChange={(e) => cmsStore.updateIFrame({ title: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <TextField
                id="url"
                label="IFrame URL"
                value={dataToUse.url}
                onChange={(e) => cmsStore.updateIFrame({ url: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
        </Stack>
    );
});
import { Stack, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";

import { CMSColorPicker, CMSEditButtons } from ".";
import { Theme as ThemeModel } from "../../Models/Site";
import { useStore } from "../../Stores";

interface Props {
    data: ThemeModel;
}

export const CMSTheme = observer(({ data }: Props) => {
    const { cmsStore } = useStore();
    const { working_model } = cmsStore;

    let isEditing = cmsStore.editedItemId === data.id;
    let dataToUse: ThemeModel = isEditing ? (working_model as ThemeModel) : data

    return (
        <Stack>
            <CMSEditButtons
                id={data.id}
                index={data.index}
                undeletable
            />
            <h5>ID: {data.id}</h5>
            <TextField
                id="name"
                label="Name"
                value={dataToUse.name}
                onChange={(e) => cmsStore.updateTheme({ name: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <CMSColorPicker
                id='primaryMain'
                label='Primary Main Color'
                value={dataToUse.palette.primary.main}
                onChange={(c) => cmsStore.updatePalette({ primaryMain: c })}
                disabled={!isEditing}
            />
            <CMSColorPicker
                id='primaryContrast'
                label='Primary Contrast Text Color'
                value={dataToUse.palette.primary.contrastText}
                onChange={(c) => cmsStore.updatePalette({ primaryContrast: c })}
                disabled={!isEditing}
            />
            <CMSColorPicker
                id='secondaryMain'
                label='Secondary Main Color'
                value={dataToUse.palette.secondary.main}
                onChange={(c) => cmsStore.updatePalette({ secondaryMain: c })}
                disabled={!isEditing}
            />
            <CMSColorPicker
                id='secondaryContrast'
                label='Secondary Contrast Text Color'
                value={dataToUse.palette.secondary.contrastText}
                onChange={(c) => cmsStore.updatePalette({ secondaryContrast: c })}
                disabled={!isEditing}
            />
            <CMSColorPicker
                id='textPrimary'
                label='Text Primary Color'
                value={dataToUse.palette.text.primary}
                onChange={(c) => cmsStore.updatePalette({ textPrimary: c })}
                disabled={!isEditing}
            />
            <CMSColorPicker
                id='textSecondary'
                label='Secondary Text Color'
                value={dataToUse.palette.text.secondary}
                onChange={(c) => cmsStore.updatePalette({ textSecondary: c })}
                disabled={!isEditing}
            />
            <CMSColorPicker
                id='backgroundPaper'
                label='Paper Background Color'
                value={dataToUse.palette.background.paper}
                onChange={(c) => cmsStore.updatePalette({ backgroundPaper: c })}
                disabled={!isEditing}
            />
            <CMSColorPicker
                id='backgroundDefault'
                label='Default Background Color'
                value={dataToUse.palette.background.default}
                onChange={(c) => cmsStore.updatePalette({ backgroundDefault: c })}
                disabled={!isEditing}
            />
            <TextField
                id="font"
                label="Font Family"
                value={dataToUse.typography.fontFamily}
                onChange={(e) => cmsStore.updateTypography({ fontFamily: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
        </Stack>
    );
});
import { observer } from "mobx-react-lite"
import { Autocomplete, Stack, TextField } from '@mui/material';

import { useStore } from "../../../Stores";
import { CMSEditButtons, CMSWidthField } from '..';
import { Socials as SocialsModel, Alignment, SocialsColor } from '../../../Models/Site';

interface Props {
    data: SocialsModel;
}

export const CMSSocials = observer(({ data }: Props) => {
    const { cmsStore } = useStore();
    const { working_model } = cmsStore;
    let isEditing = cmsStore.editedItemId === data.id;
    let dataToUse: SocialsModel = isEditing ? (working_model as SocialsModel) : data

    return (
        <Stack direction="column" spacing={2} justifyContent="space-between">
            <CMSEditButtons
                id={data.id}
                index={data.index}
            />
            <h5>ID: {data.id}</h5>
            <TextField
                id="name"
                label="Name"
                value={dataToUse.name}
                onChange={(e) => cmsStore.updateSocials({ name: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <CMSWidthField 
                value={dataToUse.width.toString()}
                onChange={(newValue)=>cmsStore.updateSocials({ width: +(newValue) })}
                disabled={!isEditing}
            />
            <Autocomplete
                id="alignment"
                value={dataToUse.alignment}
                onChange={(_, newValue)=>cmsStore.updateSocials({ alignment: newValue as Alignment })}
                options={Object.values(Alignment)}
                disabled={!isEditing}
                renderInput={(params) => (<TextField {...params} label="Alignment" />)}
            />
            <Autocomplete
                id="color"
                value={dataToUse.color}
                onChange={(_, newValue)=> cmsStore.updateSocials({ color: newValue as SocialsColor })}
                options={Object.values(SocialsColor)}
                disabled={!isEditing}
                renderInput={(params) => (<TextField {...params} label="Color" />)}
            />
            <Autocomplete
                id="colorType"
                value={dataToUse.hoverColor}
                onChange={(_, newValue)=> cmsStore.updateSocials({ hoverColor: newValue as SocialsColor })}
                options={Object.values(SocialsColor)}
                disabled={!isEditing}
                renderInput={(params) => (<TextField {...params} label="Hover Color" />)}
            />
        </Stack>
    );
});
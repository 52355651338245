import { observer } from "mobx-react-lite"
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, TextField } from '@mui/material';

import { useStore } from "../../../../Stores";
import { CMSEditButtons, CMSWidthField } from '../..';
import { Video as VideoModel, VideoService } from '../../../../Models/Site';

interface Props {
    data: VideoModel;
}

export const CMSVideo = observer(({ data }: Props) => {
    const { cmsStore } = useStore();
    const { working_model } = cmsStore;
    let isEditing = cmsStore.editedItemId === data.id;
    let dataToUse: VideoModel = isEditing ? (working_model as VideoModel) : data

    return (
        <Stack direction="column" spacing={2} justifyContent="space-between">
            <CMSEditButtons
                id={data.id}
                index={data.index}
            />
            <h5>ID: {data.id}</h5>
            <TextField
                id="name"
                label="Name"
                value={dataToUse.name}
                onChange={(e) => cmsStore.updateVideo({ name: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <CMSWidthField
                value={dataToUse.width.toString()}
                onChange={(newValue) => cmsStore.updateVideo({ width: +(newValue) })}
                disabled={!isEditing}
            />
            <TextField
                id="title"
                label="Title"
                value={dataToUse.title}
                onChange={(e) => cmsStore.updateVideo({ title: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <TextField
                id="description"
                label="Description"
                value={dataToUse.description}
                onChange={(e) => cmsStore.updateVideo({ description: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <FormControl disabled={!isEditing} >
                <FormLabel id="demo-row-radio-buttons-group-label">Service</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="video-type-group-label"
                    name="video-type-group"
                    value={dataToUse.service}
                    onChange={(e) => cmsStore.updateVideo({ service: VideoService[e.target.value as keyof typeof VideoService] })}
                >
                    <FormControlLabel value="vimeo" control={<Radio />} label="Vimeo" />
                    <FormControlLabel value="youtube" control={<Radio />} label="YouTube" />
                </RadioGroup>
            </FormControl>
            <TextField
                id="videoId"
                label="Video Id"
                value={dataToUse.videoId}
                onChange={(e) => {
                    const thumbnailUrl = (()=> {
                        if (dataToUse.thumbnailUrl) return dataToUse.thumbnailUrl;
                        if (dataToUse.service === VideoService.youtube) {
                            return `https://img.youtube.com/vi/${e.target.value}/hqdefault.jpg`
                        }
                        return dataToUse.thumbnailUrl
                    })();
                    cmsStore.updateVideo({ videoId: e.target.value, thumbnailUrl: thumbnailUrl })
                }}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
            <TextField
                id="thumbnailUrl"
                label="Thumbnail Url"
                value={dataToUse.thumbnailUrl}
                onChange={(e) => cmsStore.updateVideo({ thumbnailUrl: e.target.value })}
                disabled={!isEditing}
                fullWidth
                margin="normal"
            />
        </Stack>
    );
});
import {AppBar, Box, CssBaseline, Toolbar} from "@mui/material";
import { Route, Router, Switch } from "react-router-dom";
import { Theme, ThemeProvider } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import React, {useEffect, useMemo} from "react";

import {NavBar, Page} from ".";
import { Profile } from "../Profile";
import { Site as SiteModel } from "../../Models/Site";
import { CMS } from "../CMS";
import { useStore } from "../../Stores";
import { observer } from "mobx-react-lite";
import { history } from "../../App";
import { Admin } from "../Admin";
import { LoginForm, ChangePasswordForm, RegisterForm, AccessDenied } from '../Forms';
import { LoadingComponent } from "../Util";
import { CreatorProfile } from "../CreatorProfile";
import { ExternalRoutes, SSORoutes } from "./Core";
import { Support } from "../Support";
import { CollectionsOverview } from "../Collections";
import { ModalContainer, ToastBar } from "../Util";
import {WalletAdapterNetwork} from "@solana/wallet-adapter-base";

import {
    GlowWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter, TorusWalletAdapter
} from "@solana/wallet-adapter-wallets";
import {WalletProvider} from "@solana/wallet-adapter-react";
import {PrivacyPolicy} from "../Support/PrivacyPolicy";
import {CustomConnectionProvider} from "../../Solana/utils/CustomConnectionProvider";

interface Props {
    data: SiteModel,
    theme: Theme
}

export const Site = observer(({ data, theme }: Props) => {
    const { accountStore, modalStore, toastBarStore, commonStore, supportStore } = useStore();
    const {showSupportChat} = supportStore;

    useEffect(() => {
        document.title = data.name;
        document.querySelector('meta[name="description"]')!.setAttribute('content', data.description);
        // document.head.innerHTML += `<meta name=\'og:description\' content=\'${data.description}\'/>`;
        // document.head.innerHTML += `<meta name=\'og:title\' content=\'${data.name}\'/>`;

    }, [data, showSupportChat])
    // Creates a route
    // path: string - url path (e.g. '/my-page')
    // authenticated: boolean - requires user to be logged in
    // content: React.ReactNode - page content
    // hasPermission: boolean - an additonal condition to be met (e.g. accountStore.isAdmin)
    const createRoute = (path: string, authenticated: boolean, content: React.ReactNode, hasPermission: boolean = true) => {
        const loginCheck = authenticated && accountStore.isLoggedIn
        const conditionsMet = loginCheck || !authenticated
        return (
            <Route key={path} exact path={path} render={() => {
                if (conditionsMet && hasPermission) {
                    return content
                } else if (conditionsMet) {
                    history.push('/youshallnotpass')
                    return null;
                }
                history.push(`/login?redirectUrl=${path}`)
                return null;
            }} />
        )
    }

    // Setup Pages
    const publicRoutes = data.pages.map(page => createRoute(page.path, false, <Page data={page} footer={data.footer} />))

    // Setup Internal routes
    const privateRoutes: React.ReactElement[] = []

    privateRoutes.push(
        <Route key='/youshallnotpass' path="/youshallnotpass" render={() => {
            modalStore.openModal(<AccessDenied />, '', () => {
                history.push('/')
            });
            return null;
        }} />
    )

    privateRoutes.push(
        <Route key='/logout' path="/logout" render={() => {
            accountStore.logout()
            toastBarStore.showMessage('You have been logged out.')
            return null;
        }} />
    )

    privateRoutes.push(
        <Route key='/login' path="/login" render={() => {
            const query = new URLSearchParams(history.location.search);
            const redirectUrl = query.get('redirectUrl');
            const redirect = () => {
                if (accountStore.isLoggedIn && redirectUrl) {
                    try {
                        const url = new URL(redirectUrl);
                        window.location.href = url.href;
                        return
                    } catch {
                        return history.push(redirectUrl)
                    }
                }
                return history.push('/')
            }
            if (accountStore.isLoggedIn) {
                redirect()
            } else {
                modalStore.openModal(<LoginForm />, '', redirect)
                history.push('/')
            }
            return null;
        }} />
    )

    privateRoutes.push(
        <Route key='/register' path="/register" render={() => {
            modalStore.openModal(<RegisterForm />)
            history.push('/')
            return null;
        }} />
    )

    // privateRoutes.push(
    //     <Route key='/registerrewards' path="/registerrewards" render={() => {
    //         modalStore.openModal(<RegisterForm />)
    //         history.push('/rewards')
    //         return null;
    //     }} />
    // )

    privateRoutes.push(
        <Route key="/reset-password" path="/reset-password" render={() => {
            const query = new URLSearchParams(history.location.search);
            const userId = query.get('userId') ?? "";
            const token = query.get('passwordToken') ?? "";
            modalStore.openModal(<ChangePasswordForm userId={userId} token={token} />)
            return null;
        }} />
    )

    privateRoutes.push(
        <Route key="/confirm-email" path="/confirm-email" render={() => {
            const query = new URLSearchParams(history.location.search);
            const userId = query.get('userId') ?? "";
            const token = query.get('emailToken') ?? "";
            accountStore.confirmEmail(userId, token)
                .finally(() => {
                    toastBarStore.showMessage('Your email has been confirmed.', 'success')
                    history.push('profile')
                });
            return <LoadingComponent />;
        }} />
    )

    privateRoutes.push(
        <Route key="/unsubscribe" path="/unsubscribe" render={() => {
            const query = new URLSearchParams(history.location.search);
            const userId = query.get('userId') ?? "";
            accountStore.unsubscribe(userId)
                .finally(() => {
                    toastBarStore.showMessage('You have Unsubscribed.', 'success')
                    history.push('/')
                });
            return <LoadingComponent />;
        }} />
    )


    privateRoutes.push(<SSORoutes key='/SSORoutes' />)
    privateRoutes.push(<ExternalRoutes key='/externalRoutes' />)

    // privateRoutes.push(
    //     <Route key="/rewards" path="/rewards" render={() => {
    //         const query = new URLSearchParams(history.location.search);
    //         const code = query.get('referralCode');
    //         if (code) {
    //             CookieService.setCookie(Cookie.referral, code);
    //         }
    //         return <Rewards />;
    //     }} />
    // )

    const isEditor = accountStore.isEditor || accountStore.isAdmin;
    const isCreator = accountStore.isCreator;
    const isAdmin = accountStore.isAdmin;

    privateRoutes.push(createRoute('/cms', true, <CMS data={data} />, isEditor))
    privateRoutes.push(createRoute('/profile', true, <Profile />))
    privateRoutes.push(createRoute('/creator-profile', true, <CreatorProfile />, isCreator))
    privateRoutes.push(createRoute('/collections-overview', true, <CollectionsOverview />, isEditor))
    privateRoutes.push(createRoute('/admin', true, <Admin />, isAdmin))
    privateRoutes.push(createRoute('/support', false, <Support />))
    privateRoutes.push(createRoute('/privacy-policy', false, <PrivacyPolicy />))

    if (accountStore.user) {
        let message = 'Please confirm your email address';
        if (!accountStore.user.emailConfirmed) {
            toastBarStore.showMessage(message, 'warning', 30000, () => {
                accountStore.generateConfirmEmail()
                    .then(() => {
                        toastBarStore.showMessage('A confirmation link has been sent has been sent. Please check your inbox (and spam)', 'success')
                    })
            }, 'Resend')
        } else if (toastBarStore.message === message) {
    }
        toastBarStore.closeMenu()
    }
    const network = WalletAdapterNetwork.Mainnet;

    const wallets = useMemo(
        () => [
            //TODO: Work out how this works
            // new SolanaMobileWalletAdapter({
            //     appIdentity: { name: 'Material UI Starter App' },
            //     authorizationResultCache: createDefaultAuthorizationResultCache(),
            // }),
            new PhantomWalletAdapter(),
            new GlowWalletAdapter(),
            new SlopeWalletAdapter(),
            new SolflareWalletAdapter({ network }),
            new TorusWalletAdapter(),
        ],
        [network]
    );
    return (
        <>
            <CustomConnectionProvider>
                <WalletProvider wallets={wallets} autoConnect>
                    <ThemeProvider key={`Theme${data.id}`} theme={theme}>
                        <ToastContainer position='bottom-right' hideProgressBar />
                        <ModalContainer />
                        <CssBaseline />
                        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                            <Toolbar disableGutters>
                                <NavBar data={data.navBar} />
                            </Toolbar>
                        </ AppBar>
                        <Box sx={{
                            mt: `${commonStore.navbarHeight}px`
                        }}>
                            <Switch>
                                <Router history={history} >
                                    {privateRoutes}
                                    {publicRoutes}
                                </Router>
                            </Switch>
                            <ToastBar />
                        </Box>
                    </ThemeProvider >
                </WalletProvider>
            </CustomConnectionProvider>
        </>
    )
});

import { APIService, Cookie, CookieService } from ".";
import { AccessCode, Token, UserFormValues, ChangePasswordForm, User } from "../Models/API";
import { Service } from "../Models/Site";

export enum AuthRedirect {
    login = 'login',
    register = 'register',
    connect = 'connect',
    external = 'external'
}
export class AccountService {
    static basePath = "/account";
    static nonce(): string {
        return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
    }

    static baseUrl = process.env.REACT_APP_BASE_URL ?? 'https://nodia.space'
    static isBaseSite() {
        return window.location.host.indexOf(this.baseUrl) !== -1
    }

    public static current() {
        return APIService.requests.get<Token>(this.basePath)
    }

    static redirectQuery = (preferred: AuthRedirect): string => {
        return `redirect=${this.isBaseSite() ?  preferred : AuthRedirect.external}`
    }

    // Register

    public static registerQuery = (domain: string, recaptchaCode: string, referralCode: string): string => {
        let referrer = CookieService.getCookie(Cookie.httpReferrer) ?? 'no referrer'
        return `domain=${domain}&recaptcha=${recaptchaCode}&referralCode=${referralCode}&httpReferer=${referrer}`
    }
    public static externalRegisterQuery = (service: string, domain: string, referralCode: string, recaptchaCode: string): string => {
        return this.registerQuery(domain, recaptchaCode, referralCode) + `&service=${service}&` + this.redirectQuery(AuthRedirect.register)
    }
    public static register(user: UserFormValues, referralCode: string, domain: string, recaptchaCode: string) {
        return APIService.requests.post<Token>(`${this.basePath}/register?${this.registerQuery(domain, recaptchaCode, referralCode)}`, user)
    }
    public static externalRegister(accessCode: AccessCode, service: string, domain: string, referralCode: string, recaptchaCode: string) {
        return APIService.requests.post<Token>(`${this.basePath}/externalRegister?${this.externalRegisterQuery(service, domain, referralCode, recaptchaCode)}`, accessCode)
    }

    // Login
    
    public static externalLoginQuery = (service: string, domain: string, redirect: AuthRedirect): string => {
        return `service=${service}&domain=${domain}&` + this.redirectQuery(redirect)
    }
    public static login(user: UserFormValues, recaptchaCode: string) {
        return APIService.requests.post<Token>(`${this.basePath}/login?recaptcha=${recaptchaCode}`, user)
    }
    public static externalLogin(accessCode: AccessCode, service: string, domain: string, recaptchaCode: string) {
        return APIService.requests.post<Token>(`${this.basePath}/externalLogin?recaptcha=${recaptchaCode}&${this.externalLoginQuery(service, domain, AuthRedirect.login)}`, accessCode)
    }

    // Connect

    public static externalConnect(accessCode: AccessCode, service: string, domain: string) {
        return APIService.requests.post<Token>(`${this.basePath}/externalConnect?${this.externalLoginQuery(service, domain, AuthRedirect.connect)}`, accessCode)
    }
    public static unlinkService(service: Service): Promise<User> {
        return APIService.requests.post(`${this.basePath}/RemoveExternalConnection?externalType=${service}`, {})
    }

    // Utilities

    public static generatePasswordReset(email: string) {
        return APIService.requests.post(`${this.basePath}/GenerateResetPasswordEmail?email=${email}`, {})
    }
    public static changePassword(form: ChangePasswordForm) {
        return APIService.requests.post(`${this.basePath}/ResetPassword`, form)
    }
    public static generateConfirmationEmail() {
        return APIService.requests.post(`${this.basePath}/GenerateConfirmationEmail`, {})
    }
    public static confirmEmail(userId: string, token: string) {
        return APIService.requests.post(`${this.basePath}/ConfirmEmail`, { userId: userId, token: token })
    }
    public static unsubscribe(userId: string) {
        return APIService.requests.post(`${this.basePath}/Unsubscribe?userId=${userId}`, {})
    }

    // URL Generation

    static customDomainLogin(service: Service, redirect: AuthRedirect) {
        return `https://${this.baseUrl}/external-sso?service=${service}&redirectUrl=https://${window.location.host}/external-${redirect}`
    }

    public static twitchLoginUrl(redirect: AuthRedirect = AuthRedirect.login, redirectUrl?: string | null): string {
        CookieService.setCookie(Cookie.creatorDomain, window.location.host);
        CookieService.setCookie(Cookie.redirectUrl, redirectUrl ?? window.location.href);
        if (!this.isBaseSite()) {
            return this.customDomainLogin(Service.twitch, redirect)
        }
        const state = this.nonce();
        let url = `${process.env.REACT_APP_TWITCH_API_BASE_PATH}`;
        url += `?response_type=code`
        url += `&client_id=${process.env.REACT_APP_TWITCH_API_CLIENT_ID}`
        url += `&redirect_uri=https://${process.env.REACT_APP_BASE_URL}/twitch-${redirect}`
        url += `&scope=user:read:subscriptions user:read:email`
        url += `&state=${state}`
        return url;
    }

    public static patreonLoginUrl(redirect: AuthRedirect = AuthRedirect.login, redirectUrl?: string | null): string {
        CookieService.setCookie(Cookie.creatorDomain, window.location.host);
        CookieService.setCookie(Cookie.redirectUrl, redirectUrl ?? window.location.href);
        if (!this.isBaseSite()) {
            return this.customDomainLogin(Service.patreon, redirect)
        }
        const state = this.nonce();
        let url = `${process.env.REACT_APP_PATREON_API_BASE_PATH}`;
        url += `?response_type=code`
        url += `&client_id=${process.env.REACT_APP_PATREON_API_CLIENT_ID}`
        url += `&redirect_uri=https://${process.env.REACT_APP_BASE_URL}/patreon-${redirect}`
        url += `&scope=identity identity%5Bemail%5D identity.memberships campaigns campaigns.members%5Bemail%5D`
        url += `&state=${state}`
        return url
    }

    // public static rallyLoginUrl(redirect: AuthRedirect = AuthRedirect.login, redirectUrl?: string | null) {
    //     CookieService.setCookie(Cookie.creatorDomain, window.location.host);
    //     CookieService.setCookie(Cookie.redirectUrl, redirectUrl ?? window.location.href);
    //     if (!this.isBaseSite()) {
    //         return Promise.resolve({ url: this.customDomainLogin(Service.rally, redirect) })
    //     }
    //     return APIService.requests.get<RedirectUrl>(`${this.basePath}/rallyAuthRedirect?redirect=${redirect}`);
    // }

    public static googleLoginUrl(redirect: AuthRedirect = AuthRedirect.login, redirectUrl?: string | null): string {
        CookieService.setCookie(Cookie.creatorDomain, window.location.host);
        CookieService.setCookie(Cookie.redirectUrl, redirectUrl ?? window.location.href);
        if (!this.isBaseSite()) {
            return this.customDomainLogin(Service.google, redirect)
        }
        const state = this.nonce();
        let url = `${process.env.REACT_APP_GOOGLE_API_BASE_PATH}`;
        url += `?response_type=code`
        url += `&client_id=${process.env.REACT_APP_GOOGLE_API_CLIENT_ID}`
        url += `&redirect_uri=https://${process.env.REACT_APP_BASE_URL}/google-${redirect}`
        url += `&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid`
        url += `&state=${state}`
        url += `&access_type=offline`
        return url;
    }

    // public static instagramLoginUrl(redirect: AuthRedirect = AuthRedirect.connect, redirectUrl?: string | null): string {
    //     CookieService.setCookie(Cookie.creatorDomain, window.location.host);
    //     CookieService.setCookie(Cookie.redirectUrl, redirectUrl ?? window.location.href);
    //     if (!this.isBaseSite()) {
    //         return this.customDomainLogin(Service.instagram, redirect)
    //     }
    //     const state = this.nonce();
    //     let url = `${process.env.REACT_APP_INSTAGRAM_API_BASE_PATH}`;
    //     url += `?response_type=code`
    //     url += `&client_id=${process.env.REACT_APP_INSTAGRAM_API_CLIENT_ID}`
    //     url += `&redirect_uri=https://${process.env.REACT_APP_BASE_URL}/instagram-${redirect}`
    //     url += `&scope=user_profile,user_media`
    //     url += `&state=${state}`
    //     return url;
    // }

    // public static facebookLoginUrl(redirect: AuthRedirect = AuthRedirect.login, redirectUrl?: string | null): string {
    //     CookieService.setCookie(Cookie.creatorDomain, window.location.host);
    //     CookieService.setCookie(Cookie.redirectUrl, redirectUrl ?? window.location.href);
    //     if (!this.isBaseSite()) {
    //         return this.customDomainLogin(Service.facebook, redirect)
    //     }
    //     const state = this.nonce();
    //     let url = `${process.env.REACT_APP_FACEBOOK_API_BASE_PATH}`;
    //     url += `?response_type=code`
    //     url += `&client_id=${process.env.REACT_APP_FACEBOOK_API_CLIENT_ID}`
    //     url += `&redirect_uri=https://${process.env.REACT_APP_BASE_URL}/facebook-${redirect}`
    //     url += `&scope=public_profile,email`
    //     url += `&state=${state}`
    //     return url;
    // }
}

import { APIService } from '.';
import {SplToken, TokenBondingCurveToken} from "../Models/API";

export class SolanaService {
    static basePath = "/solana";

    // static sendPublicKey = (publicKey : string) => {
    //     return APIService.requests.post(`${this.basePath}/PostPublicKey?keypair=${publicKey}`, {})
    // }
    static getSplTokens = () : Promise<SplToken[]> => {
        return APIService.requests.get(`${this.basePath}/GetSplTokens`)
    }
    static getTokenBondingCurveTokens = () : Promise<TokenBondingCurveToken[]> => {
        return APIService.requests.get(`${this.basePath}/GetTokenBondingCurveTokens`)
    }

    static GetSplTokenPrice = (mintAddress: string) : Promise<number> => {
        return APIService.requests.get(`${this.basePath}/GetSplTokenPrice?mintAddress=${mintAddress}`)
    }
}

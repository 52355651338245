import { makeAutoObservable } from "mobx";
import { v4 as uuid } from "uuid";
import { Type, Content } from "..";

export enum VideoService {
    vimeo = "vimeo",
    youtube = "youtube"
};

export class Video implements Content {
    id: string = uuid();
    name: string = "Video";
    type: Type = Type.video;
    index: number = 0;
    width: number = 12;
    title: string = "New Video";
    description: string = "";
    service: VideoService = VideoService.vimeo;
    videoId: string = "";
    thumbnailUrl: string = "";

    constructor() {
        makeAutoObservable(this)
    }
}

export class PlaylistSection implements Content {
    id: string = uuid();
    name: string = "Section";
    type: Type = Type.playlistSection;
    title: string = "New Section";
    index: number = 0;
    width: number = 12;
    videos: Video[] = [];

    constructor() {
        makeAutoObservable(this)
    }
}

export class Playlist implements Content {
    id: string = uuid();
    name: string = "Playlist";
    type: Type = Type.playlist;
    index: number = 0;
    width: number = 12;
    sections: PlaylistSection[] = [];

    constructor() {
        makeAutoObservable(this)
    }
}

export interface PlaylistUpdater {
    name?: string;
    width?: number;
}
export interface PlaylistSectionUpdater {
    name?: string;
    width?: number;
    title?: string;
}
export interface VideoUpdater {
    name?: string;
    width?: number;
    title?: string;
    description?: string;
    service?: VideoService;
    videoId?: string;
    thumbnailUrl?: string;
}
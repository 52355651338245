import React from 'react';
import { observer } from "mobx-react-lite"
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ListItemText from '@mui/material/ListItemText';
import { Box, Button, ClickAwayListener, Menu, Drawer, IconButton, ListItemButton, ListItemIcon, MenuItem, Divider } from '@mui/material';
import { Delete, CopyAll, ContentCopy, ContentPaste, ExpandLess, ExpandMore } from '@mui/icons-material';

import { useStore } from '../../../Stores';
import { Site, Model } from '../../../Models/Site';

interface Props {
    data: Site;
    collectionTemplateMode?: boolean;
}

export const CMSSiteTree = observer(({ data, collectionTemplateMode = false }: Props) => {
    const { cmsStore, cmsNavBarStore, toastBarStore, commonStore } = useStore();
    const { saveSiteAPI } = cmsStore;

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [menuItem, setMenuItem] = React.useState<Model | undefined>();
    const menuOpen = Boolean(anchorEl);

    const saveSite = () => {
        saveSiteAPI().then(() => {
            toastBarStore.showMessage('Your changes have been saved.', 'success')
        })
    }

    const downloadSite = () => {
        const fileData = JSON.stringify(data, null, 4);
        const blob = new Blob([fileData], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = `${data.name}.json`;
        link.href = url;
        link.click();
    }

    const handleContextMenu = (event: React.MouseEvent<HTMLElement>, model: Model) => {
        event.preventDefault();
        setMenuItem(model)
        setAnchorEl(event.currentTarget)
    }

    const closeMenu = () => {
        setAnchorEl(null)
        setMenuItem(undefined)
    }

    const createMenuItem = (title: string, icon: JSX.Element, action: () => void, disabled?: boolean) => {
        return (
            <MenuItem
                sx={{ pointerEvents: 'visiblePainted' }}
                onClick={() => {
                    action()
                    closeMenu()
                }}
                disabled={disabled}
            >
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText>{title}</ListItemText>
            </MenuItem>
        );
    }


    const expandButton = (id: string): React.ReactElement => (
        <IconButton
            key={`IconButton${id}`}
            onClick={(e) => {
                e.stopPropagation();
                cmsNavBarStore.open(id, !cmsNavBarStore.isOpen(id));
            }}>
            {cmsNavBarStore.isOpen(id) ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
    );

    const buildItem = (model: Model, inset: number): React.ReactElement => {
        if (model && model.id.length > 0) {
            return (
                <Box key={`Box${model.id}`}>
                    <ListItemButton
                        key={`ListItemButton${model.id}-${model.index}`}
                        onClick={() => cmsStore.select(model.id)}
                        sx={{ pl: inset }}
                        disabled={cmsStore.editedItemId !== undefined}
                        selected={cmsStore.selectedItemId === model.id}
                        onContextMenu={(e) => handleContextMenu(e, model)}
                    >
                        <ListItemText>{model.name}</ListItemText>
                        {Model.itemsInModel(model).length > 0 ? expandButton(model.id) : null}
                    </ListItemButton>
                    <Collapse
                        key={`Collapse${model.id}`}
                        in={cmsNavBarStore.isOpen(model.id)}
                    >
                        <List key={`List${model.id}`} component="div" disablePadding>
                            {Model.itemsInModel(model).map(
                                function (x) {
                                    return buildItem(x, inset + 4)
                                }
                            )}
                        </List>
                    </Collapse>
                </Box>
            );
        }
        return (
            <></>
        );
    }
    const drawerWidth = 240;

    let fileReader: FileReader
    const handleFileRead = () => {
        const content = fileReader.result;
        const uploadedSite: Site = JSON.parse(content as string)
        cmsStore.setData(uploadedSite)
        cmsStore.save()
    };
    const handleFileChosen = (file: Blob) => {
        fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        fileReader.readAsText(file);
    };

    const uploadButton = (
        <>
            <input
                accept="application/json"
                style={{ display: "none" }}
                id="uploadJSON"
                name="Site JSON"
                type="file"
                onChange={(t) => {
                    if (t.target.files && t.target.files.length > 0) {
                        handleFileChosen(t.target.files[0])
                    }
                }}
            />
            <Button
                sx={{ mx: 2, my: 1 }}
                variant="outlined"
                onClick={() => {
                    document.getElementById("uploadJSON")?.click()
                }}
            >
                Upload Site
            </Button>
        </>
    )
    const canPaste = menuItem
        && cmsNavBarStore.pasteboard
        && Model.canAdd(cmsNavBarStore.pasteboard.type, menuItem.type)
    const showMenu = (model: Model) => {
        // Can add
        if (Model.canCopy(model.type)) return true

        // Can paste
        if (canPaste) return true

        // Can delete
        if (Model.canDelete(model)) return true
        return false
    };
    const dataToUse = collectionTemplateMode ? data.pages[0] : data
    return (
        <Box sx={{
            bgcolor: 'background.paper',
            height: `100%`
        }}>
            <Drawer
                key="CMSNavBarDrawer"
                anchor="left"
                variant="persistent"
                open={cmsNavBarStore.drawerOpen}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' }
                }}
            >
                <List
                    key="CMSNavBarList"
                    sx={{ bgcolor: 'background.paper', pt: `${commonStore.navbarHeight}px` }}
                    component="nav"
                >
                    {buildItem(dataToUse, 2)}
                </List>
                {collectionTemplateMode ? undefined :
                    <>
                        <Button variant='contained' disabled={!cmsStore.hasChanges} sx={{ mx: 2, my: 1 }} onClick={saveSite}>Save Site</Button>
                        <Button variant='outlined' sx={{ mx: 2, my: 1 }} onClick={downloadSite}>Download Site</Button>
                        {uploadButton}
                    </>
                }

                {menuItem && showMenu(menuItem) ?
                    <ClickAwayListener onClickAway={closeMenu}>
                        <Menu
                            id="mouse-over-popover"
                            sx={{
                                width: '100%',
                                pointerEvents: 'none',
                                justifyItems: 'flex-end'
                            }}
                            anchorEl={anchorEl}
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            transformOrigin={{ vertical: "top", horizontal: "right" }}
                            open={menuOpen}
                            onClose={closeMenu}
                            MenuListProps={{
                                'aria-labelledby': 'account-button',
                            }}
                            PaperProps={{
                                style: {
                                    minWidth: 200,
                                    maxWidth: 400
                                }
                            }}
                            elevation={1}
                            disableAutoFocusItem
                            disableRestoreFocus
                            variant='selectedMenu'
                        >
                            {Model.canCopy(menuItem.type) ? createMenuItem('Duplicate', <CopyAll />, () => {
                                cmsStore.duplicate(menuItem.id)
                            }) : undefined}
                            {Model.canCopy(menuItem.type) ? <Divider /> : undefined}
                            {Model.canCopy(menuItem.type) ? createMenuItem('Copy', <ContentCopy />, () => {
                                let copy = Model.copy(menuItem);
                                cmsNavBarStore.setPasteboard(copy)
                            }) : undefined}
                            {canPaste ? createMenuItem('Paste', <ContentPaste />, () => {
                                let copy = cmsNavBarStore.pasteboard
                                if (copy) {
                                    cmsStore.addModel(copy, menuItem.id);
                                    cmsNavBarStore.setPasteboard(Model.copy(copy))
                                }
                            }) : undefined}
                            {Model.canCopy(menuItem.type) && Model.canDelete(menuItem) ? <Divider /> : undefined}
                            {Model.canDelete(menuItem) ? createMenuItem('Delete', <Delete />, () => {
                                let parentId = cmsStore.delete(menuItem.id)
                                if (parentId) {
                                    cmsNavBarStore.open(parentId, true)
                                    cmsStore.select(parentId)
                                }
                            }) : undefined}
                        </Menu>
                    </ClickAwayListener>
                    : undefined}
            </Drawer>
        </Box>
    );
});
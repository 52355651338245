import { Collections, LibraryBooks } from '@mui/icons-material';
import { observer } from "mobx-react-lite";

import { useStore } from "../../Stores";
import { MasterDetail, Page, Section } from '../Common/MasterDetail';
import { CollectionsEditor, TemplatesEditor } from '.';

export const CollectionsOverview = observer(() => {
    const { accountStore, creatorStore } = useStore();
    const { creator } = creatorStore;
    const { user } = accountStore;

    if (!user) {
        accountStore.getUser()
        return (
            <></>
        );
    }
    if (!creator) {
        creatorStore.getCreator({ userId: user.id })
        return (
            <></>
        );
    }

    const collectionsPage = new Page("Collection Editor", "", <Collections />, <CollectionsEditor />);
    const templatesPage = new Page("Template Editor", "", <LibraryBooks />, <TemplatesEditor />);
    const sections = [
        new Section([collectionsPage]),
        new Section([templatesPage])
    ]

    return (
        <MasterDetail sections={sections} />
    )
});
import { Button, Card, CardContent, CardMedia, Container, Stack, Typography } from "@mui/material"
import { Blocked as BlockedModel, Condition as ConditionModel, Service } from "../../../Models/Site"
import { observer } from "mobx-react-lite";
import { useStore } from "../../../Stores";
import React from "react";
import { LoginForm, ServiceLoginButton } from "../../Forms";
import TwitchLogo from "../../../Assets/connectedaccounts/TwitchLogo.png";
import PatreonLogo from "../../../Assets/connectedaccounts/PatreonLogo.png";
import GoogleLogo from "../../../Assets/connectedaccounts/GoogleLogo.png";
import { AuthRedirect } from "../../../Services";

interface Props {
    data: BlockedModel
}

const capitalize = (s: string): string => {
    return s && s[0].toUpperCase() + s.slice(1);
};

export const Blocked = observer(({ data }: Props) => {
    const { accountStore, modalStore } = useStore()

    const requiredCondition = (condition: ConditionModel): string => {
        switch (condition.service) {
            case Service.twitch:
                return `This page requires you to have a ${condition.condition} subscription to ${condition.creatorPath} on Twitch`
            case Service.patreon:
                return `This page requires you to be a ${condition.condition} patron of ${condition.creatorPath} on Patreon`
            // case Service.rally:
            //     return `This page requires you to hold at least ${condition.condition} in your Rally Account`
            case Service.google:
                return `This page requires you to have a Google account connected`
            // case Service.instagram:
            //     return `This page requires you to have an Instagram account connected`
            // case Service.facebook:
            //     return `This page requires you to have an Facebook account connected`
        }
    };

    const userProgressToCondition = (condition: ConditionModel): string => {
        if (condition.isConnected) {
            switch (condition.service) {
                case Service.twitch:
                    return `Current subscription tier: ${condition.userState}`
                case Service.patreon:
                    return `Current patronange level: ${condition.userState}`
                // case Service.rally:
                //     return `Currently holding: ${condition.userState}`
            }
        }
        return `No ${capitalize(condition.service)} account is linked to this account.`
    };

    const imageForCondition = (condition: ConditionModel): string => {
        switch (condition.service) {
            case Service.twitch:
                return TwitchLogo
            case Service.patreon:
                return PatreonLogo
            // case Service.rally:
            //     return RallyLogo
            case Service.google:
                return GoogleLogo
            // case Service.instagram:
            //     return InstagramLogo
            // case Service.facebook:
            //     return FacebookLogo
        }
    }

    const buttonForCondition = (condition: ConditionModel): React.ReactElement => {
        if (condition.isConnected) {
            return (
                <Button variant="contained" sx={{ flex: '0 0 auto' }} onClick={() => {
                    const url = ((): string => {
                        switch (condition.service) {
                            case Service.twitch:
                                return `${process.env.REACT_APP_TWITCH_WEB_BASE_PATH}/${condition.creatorPath}`
                            case Service.patreon:
                                return `${process.env.REACT_APP_PATREON_WEB_BASE_PATH}/${condition.creatorPath}`
                            // case Service.rally:
                            //     return `${process.env.REACT_APP_RALLY_WEB_BASE_PATH}/${condition.creatorPath}`
                            case Service.google:
                                return `${process.env.REACT_APP_GOOGLE_WEB_BASE_PATH}/${condition.creatorPath}`
                            // case Service.instagram:
                            //     return `${process.env.REACT_APP_INSTAGRAM_WEB_BASE_PATH}/${condition.creatorPath}`
                            // case Service.facebook:
                            //     return `${process.env.REACT_APP_FACEBOOK_WEB_BASE_PATH}/${condition.creatorPath}`
                        }
                    })();
                    window.open(url, "_blank")
                }}>
                    {`Go to ${capitalize(condition.service)}`}
                </Button>
            )
        }
        return (
            <ServiceLoginButton
                redirect={AuthRedirect.connect}
                service={condition.service}
                title={`Link ${capitalize(condition.service)} Account`}
                props={{ variant: 'contained', sx: { flex: '0 0 auto' } }} />
        )
    };

    const conditionCard = (title: string, description: string, image: string, button: React.ReactNode) => {
        return (
            <Card sx={{ display: 'flex', m: 2, p: 2, alignItems: 'center', maxWidth: '1100px', minWidth: '450px', width: '75%' }}>
                {
                    image ?
                        <CardMedia
                            height='100%'
                            component="img"
                            sx={{ width: '150px', px: 1 }}
                            image={image}
                            alt={title}
                        />
                        : undefined
                }
                <CardContent sx={{ flex: '1 1 auto' }}>
                    <Typography variant="h5">
                        {title}
                    </Typography>
                    <Typography component="div" variant="body1">
                        {description}
                    </Typography>
                </CardContent>
                {button}
            </Card>
        )
    }

    const conditions = data.conditions.map(condition => {
        return conditionCard(
            requiredCondition(condition),
            userProgressToCondition(condition),
            imageForCondition(condition),
            buttonForCondition(condition)
        )
    })
    return (
        <Stack direction='column' alignItems='center'>
            <Container>
                <Typography variant="h1" sx={{ backgroundImage: 'none', textAlign: 'center', color: 'white' }} >
                    This Page is Locked
                </Typography>
            </Container>
            {accountStore.isLoggedIn
                ? conditions
                : conditionCard(
                    'Please log in',
                    'You must be logged in to view this page.',
                    '',
                    <Button variant='contained' onClick={() => modalStore.openModal(<LoginForm />)}>Log in</Button>
                )}
        </Stack>
    )
});

import { Button, Card, CardContent, CardMedia, List, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

import TwitchLogo from "../../Assets/connectedaccounts/TwitchLogo.png";
import PatreonLogo from "../../Assets/connectedaccounts/PatreonLogo.png";
import GoogleLogo from "../../Assets/connectedaccounts/GoogleLogo.png";
import { Service } from "../../Models/Site";
import { AccountService, AuthRedirect } from "../../Services";
import { useStore } from "../../Stores";

export const ConnectedAccounts = observer(() => {
    const { accountStore } = useStore();
    const { user } = accountStore;

    if (!user) {
        accountStore.getUser()
        return (
            <></>
        );
    }

    const url = (service: Service): string => {
        switch (service) {
            case Service.twitch:
                return process.env.REACT_APP_TWITCH_WEB_BASE_PATH ?? "";
            case Service.patreon:
                return process.env.REACT_APP_PATREON_WEB_BASE_PATH ?? "";
            // case Service.rally:
            //     return process.env.REACT_APP_RALLY_WEB_BASE_PATH ?? "";
            case Service.google:
                return process.env.REACT_APP_GOOGLE_WEB_BASE_PATH ?? "";
            // case Service.instagram:
            //     return process.env.REACT_APP_INSTAGRAM_WEB_BASE_PATH ?? "";
            // case Service.facebook:
            //     return process.env.REACT_APP_FACEBOOK_WEB_BASE_PATH ?? "";
        }
    };

    const toggleAccount = async (service: Service, isConnected: boolean) => {
        if (isConnected) {
            await accountStore.unlinkService(service)
            return
        }
        switch (service) {
            case Service.twitch:
                window.location.href = AccountService.twitchLoginUrl(AuthRedirect.connect)
                return;
            case Service.patreon:
                window.location.href = AccountService.patreonLoginUrl(AuthRedirect.connect)
                return;
            case Service.google:
                window.location.href = AccountService.googleLoginUrl(AuthRedirect.connect)
                return;
            // case Service.instagram:
            //     window.location.href = AccountService.instagramLoginUrl(AuthRedirect.connect)
            //     return;
            // case Service.facebook:
            //     window.location.href = AccountService.facebookLoginUrl(AuthRedirect.connect)
            //     return;
            // case Service.rally:
            //     AccountService.rallyLoginUrl(AuthRedirect.connect).then((redirect: RedirectUrl) => {
            //         window.location.href = redirect.url;
            //     });
            //     break;
        }
    };
    const accountCard = (label: string, title: string, image: string, connected: boolean, service: Service) => {
        return (
            <Card sx={{ display: 'flex', mt: 5 }}>
                <CardMedia
                    height='100%'
                    component="img"
                    sx={{ width: '150px', alignSelf: 'center', px: 1 }}
                    image={image}
                    alt={label}
                />
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography variant="subtitle1" color="text.secondary" component="a" href={url(service)} target='_blank'>
                        {label}
                    </Typography>
                    <Typography component="div" variant="h5">
                        {title}
                    </Typography>
                </CardContent>
                <Button variant={connected ? 'outlined' : 'contained'} sx={{ m: 3, width: '150px' }} onClick={() => toggleAccount(service, connected)}>{connected ? 'Disconnect' : 'Connect'}</Button>
            </Card>
        );
    }
    const twitchConnected = user.twitchUser ? user.twitchUser.id !== null : false
    const patreonConnected = user.patreonUser ? user.patreonUser.id !== null : false
    // const rallyConnected = user.rallyUser ? user.rallyUser.username !== null : false
    const googleConnected = user.googleUser ? user.googleUser.id !== null : false
    // const instagramConnected = user.instagramUser ? user.instagramUser.id !== null : false
    // const facebookConnected = user.facebookUser ? user.facebookUser.id !== null : false

    let twitchTitle = 'twitch.tv'
    let twitchBody = 'Not Connected'
    if (twitchConnected && user.twitchUser) {
        twitchTitle = user.twitchUser.id
        twitchBody = user.twitchUser.email
    }
    const twitchAccount = accountCard(
        twitchTitle,
        twitchBody,
        TwitchLogo,
        twitchConnected,
        Service.twitch
    )

    let patreonTitle = 'patreon.com'
    let patreonBody = 'Not Connected'
    if (patreonConnected && user.patreonUser) {
        patreonTitle = user.patreonUser.id
        patreonBody = user.patreonUser.email
    }
    const patreonAccount = accountCard(
        patreonTitle,
        patreonBody,
        PatreonLogo,
        patreonConnected,
        Service.patreon
    )
    //
    // let rallyTitle = 'rally.io'
    // let rallyBody = 'Not Connected'
    // if (rallyConnected && user.rallyUser) {
    //     rallyTitle = user.rallyUser.username
    //     rallyBody = user.rallyUser.emailAddress
    // }
    // const rallyAccount = accountCard(
    //     rallyTitle,
    //     rallyBody,
    //     RallyLogo,
    //     rallyConnected,
    //     Service.rally
    // )

    let googleTitle = 'google.com'
    let googleBody = 'Not Connected'
    if (googleConnected && user.googleUser) {
        googleTitle = user.googleUser.id
        googleBody = user.googleUser.email
    }
    const googleAccount = accountCard(
        googleTitle,
        googleBody,
        GoogleLogo,
        googleConnected,
        Service.google
    )
    //
    // let instagramTitle = 'instagram.com'
    // let instagramBody = 'Not Connected'
    // FIX ME
    // if (instagramConnected && user.instagramUser) {
    //     instagramTitle = user.instagramUser.id
    //     instagramBody = user.instagramUser.email
    // }
    // const instagramAccount = accountCard(
    //     instagramTitle,
    //     instagramBody,
    //     InstagramLogo,
    //     instagramConnected,
    //     Service.instagram
    // )
    //
    // let facebookTitle = 'facebook.com'
    // let facebookBody = 'Not Connected'
    // if (facebookConnected && user.facebookUser) {
    //     facebookTitle = user.facebookUser.id
    //     facebookBody = user.facebookUser.email
    // }
    // const facebookAccount = accountCard(
    //     facebookTitle,
    //     facebookBody,
    //     FacebookLogo,
    //     facebookConnected,
    //     Service.facebook
    // )

    return (
        <List>
            {twitchAccount}
            {patreonAccount}
            {/*{rallyAccount}*/}
            {googleAccount}
            {/* {instagramAccount} */}
            {/*{facebookAccount}*/}
        </List>
    );
});

import { ListItem, ListItemIcon, ListItemText, Box, Drawer, Toolbar, List, Divider, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { v4 as uuid } from "uuid";
import { useStore } from "../../Stores";
import Consts from "./Consts";

export class Section {
    id: string;
    pages: Page[]

    constructor(pages: Page[]) {
        this.id = uuid()
        this.pages = pages;
    }
}

export class Page {
    id: string;
    primary: string;
    secondary: string;
    icon: JSX.Element;
    content: React.ReactNode;

    constructor(primary: string, secondary: string, icon: JSX.Element, content: React.ReactNode) {
        this.id = uuid()
        this.primary = primary;
        this.secondary = secondary;
        this.icon = icon;
        this.content = content;
    }
}

interface Props {
    sections: Section[]
    width?: number,
    children?: React.ReactNode
}

// because its JS innit
const magicNumber = 70

export const MasterDetail = observer(({ width = Consts.drawerWidth, sections, children }: Props) => {
    const { commonStore } = useStore() 
    const [currentPage, setCurrentPage] = React.useState(sections[0].pages[0]) 
    if(!sections.flatMap(s=>s.pages).find(p=>p.id === currentPage.id)) {
        setCurrentPage(sections[0].pages[0])
    }

    const listItem = (page: Page) => {
        return (
            <ListItem key={page.id} button onClick={() => setCurrentPage(page)} selected={currentPage === page} sx={{ py: 2 }} >
                <ListItemIcon>
                    {page.icon}
                </ListItemIcon>
                <ListItemText primary={page.primary} secondary={page.secondary} />
            </ListItem>
        )
    }
    const section = (section: Section) => {
        return (
            <Box key={section.id}>
                {section.pages.map((page) => listItem(page))}
                <Divider />
            </Box>
        );
    }
    return (
        <Box sx={{ display: 'flex' }}>
            <Drawer
                variant="permanent"
                sx={{
                    width: width,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: width,  },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto', pt: `${commonStore.navbarHeight - magicNumber}px` }}>
                    <List>
                        {sections.map((s) => section(s))}
                        {children}
                    </List>
                </Box>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Typography variant="h2" textAlign='center'>{currentPage.primary}</Typography>
                {currentPage.content}
            </Box>
        </Box>
    );
})
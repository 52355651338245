import { makeAutoObservable, } from "mobx"

export class MenuStore {
    open: boolean = false;
    anchorEl: HTMLElement | null = null;

    constructor() {
        makeAutoObservable(this)
    }

    openMenu = (event: React.MouseEvent<HTMLElement>) => {
        this.open = true;
        this.anchorEl = event.currentTarget;
    }

    closeMenu = () => {
        this.open = false;
        this.anchorEl = null;
    }
}
import { Dialog, Box, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

interface Props {
    open: boolean;
    title?: string;
    message?: string;
    cancelText?: string;
    confirmText?: string;
    onClose: () => void;
    onConfirm: () => void;
}

export const ConfirmationDialog = ({ open, title = "Are you sure?", message, cancelText = "Cancel", confirmText = "Confirm", onClose, onConfirm }: Props) => {
    return (
        <Dialog onClose={onClose} open={open}>
            <Box sx={{ width: '320px' }}>
                <DialogTitle>Are you sure?</DialogTitle>
                { message ? <DialogContent>{message}</DialogContent> : undefined }
                <DialogActions>
                    <Button variant='contained' onClick={onClose}>{cancelText}</Button>
                    <Button variant='outlined' color='error' onClick={() => {
                        onConfirm()
                        onClose()
                    }}>{confirmText}</Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
import { APIService } from '.';
import { Collection, Template, TemplateData, CollectionKey, InflatedCollection, InflatedCollectionSearchParam, CollectionData, TemplateDataDTO, NFTCollectionDTO } from '../Models/API/Collections';
import { Content, Model } from '../Models/Site';

interface Parameters {
    collectionId?: string,
    siteId?: string,
    templateId?: string
}

export class CollectionService {
    static basePath = "/Collection";

    private static parameters = (parameters: Parameters): string => {
        var parameterString = '';
        if (parameters.collectionId) parameterString += `collectionId=${parameters.collectionId}`
        if (parameters.templateId) parameterString += `${parameterString ? '&' : ''}templateId=${parameters.templateId}`
        if (parameters.siteId) parameterString += `${parameterString ? '&' : ''}siteId=${parameters.siteId}`
        return parameterString
    }

    private static pagingParams = (page: number, perPage: number) => `pageNumber=${page}&pageSize=${perPage}`

    // Collections

    static getList = (siteId: string) => {
        return APIService.requests.get<Collection[]>(`${this.basePath}/GetList?${this.parameters({ siteId: siteId })}&creatorCollections=${true}`)
    }

    static getInflated = (collectionId: string, templateId: string, siteId: string, page: number, perPage: number = 10, param: InflatedCollectionSearchParam) => {
        return APIService.requests.post<InflatedCollection>(
            `${this.basePath}/GetInflated?${this.parameters({ collectionId: collectionId, siteId: siteId, templateId: templateId })}&${this.pagingParams(page, perPage)}`,
            param
        )
    }

    static getInflatedCard = (cardId: string, collectionId: string, templateId: string, siteId: string) => {
        return APIService.requests.get<Content>(`${this.basePath}/GetInflatedCard?${this.parameters({ collectionId: collectionId, siteId: siteId, templateId: templateId })}&cardId=${cardId}`)
    }

    static getData = (collectionId: string, siteId: string) => {
        return APIService.requests.get<CollectionData>(`${this.basePath}/GetData?${this.parameters({ collectionId: collectionId, siteId: siteId })}`)
    }

    static getKeys = (siteId: string, collectionId: string) => {
        return APIService.requests.get<CollectionKey[]>(`${this.basePath}/GetKeys?${this.parameters({ siteId: siteId, collectionId: collectionId })}`)
    }

    static updateKey = (siteId: string, collectionId: string, key: { id: string, name: string, starred: boolean }) => {
        return APIService.requests.put<CollectionKey>(`${this.basePath}/UpdateKey?${this.parameters({ siteId: siteId, collectionId: collectionId })}`, key)
    }

    static createCollection = (siteId: string) => {
        return APIService.requests.post(`${this.basePath}/CreateCollection?${this.parameters({ siteId: siteId })}`, {})
    }

    static createNFTCollection = (creatorId: string, walletAddress: string, dto: NFTCollectionDTO) => {
        return APIService.requests.post(`${this.basePath}/CreateNFTCollection?walletAddress=${walletAddress}&creatorId=${creatorId}`, dto)
    }

    static updateCollection = (collectionId: string, siteId: string) => {
        return APIService.requests.put(`${this.basePath}/UpdateCollection?${this.parameters({ collectionId: collectionId, siteId: siteId })}`, {})
    }

    static deleteCollection = (collectionId: string, siteId: string) => {
        return APIService.requests.del(`${this.basePath}/DeleteCollection?${this.parameters({ collectionId: collectionId, siteId: siteId })}`)
    }

    // Templates

    static getTemplateList = (siteId: string, collectionId: string) => {
        return APIService.requests.get<Template[]>(`${this.basePath}/GetTemplatesList?${this.parameters({ siteId: siteId, collectionId: collectionId })}`)
    }

    static getTemplateData = (siteId: string, collectionId: string, templateId: string) => {
        return APIService.requests.get<TemplateDataDTO>(`${this.basePath}/GetTemplateData?${this.parameters({ siteId: siteId, collectionId: collectionId, templateId: templateId })}`).then(data => {
            // data.content = Model.resetIds(data.content as Content) as Content
            // return data
            let returnValue = new TemplateData()
            returnValue.id = data.id
            returnValue.name = data.name
            returnValue.height = data.height
            returnValue.width = data.width
            const baseContent = JSON.parse(data.content) as Content
            returnValue.content = Model.resetIds(baseContent) as Content
            return returnValue
        })
    }

    static createTemplate = (siteId: string, collectionId: string, templateName: string, template: Content) => {
        const uploadData = Model.templateIds(template, true)
        return APIService.requests.post<Template>(`${this.basePath}/CreateTemplate?${this.parameters({ siteId: siteId, collectionId: collectionId })}&templateName=${templateName}`, uploadData)
    }

    static updateTemplate = (siteId: string, collectionId: string, template: TemplateData) => {
        if (template.content) {
            const uploadData = Model.templateIds(template.content, true)
            const query = `${this.parameters({ siteId: siteId, collectionId: collectionId, templateId: template.id })}&name=${template.name}&width=${template.width}&height=${template.height}`
            return APIService.requests.put<Template>(`${this.basePath}/UpdateTemplate?${query}`, uploadData)
        }
        throw new Error('No Content')
    }

    static deleteTemplate = (templateId: string) => {
        return APIService.requests.del(`${this.basePath}/DeleteTemplate?${this.parameters({ templateId: templateId })}`)
    }
}
import { makeAutoObservable } from 'mobx';
import { Type, Content } from '.';

export class Footer implements Content {
    id: string = "";
    name: string = "";
    type: Type = Type.footer;
    index: number = 0;
    width: number = 12;
    content: Content[] = [];

    constructor() {
        makeAutoObservable(this)
    }
}

export interface FooterUpdater {
    name?: string;
}
import {observer} from "mobx-react-lite";
import {Grid, Paper, Table, TableRow, Typography} from "@mui/material";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import React from "react";
import {useStore} from "../../../../Stores";

export const RallySwapPriceInfo = observer (() => {
    const {swapStore} = useStore()
    const {sourceToken, sourceTokenPrice, destinationToken, destinationTokenPrice} = swapStore

    return (
        <Paper elevation={3}>
            <Typography align={'center'}
                        sx={{marginTop: 4}}
            >Current Token Prices</Typography>
            <Grid container sx={{justifyContent: "center"}}>
                <Grid item>
                    <Table sx={{
                        mb:3,
                        [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none"
                        }
                    }}>
                        <TableRow>
                            <TableCell padding={"none"}
                                       sx={{width: 40}}>
                                <img src={"https://cdn-icons-png.flaticon.com/512/4583/4583966.png"}
                                     width={"40"}
                                     alt={"Source Token Image"}
                                     height={"40"}/>
                            </TableCell>
                            <TableCell sx={{width: "25ch"}}>
                                <Typography fontWeight={'bold'}>
                                    {(sourceToken.ticker!="") ? sourceToken.ticker : "Choose a token"}
                                </Typography>
                                <Typography>
                                    {sourceToken.name}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography fontWeight={'bold'}>
                                    {sourceTokenPrice ? "$" + sourceTokenPrice.toFixed(3).toString() : "Choose Source Token"}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding={"none"}
                                       sx={{width: 40}}>
                                <img src={"https://cdn-icons-png.flaticon.com/512/4583/4583966.png"}
                                     width={"40"}
                                     alt={"Destination Token Image"}
                                     height={"40"}/>
                            </TableCell>
                            <TableCell sx={{width: "25ch"}}>
                                <Typography fontWeight={'bold'}>
                                    {(destinationToken.name!="") ? destinationToken.ticker : "Choose a token"}
                                </Typography>
                                <Typography>
                                    {destinationToken.name}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography fontWeight={'bold'}>
                                    {destinationTokenPrice ? "$" + destinationTokenPrice.toFixed(3).toString() : "Choose Destination Token"}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </Table>
                </Grid>
            </Grid>
        </Paper>
    )
})

import { makeAutoObservable } from "mobx";
import { v4 as uuid } from "uuid";
import { Type, Content } from "..";

export class HTML implements Content {
    id: string = uuid();
    name: string = "HTML";
    type: Type = Type.html;
    index: number = 0;
    rawhtml: string = "Raw Html";
    width: number = 12;

    constructor() {
        makeAutoObservable(this)
    }
}

export interface HTMLUpdater {
    name?: string;
    rawhtml?: string;
    width?: number;
}
import { makeAutoObservable } from 'mobx';
import { v4 as uuid } from "uuid";
import { Model, Type } from '.';
 
export class NavBarItem implements Model {
    id: string = uuid();
    name: string = "New Item";
    type: Type = Type.navBarItem;
    index: number = 0;
    path: string = "/";
    text: string = "New Item";
    subRoutes: NavBarItem[] = [];

    constructor() {
        makeAutoObservable(this)
    }
}

export interface NavBarItemUpdater {
    name?: string;
    path?: string;
    text?: string;
}
import { Stack, Box, Card, CardHeader, CardContent, List, ListItem, IconButton, CircularProgress, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { Language, Delete, Refresh, Add } from '@mui/icons-material';
import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";

import { useStore } from "../../Stores";
import { CreatorDomain } from "../../Models/Admin/Creator";
import Consts from "../Common/Consts";

export const CreatorDomains = observer(() => {
    const { creatorStore } = useStore();
    const { domains } = creatorStore;

    const [addDomainOpen, setAddDomainOpen] = React.useState(false);
    const [refreshing, setRefreshing] = React.useState(false);
    const [domain, setDomain] = React.useState('');

    const handleCloseDialog = () => {
        setAddDomainOpen(false)
    }
    const openAddDomainDialog = () => {
        setAddDomainOpen(true)
    }
    const addDomain = () => {
        if (domain) {
            setAddDomainOpen(false)
            setDomain('')
            setRefreshing(true)
            creatorStore.addDomain(domain).finally(() => {
                setRefreshing(false)
            })
        }
    }
    const refreshDomains = () => {
        if (refreshing) { return }
        setRefreshing(true)
        creatorStore.getDomains().finally(() => {
            setRefreshing(false)
        })
    }

    useEffect(() => {
        refreshDomains()
    }, []) // Run once (dont delete it plz)

    const deleteDomain = (domain: string) => {
        setRefreshing(true)
        creatorStore.deleteDomain(domain).finally(() => {
            setRefreshing(false)
        })
    }
    const listItem = (domain: CreatorDomain) => {
        return (
                <ListItem
                    sx={{
                        mb: 2,
                        borderBottom: '1px solid rgba(255, 255, 255, 0.25)'
                    }}
                    key={domain.domain}
                    secondaryAction={
                        <IconButton edge="end" aria-label="delete" onClick={() => deleteDomain(domain.domain)}>
                            <Delete />
                        </IconButton>
                    }>
                    {domain.domain}
                </ListItem>
        )
    }

    const items = domains ? domains.map(listItem) : []
    const content = (() => {
        if (refreshing) {
            return (
                <Box display='flex' flexDirection='row' paddingX={1} paddingY={2}>
                    <Typography variant='body1' color='primary.main' sx={{ flex: 1 }}>Loading domains</Typography>
                    <CircularProgress sx={{ flex: '0 1 auto' }} size={25} color="primary" />
                </Box>
            )
        } else if (items.length > 0) {
            return (
                <List>
                    {items}
                </List>
            )
        } else {
            return (
                <Typography variant='body1' color='primary.main' sx={{ flex: 1 }}>No domains</Typography>
            )
        }
    })()

    return (
        <Stack direction='column' alignItems='center'>
            <Box sx={Consts.containerSX}>
                <Card sx={{ display: 'flex', flexDirection: 'column', mt: 5, alignItems: 'leading' }} >
                    <CardHeader
                        avatar={<Language color='primary' />}
                        title="Domains"
                        action={
                            <>
                                <IconButton aria-label="add" onClick={openAddDomainDialog}>
                                    <Add />
                                </IconButton>
                                <IconButton aria-label="settings" onClick={refreshDomains} disabled={refreshing}>
                                    <Refresh />
                                </IconButton>
                            </>
                        }
                    />
                    <CardContent sx={{ flex: '1 0 auto', display: 'flex', flexDirection: 'column' }}>
                        {content}
                    </CardContent>
                </Card>
            </Box>
            <Dialog open={addDomainOpen} onClose={handleCloseDialog}>
                <Box sx={{ width: '400px' }}>
                    <DialogTitle>Add Domain</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="domain"
                            label="Domain"
                            fullWidth
                            variant="standard"
                            value={domain}
                            onChange={(e) => setDomain(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>Cancel</Button>
                        <Button variant='contained' onClick={addDomain}>Add Domain</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </Stack>
    )
});